import React from 'react'
import { TreeSelect } from 'antd'
import 'antd/dist/antd.css'
import axios from 'axios'
import secureStorage from '../../../tools/SecureStorage'
import { useState } from 'react'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'

const { SHOW_PARENT } = TreeSelect;

export default function MultiSelectTree(props) {
    const intl = useIntl()
    const [treeData, setTreeData] = useState([])

    const getLocations = () => {
        axios.post('/locations/', {},
          {
              headers: {
                  "Authorization": `Bearer ${secureStorage.getItem("access")}`,
                  'content-type': 'application/json'
              }
          }
        ).then(res => {
            setTreeData(res.data)
        }).catch(error => console.log(error))
    }
    
    useEffect(() => getLocations(), [])
    useEffect(() => console.clear(), [treeData])
    
    return(
        <TreeSelect 
            treeData={treeData}
            showSearch={true}
            value={props.value}
            onChange={props.onChange}
            // treeCheckable
            showCheckedStrategy={SHOW_PARENT}
            placeholder={intl.formatMessage({id: "filter.placeholder"})}
            style={{width: "100%"}}
        />
    )
}
