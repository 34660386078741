import React from 'react'
import DecoracionOndaBottom from '../decoraciones/DecoracionOndaBottom'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que contiene el header de cada pagina publica
   -----------------------------------------------------------------------------------------------
*/
class Footer extends React.Component{
    render(){
      return (
          <footer className="text-center mt-3 pb-0 d-flex align-items-center">
            <DecoracionOndaBottom id="DecoracionBottom" />
          </footer>
      )
    }
}
export default Footer