import React from 'react'
import { Route, Switch } from 'react-router-dom'

/* 
   -----------------------------------------------------------------------------------------------
   Switch con las rutas para usuarios de tipo Manager
   -----------------------------------------------------------------------------------------------
*/
export default function ManagerUser(){
    return(
        <Switch>
            <Route exact path="/u/">
                <h1>Entraste al Dashboard Manager</h1>
            </Route>
        </Switch>
    )
  }