import React from "react"
import { IntlProvider } from "react-intl"
import {locale} from './locale'
import CookieTools from '../tools/cookies'
/* 
   -----------------------------------------------------------------------------------------------
    Proveedor del idioma selecto mediante la funcionalidad de react "Contexto"    
   -----------------------------------------------------------------------------------------------
*/

// Creamos un contexto 
const Context = React.createContext()

class IntlProviderWrapper extends React.Component {
  constructor(...args) {
    super(...args)

    // Manejamos cookies para no perder la preferencia de idiomas
    this.ct = new CookieTools()

    // Cambia el idioma al ingles
    this.switchToEnglish = () => {
      // Establece el idioma ingles y carga los mensajes del objeto "locale" en locale.js
      this.setState({ locale: "en", messages: locale.en })
      // Comprobamos si existe una cookie del idioma
      if(this.ct.getCookie("userLang") === "es" || this.ct.getCookie("userLang") === ""){
        // Sobreescribimos la cookie con la preferencia en Ingles
        this.ct.setCookie("userLang","en", 365)
      }
    }

    // Cambia el idioma al español
    this.switchToSpanish = () => {
      // Establece el idioma español y carga los mensajes del objeto "locale" en locale.js
      this.setState({ locale: "es", messages: locale.es })
      // Comprobamos si existe una cookie del idioma
      if(this.ct.getCookie("userLang") === "en" || this.ct.getCookie("userLang") === ""){
        // Sobreescribimos la cookie con la preferencia en Español
        this.ct.setCookie("userLang","es", 365)
      }
    }

    // Buscamos la cookie de preferencia de idioma
    let userLang = this.ct.getCookie("userLang")

    // Si no existe la establece a Ingles como predeterminado
    // si hay preferencia el idioma sera el recibido en la linea 44
    if(userLang === ""){
      userLang = "en"
    }

    // Establecemos el estado final, con la data para la I18N y pasamos 
    // las funciones de switch para poder cambiar el idioma en componentes hijos
    this.state = {
      locale: userLang,
      messages: locale[userLang],
      switchToEnglish: this.switchToEnglish,
      switchToSpanish: this.switchToSpanish
    }
  }

  render() {
    const { children } = this.props
    const { locale, messages } = this.state
    return (
      <Context.Provider value={this.state}>
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="en"
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    )
  }
}

export { IntlProviderWrapper, Context as IntlContext }
