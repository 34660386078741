import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Titulo from '../compartidos/tools/Titulo'
import { FormattedMessage } from 'react-intl'
import secureStorage from '../../tools/SecureStorage'
import ButtonLoader from '../compartidos/tools/ButtonLoader'
import UsersInput from './UsersInput'
import { Dropdown } from 'primereact/dropdown';
import ReactGA from 'react-ga'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que muestra el balance de cualquier usuario dado su M2 USERNAME
   -----------------------------------------------------------------------------------------------
*/
export default function UserBalance(){
    const [loader, setLoader] = useState(false) 
    const [callback, setCallback] = useState({})
    const [user, setUser] = useState("")
    const [currency, setCurrency] = useState("DEFAULT")
    const currency_value = useRef()

    useEffect(() => {ReactGA.pageview(window.location.pathname);}, [])

    const currency_list = [
        {label: "User's Currency", value: "DEFAULT"},
        {label: "COP", value: "COP"},
        {label: "USD", value: "USD"},
        {label: "EUR", value: "EUR"}
    ]

    const getUrl = () => {
        let url = "/api/v1/get/profile/?p=user_balance_get"
        // eslint-disable-next-line
        if(user !== '' && !user.match(/[^\w\s\-_,ñ\[\]]/g)){
            url += `&username=${user.trim()}`
        }
        if(currency !== "DEFAULT"){
            url += `&currency=${currency}`
        }
        return url;
    }

    // Funcion que hace la peticion parar mostrar el balance del usuario
    const _getUserBalance = (e) => {
        e.preventDefault()
        // Borramos el callback mostrado en la operacion anterior
        setCallback({})
        setLoader(true)

        axios.get(getUrl(),
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`
                }
            }
        ).then(res => {
            let respuesta = res.data
            // En caso de que la respuesta sea un error
            if(JSON.stringify(respuesta).match(/"(error|message)":"((\\"|[^"])*)"/i)){
                respuesta = JSON.stringify(respuesta).match(/"(error|message)":"((\\"|[^"])*)"/i)
                respuesta = respuesta[0].match(/"([\w\s])+"/g)
                setCallback({c_user: "Error", respuesta: "error", error: respuesta[1]})
            // Si todo sale bien al hacer la peticion
            }else{
                setCallback({c_user: user, respuesta: "ok", ...res.data.page})
                currency_value.current = currency
            }
            setLoader(false)
        }).catch(error => {
            setLoader(false)
            // Mostramos un error si ocurre algo del lado del servidor
            setCallback({c_user: "Error", respuesta: "error", error: "forms.signUp.error"})
        })
    }

    return(
        <>
            <Titulo>
                <FormattedMessage id="header.menu.userBalance"/>
            </Titulo>

            <form method="GET" className="mb-3" onSubmit={(e) => _getUserBalance(e)}>
                <div className="row align-items-end justify-content-center">

                    <div className="col-12 col-sm-6 col-md-4 mb-2 mb-sm-0">
                        <label htmlFor="u-balance-autoclomplete" className="p-d-block">
                            <FormattedMessage id="details.placeholder.username"/>:
                        </label>
                        <UsersInput value={user} onChange={e => setUser(e.value)}/>
                    </div>

                    <div className="col-6 col-sm-3 col-md-2">
                        <label className="p-d-block">
                            <FormattedMessage id="currency"/>:
                        </label>
                        <Dropdown className="w-100 p-inputtext-sm" value={currency} options={currency_list} onChange={(e) => setCurrency(e.value)}/>
                    </div>

                    <div className="col-6 col-sm-3 col-md-2">
                        <ButtonLoader
                            loader={loader} className="button-tooltip-loader btn btn-sm btn-block btn-ariatel--primary" 
                            type="submit" style={{minHeight: '33px'}} 
                            onClick={(e) => _getUserBalance(e)} tooltipPlace="top"
                        >
                            <FormattedMessage id="calls.search" />
                        </ButtonLoader> 
                    </div>
                </div>
            </form>

            {/* Lugar donde saldra el callback cuando llegue la respuesta del servidor */}
            <div style={{minHeight: '30vh'}}>
                {callback.respuesta ? 
                <Checker respuesta={callback.respuesta} 
                    error={callback.error} 
                    balance={callback.balance}
                    currency={callback.currency || currency_value.current}
                    c_user={callback.c_user}
                   />
                : null}
            </div>
        </> 
    )
}

/* 
   -----------------------------------------------------------------------------------------------
    Componente que muestra un formato segun la data que le pasemos por sus props,
    la usamos para asignar varios formatos a las respuestas que nos de el servidor
   -----------------------------------------------------------------------------------------------
*/
const Checker = (props) => {
    return (
        <>
            {props.respuesta === "error" ?

                <section className="row justify-content-center mb-3">
                    <div className="col-12 col-sm-8 col-md-6 col-xl-4">
                        <div className="card shadow-sm user-details-card-tabs">
                            <div className="card-body pt-1 pb-2">
                                <h4 className="my-0 py-1 text-center border-bottom text-muted font-weight-bold">
                                    {props.c_user || "Error"}
                                </h4>
                                <h6 className="text-center text-secondary mt-3 mb-1 font-weight-light">
                                    <FormattedMessage id={
                                        props.error.replace(/"/g, "") === "Incorrect hash" ?
                                        "payments." + props.error.replace(/"/g, "") :
                                        props.error.replace(/"/g, "")
                                    }/>
                                </h6>
                            </div>
                        </div>
                    </div>
                </section> :
                <section className="row justify-content-center mb-3">
                    <div className="col-12 col-sm-8 col-md-6 col-xl-4">
                        <div className="card shadow-sm user-details-card-tabs">
                            <div className="card-body py-1">
                                <h4 className="my-0 py-1 text-center border-bottom text-muted font-weight-normal">
                                    {props.c_user}
                                </h4>
                                <h6 className="text-center text-secondary mt-3 mb-1 font-weight-light">
                                    <FormattedMessage id="currentBalance"/>
                                </h6>
                                <h6 className="text-center text-secondary my-1 font-weight-bold">
                                    {props.balance} {props.currency}
                                </h6>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}