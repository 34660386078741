import React, { useContext } from 'react'
import { useState } from 'react'
import {useIntl} from 'react-intl'
import { AutoComplete } from 'primereact/autocomplete'
import { AuthContext } from '../AuthContext'
import CryptoTools from '../../tools/crypto_tools'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que permite ver los datos de un usuario dado su M2 USERNAME
   -----------------------------------------------------------------------------------------------
*/
export default function UsersInput(props) {
    const intl = useIntl()
    const crypto = new CryptoTools()
    const [fUsers, setFUsers] = useState(null)
    const users = JSON.parse(crypto.desencriptar(
        useContext(AuthContext).m2.users_disponibles)).map((el)=>{
            return el["username"]
    })

    // Funcion que filtra los usuarios disponibles
    const searchUsers = (event) => {
        setTimeout(() => {
            let filteredUsers;
            if (!event.query.trim().length) {
                filteredUsers = [...users]
            } else {
                filteredUsers = users.filter((user) => {
                    return (user.toLowerCase().startsWith(event.query.toLowerCase()) || user.toLowerCase().includes(event.query.toLowerCase().trim()))
                })
            }

            setFUsers(filteredUsers)
        }, 250);
    }

    return(
        <AutoComplete value={props.value} suggestions={fUsers} 
            name={props.name}
            inputStyle={props.style}
            inputClassName={`p-inputtext-sm ${props.className}`}
            inputId={props.id}
            onChange={props.onChange} className="w-100"
            completeMethod={e => searchUsers(e)}
            placeholder={intl.formatMessage({id:"choose.user"})}
        />
    )
}

export function UsersTPInput(props) {
    const intl = useIntl()
    const crypto = new CryptoTools()
    const [fUsers, setFUsers] = useState(null)
    const users = JSON.parse(crypto.desencriptar(
        useContext(AuthContext).m2.users_tp_disponibles)).map((el)=>{
            return el["username"]
    })

    // Funcion que filtra los usuarios disponibles
    const searchUsers = (event) => {
        setTimeout(() => {
            let filteredUsers;
            if (!event.query.trim().length) {
                filteredUsers = [...users]
            } else {
                filteredUsers = users.filter((user) => {
                    return (user.toLowerCase().startsWith(event.query.toLowerCase()) || user.toLowerCase().includes(event.query.toLowerCase().trim()))
                })
            }

            setFUsers(filteredUsers)
        }, 250);
    }

    return(
        <AutoComplete value={props.value} suggestions={fUsers} 
            name={props.name}
            inputStyle={props.style}
            inputClassName={`p-inputtext-sm ${props.className}`}
            inputId={props.id}
            onChange={props.onChange} className="w-100"
            completeMethod={e => searchUsers(e)}
            placeholder={intl.formatMessage({id:"choose.user"})}
        />
    )
}

export function UsersOPInput(props) {
    const intl = useIntl()
    const crypto = new CryptoTools()
    const [fUsers, setFUsers] = useState(null)
    const users = JSON.parse(crypto.desencriptar(
        useContext(AuthContext).m2.users_op_disponibles)).map((el)=>{
            return el["username"]
    })

    // Funcion que filtra los usuarios disponibles
    const searchUsers = (event) => {
        setTimeout(() => {
            let filteredUsers;
            if (!event.query.trim().length) {
                filteredUsers = [...users]
            } else {
                filteredUsers = users.filter((user) => {
                    return (user.toLowerCase().startsWith(event.query.toLowerCase()) || user.toLowerCase().includes(event.query.toLowerCase().trim()))
                })
            }

            setFUsers(filteredUsers)
        }, 250)
    }

    return(
        <AutoComplete value={props.value} suggestions={fUsers} 
            name={props.name}
            inputStyle={props.style}
            inputClassName={`p-inputtext-sm ${props.className}`}
            inputId={props.id}
            onChange={props.onChange} className="w-100"
            completeMethod={e => searchUsers(e)}
            placeholder={intl.formatMessage({id:"choose.user"})}
        />
    )
}