import React, {useState, useEffect} from 'react'
import axios from 'axios'
import secureStorage from '../../../tools/SecureStorage'
import { FormattedMessage, useIntl } from 'react-intl'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



// eslint-disable-next-line no-extend-native
String.prototype.secsToHHMMSSPoints = function () {
    let sec_num = parseInt(this, 10)
    let hours   = Math.floor(sec_num / 3600)
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60)
    let seconds = sec_num - (hours * 3600) - (minutes * 60)

    if (hours   < 10) {hours   = "0"+hours}
    if (minutes < 10) {minutes = "0"+minutes}
    if (seconds < 10) {seconds = "0"+seconds}
    return hours+':'+minutes+':'+seconds
}

/* 
   -----------------------------------------------------------------------------------------------
    Componente que muestra los top destinos de las ultimas horas
   -----------------------------------------------------------------------------------------------
*/
const TopDestinos = () => {
        const [topDestinos, setTopDestinos] = useState([])
        const [error, setError] = useState(false)
        const intl = useIntl()
        const settings = {
            arrows: false,
            autoplay: true,
            dots: false,

        }

        // Hacemos una peticion apenas se monte el componente para recibir los destinos
        //  y los almacenamos en una cookie por 1 hora y media
        useEffect(() => {
            axios.post( '/cdr/top/destinations/', {},
                {
                    headers: {
                        "Authorization": `Bearer ${secureStorage.getItem("access")}`,
                        'content-type': 'application/json'
                    }
                }
            ).then(resp => {
                const data = JSON.parse(resp.data)
                setTopDestinos(data)
                if(data.length === 0){setError(true)}
            }).catch(error => {
                // Si hay un error la marca como vacio y se indica que hay un error
                setTopDestinos([])
                console.error(error)
                setError(true)
            })
        }, [])

        return(
            // Card que delimita el cuadro de los top destinos
            <div className="card">
                {/* Si hay destinos muestra el carousel, mientras no haya muestra un spinner y si hay error
                    Indica que no hay destinos top
                */}
                {topDestinos.length > 0 ?
                        <Slider {...settings}>
                            {topDestinos.map((e,i) => {
                                return(
                                    <div className="card-body" key={"top-dst-" + i}>
                                        <p className="text-muted text-center text-uppercase font-weight-light mb-0">
                                            {e.name}
                                        </p>
                                        <h4 className="text-primary text-center mt-0">{Intl.NumberFormat().format(e.total)} {intl.formatMessage({id: "quickstat.calls"})}</h4>
                                        <hr/>
                                        <div className="text-right d-flex justify-content-between text-capitalize" style={{lineHeight: "1"}}>
                                            <small className="text-muted">ASR: {((e.answered / e["total calls"]) * 100).toFixed(2)} %</small>
                                            <small className="text-muted">ACD: {((e.duration / e.answered)).toString().secsToHHMMSSPoints()}</small>
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                    : error ?
                        <div className="card-body d-flex align-items-center justify-content-center" style={{minHeight: '137px'}}>
                            <h6 className="text-center text-primary">
                                <FormattedMessage id="notopdestinos"/>
                            </h6>
                        </div> :
                        <div className="d-flex w-100 align-items-center justify-content-center" style={{minHeight: '137px'}}>
                            <div className="spinner-border spinner-border text-primary" role="status">
                                <span className="sr-only">
                                    <FormattedMessage id="privado"/>
                                </span>
                            </div>
                        </div>
                }
            </div>
        )
}

export default TopDestinos