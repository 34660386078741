import React, { useState } from 'react'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import secureStorage from '../../tools/SecureStorage'
import { useHistory } from 'react-router'
import Alert from '../formulario/elementos/Alert' 
import {Password} from 'primereact/password'
import ButtonLoader from './tools/ButtonLoader'

/* 
   -----------------------------------------------------------------------------------------------
    Componente para cambiar la contraseña cuando el usuario esta dentro del cliente
   -----------------------------------------------------------------------------------------------
*/
export default function ChangePassword(){
    const [old, setOld] = useState('')
    const [pass, setPass] = useState('')
    const [rep, setRep] = useState('')
    const history = useHistory()
    const [alerta, setAlerta] = useState(false)
    const [loader, setLoader] = useState(false)
    const [oldError, setOldError] = useState('')
    const [passError, setPassError] = useState('')
    const [repError, setRepError] = useState('')

    const handleInputTyping = (e, setter, setterError) => {
        setter(e.target.value)
        setterError('')
    }
    
    const resetSomeValues = () => {
        setAlerta(false)
        setOldError('')
        setPassError('')
        setRepError('')
    }

    const _changePassword = (e) => {
        e.preventDefault()
        setLoader(true)
        resetSomeValues()

        axios.post('/api/v1/rest-auth/password/change/',
            {
                old_password :old,
                new_password1: pass,
                new_password2: rep
            },
            {
                headers: {
                    Authorization: `Bearer ${secureStorage.getItem("access")}`
                }
            }
        ).then(resp => {
            // Mostramos que se cambio correctamente
            setAlerta(true)
            setLoader(false)
            // Redirigimos al dashboard
            setTimeout(() => {
                history.push('/u/')
            }, 3000)
        }).catch(err => {
            // Mostramos un error con el mensaje devuelto por la API
            const error = err.response.data
            if("old_password" in error){
                const errores = " " + error["old_password"].map((e) => e)
                setOldError(errores)
            }
            if("new_password1" in error){
                const errores = " " + error["new_password1"].map((e) => e)
                setPassError(errores)
            }
            if("new_password2" in error){
                const errores = " " + error["new_password2"].map((e) => e)
                setRepError(errores)
            }
            setLoader(false)
        })
    }

    return(
        <>
            <section className="row">       
                <div className="col-12 mt-5 mb-4 d-flex justify-content-center">
                    
                    <form className="p-4 shadow bg-white rounded" style={{maxWidth: "400px"}} onSubmit={(e) => _changePassword(e)}>
                        <legend className="text-center w-100 mb-3 text-muted">
                            <FormattedMessage id="forms.changePass.legend"/>
                        </legend>
                        <hr/>
                        
                        {/* Contraseña original */}
                        <div className="mb-3">
                            <label>
                                <FormattedMessage id="forms.changePass.oldPass"/>:
                            </label>
                            <Password feedback={false} autoComplete="false" className="pass-change-inputs w-100" value={old} onChange={(e) => handleInputTyping(e, setOld, setOldError)} />
                            <small className="text-danger">{oldError}</small>
                        </div>

                        {/* Nueva contraseña 1 */}
                        <div className="mb-3">
                            <label>
                                <FormattedMessage id="forms.changePass.newPass"/>:
                            </label>
                            <Password feedback={false} className="pass-change-inputs w-100" value={pass} onChange={(e) => handleInputTyping(e, setPass, setPassError)} />
                            <small className="text-danger">{passError}</small>
                        </div>

                        {/* Nueva contraseña 2 */}
                        <div className="mb-4">
                            <label>
                                <FormattedMessage id="forms.changePass.newPassRepeat"/>:
                            </label>
                            <Password feedback={false} className="pass-change-inputs w-100" value={rep} onChange={(e) => handleInputTyping(e, setRep, setRepError)} />
                            <small className="text-danger">{repError}</small>
                        </div>

                        {/* Alertas */}
                        <Alert alert={"mt-3 alert-success mw-100 " + (alerta ? "d-block" : "d-none")}>
                            <strong >
                                <FormattedMessage id="forms.changePass.success"/>
                            </strong>
                        </Alert>
                        
                        {/* Submit Button */}
                        <ButtonLoader
                            loader={loader} className="button-tooltip-loader btn btn-block btn-ariatel--primary btn-sm" 
                            type="submit" onClick={(e) => (e)} style={{minHeight: '35px'}}
                        >
                            <FormattedMessage id="header.menu.changePassword" />
                        </ButtonLoader> 
                    </form>
                </div>
            </section>
        </>
    )
}