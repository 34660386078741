import React, { useState, useEffect } from 'react'
// import 'primeicons/primeicons.css'
// import 'primereact/resources/themes/nova/theme.css'
// import 'primereact/resources/primereact.css'
import {injectIntl} from 'react-intl'
import CanvasJSReact from "./canvasjs.react"
import {FormattedMessage} from 'react-intl'
import {Checkbox} from 'primereact/checkbox'
import axios from 'axios'
import secureStorage from '../../../../tools/SecureStorage'

// Inicializamos el componente CanvasJSChart
const CanvasJSChart = CanvasJSReact.CanvasJSChart

/* 
   -----------------------------------------------------------------------------------------------
    Componente que muestra una grafica con las llamadas de la ultima semana 
   -----------------------------------------------------------------------------------------------
*/
const CallsChart = ({intl}) => {
    // eslint-disable-next-line no-unused-vars
    let chart
    const [calls, setCalls] = useState([])
    const [showAll, setShowAll] = useState(true)
    const [showFailed, setShowFailed] = useState(true)
    const [showAnswered, setShowAnswered] = useState(true)
    const [showNoAnswered, setShowNoAnswered] = useState(true)
    const [showBusy, setShowBusy] = useState(true)

    // Con este evitamos que todos los checks esten desactivados
    // lo cual genera errores (1 checkbox activo minimo)
    const checker = (e, funcion) => {
        if(!e.checked){
            const valores = [showAll, showFailed, showAnswered, showNoAnswered]
            let contador = 0;
            for(let valor of valores){
                if(!valor) contador += 1
            }
            if(contador !== 4){
                funcion(e.checked)
            }
        }else{
            funcion(e.checked)
        }
    }

    // Funcion para recibir la data mediante una peticion
    const getCalls = (source) => {
        // Vaciamos el valor por si ya hubo una peticion anterior
        setCalls([])

        // Hacemos la peticion
        axios.post('/cdr/chart/week/', {},
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`
                },
                cancelToken: source.token
            }
        ).then(res => {
            res.data = JSON.parse(res.data)
            let newDict = {
                "ANSWERED": [],
                "BUSY": [],
                "FAILED": [],
                "NO ANSWER": [],
                "TOTAL": []
            }

            for(let registro of res.data){
                for(let key of Object.keys(newDict)){
                    newDict[key].push(
                        {x: registro["calldate"], y: parseInt(registro[key])}
                    )
                }
            }
            setCalls(newDict)
        }
        ).catch(error => setCalls([error]))
    }

    // Apenas se monte el component ya se esta llamanddo a getCalls()
    useEffect(() => {
        const source = axios.CancelToken.source()
        getCalls(source)
        // const interval = setInterval(getCalls, 3600)
        return () => {
            // clearInterval(interval)
            source.cancel("component unmounted")
        }
    }, [])

    return(
        <>
            {!Array.isArray(calls) ?
                <div className="position-relative">
                    <div className="mb-3 d-flex flex-wrap align-items-center justify-content-around">
                        {/* All */}
                        <div className="pb-2 pb-sm-0 d-inline-flex align-items-center">
                            <Checkbox onChange={e => checker(e,setShowAll)} name="cb1" inputId="cb1" checked={showAll}></Checkbox>
                            <label htmlFor="cb1" className="p-checkbox-label mb-0 ml-2">
                                <FormattedMessage id="calls.filter.c_type_all"/>
                            </label>
                        </div>
                        {/* Failed */}
                        <div className="pb-2 pb-sm-0 d-inline-flex align-items-center">
                            <Checkbox onChange={e => checker(e,setShowFailed)} name="cb2" inputId="cb2" checked={showFailed}></Checkbox>
                            <label htmlFor="cb2" className="p-checkbox-label mb-0 ml-2">
                                <FormattedMessage id="calls.filter.c_type_failed"/>
                            </label>
                        </div>
                        {/* Answered */}
                        <div className="pb-2 pb-sm-0 d-inline-flex align-items-center">
                            <Checkbox onChange={e => checker(e,setShowAnswered)} name="cb3" inputId="cb3" checked={showAnswered}></Checkbox>
                            <label htmlFor="cb3" className="p-checkbox-label mb-0 ml-2">
                                <FormattedMessage id="calls.filter.c_type_answered"/>
                            </label>
                        </div>
                        {/* No Answered */}
                        <div className="pb-2 pb-sm-0 d-inline-flex align-items-center">
                            <Checkbox onChange={e => checker(e,setShowNoAnswered)} name="cb4" inputId="cb4" checked={showNoAnswered}></Checkbox>
                            <label htmlFor="cb4" className="p-checkbox-label mb-0 ml-2">
                                <FormattedMessage id="calls.filter.c_type_no_answer"/>
                            </label>
                        </div>
                        {/* Busy */}
                        <div className="pb-2 pb-sm-0 d-inline-flex align-items-center">
                            <Checkbox onChange={e => checker(e,setShowBusy)} name="cb4" inputId="cb4" checked={showBusy}></Checkbox>
                            <label htmlFor="cb4" className="p-checkbox-label mb-0 ml-2">
                                <FormattedMessage id="calls.filter.c_type_busy"/>
                            </label>
                        </div>
                    </div>
                    
                    {/* Grafica */}
                    <CanvasJSChart options={{
                        animationEnabled: true,
                        zoomEnabled: true,
                        theme: "light2",
                        axisX: {
                            title: intl.formatMessage({id: "time"}),
                            // scaleBreaks: {
                            //     autoCalculate: true
                            // }
                        },
                        axisY: {
                            title: intl.formatMessage({id: "quickstat.calls"}),
                            includeZero: false,
                            // scaleBreaks: {
                            //     autoCalculate: true
                            // }
                        },
                        toolTip: {
                            shared: true
                        },
                        data: [
                            {
                                type: "line",
                                name: intl.formatMessage({id: "calls.filter.c_type_all"}),
                                showInLegend: true,
                                visible: showAll,
                                xValueFormatString: "DD MMM hh:mm TT",
                                xValueType: "dateTime",
                                color: "#3ba0dd",
                                yValueFormatString: "###0 " + intl.formatMessage({id: "quickstat.calls"}),
                                dataPoints: calls["TOTAL"]
                            },
                            {
                                type: "line",
                                name: intl.formatMessage({id: "calls.filter.c_type_failed"}),
                                showInLegend: true,
                                visible: showFailed,
                                xValueFormatString: "DD MMM hh:mm TT",
                                xValueType: "dateTime",
                                color: "#6f6e7d",
                                yValueFormatString: "###0 " + intl.formatMessage({id: "quickstat.calls"}),
                                dataPoints: calls["FAILED"]
                            },
                            {
                                type: "line",
                                name: intl.formatMessage({id: "calls.filter.c_type_answered"}),
                                showInLegend: true,
                                visible: showAnswered,
                                xValueFormatString: "DD MMM hh:mm TT",
                                xValueType: "dateTime",
                                color: "#45a23a",
                                yValueFormatString: "###0 " + intl.formatMessage({id: "quickstat.calls"}),
                                dataPoints: calls["ANSWERED"]
                            },
                            {
                                type: "line",
                                name: intl.formatMessage({id: "calls.filter.c_type_no_answer"}),
                                showInLegend: true,
                                visible: showNoAnswered,
                                xValueFormatString: "DD MMM hh:mm TT",
                                xValueType: "dateTime",
                                color: "#d24646",
                                yValueFormatString: "###0 " + intl.formatMessage({id: "quickstat.calls"}),
                                dataPoints: calls['NO ANSWER']
                            },
                            {
                                type: "line",
                                name: intl.formatMessage({id: "calls.filter.c_type_busy"}),
                                showInLegend: true,
                                visible: showBusy,
                                xValueFormatString: "DD MMM hh:mm TT",
                                xValueType: "dateTime",
                                color: "#ddb33b",
                                yValueFormatString: "###0 " + intl.formatMessage({id: "quickstat.calls"}),
                                dataPoints: calls['BUSY']
                            }
                        ]
                        // eslint-disable-next-line
                    }} onRef={ref => chart = ref}/>
                    <div className="bg-white position-absolute" style={{width: "60px", height: "13px", bottom: "0px"}}></div>
                </div>
            : calls.length === 0 ? 
                    <div className="callsChart-loader d-flex w-100 align-items-center justify-content-center" style={{minHeight: '400px'}}>
                        <div className="spinner-priv spinner-border text-primary" role="status">
                            <span className="sr-only">
                                <FormattedMessage id="privado"/>
                            </span>
                        </div>
                    </div>
                :
                    <div className="callsChart-loader d-flex w-100 flex-column align-items-center justify-content-center" style={{minHeight: '400px'}}>
                        <div className="text-primary h3">
                            <FormattedMessage id="devices.error.search"/>
                        </div>
                        <p className="text-muted">It's possible that the specified user does not have Call Records</p>
                    </div>
            }
        </>
    )
}

export default injectIntl(CallsChart)