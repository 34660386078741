import React from 'react'

/* 
   -----------------------------------------------------------------------------------------------
    Componente con el contenido de la barra de navegacion inferior mostrada en moviles
   -----------------------------------------------------------------------------------------------
*/
export default function FooterNav(props) {
    return(
        <>
            <nav className="navbar fixed-bottom shadow-lg border-top navbar-light bg-white p-0 d-md-none footer-dashboard-nav">
                <div className="navbar-expand align-self-stretch w-100">
                    <ul className="navbar-nav nav-fill">
                        {props.children}
                    </ul>
                </div>
            </nav>
        </>
    )
}