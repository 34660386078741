import React, { useContext } from 'react'
import CryptoTools from '../tools/crypto_tools'
import { AuthContext } from './AuthContext'
import Spinner from './Spinner'
import AdminUser from './users switches/AdminUser'
import UserNoM2 from './users switches/UserNoM2'
import UserNoProfile from './users switches/UserNoProfile'
// import SimpleUser from './users switches/SimpleUser'
import ManagerUser from './users switches/ManagerUser'
import OPUser from './users switches/OPUser'
// import TPUser from './users switches/TPUser'
// import OPTPUser from './users switches/OPTPUser'

/* 
   -----------------------------------------------------------------------------------------------
   Segun el tipo de usuario renderizamos un Switch especifico con herramientas diferentes
   -----------------------------------------------------------------------------------------------
*/
export default function SecureZone(props){
    const data = useContext(AuthContext)
    const crypto = new CryptoTools()

    if(data.isAuth){
        switch (crypto.desencriptar(data.user_type)) {
            case "Admin":
                return <AdminUser/>
            case "Manager":
                return <ManagerUser/>
            case "OP":
            case "User":
            case "TP/OP":
            case "TP":
                return <OPUser/>
                // return <SimpleUser/>
                // return <OPTPUser/>
                // return <TPUser/>
            case "NM2":
                return <UserNoM2/>
            default:
                return <UserNoProfile/>
        }        
    } else {
        return <Spinner altura="vh-100"/>
    }
    
}



