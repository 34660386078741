import React, { useEffect } from 'react'
import $ from 'jquery'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que se encarga de renderizar el mismo boton con un loader al lado
   -----------------------------------------------------------------------------------------------
*/
export default function ButtonLoader(props){
    const showError = props.showError

    useEffect(() => {
        $(".button-tooltip-loader").tooltip()
        return () => {
            $('.button-tooltip-loader').tooltip('hide')
        }
    }, [])

    useEffect(() => {
        if(showError){
            $('.button-tooltip-loader').tooltip('show')
            
            setTimeout(() => {
            $('.button-tooltip-loader').tooltip('hide')
            }, 2000)
        }
    },[showError])

    return(
        <button onClick={props.onClick} 
            type={props.type || "button"}
            data-toggle="tooltip" data-placement={props.tooltipPlace || "left"}
            data-title={props.errorTitle || "Error"}
            data-trigger="manual"
            disabled={props.loader}
            className={props.className || "button-tooltip-loader"}
            style={props.style || {}}
        >
            {props.children}
            <div className={
                props.loader ? "spinner-border ml-3 spinner-border-sm text-light" : 
                    "d-none spinner-border ml-3 spinner-border-sm text-light"
                } 
                    role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </button>
    )
}

export function ButtonLoaderOutline(props){
    const showError = props.showError

    useEffect(() => {
        $(".button-tooltip-loader").tooltip()
        return () => {
            $('.button-tooltip-loader').tooltip('hide')
        }
    }, [])

    useEffect(() => {
        if(showError){
            $('.button-tooltip-loader').tooltip('show')
            
            setTimeout(() => {
            $('.button-tooltip-loader').tooltip('hide')
            }, 2000)
        }
    },[showError])

    return(
        <button onClick={props.onClick} 
            type={props.type || "button"}
            data-toggle="tooltip" data-placement={props.tooltipPlace || "left"}
            data-title={props.errorTitle || "Error"}
            data-trigger="manual"
            disabled={props.loader}
            className={props.className || "button-tooltip-loader"}
            style={props.style || {}}
        >
            {props.children}
            <div className={
                props.loader ? "spinner-border ml-3 spinner-border-sm text-primary" : 
                    "d-none spinner-border ml-3 spinner-border-sm text-primary"
                } 
                    role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </button>
    )
}