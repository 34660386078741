import React, {useState, useEffect, useRef, useContext} from 'react'
import Titulo from '../compartidos/tools/Titulo'
import { InputNumber } from 'primereact/inputnumber'
import {FormattedMessage, useIntl} from 'react-intl'
import $ from 'jquery'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import secureStorage from '../../tools/SecureStorage'
import MultiSelectTree from '../compartidos/tools/MultiselectTree'
import { Calendar } from 'primereact/calendar'
import { AuthContext } from '../AuthContext'
import ReactGA from 'react-ga'

/* 
   -----------------------------------------------------------------------------------------------
    Este componente permite crear y eliminar Ofertas sin necesidad de que los usuarios tengan
    que entrar en el admin de Django 
   -----------------------------------------------------------------------------------------------
*/
const AddOrDeleteOfertasAdmin = () => {
    const intl = useIntl()
    const data_u = useContext(AuthContext)
    const [currentOffers, setCurrentOffers] = useState([])
    const [country, setCountry] = useState([])
    const [percent, setPercent] = useState(0.0)
    const [price, setPrice] = useState(0.0)
    const [finalPrice, setFinalPrice] = useState(0.0)
    const [loader, setLoader] = useState(false)
    const timeout = useRef(null)
    const percentRef = useRef(null)
    const priceRef = useRef(null)
    const finalPriceRef = useRef(null)
    const [duracion, setDuracion] = useState([])

    // Peticion para traer las ofertas existentes
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
        // Hacemos una peticion para obtener las ofertas actuales
        getOffers()
        // Inicializamos los Tooltips para que puedan ser activados
        $(function () {
            $('.btn-submit-add-offers').tooltip()
            $("#tooltip-expiration-info").tooltip()
        })

        return () => {
            $('.btn-submit-add-offers').tooltip('dispose')
            $("#tooltip-expiration-info").tooltip('dispose')
        }
    }, [])

    // Funcion que se encarga de mostrar u ocultar el mensaje "no hay ofertas"
    // cada vez que currentOffers se actualiza
    useEffectNotInit(() => {
        if(currentOffers.length > 0){
            $('#nooffers').addClass('d-none')
        }else{
            $('#nooffers').removeClass('d-none') 
        }
    }, [currentOffers])

    // Llena automaticamente el campo numerico faltante al llenar otros dos 
    useEffectNotInit(() => {
        clearTimeout(timeout.current)
        timeout.current = setTimeout(() => {
        if (percent !== null && price !== null && finalPriceRef !== null) {

          if (percent !== 0 && price !== 0 && (percentRef.current.inputEl === document.activeElement || priceRef.current.inputEl === document.activeElement)){
              setFinalPrice(price - (percent * price / 100))
          } else if (percent !== 0 && finalPrice !== 0 && (percentRef.current.inputEl === document.activeElement || finalPriceRef.current.inputEl === document.activeElement)){
              setPrice((finalPrice * 100 / (100 - percent)))
          } else if (price !== 0 && finalPrice !== 0  && (priceRef.current.inputEl === document.activeElement || finalPriceRef.current.inputEl === document.activeElement)){
              setPercent(100 - (finalPrice * 100 / price))
          } else {
              if (percent !== 0 && price !== 0 && finalPrice === 0 && (finalPriceRef.current.inputEl === document.activeElement)){
                  setFinalPrice(price - (percent * price / 100))
              } else if (percent !== 0 && price === 0 && finalPrice !== 0 && (priceRef.current.inputEl === document.activeElement)){
                  setPrice((finalPrice * 100 / (100 - percent)))
              } else if (percent === 0 && price !== 0 && finalPrice !== 0 && (percentRef.current.inputEl === document.activeElement)){
                  setPercent(100 - (finalPrice * 100 / price))
              }
          }
        }
    }, 1200)
        
    }, [percent, price, finalPrice])
    
    // Muestra una alerta de "Oferta creada/eliminada exitosamente"
    const offerSuccess = (alertype) => {
        const handler = '.alert.alert-success.' + alertype
        $(handler).removeClass("d-none")
        setTimeout(() => {
            $(handler).addClass("d-none")
        }, 5000)
    }

    // Muestra una alerta de error al realizar cierta accion de creacion/eliminacion
    const offerError = (alertype) => {
        const handler = '.alert.alert-danger.' + alertype
        $(handler).removeClass("d-none")
        setTimeout(() => {
            $(handler).addClass("d-none")
        }, 5000)
    }

    // Establece que alerta mostrar
    // --- Alertype: Indica la accion que el usuario hizo
    // --- Bool: indica si es una alerta de error o success
    const handleAlert = (alertype, bool) => {
        bool ? offerSuccess(alertype) : offerError(alertype)
    }

    // Hace una peticion que devuelve todas las ofertas disponibles
    const getOffers = () => {
        axios.get( '/ofertas/?p=get_all',
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`,
                    'content-type': 'application/json'
                }
            }
        ).then(resp => {
            // Establecemos las ofertas devueltas en nuestra tabla
            setCurrentOffers(resp.data.ofertas)
        }).catch(error => {
            setCurrentOffers([])
        })
    }

    // Mostramos un tooltip de error en caso que el formulario este mal
    const mostrarError = () => {
        $('.btn-submit-add-offers').tooltip('show')
         setTimeout(() => {
            $('.btn-submit-add-offers').tooltip('hide')
         }, 3000)
    }

    const getAddOfferUrl = () => {
        let url = `/ofertas/?p=create_offer&country=${country}&normal_price=${
            price.toFixed(4)
        }&percent=${
            percent.toFixed(4)
        }&final_price=${
            finalPrice.toFixed(4)
        }`

        if(duracion.length <= 0){
            const now = new Date()
            const now_str = now.toISOString().split("T")[0]

            const expiration = new Date()
            expiration.setDate(expiration.getDate() + 7)
            const expiration_str = expiration.toISOString().split("T")[0]

            setDuracion([now, expiration])
            return (url + `&start=${now_str}&end=${expiration_str}`)

        }else{

            if(duracion[1] === null){
                const now_str = new Date().toISOString().split("T")[0]
                const expiration_str = duracion[0].toISOString().split("T")[0]
                setDuracion([new Date(), duracion[0]])
                return (url + `&start=${now_str}&end=${expiration_str}`)
            } else{
                const now_str = duracion[0].toISOString().split("T")[0]
                const expiration_str = duracion[1].toISOString().split("T")[0]
                return (url + `&start=${now_str}&end=${expiration_str}`)
            }

        }
    }

    // Funcion para añadir una oferta
    const addOffer = () => {
        // Hacemos la peticion
        axios.get(getAddOfferUrl(),
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`,
                    'content-type': 'application/json'
                }
            }
        ).then(res => {
            // Si se creo en el back correctamente añadimos la oferta en la
            // tabla de ofertas actuales
            if(res.data.status === "done"){

                setCurrentOffers([
                    {
                        country: country, 
                        percent: parseFloat(percent.toFixed(4)), 
                        price_without_discount: parseFloat(price.toFixed(4)), 
                        id: res.data.id,
                        final_price: parseFloat(finalPrice.toFixed(4)),
                        currency: res.data.currency,
                        duration_info: res.data.duration_info
                    },
                    ...currentOffers
                ]) 
            }
            // Indicamos que se creo correctamente
            handleAlert("create",res.data.status === "done")
        }).catch(error => handleAlert("create",false))
    }

    // Funcion para eliminar una oferta
    const deleteOffer = (id) => {
        if(!isNaN(id)){
            // URL con la ID de la oferta
            const url = `/ofertas/?p=delete_offer&offer_id=${id}`
            
            // Hacemos la peticion
            axios.get(url,
                {
                    headers: {
                        "Authorization": `Bearer ${secureStorage.getItem("access")}`,
                        'content-type': 'application/json'
                    }
                }
            ).then(res => {
                // Si se elimino en el back correctamente eliminamos la oferta de la
                // tabla de ofertas actuales
                if(res.data.status === "eliminado"){
                    const fresh_offers = currentOffers.filter((e) => {return e.id !== id})
                    setCurrentOffers(fresh_offers)
                }
                // Indicamos que se elimino correctamente
                handleAlert("delete",res.data.status === "eliminado")
            }).catch(error => handleAlert("delete",false))
        }else{
            handleAlert("delete",false)
        }
    }
    
    // Funcion principal para crear oferta
    const handleSubmit = () => {
        setLoader(true)
        // Si el formulario es valido añadimos oferta, mostramos el tooltip de error
        validarForm() ? addOffer() : mostrarError()
        setLoader(false)
        // Vaciamos los campos de los usuarios
        handleReset()
    }

    const validarDuracion = () => {
        for(let i in duracion){
          if(duracion[i] !== null){
              if(!(duracion[i] instanceof Date)) {
                return true
              }
          }
        }
        return false
    }

    // Funcion para validar el formulario de crear oferta
    const validarForm = () => {
        // Variable que nos dice si es valido o no el formulario
        let valid = true
        // Validamos country
        if(country.length === 0) {valid = false}
        // Validamos que percent y price sean numeros mayores a 0
        if(percent <= 0 || price <= 0 || finalPrice <= 0) {valid = false}
        // Validamos que percent y price sean numeros
        if(typeof(percent) !== "number" || typeof(price) !== "number" || typeof(finalPrice) !== "number") {valid = false}
        // Validamos duracion
        if(validarDuracion()) {valid = false}
        // retornamos si es valido o no
        return valid
    }

    // Funcion que resetea los campos del formulario de Agregar Ofertas
    const handleReset = () => {
        setCountry([])
        setPercent(0)
        setPrice(0)
        setFinalPrice(0)
    }
    
    return(
        <>
            <Titulo>
                <FormattedMessage id="addoffers.title"/>
            </Titulo>

            <section className="row">
                {/* Formulario de agregar Ofertas */}
                <div className="order-2 order-m2-1 col-md-5 mb-4 col-lg-4">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="text-center text-muted mb-0">
                                <FormattedMessage id="addoffers.newoffer"/>
                            </h3>
                        </div>

                        <div className="card-body pt-3">
                            <div className="form-row">
                                {/* Calendar */}
                                <div className="col input-group mb-2 offers-form">
                                    <label htmlFor="country" className="text-muted"
                                    id="tooltip-expiration-info"
                                    data-html="true"
                                    data-title={intl.formatMessage({id: "addoffers.tooltip.info"})}
                                    data-toggle="tooltip" data-placement="right"
                                    >
                                        <FormattedMessage id="addoffers.select.time"/>: <FontAwesomeIcon icon="question-circle" size="xs"/>
                                    </label>
                                    <Calendar className="w-100 calendar-full-width" selectionMode="range" minDate={new Date(Date.now())} value={duracion} onChange={(e) => setDuracion(e.value)} readOnlyInput></Calendar>
                                </div>
                            </div>
                            <div className="form-row"> 
                                {/* Country */}
                                <div className="col input-group mb-2">
                                    <label htmlFor="country" className="text-muted">
                                        <FormattedMessage id="addoffers.select.country"/>:
                                    </label>
                                    <MultiSelectTree value={country} onChange={v => setCountry(v)}/>
                                </div>
                            </div>
                            <hr className="mt-2 mb-3"/>

                            <div className="form-row">
                                {/* percent */}
                                <div className="col input-group mb-3">
                                    <label htmlFor="offers-percent" className="text-muted">
                                        <FormattedMessage id="addoffers.percent"/>:
                                    </label>
                                    <InputNumber ref={percentRef} inputClassName="w-100" className="w-100" id="offers-percent" name="percent" suffix=" %" minFractionDigits={1} maxFractionDigits={4} value={percent} 
                                        onChange={(e) => {
                                            e.value <= -0.9999 ? setPercent(0.0) : setPercent(e.value)
                                        }}
                                    />
                                </div>
                                {/* price */}
                                <div className="col input-group mb-3">
                                    <label htmlFor="offers-price" className="text-muted">
                                        <FormattedMessage id="addoffers.price"/> ({data_u.m2.default_curr}):
                                    </label>
                                    <InputNumber ref={priceRef} inputClassName="w-100" className="w-100" id="offers-price" name="price" prefix="$ " mode="decimal" minFractionDigits={1} maxFractionDigits={4} value={price} 
                                        onChange={(e) => {
                                            e.value <= -0.9999 ? setPrice(0.0) : setPrice(e.value)
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col input-group mb-3">
                                    <label htmlFor="offers-final-price" className="text-muted">
                                        <FormattedMessage id="addoffers.finalPrice"/> ({data_u.m2.default_curr}):
                                    </label>
                                    <InputNumber ref={finalPriceRef} className="w-100" id="offers-final-price" name="final-price" prefix="$ " mode="decimal" minFractionDigits={1} maxFractionDigits={4} value={finalPrice} 
                                        onChange={(e) => {
                                            e.value <= -0.9999 ? setFinalPrice(0.0) : setFinalPrice(e.value)
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Submit Button */}
                            <button 
                                onClick={handleSubmit}
                                type="button"
                                disabled={loader}
                                title={intl.formatMessage({id: "forms.invalid"})}
                                data-trigger="manual"
                                data-toggle="tooltip" data-placement="top"
                                className="w-100 mt-3 d-flex justify-content-center align-items-center btn btn-ariatel--primary btn-submit-add-offers"
                            >
                                <FormattedMessage id="addoffers.button"/>
                                <div className={
                                    loader ? "spinner-border ml-3 spinner-border-sm text-light" : 
                                        "d-none spinner-border ml-3 spinner-border-sm text-light"
                                    } 
                                        role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

                {/* Tabla de Ofertas actuales */}
                <div className="order-1 order-md-2 col-md-7 col-lg-8">
                    <div className="card mb-3">
                        <div className="card-header">
                            <h3 className="text-center text-muted mb-0">
                                <FormattedMessage id="addoffers.current"/> ({data_u.m2.default_curr})
                            </h3>
                        </div>

                        <div className="card-body pt-1">
                            {/* Tabla de Ofertas actuales */}
                            <table className="table-offers table rounded mb-0 table-hover">
                                <caption id="nooffers" className="text-center bg-light d-none">
                                    <h4>
                                        <FormattedMessage id="nooffers"/>
                                    </h4>
                                </caption>

                                <thead>
                                    <tr>
                                        <th className="border-top-0 border-bottom-1" scope="col">
                                            <FormattedMessage id="forms.signUp.country"/>
                                        </th>
                                        <th className="border-top-0 border-bottom-1" scope="col">
                                            <FormattedMessage id="addoffers.percent"/>
                                        </th>
                                        <th className="border-top-0 border-bottom-1" scope="col">
                                            <FormattedMessage id="addoffers.price"/>
                                        </th>
                                        <th className="border-top-0 border-bottom-1" scope="col">
                                            <FormattedMessage id="addoffers.finalPrice"/>
                                        </th>
                                        <th className="border-top-0 border-bottom-1" scope="col">
                                        </th>
                                        <th className="border-top-0 border-bottom-1" style={{width:"50px"}} scope="col"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {currentOffers.map((element, index) => {
                                        return(
                                            <tr key={index}>
                                                <td>{element.country}</td>
                                                <td>{element.percent} %</td>
                                                <td>$ {element.price_without_discount}</td>
                                                <td className="text-success">$ {element.final_price}</td>
                                                <td className="text-info">
                                                    <small>
                                                        <FormattedMessage id={"offer." + element.duration_info[0]}/>
                                                        {element.duration_info[1]}
                                                        <FormattedMessage id="offer.days"/>
                                                    </small>
                                                </td>
                                                <td>
                                                    <button type="button" onClick={e => deleteOffer(element.id)} 
                                                        className="btn p-0 del-offer" 
                                                        style={{verticalAlign: "top"}}>
                                                        <FontAwesomeIcon icon="trash-alt" className="text-muted"/>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>

            {/* Alertas */}
            <Alerts/>
        </>
    )
}

/* 
   -----------------------------------------------------------------------------------------------
    Este componente muestra alertas segun las clases CSS que se pasen como parametros
    en la funcion HandleAlert
   -----------------------------------------------------------------------------------------------
*/
const Alerts = () => {
    return(
        <>
            <div className="alert alert-success create position-fixed d-none" 
                style={{bottom: "15px", right: "15px", zIndex: 1000}} 
                role="alert"
            >
                <FormattedMessage id="addoffers.alert.create.success"/>
            </div>
            <div className="alert alert-danger create position-fixed d-none"
                style={{bottom: "15px", right: "15px", zIndex: 1000}} 
                role="alert">
                <FormattedMessage id="addoffers.alert.create.error"/>
            </div>

            <div className="alert alert-success delete position-fixed d-none" 
                style={{bottom: "15px", right: "15px", zIndex: 1000}} 
                role="alert"
            >
                <FormattedMessage id="addoffers.alert.delete.success"/>
            </div>
            <div className="alert alert-danger delete position-fixed d-none"
                style={{bottom: "15px", right: "15px", zIndex: 1000}} 
                role="alert">
                <FormattedMessage id="addoffers.alert.delete.error"/>
            </div>
        </>
    )
}

/* 
   -----------------------------------------------------------------------------------------------
    Custom useEffect que no se ejecuta al montar el componente
   -----------------------------------------------------------------------------------------------
*/
const useEffectNotInit = (fn, inputs) => {
  const didMountRef = useRef(false)

  useEffect(() => {
      didMountRef.current ? fn() : didMountRef.current = true
      // eslint-disable-next-line
  }, inputs)
}

export default AddOrDeleteOfertasAdmin