import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FooterNavSidebarLink, HeaderNavDropdownButton, HeaderNavDropdownLink} from '../compartidos/tools/HeaderLinks'
import Header from '../compartidos/secciones/Header'
import Footer from '../compartidos/secciones/Footer'
import Policy from '../compartidos/policy/Policy'
import Main from '../compartidos/secciones/Main'
import Dashboard from '../admin/Dashboard'
import Aggregates from '../admin/Aggregates'
import Devices from '../admin/Devices'
import UserDetails from '../admin/UserDetails'
import UserBalance from '../admin/UserBalance'
import AddOrDeleteOfertas from '../admin/AddOrDeleteOfertas'
import PaymentCreateAdmin from '../admin/PaymentCreate'
import BalanceUpdate from '../admin/BalanceUpdate'
import TariffsUpdate from '../admin/TariffsUpdate'
import TariffsCreate from '../admin/TariffsCreate'
// import RatesAdmin from '../admin/Rates'
import PaymentsAdmin from '../admin/Payments'
import UserProfile from '../compartidos/UserProfile'
// import ChangePassword from '../compartidos/ChangePassword'
import Dashboard404 from '../404/Dashboard404'
import Transfer from '../compartidos/add payments/methods/Transfer'
import PaymentCreate from '../compartidos/add payments/PaymentCreate'
import Rates from '../compartidos/Rates'
import Payments from '../compartidos/Payments'
import { FormattedMessage } from 'react-intl'
import FooterNavAdmin from '../admin/FooterNavAdmin'
// import CdrLobby from '../compartidos/call records/CdrLobby'
import CdrTable from '../compartidos/call records/CdrTable'
// import CdrLobbyAdmin from '../admin/call records/CdrLobby'
import CdrTableAdmin from '../admin/call records/CdrTable'
import PSE from '../compartidos/add payments/wompi/PSE/PSE'
import PSEResponse from '../compartidos/add payments/wompi/PSE/PSEResponse'
import BotonBancolombia from '../compartidos/add payments/wompi/Boton Bancolombia/BotonBancolombia'
import BotonBancolombiaResponse from '../compartidos/add payments/wompi/Boton Bancolombia/BotonBancolombiaResponse'
import AddCreditCard from '../compartidos/add payments/wompi/Credit Card/AddCreditCard'
import ListCreditCard from '../compartidos/add payments/wompi/Credit Card/ListCreditCard'
import CreditCard from '../compartidos/add payments/wompi/Credit Card/CreditCard'
import ResponseCreditCard from '../compartidos/add payments/wompi/Credit Card/ResponseCreditCard'
import UserRanges from '../admin/UserRange'
import ResponseCreditCardHistory from '../compartidos/add payments/wompi/Credit Card/ResponseCreditCardHistory'
import PSEResponseHistory from '../compartidos/add payments/wompi/PSE/PSEResponseHistory'
import BotonBancolombiaResponseHistory from '../compartidos/add payments/wompi/Boton Bancolombia/BotonBancolombiaResponseHistory'

/* 
   -----------------------------------------------------------------------------------------------
   Switch con las rutas para usuarios de tipo Administrador
   -----------------------------------------------------------------------------------------------
*/
export default function AdminUser(){
    return(
        <div id="container-of-client" className="d-flex flex-wrap flex-row justify-content-start">
            <Header footerNav={<FooterNavAdmin admin={SMAdmin} user={SMUser}/>} navLG={LG}/>
            <Main>
                <Switch>
                    <Route exact path="/u/">
                        <Dashboard/>
                    </Route>
                    {/* Policy */}
                        <Route exact path="/u/policy/terms-and-conditions/">
                            <Policy/>
                        </Route>
                        <Route exact path="/u/policy/privacy-policy/">
                            <Policy/>
                        </Route>

                        <Route exact path="/u/policy/terms-of-use/">
                            <Policy/>
                        </Route>
                        <Route exact path="/u/policy">
                            <Redirect to="/u/policy/terms-and-conditions/"/>
                        </Route>

                        <Route path="/u/policy/*">
                            <Redirect to="/u/policy/terms-and-conditions/"/>
                        </Route>
                    {/* Policy */}
                    <Route exact path="/u/ranges/">
                        <UserRanges/>
                    </Route>
                    <Route exact path="/u/aggregates/">
                        <Aggregates/>
                    </Route>
                    <Route exact path="/u/devices/">
                        <Devices/>
                    </Route>
                    <Route exact path="/u/details/">
                        <UserDetails/>
                    </Route>
                    <Route exact path="/u/balances/">
                        <UserBalance/>
                    </Route>
                    <Route exact path="/u/create/offers/">
                        <AddOrDeleteOfertas/>
                    </Route>
                    <Route exact path="/u/create/payments/">
                        <PaymentCreateAdmin/>
                    </Route>
                    <Route exact path="/u/update/balance/">
                        <BalanceUpdate/>
                    </Route>
                    <Route exact path="/u/update/tariffs/">
                        <TariffsUpdate/>
                    </Route>
                    <Route exact path="/u/create/tariffs/">
                        <TariffsCreate/>
                    </Route>
                    <Route exact path="/u/rates/">
                        <Rates/>
                    </Route>
                    <Route exact path="/u/payments/history/">
                        <PaymentsAdmin/>
                    </Route>
                    <Route exact path="/u/profile/">
                        <UserProfile/>
                    </Route>
                    {/* <Route exact path="/u/change/password/">
                        <ChangePassword/>
                    </Route> */}
                    <Route exact path="/u/pay/">
                        <PaymentCreate/>
                    </Route>
                    <Route exact path="/u/pay/by/transfer/">
                        <Transfer/>
                    </Route>
                    <Route exact path="/u/calls/records/">
                        {/* <CdrLobbyAdmin/> */}<CdrTableAdmin/>
                    </Route>
                    <Route exact path="/u/calls/records/:u/">
                        <CdrTableAdmin/>
                    </Route>
                    {/* <Route exact path="/u/calls/records/last/week/">
                        <CdrTableAdmin/>
                    </Route> */}
                    {/* <Route exact path="/u/s/calls/records/">
                        <CdrLobby/>
                    </Route> */}
                    <Route exact path="/u/s/calls/records/">
                        <CdrTable/>
                    </Route>
                    <Route exact path="/u/s/payments/history/">
                        <Payments/>
                    </Route>
                    <Route exact path="/u/s/rates/">
                        <Rates/>
                    </Route>
                    <Route exact path="/u/pay/by/pse/">
                        <PSE/>
                    </Route>
                    <Route exact path="/u/pay/by/pse/response/">
                        <PSEResponse/>
                    </Route>
                    <Route exact path="/u/pay/by/bancolombia/">
                        <BotonBancolombia/>
                    </Route>
                    <Route exact path="/u/pay/by/bancolombia/response/*">
                        <BotonBancolombiaResponse/>
                    </Route>
                    <Route exact path="/u/add/credit-card/">
                        <AddCreditCard/>
                    </Route>
                    <Route exact path="/u/list/credit-card/">
                        <ListCreditCard/>
                    </Route>
                    <Route exact path="/u/pay/by/credit-card/">
                        <CreditCard/>
                    </Route>
                    <Route exact path="/u/pay/by/credit-card/response/">
                        <ResponseCreditCard/>
                    </Route>
                    <Route exact path="/u/transaction/credit-card/:reference/">
                        <ResponseCreditCardHistory/>
                    </Route>
                    <Route exact path="/u/transaction/pse/:reference/">
                        <PSEResponseHistory/>
                    </Route>
                    <Route exact path="/u/transaction/boton-bancolombia/:reference/">
                        <BotonBancolombiaResponseHistory/>
                    </Route>
                    <Route exact path="/u/404/">
                        <Dashboard404/>
                    </Route>
                    <Route exact path="*">
                        <Redirect to="/u/404/"/>
                    </Route>
                </Switch>
            </Main>
            <Footer/>
        </div>
    )
}

/* 
   -----------------------------------------------------------------------------------------------
   Fragmento con links visibles en el navbar de desktop
   -----------------------------------------------------------------------------------------------
*/
const LG = <>
    <HeaderNavDropdownButton icon="user-tie" className="d-none d-sm-block">
        <h6 className="dropdown-header">
            <FormattedMessage id="admin.pages"/>
        </h6>
        <div className="dropdown-divider"></div>
        <HeaderNavDropdownLink msgId="header.menu.payments" icon="scroll" to="/u/payments/history/"/>
        <HeaderNavDropdownLink msgId="header.menu.rates" icon="clipboard-list" to="/u/rates/"/>
        <HeaderNavDropdownLink msgId="header.menu.create.tariff" icon="file-import" to="/u/create/tariffs/"/>
        <HeaderNavDropdownLink msgId="header.menu.update.tariff" icon="file-invoice-dollar" to="/u/update/tariffs/"/>
        <HeaderNavDropdownLink msgId="header.menu.update.balance" icon="piggy-bank" to="/u/update/balance/"/>
        <HeaderNavDropdownLink msgId="header.menu.add.payments.admin" icon="donate" to="/u/create/payments/"/>
        <HeaderNavDropdownLink msgId="ofertas" icon="percentage" to="/u/create/offers/"/>
        <HeaderNavDropdownLink msgId="header.menu.devices" icon="mobile-alt" to="/u/devices/"/>
        <HeaderNavDropdownLink msgId="header.menu.ranges" icon="coins" to="/u/ranges/"/>
        <HeaderNavDropdownLink msgId="header.menu.aggregates" icon="tasks" to="/u/aggregates/"/>
        <HeaderNavDropdownLink msgId="header.menu.userInformation" icon="users" to="/u/details/"/>
        <HeaderNavDropdownLink msgId="header.menu.userBalance" icon="address-book" to="/u/balances/"/>
        <HeaderNavDropdownLink msgId="calls.nav.title" icon="table" to="/u/calls/records/"/>
    </HeaderNavDropdownButton>
    <HeaderNavDropdownButton icon="user" className="d-none d-sm-block">
        <h6 className="dropdown-header">
            <FormattedMessage id="user.pages"/>
        </h6>
        <div className="dropdown-divider"></div>
        <HeaderNavDropdownLink msgId="header.menu.add.payments" icon="hand-holding-usd" to="/u/pay/"/>
        <HeaderNavDropdownLink msgId="header.menu.rates" icon="clipboard-list" to="/u/s/rates/"/>
        <HeaderNavDropdownLink msgId="header.menu.payments" icon="scroll" to="/u/s/payments/history/"/>
        <HeaderNavDropdownLink msgId="calls.nav.title" icon="table" to="/u/s/calls/records/"/>
    </HeaderNavDropdownButton>
</>

/* 
   -----------------------------------------------------------------------------------------------
   Fragmento con links visibles en el navbar de mobile (Admin Sidebar)
   -----------------------------------------------------------------------------------------------
*/
const SMAdmin = <>
    <FooterNavSidebarLink msgId="header.menu.payments" icon="scroll" to="/u/payments/history/"/>
    <FooterNavSidebarLink msgId="header.menu.rates" icon="clipboard-list" to="/u/rates/"/>
    <FooterNavSidebarLink msgId="header.menu.create.tariff" icon="file-import" to="/u/create/tariffs/"/>
    <FooterNavSidebarLink msgId="header.menu.update.tariff" icon="file-invoice-dollar" to="/u/update/tariffs/"/>
    <FooterNavSidebarLink msgId="header.menu.update.balance" icon="piggy-bank" to="/u/update/balance/"/>
    <FooterNavSidebarLink msgId="header.menu.add.payments.admin" icon="donate" to="/u/create/payments/"/>
    <FooterNavSidebarLink msgId="ofertas" icon="percentage" to="/u/create/offers/"/>
    <FooterNavSidebarLink msgId="header.menu.devices" icon="mobile-alt" to="/u/devices/"/>
    <FooterNavSidebarLink msgId="header.menu.ranges" icon="coins" to="/u/ranges/"/>
    <FooterNavSidebarLink msgId="header.menu.aggregates" icon="tasks" to="/u/aggregates/"/>
    <FooterNavSidebarLink msgId="header.menu.userInformation" icon="users" to="/u/details/"/>
    <FooterNavSidebarLink msgId="header.menu.userBalance" icon="address-book" to="/u/balances/"/>
    <FooterNavSidebarLink msgId="calls.nav.title" icon="table" to="/u/calls/records/"/>       
</>

/* 
   -----------------------------------------------------------------------------------------------
   Fragmento con links visibles en el navbar de mobile (User Sidebar)
   -----------------------------------------------------------------------------------------------
*/
const SMUser = <>
    <FooterNavSidebarLink msgId="header.menu.add.payments" icon="hand-holding-usd" to="/u/pay/"/>
    <FooterNavSidebarLink msgId="header.menu.rates" icon="clipboard-list" to="/u/s/rates/"/>
    <FooterNavSidebarLink msgId="header.menu.payments" icon="scroll" to="/u/s/payments/history/"/>
    <FooterNavSidebarLink msgId="calls.nav.title" icon="table" to="/u/s/calls/records/"/>
</>