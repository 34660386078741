import axios from 'axios'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import CookieTools from '../../../tools/cookies'
import secureStorage from '../../../tools/SecureStorage'
import Alert from '../elementos/Alert'
import Validador from '../elementos/Validador'
import CryptoJS from 'crypto-js'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que contiene el formulario de Inicio y sus funciones de validacion
   -----------------------------------------------------------------------------------------------
*/
export default function FormularioDeInicio(props){
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [ableToSubmit, setAbleToSubmit] = useState(true)
    // Validaciones de los campos
    const [usernameVal, setUsernameVal] = useState('')
    const [passwordVal, setPasswordVal] = useState('')
    // Alerts system
    const [alerta, setAlerta] = useState(false)
    const [alertaMsg, setAlertaMsg] = useState('')
    // i18n
    const intl = useIntl()
    // React Router DOM
    const history = useHistory()

    // Si hay algun Token o una cookie de Top destinos la elimina
    useEffect(() => {
        const ct = new CookieTools()
        if((!!secureStorage.getItem("access")) || (!!secureStorage.getItem("refresh"))){
            secureStorage.clear()
        }
        ct.deleteCookie("top destinos")
    }, [])

    // Valida un campo especifico
    const validarCampo = (e, field, setFieldVal) => {

        // Instanciamos un objeto que recibe el valor del campo actual
        // Dicha clase instanciada es Validador que facilita la validacion
        const campo = new Validador(field)

        // Vemos si esta vacio el campo actual y almacenamos el mensaje 
        // de error en la variable
        let mensaje = campo.checkVacio(
            intl.formatMessage({id: 'forms.field.empty'})
        ).resultado

        // Si hay mensaje  colocamos un borde al campo rojo, si no, 
        // lo quitamos en caso de que haya
        if(mensaje.length > 0){
            e.target.classList.add('border-danger') 
        } else {
            e.target.classList.remove('border-danger') 
        }

        // Establecemos el mensaje de error como valor del estado de 
        // validacion del respectivo campo
        setFieldVal(mensaje)
    }

    // Funcion llamada al momento de hacer submit
    const _onSubmit = (e) => {
        e.preventDefault() // Prevenimos los eventos por defecto del Submit
        setAlerta(false) // Eliminamos las alertas previas
        setAbleToSubmit(false) // Bloqueamos el boton del Submit
        
        // Filtramos simbolos no permitidos
        const user = username.replaceAll(
            /[\s{}[\]:;,'"¿?*|&/()$%@!=<>]/g,
            ""
        )
        
        // Nos logueamos
        axios.post('/api/v1/rest-auth/login/',
            {
                username: user,
                email: "",
                password: CryptoJS.SHA1(password).toString()
            },
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        ).then(res => {

            // Luego de satisfactoriamente habernos logueado, pedimos el token
            axios.post('/api/v1/token/', 
                {
                    username: user,
                    password: CryptoJS.SHA1(password).toString()
                },
                {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            ).then(resp => {
                // el access y el refresh token los almacenamos en el LocalStorage
                secureStorage.setItem("access", resp.data.access)
                secureStorage.setItem("refresh", resp.data.refresh)
                setAbleToSubmit(true)
                history.push("/u/") // Llevamos al user al Dashboard
            }).catch(() => { // Si hay un error cuando pedimos el token
                setAlertaMsg(intl.formatMessage({id: 'forms.login.dataError'}))
                setAlerta(true)
                setAbleToSubmit(true) // Desbloqueamos el submit button
            })
        
        }).catch(error => { // Si hay un error cuando nos intentamos loguear
            const e = error.response.data
            if(error.response.status >= 500){
                setAlertaMsg(intl.formatMessage({id: 'error'}))
                setAlerta(true)
                setAbleToSubmit(true) // Desbloqueamos el submit button
                return;
            }
            if("non_field_errors" in e){
                const email_verification = e.non_field_errors[0].match(
                    /(correo|mail|email)/g
                )
                if(!!email_verification){
                    setAlertaMsg(intl.formatMessage({id: "confirmation.login.error"}))
                    setAlerta(true)
                }else{
                    setAlertaMsg(intl.formatMessage({id: "forms.login.userOrpasswordIncorrect"}))
                    setAlerta(true)
                }
            }
            setAbleToSubmit(true) // Desbloqueamos el submit button
        })
    }


    return(
        <form onSubmit={_onSubmit} className="position-relative form-ini bg-white rounded shadow border mx-2 py-3 px-4">
            {/* Titulo */}
            <div className="text-center">
                <h3 className="text-muted my-0"><FormattedMessage id="forms.login.title"/></h3>
                <hr/>
            </div>

            {/* Username */}
            <div>

                <label className="mt-2">
                    {intl.formatMessage({id: "forms.field.username"})}
                </label>

                <InputText
                    className="form-control"
                    value={username} 
                    onChange={e => setUsername(e.target.value)}
                    autoComplete="username" 
                    required
                    autoCorrect="off" autoCapitalize="none"
                    onBlur={(e) => validarCampo(e, username, setUsernameVal)}
                />

                <small className="text-danger text-validator">
                    {usernameVal}
                </small>
            </div>

            {/* Password */}
            <div>
                <label className="mt-2">
                    {intl.formatMessage({id: "forms.field.password"})}
                </label>

                <Password 
                    className="d-block"
                    inputClassName="form-control"
                    value={password} 
                    onChange={e => setPassword(e.target.value)} 
                    feedback={false}
                    autoComplete="current-password"
                    required
                    onBlur={(e) => validarCampo(e, password, setPasswordVal)}
                />

                <small className="text-danger text-validator">
                    {passwordVal}
                </small>
            </div>

            {/* Alerta de errores */}
            {alerta &&
                <Alert alert="alert-danger mt-3">
                    <strong >Oops!</strong> {alertaMsg}

                    {!!alertaMsg.match(
                        /(No has verificado tu correo|Your email has not been confirmed)/g
                    ) ?
                    <>
                        , <Link to="/confirm-email/send-again/" className="text-danger">
                            <u><FormattedMessage id="confirmation.login.error.link"/></u>
                        </Link>
                    </> 
                    : null
                    }
                </Alert>
            }
    
            {/* Boton de Submit */}
            <button className="btn btn-ariatel--primary btn-block my-3" type="submit" disabled={!ableToSubmit}>
                {intl.formatMessage({id: "forms.login.submitButton"})}
            </button>

            {/* Links de ayuda */}
            {/* <div className="text-secondary">
              <FormattedMessage id="forms.login.signUpRedirect.text"/>
              <Link className="text-ariatel--primary" to="/registration/">
                <FormattedMessage id="forms.login.signUpRedirect.link"/>
              </Link>
            </div>
            <div className="text-secondary">
              <FormattedMessage id="forms.login.changePwRedirect.text"/> 
              <Link className="text-ariatel--primary" to="/password-reset/">
                <FormattedMessage id="forms.login.changePwRedirect.link"/>
              </Link>
            </div> */}

        </form>
    )
}