import React, { useEffect } from 'react'
import LanguageSwitch from '../../translation/LanguageSwitch'
import { FormattedMessage } from 'react-intl'
import Header from '../formulario/Header'
import ReactGA from 'react-ga'

/* 
   -----------------------------------------------------------------------------------------------
   Componente con mensaje de error cuando un usuario no ha sido activado
   -----------------------------------------------------------------------------------------------
*/
export default function UserNoM2(){
    useEffect(() => { ReactGA.pageview(window.location.pathname); }, [])
    return(
        <>
          {/* Header */}
          <Header/>
          {/* Main */}
          <main className="d-flex justify-content-center pt-5">
              <div className="position-relative bg-white rounded shadow border mx-2 py-3 px-4" style={{maxWidth: "28em"}}>
                <h2 className="text-center"><FormattedMessage id="noAuthTitle"/></h2>
                <hr/>
                <p className=""><FormattedMessage id="noAuthText"/></p>
                <small className="text-muted">
                    <i><FormattedMessage id="noAuthWorking"/></i>
                </small>
              </div>
          </main>
          {/* Lenguaje Switch */}
          <LanguageSwitch margins="my-3" />
        </>
    )
}