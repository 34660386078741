export const locale = {
    "es": {
        // General
        "enter.amount": "Ingrese un monto",
        "more.info": "Más Información",
        "see.more": "Ver más",
        "pending.message": "Todas las transacciones apareceran como pendiente, para revisar si su transaccion fue aceptada, declinada o rechazada revise en el",
        "monto.warning.range": "El monto a pagar debe estar en un rango entre",
        "change.range.button": "Cambiar rango",
        "title.range": "Cambiar rangos de pagos",
        "success.range": "Rango actualizado correctamente",
        "yesterday": "Ayer",
        "3.hours.ago": "Hace 3 horas",
        "tariff": "Tarifa",
        "exchange.rate": "Tasa cambiaria del pago",
        "exchange.msg": "Tasa cambiaria de",
        "values.tables": "Todos los valores son mostrados en la moneda por defecto del sistema",
        "dates.info.1": "Las fechas estan representadas con: ",
        "status": "Estado",
        "iexact": "Igual a (por defecto)",
        "gt": "Mayor a",
        "gte": "Mayor o igual a",
        "lt": "Menor a",
        "lte": "Menor o igual a",
        "icontains": "Que contenga",
        "istartswith": "Que comience con",
        "iendswith": "Que termine con",
        "payment.delay": "El pago puede tardar unos minutos en procesarse",
        "extraFilters": "Filtros adicionales",
        "filterOnPage": "Filtrar en esta pagina",
        "searchInv": "Escoja una tarifa",
        "tax_info": "Info. de Impuesto",
        "tax_total_name": "Nombre del impuesto total",
        "tax_compound": "Impuesto compuesto",
        "contact": "Contacto",
        "isEnabled": "Activo",
        "aqui": "aquí",
        "elements": "elementos",
        "of": "de",
        "page": "Página",
        "502error": "Hay un error cuando intentamos buscar la informacion solicitada",
        "download": "Descargar",
        "see": "Ver",
        "DECLINED": " rechazada",
        "APPROVED": " aprobada",
        "PENDING": " pendiente",
        "remove": "Eliminar",
        "noMatches": "No se encontraron coincidencias",
        "reload.tariffs": "Si no aparecen tarifas creadas recientemente por favor recarge la pagina",
        "payments": "Pagos",
        "payments.of": "Pagos de",
        "everyone": "todos los usuarios",
        "exportcsv": "Exportar CSV",
        "segundos": "segundos",
        "resend.mail": "Puedes volver a enviar el correo en",
        "tariffs": "Tarifas",
        "utype": "Tipo de usuario",
        "devices": "Dispositivos",
        "admin.pages": "Páginas solo para el administrador",
        "user.pages": "Páginas para otro tipo de usuario",
        "payments.helper": "Deje el campo de nombres vacios para buscar los pagos de todos los usuarios",
        "formato.error": "Formato Invalido",
        "balance": "Saldo",
        "totals": "Total",
        "choose.user": "Escoja un usuario",
        "answered.calls": "Llamadas Atendidas",
        "active.calls": "Total de Llamadas Activas",
        "username.incorrect.format": "El nombre de usuario tiene un formato no valido",
        "errorcatcher.title": "Ocurrió un error inesperado",
        "errorcatcher.text": "Por favor presione el boton para volver al inicio",
        "bank.choose": "Escoge un banco",
        "bank": "Banco",
        "error.signup.modal.title":"Ha ocurrido un error mientras se cargaban tus datos",
        "error.signup.modal.exp1":"Si te has registrado recientemente, esto se debe a que nuestro equipo aun no te ha asignado un ID de usuario",
        "error.signup.modal.exp2":"Por favor, sea paciente y espere mientras nuestro equipo le asigna un ID, mientras, puede aceder a la plataforma pero sus opciones serán limitadas",
        "awesome": "¡Muy bien!",
        "destinos": "Destinos más populares",
        "ofertas": " Ofertas",
        "filter.placeholder": "Filtrar",
        "time": "Tiempo",
        "noAuthTitle": "Tu cuenta no ha sido activada aún",
        "noAuthWorking": "¡Estamos trabajando en ello!",
        "noAuthText": "Parece que te has registrado pero nuestro equipo no ha activado tu cuenta, por favor espera a que nuestro equipo la active.",
        "balanceFound": "¡Balance encontrado!",
        "currentBalance": "Saldo Actual: ",
        "registration": "Registro",
        "accountSummary": "Resumen de su cuenta",
        "chart.calls": "Gráfica de llamadas",
        "quickStats": "Estadisticas rapidas",
        "email": "Correo",
        "welcome": "Bienvenido",
        "update": "Actualizar",
        "error": "Algo ocurrió por favor recargue la pagina o vuelva mas tarde",
        "others": "Otro",
        "currency": "Divisa",
        "number": "Numero",
        "privado": "Cargando...",
        "loading.info": "Estamos cargando tu información...",
        "no.calls": "No has tenido llamadas",
        "info.calls": "Todos los campos son opcionales y tendrán el valor de defecto: si el ID del usuario esta vacio vera sus propias llamadas, si los campos 'desde' y 'hasta' estan ambas vacias se tomara desde las 12:00 AM hasta la hora actual",
        "aboutYou": "Sobre tí",
        "noDataTitle": "¿Quien eres?",
        "noDataText1": "Parece que no hay información de este usuario en nuestra web, por favor comunicate con nosotros a través de",
        "noDataText2": "para llegar a una solución",
        "noDataTextemail": " nuestro correo electronico ",

        // Header
        "header.menu.userBalance": "Ver Saldo de usuarios",
        "header.menu.userInformation": " Ver Información de usuarios",
        "header.menu.ranges": "Rangos de pagos",
        "header.menu.reports": "Reportes",
        "header.menu.accounting": "contabilidad",
        "header.menu.billing": "Facturación",
        "header.menu.logout": " Cerrar Sesión",
        "header.menu.profile": " Tu Perfil",
        "header.menu.changePassword": " Cambiar Contraseña",
        "header.menu.aggregates": " Ver Agregados",
        "header.menu.devices": "  Detalles de Dispositivos",
        "header.menu.rates": " Ver Rates",
        "header.menu.update.balance": " Actualizar el saldo de usuarios",
        "header.menu.create.tariff": " Crear una nueva tarifa",
        "header.menu.update.tariff": " Cambiar el nombre de una tarifa",
        "header.menu.payments": " Historial de Pagos",
        "header.menu.add.payments.admin": " Realizar pagos",
        "header.menu.add.payments": " Añadir saldo a tu cuenta",

        // Footer 
        "footer.policyLink.termsAndConditions": "Terminos y Condiciones",
        "footer.policyLink.privacy": "Politica de Privacidad",
        "footer.policyLink.termsUse": "Terminos de Uso",
        "footer.policyLink.userGuide": "Guia de Usuario",
        "footer.copyright": "©2020-2021 AriaTel SAS. Todos los derechos reservados.",

         // Out of service
         "outOfService.title": "Fuera de servicio",
         "outOfService.text": "Este servicio no esta disponible en este momento. ¡Estamos trabajando en ello!",
         "outOfService.redirectLink": "Ir a alternativas disponibles",

        // 404
        "404.title": "Oops! No pudimos encontrar esa pagina",
        "404.text": "Parece que la pagina que buscas no existe",
        "404.redirectLink": "Vuelve al Inicio",

        // Your Profiles
        "profile.agreement.date":"Fecha de registro",
        "profile.agreement.number":"Numero de registro",
        "profile.taxation.country":"País fiscal",
        "profile.vat.regularNumber":"Cantidad de IVA",
        "profile.vat.percent":"Porcentaje de IVA",
        "profile.language":"Lenguaje",
        "profile.username":"Nombre de usuario",
        "profile.company":"Compañia",
        "profile.email":"Correo electronico",
        "profile.movil":"Movil",
        "profile.country":"País",
        "profile.city":"Ciudad",        

        // Email Confirmation
        "verify_email.success.title": "Tu correo fue verificado",
        "verify_email.success.p1": "Ahora nuestro equipo debe asignarte información adicional",
        "verify_email.success.p2": "Por el momento podras entrar al sitio, pero tendras limitaciones",
        "verify_email.error.title": "Ha ocurrido un error",
        "verify_email.error.p1": "Intente mas tarde o vuelva a enviar el correo de verificación",
        "confirmation.login.error": "No has verificado tu correo",
        "confirmation.login.error.link": "verificalo aquí",
        "resend.button": "Reenviar correo",
        "resend.title": "Introduzca el correo ligado a su cuenta",
        "resend.want.button": "Quiero verificar mi correo",
        "verify_lobby.title": "Por favor, confirme su correo",
        "verify_lobby.p1": "Confirme su correo electronico con el link que le enviamos a la dirección dada en el registro de su cuenta.",
        "verify_lobby.p2": "Si no recibió el correo electronico, haga click en el boton debajo para reenviar el correo de verificación",

        
        // Quickstat
        "quickstat.today": "Hoy",
        "quickstat.month": "Mes",
        "quickstat.calls": "Llamadas",
        "quickstat.duration": "Duración",
        "quickstat.revenue": "Ingresos",
        "quickstat.self_cost": "Costo",
        "quickstat.profit": "Ganancia",
        "quickstat.margin": "Margen",
        "quickstat.price": "Precio",
        
        // Formularios
        "forms.field.email": "Correo Electronico",
        "forms.field.username": "Nombre de Usuario",
        "forms.invalid": "Formulario invalido, por favor intente otra vez",
        "forms.field.password": "Contraseña",

            // Validaciones
            "forms.field.empty": "Este campo no puede estar vacio",
            "forms.field.invalidMail": "Escribe una direccion de correo valida",
            "forms.field.invalidPhone": 'El numero es invalido, no uses simbolos como "(  )  [  ]  {  }"',
            "forms.field.passwordUnmatch": "Las contraseñas no coinciden",

            // Registro
            "forms.signUp.title": "Registrarse",
            "forms.signUp.repeatPassword": "Repetir Contraseña",
            "forms.signUp.phone": "Telefono",
            "forms.signUp.country": "País",
            "forms.signUp.state": "Estado",
            "forms.signUp.company": "Compañia",
            "forms.signUp.loginRedirection.text": "Ya tengo una cuenta",
            "forms.signUp.loginRedirection.link": " iniciar Sesion",
                // onSubmit 
                "forms.signUp.submitButton":"Enviar una peticion",
                "forms.signUp.success": " Tu cuenta ha sido registrada, ahora nuestro equipo activara su cuenta tan pronto como sea posible",
                "forms.signUp.error": " Algo ocurrió, intenta mas tarde",
            
            // Inicio
            "forms.login.title": "Iniciar Sesion",
            "forms.login.signUpRedirect.text": "¿No tiene cuenta? ", 
            "forms.login.signUpRedirect.link": "Registrese",
            "forms.login.changePwRedirect.text": "¿Olvido su Contraseña? ",
            "forms.login.changePwRedirect.link": "Cambiela",
                // onSubmit 
                "forms.login.submitButton": "Iniciar Sesion",
                "forms.login.dataError": "Hay un error cuando buscamos tus datos",
                "forms.login.userOrpasswordIncorrect": "El usuario o la contraseña son incorrectos",
                "forms.login.error": "Ocurrió un error, intente mas tarde",
            
            // Password Reset
            "forms.passReset.title": "Correo del Usuario",
            "forms.passReset.loginRedirect": "Volver al Inicio",            
                // onSubmit
                "forms.passReset.submitButton": "Enviar Correo",
                "forms.passReset.emailError": "El correo electronico es incorrecto",
                "forms.passReset.error": "Hubo un error, intente mas tarde",

            // Password Reset Confirm
            "forms.passResetConfirm.info.title": "Se ha enviado un correo a la direccion proporcionada, revise su bandeja para cambiar su contraseña",
            "forms.passResetConfirm.info.text": "Su contraseña fue cambiada exitosamente",
            "forms.passResetConfirm.title": "Cambie la Contraseña",
            "forms.passResetConfirm.newPass": "Nueva contraseña",
            "forms.passResetConfirm.newPassRepeat": "Repetir la nueva contraseña",
            "forms.passResetConfirm.token": "Token secreto",
            "forms.passResetConfirm.uid": "ID del usuario",
            "forms.passResetConfirm.tryAgain.text": "¿No se envió ningún correo?",
            "forms.passResetConfirm.tryAgain.link": " Intente otra vez",
                // onSubmit
                "forms.passResetConfirm.submitButton": "Cambiar Contraseña",    
                "forms.passReset.tokenOrIdError": " Token o ID incorrectos",
            
            // Password Change
            "forms.changePass.legend": "Cambie su Contraseña",
            "forms.changePass.oldPass": "Contraseña Actual",
            "forms.changePass.newPass": "Nueva Contraseña",
            "forms.changePass.newPassRepeat": "Repita su nueva contraseña",
                //onSubmit
                "forms.changePass.error":"Esa no es tu contraseña actual",
                "forms.changePass.success": "Tu contraseña fue cambiada",
                "forms.changePass.submitButton": "Cambiar",  
    
        // Aggregates        
        "aggregates.time_interval.today": "Hoy",
        "aggregates.time_interval.1d": "Hace un día",
        "aggregates.time_interval.2d": "Hace dos días",
        "aggregates.time_interval.1w": "Hace una semana",
        "aggregates.time_interval.15d": "Hace 15 días",
        "aggregates.time_interval.1m": "Hace un mes",
        "aggregates.time_interval.2m": "Hace 2 meses", 
        "aggregates.time_interval.3m": "Hace 3 meses", 
        "aggregates.time_interval.6m": "Hace 6 meses",
        "aggregates.time_interval.1y": "Hace 1 año", 
        "aggregates.time_interval.2y": "Hace 2 años",
        "aggregates.time_interval.title": "Seleccionar un periodo de tiempo",
        "aggregates.dst_name": "Nombre de DST",
        "aggregates.dst_group": "Grupo de DST",
        "aggregates.originator": "Origen",
        "aggregates.originator_id": "ID de origen",
        "aggregates.terminator": "Destino",
        "aggregates.terminator_id": "ID de destino",
        "aggregates.billed_originator_with_tax": "Origen con impuesto",
        "aggregates.duration": "Duración",
        "aggregates.answered_calls": "Atendidas",
        "aggregates.total_calls": "Totales",
        "aggregates.asr": "ASR",
        "aggregates.acd": "ACD",
        "aggregates.pdd": "PDD",
        "aggregates.profit": "Ganancia",
        "aggregates.customer": "Cliente",
        "aggregates.time_b": "Tiempo Facturado",
        "aggregates.billed": "Facturado",

        // Calls
        "calls.rpid": "RPID",
        "calls.tp_src": "Numero SRC enviado al TP",
        "calls.tp_dst": "Numero DST enviado al TP",
        "calls.dc": "Codigos de desconexión",
        "calls.op_dc": "Codigos de desconexión del OP",
        "calls.tp_dc": "Codigos de desconexión del TP",
        "calls.routing_attempt": "Intento de ruteo",
        "calls.mos": "MOS",
        "calls.mos_packetloss": "Packet loss",
        "calls.mos_jitter": "Jitter",
        "calls.mos_roundtrip": "Roundtrip",
        "calls.accountcode": "Codigo de cuenta", 
        "calls.answer_time": "Tiempo de respuesta", 
        "calls.billsec": "Billsec", 
        "calls.date": "Fecha", 
        "calls.did_billsec": "DID billsec", 
        "calls.did_inc_price": "DID inc precio", 
        "calls.did_price": "Precio de DID", 
        "calls.did_prov_price": "Precio DID proveedor", 
        "calls.did_provider_id": "ID del DID proveedor", 
        "calls.dst_device_id": "DST de ID de dispositivo", 
        "calls.dst_user_id": "DST de ID de usuario", 
        "calls.end_time": "Tiempo de finalizado", 
        "calls.hangupcause": "Razon de colgado", 
        "calls.localized_dst": "DST localizado", 
        "calls.originator_codec": "Codec de OG", 
        "calls.originator_ip": "IP de OG", 
        "calls.pai": "PAI", 
        "calls.partner_price": "Precio de partner", 
        "calls.partner_rate": "Tarifa de partner", 
        "calls.pdd": "PDD", 
        "calls.processed": "Procesado", 
        "calls.provider_billsec": "Billsec de proveedor", 
        "calls.provider_id": "ID de proveedor", 
        "calls.real_billsec": "Billsec real", 
        "calls.real_duration": "Duracion real", 
        "calls.reseller_price": "Precio de reseller", 
        "calls.reseller_rate": "Tarifa de reseller", 
        "calls.server_id": "ID de servidor", 
        "calls.src_device_id": "ID de dispositivo del SRC", 
        "calls.src_user_id": "user ID del SRC", 
        "calls.terminated_by": "Finalizada por", 
        "calls.terminator_codec": "Codec de terminator", 
        "calls.terminator_ip": "IP del terminator", 
        "calls.user_billsec": "Billsec de usuario", 
        "calls.user_id": "ID de usuario", 
        "calls.calldate2": "Inicio de la llamada",
        "calls.timezone": "Zona horaria",
        "calls.clid": "CLID",
        "calls.destination": "Destino",
        "calls.dispod": "Disposición",
        "calls.dst": "Destino",
        "calls.duration": "Duración",
        "calls.nice_billsec": "Nice billsec",
        "calls.prefix": "Prefijo",
        "calls.provider": "Proveedor",
        "calls.provider_price": "Precio del proveedor",
        "calls.provider_rate": "Tarifa del proveedor",
        "calls.src": "SRC",
        "calls.user": "Usuario",
        "calls.user_price": "Precio del usuario",
        "calls.user_rate": "Tarifa del usuario",
        "calls.uniqueid": "ID unica",
        "calls.titulo": " Registro de Llamadas",
        "calls.filter.p_start": "Desde",
        "calls.filter.p_end": "Hasta",
        "calls.filter.c_type": "Tipo de Llamada",
        "calls.filter.c_type_all": "Todas",
        "calls.filter.c_type_answered": "Contestadas",
        "calls.filter.c_type_no_answer": "No Contestadas",
        "calls.filter.c_type_failed": "Fallidas",
        "calls.filter.c_type_busy": "Ocupadas",
        "calls.filter.o_point": "ID de Punto de Origen",
        "calls.search": "Buscar",
        "calls.reset": "Limpiar Campos",
        "calls.segundos": "(Seg.)",
        "calls.cdr_table_export": "Registros",
        "calls.nav.title": "Registros de llamadas",
        "records.of": "Registros de ",
        "Enero": "Enero",
        "Febrero": "Febrero",
        "Marzo": "Marzo",
        "Abril": "Abril",
        "Mayo": "Mayo",
        "Junio": "Junio",
        "Julio": "Julio",
        "Agosto": "Agosto",
        "Septiembre": "Septiembre",
        "Octubre": "Octubre",
        "Noviembre": "Noviembre",
        "Diciembre": "Diciembre",
        "records.to.down": "Registros para descargar",
        "records.month.error": "Hay un error cuando buscamos tus registros mensuales",
        "records.week.error": "No pudimos encontrar los registros solicitados",
        "records.week.filter.button":"Filtrar en el registro",
        "records.week.filter.reset":"Eliminar filtros del registro",
        "records.week.table.reset":"Eliminar filtros de la tabla debajo",

        // Devices
        "devices.spec": "Especificación",
        "devices.val": "Valor",
        "devices.error.choose": "Debes seleccionar un dispositivo",
        "devices.error.search": "No podemos encontrar lo que buscas",
        "devices.error.notFound": "No se encontraron dispositivos",
        "devices.choose": "Escoge un Dispositivo",
        "devices.id" : "ID",
        "devices.name" : "Nombre",
        "devices.host" : "Host",
        "devices.secret" : "Secret",
        "devices.ipaddr" : "Dirección IP",
        "devices.port" : "Puerto",
        "devices.accountcode" : "Accountcode",
        "devices.callerid" : "Caller ID",
        "devices.username" : "Usuario",
        "devices.device_type" : "Tipo de dispositivo",
        "devices.user_id" : "ID de usuario",
        "devices.insecure" : "Inseguro",
        "devices.description" : "Descripción",
        "devices.timeout" : "Timeout",
        "devices.allow_duplicate_calls" : "Permitir duplicar llamadas",
        "devices.call_limit" : "Limite de llamadas",
        "devices.grace_time" : "Grace time",
        "devices.server_id" : "ID del servidor",
        "devices.change_failed_code_to" : "Cambiar codigo de 'Failed' a",
        "devices.max_timeout" : "Maximo timeout",
        "devices.cps_call_limit" : "Limite de llamadas de CPS",
        "devices.cps_period" : "Periodo de CPS",
        "devices.callerid_number_pool_id" : "Caller ID number pool ID",
        "devices.op" : "OP",
        "devices.op_active" : "OP activo",
        "devices.op_tech_prefix" : "OP tech prefix",
        "devices.op_routing_algorithm" : "Algoritmo de routing OP",
        "devices.op_routing_group_id" : "ID de grupo de routing OP",
        "devices.op_tariff_id" : "ID de tarifa del OP",
        "devices.op_capacity" : "Capacidad del OP",
        "devices.op_src_regexp" : "Expresión regular del source en el OP",
        "devices.op_src_deny_regexp" : "Expresión regular de rechazo del source en el OP",
        "devices.tp" : "TP",
        "devices.tp_active" : "TP activo",
        "devices.tp_tech_prefix" : "TP tech prefix",
        "devices.tp_tariff_id" : "ID de tarifa del TP",
        "devices.tp_capacity" : "Capacidad TP",
        "devices.tp_src_regexp" : "Expresión regular del source en el TP",
        "devices.tp_src_deny_regexp" : "Expresión regular de rechazo del source en el TP",
        "devices.custom_sip_header" : "Cabecera SIP personalizada",
        "devices.register" : "Registro",
        "devices.interpret_busy_as_failed" : "Interpretar Busy como Failed",
        "devices.interpret_noanswer_as_failed" : "Interpretar No Answered como Failed",
        "devices.sticky_contact" : "Sticky contact",
        "devices.periodic_check" : "Chequeo periodico",
        "devices.alive" : "Vivo",
        "devices.op_custom_tariff_id" : "ID de tarifa personalizada en el OP",
        "devices.ipaddr_range_start" : "Dirección IP (Rango de inicio)",
        "devices.ipaddr_range_end" : "Dirección IP (Rango de finalización)",
        "devices.op_destination_transformation" : "Transformación de destino en el OP",
        "devices.quality_routing_id" : "ID de routing de calidad",
        "devices.op_source_transformation" : "OP source transformation",
        "devices.tp_source_transformation" : "TP source transformation",
        "devices.disable_q850" : "Desactivar causa Q.850",
        "devices.forward_rpid" : "Forward RPID",
        "devices.forward_pai" : "Forward PAI",
        "devices.bypass_media" : "Bypass media",
        "devices.disable_sip_uri_encoding" : "Desactivar SIP URI encoding",
        "devices.use_invite_dst" : "Usar destino de petición INVITE",
        "devices.inherit_codec" : "Heredar codec",
        "devices.enforce_lega_codecs" : "Enforce lega codecs",
        "devices.use_pai_if_cid_anonymous" : "Usar PAI si CID es anonimo",
        "devices.ring_instead_progress" : "Repicar en lugar de Session Progress",
        "devices.set_sip_contact" : "Establecer contacto SIP",
        "devices.hidden_device" : "Dispositivo oculto",
        "devices.change_rpidpai_host" : "Cambiar RPID/PAI host",
        "devices.progress_timeout" : "Progress Timeout",
        "devices.op_number_pool_id" : "OP number pool ID",
        "devices.op_match_tariff_id" : "OP match tariff ID",
        "devices.op_use_pai_as_number" : "OP usar PAI  number",
        "devices.op_callerid_matches" : "OP caller ID matches",
        "devices.op_dst_matches" : "OP destination matches",
        "devices.op_dst_number_pool_id" : "Destination number pool ID",
        "devices.ignore_183nosdp" : "Ignorar SIP 183 sin SDP",
        "devices.op_fake_ring" : "OP fake ring",
        "devices.op_username" : "OP Usuario",
        "devices.op_password" : "OP Contraseña",
        "devices.cip" : "CIP",
        "devices.callerid_number_pool_type" : "Caller ID number pool type",
        "devices.callerid_number_pool_deviation" : "Caller ID number pool deviation",
        "devices.op_tariff_intra" : "OP tariff intra",
        "devices.op_tariff_inter" : "OP tariff inter",
        "devices.op_tariff_indeter" : "OP tariff indeter",
        "devices.us_jurisdictional_routing" : "US jurisdictional routing",
        "devices.tp_tariff_intra" : "TP tariff intra",
        "devices.tp_tariff_inter" : "TP tariff inter",
        "devices.tp_tariff_indeter" : "TP tariff indeter",
        "devices.tp_us_jurisdictional_routing" : "TP US jurisdictional routing",
        "devices.tariff_intra" : "tariff intra",
        "devices.tariff_inter" : "tariff inter",
        "devices.tariff_indeter" : "tariff indeter",
        "devices.codecs" : "Codec",

        // Rates
        "rates.of.tariff": "Rates de tarifa con ID",
        "rates.of.deviceid": "Rates de dispositivo con ID",
        "rates.error.choose.nothing": "Debes seleccionar una tarifa",
        "rates.tariffID": "ID de la tarifa",
        "rates.deviceID": "ID del dispositivo",
        "rates.searchby": "Buscar Por",
        "rates.deviceID.type": "Escoge un Dispositivo",
        "rates.tariffID.type": "Escoge una Tarifa",
        "rates.direction": "Dirección",
        "rates.destination": "Destino",
        "rates.prefix": "Prefijo",
        "rates.code": "Codigo",
        "rates.tariff_rate": "Tarifa de destino",
        "rates.con_fee": "Tarifa de conexion",
        "rates.increment": "Incremento",
        "rates.min_time": "Tiempo minimo",
        "rates.start_time": "Tiempo de inicio",
        "rates.end_time": "Tiempo de finalización",

        // Update Balance
        "update.balance.h6": " Suma o resta al Saldo actual",
        "update.balance.new.balance": "Nuevo Saldo",
        "update.balance.balance.now": "Saldo ahora (USD)",
        "User was not found": "El usuario no fue encontrado",
        "User balance updated": "Saldo del usuario actualizado",
        "update.balance.error.nothing": "Debes llenar todos los campos",
        "Incorrect hash": "Error cuando intentamos cambiar el saldo",

        // Create Tariff
        "tariff.tariff.name": "Nombre de la tarifa",
        "tariff.create": "Crear",
        "tariff.update.wrongid": "ID de tarifa es incorrecto",
        "tariff.success": "Tarifa creada satisfactoriamente",
        "tariff.Incorrect hash": "Error al intentar crear la tarifa",
        "tariff.update.Incorrect hash": "Error al intentar actualizar la tarifa",
        "tariff.name": "Nombre",

        // Update Tariff
        "update.tariff.success": "Nombre de la tarifa cambiada satisfactoriamente",
        "update.tariff.current.name": "Nombre actual",
        "update.tariff.new.name": "Nuevo nombre",

        // Payments
        "payments.amount.payed": "Monto pagado",
        "payments.amount.payed.tax": "Monto pagado con impuesto",
        "payments.amount.charged": "Monto cargado a su cuenta",
        "payments.amount.between": "Monto entre",
        "payments.Payments were not found": "No se encontraron pagos",
        "Payments were not found": "No se encontraron pagos",
        "Bad login": "No tienes permiso para realizar esta acción",
        "payments.Bad login": "No tienes permiso para ver esta información",
        "payments.Incorrect hash": "Error al buscar la informacion solicitada",
        "payments.user": "Usuario",
        "payments.date": "Fecha",
        "payments.amount": "Monto",
        "payments.amount_with_tax": "Monto con impuesto",
        "payments.comment": "Comentario",
        "payments.currency": "Moneda",

        // Add Payments
        "cc.card.warning": "Cada transacción será cobrada a su tarjeta en la moneda Peso colombiano (COP)",
        "error.add.cc.repeated": "Parece que la tarjeta ya está registrada",
        "error.add.cc": "Ocurrió un error cuando intentamos almacenar tu tarjeta de credito, intente más tarde",
        "banks.city.state": "Ciudad/Estado del banco",
        "banks.info.accountnumber.ahorro": "Número de cuenta ahorros",
        "banks.info.accountnumber.corriente": "Número de cuenta corriente",
        "banks.info.nit": "NIT",
        "banks.info.contact": "Contacto del banco",
        "banks.info.ach": "Número de ruta",
        "banks.info.abaoriban": "Código ABA",
        "banks.info.accountname": "Nombre de la cuenta",
        "banks.info.accountnumber": "Número de la cuenta",
        "banks.info.address": "Dirección del banco",
        "banks.info.phone": "Número telefónico del banco",
        "banks.info.swiftcode": "Código SWIFT",
        "add.payments.choose.amount": "Selecciona un monto",
        "payments.p_id": "ID de pago",
        "add.payments.choose.method": "Metodos de pago",
        "add.payments.transfer": "Transferencia",
        "add.payments.creditCard": "Tarjeta de Credito",
        "add.payments.text.banks": "Añade saldo a tu cuenta a traves de las diferentes formas de pago en los bancos que tenemos disponibles para ti:",
        "add.payments.bank.title": "Bancos disponibles",
        "add.payments.failed.user.title": "No pudimos subir su archivo",
        "add.payments.success.user.title": "¡Documento subido correctamente!",
        "add.payments.success.user.text": "Ahora nuestro equipo revisara tu archivo, luego añadiremos el saldo respectivo a su cuenta",
        "add.payments.empty": "Debes llenar todos los campos del formulario",
        "add.payments.report": "Reporta tu pago",
        "add.payments.choose.file": "Escoge un archivo",
        "add.payments.error.server": "¡Oops! Ocurrió un error cuando intentamos subir su documento, por favor intente mas tarde",
        "add.payments.error.filetype": "Tu documento debe ser una imagen o un PDF",
        "add.payments.error.password": "La contraseña ingresada es incorrecta",
        "add.payments.error0": "El monto ingresado está fuera de los rangos permitidos",
        "add.payments.error1": "El tipo de dato ingresado no es correcto",
        "add.payments.error2": "El pago no puede ser de 0.0$",
        "add.payments.error3": "Establece un comentario al pago",
        "add.payments.error4": "Debes colocar el ID del pago",
        "add.payments.taxInfo": "Puede ingresar el monto con O sin impuestos. No ambos al mismo tiempo. Según lo que ingrese, se agregarán impuestos o no.",
        "add.payments.providerInfo": "Los pagos son predeterminados del cliente, si elige establecer en proveedor, el pago será al proveedor.",
        "add.payments.inTax": "Añadir impuesto",
        "add.payments.toProvider": "Pago a proveedor",
        "add.payments.create": "Hacer pago",
        "add.payments.create.pse": "Pagar a través de PSE",
        "add.payments.create.creditCard": "Pagar con tarjeta de credito",
        "add.payments.create.transfer": "Pagar por transferencia",
        "add.payments.close": "Cerrar",
        "add.payments.generatePDF": "Generar PDF",
        "add.payments.errorMsg": "Ha ocurrido un error al intentar crear el pago, por favor verifique si escogió un usuario real",
        "add.payments.failed": "¡Creación del pago fallida!",
        "add.payments.success": "¡Pago Realizado!",

        // PayU
        "payu.status": "Estado de la transacción",
        "payu.transaction": "Transacción",
        "payu.about": "Sobre el pago",
        "payu.amount": "Monto (USD)",
        "payu.tax": "Monto del impuesto",
        "payu.description": "Descripción del pago",
        "payu.email": "Correo electronico del comprador",
        "payu.ref": "Referencia",
        "payu.id": "Identificador de la transacción",
        "payu.date": "Fecha de transacción",
        "payu.card": "Sobre la tarjeta utilizada",
        "payu.cardtype": "Tipo de tarjeta",
        "payu.fees": "Cuotas de pago establecidas",
        "payu.foot": "Si presenta algun incoveniente por favor comuniquese al siguiente numero telefonico",
        
        // Add and delete Offers
        "offer.days": " días",
        "offer.disponible": "Oferta disponible, quedan ",
        "offer.pendiente": "Oferta se activará en ",
        "addoffers.tooltip.info": "<i class='text-warning'>Escoger solo una fecha indica que esa sera la fecha de expiración de la oferta, comenzando por el día actual</i><div class='my-2 border border-info rounded h-0 w-100'></div><i class='text-warning'>Si lo deja vacio se tomara un plazo de una semana comenzado por el dia actual</i>",
        "addoffers.select.time": "Seleccione la duración de la oferta",
        "addoffers.title": "Añadir Ofertas",
        "addoffers.newoffer": "Crear nueva",
        "addoffers.header": " Añadir o borrar ofertas",
        "addoffers.select.country": "Seleccione una locación",
        "addoffers.percent": "Descuento",
        "addoffers.price": "Precio Normal",
        "addoffers.finalPrice": "Precio Final",
        "addoffers.button": "Crear Oferta",
        "addoffers.current": "Ofertas actuales",
        "addoffers.alert.create.success": "¡Oferta añadida correctamente!",
        "addoffers.alert.create.error": "No pudimos crear la oferta debido a un error",
        "addoffers.alert.delete.success": "¡Oferta eliminada correctamente!",
        "addoffers.alert.delete.error": "No pudimos eliminar la oferta debido a un error",
        "nooffers": "Actualmente no hay ofertas",
        "notopdestinos":"No tienes destinos top actualmente",
        
        // Metodos de pago
        "btn.bancolombia.title": "Pago mediante Botón Bancolombia",
        "btn.bancolombia.redirection.title": "Fuiste redirigido al portal de Bancolombia",
        // "btn.bancolombia": "",
        // "btn.bancolombia": "",
        // "btn.bancolombia": "",
        // "btn.bancolombia": "",
        "pse.person": "Tipo de persona",
        "pse.pasaporte": "Pasaporte",
        "pse.banks.load.error": "Hubo un error al traer la informacion de los bancos disponibles, por favor, intente mas tarde",
        "pse.title": "Pago mediante PSE",
        "pse.doc": "Documento de Identidad",
        "pse.boton": "Hacer transacción",
        "pse.redirection.title": "Fuiste redirigido al portal del PSE",
        "pse.redirection.body": "Puedes cerrar esta ventana",
        "pse.transaction.done": "Transacción de PSE finalizada",
        "pse.boton.pdf": "Descargar recibo en PDF",
        "pse.invoice": "Recibo",
        "pse.factura": "Factura",
        "pse.bank": "Banco de la transacción",
        "cash.title": "Pagos en efectivo",
        "cash.choose": "Seleccione el monto a recargar",
        "cash.response.title": "Transacción en efectivo creada",
        "cash.exp.date": "Fecha de expiración",
        "cash.auth": "Autorización",
        "cc.title": "Transacción con tarjeta de credito finalizada",
        "cc.conf.body": "¿Estas seguro de querer continuar?",
        "cc.conf.title": "Borrar tarjeta de credito",
        "cc.list.title": "Tarjetas registradas",
        "cc.recharge": "Recargar saldo",
        "cc.nocc": "Parece que no encontramos tarjetas registradas",
        "cc.nofoundcc": "No tienes tarjetas registradas",
        "cc.number": "Numero de tarjeta",
        "cc.exp": "Fecha de expiracion",
        "cc.type": "Tipo de Tarjeta",
        "cc.name": "Nombre del tarjetahabiente",
        "cc.msgsuccess": "Tarjeta eliminada correctamente",
        "cc.msg.error": "La tarjeta no pudo ser eliminada",
        "cc.cards": "tarjetas",
        "cc.register": "Registrar una tarjeta",
        "cc": "Tarjeta de credito",
        "cc.dues": "Cuotas",
        "cc.amount.recharge": "Monto a recargar",
        "cc.pay.title": "Recarga mediante tarjeta de credito",
        "cc.hasnt": "No tengo tarjetas de credito registradas",
        "cc.trans.res": "Resultado de la transacción",
        "cc.recharge.error": "Hubo un error al recargarte saldo",
        "cc.error.list.title": "El formulario presenta los siguientes errores",
        "cc.mm.error": "Mes ingresado invalido",
        "cc.yy.error": "Año ingresado invalido",
        "cc.date.error": "Fecha ingresada invalida",
        "cc.your.name": "Tu nombre aquí",
        "cc.save": "Guardar tarjeta",
        "cc.save.success": "Tu tarjeta de credito fue guardada con exito",
        "cc.save.success.body": "Ahora puedes usarla cada vez que vayas a realizar una transacción en este portal",
        "cc.save.error": "Hubo un error al guardar tu tarjeta de credito",
        "cc.add.another": "Añadir otra tarjeta",
        "cc.pay.redirection": "Recargar saldo con mi tarjeta",
        "cc.choose.card": "Escoja una tarjeta de credito",
        "cash.choose.fran": "Escoja una franquicia",

        // User Details
        "details.placeholder.username": "Escriba el nombre",
        "details.placeholder.userID": "Escriba el ID",
        "profile.details.username": "Nombre de usuario",
        "profile.details.first_name": "Nombre",
        "profile.details.surname": "Apellido",
        "profile.details.personalid": "ID Personal",
        "profile.registration.reg_address": "Dirección",
        "profile.registration.reg_postcode": "Codigo Postal",
        "profile.registration.reg_city": "Ciudad",
        "profile.registration.reg_country": "País",
        "profile.registration.reg_state": "Estado",
        "profile.registration.reg_phone": "Telefono",
        "profile.registration.reg_mobile": "Movil",
        "profile.registration.reg_fax": "Fax",
    },
    "en": {
        // General
        "enter.amount": "Enter an amount",
        "more.info": "More Information",
        "see.more": "See more",
        "pending.message": "Every transaction will appear as pending, if you want to know if your transaction was accepted or declined check in the",
        "monto.warning.range": "The amount to be paid must be in a range between",
        "change.range.button": "Update range",
        "title.range": "Change Payment Ranges",
        "success.range": "Range updated successfully",
        "yesterday": "Yesterday",
        "3.hours.ago": "3 Hours ago",
        "tariff": "Tariff",
        "exchange.rate": "Payment exchange rate",
        "exchange.msg": "Exchange from",
        "values.tables": "All values retrieved in default currency system",
        "dates.info.1": "Dates are represented in",
        "status": "Status",
        "iexact": "Equal to (default)",
        "gt": "Greater than",
        "gte": "Greater than or equal to",
        "lt": "Less than",
        "lte": "Less than or equal to",
        "icontains": "Contains",
        "istartswith": "Starts with",
        "iendswith": "Ends with",
        "payment.delay": "Payment may take a few minutes to process",
        "extraFilters": "Extra filters",
        "filterOnPage": "Filter on this page",
        "searchInv": "Choose a tariff",
        "tax_info": "Tax Info.",
        "tax_total_name": "Tax total name",
        "tax_compound": "Compound tax",
        "contact": "Contact",
        "isEnabled": "Is enabled?",
        "aqui": "here",
        "elements": "elements",
        "of": "of",
        "page": "Page",
        "502error": "There's an error when we try to search your data",
        "download": "Download",
        "see": "Visualize",
        "DECLINED": " declined",
        "APPROVED": " approved",
        "PENDING": " pending",
        "remove": "Remove",
        "noMatches": "No matches found",
        "reload.tariffs": "If tariffs created recently do not appear, please reload the page",
        "payments": "Payments",
        "payments.of": "Payments of",
        "everyone": "all the users",
        "exportcsv": "Export CSV",
        "segundos": "seconds",
        "resend.mail": "You could send the mail again in",
        "tariffs": "Tariffs",
        "utype": "User Type",
        "devices": "Devices",
        "admin.pages": "Pages only for admin",
        "user.pages": "Pages for another user type",
        "payments.helper": "Leave the field \"name\" empty to search payments for all the users",
        "formato.error": "Invalid Format",
        "balance": "Balance",
        "totals": "Totals",
        "choose.user": "Choose an user",
        "answered.calls": "Answered Calls",
        "active.calls": "Total Active Calls",
        "username.incorrect.format": "Username format is not valid",
        "errorcatcher.title": "Something happened",
        "errorcatcher.text": "Please click on the button to go back to the login page",
        "bank.choose": "Choose a bank",
        "bank": "Bank",
        "error.signup.modal.title":"It has occurred an error trying to load all your data",
        "error.signup.modal.exp1":"If you registered recently, this is because our team has not give you an user ID.",
        "error.signup.modal.exp2":"Please be patient and wait until our team assign you and ID, meanwhile, you can access but you'll have limited options",
        "awesome": "Awesome!",
        "destinos": "Top Destinations",
        "ofertas": " Offers",
        "filter.placeholder": "Search",
        "time": "Time",
        "noAuthTitle": "Your account hasn't been activated",
        "noAuthWorking": "We are working on it!",
        "noAuthText": "It seems that you signed up but our team hasn't activate your account, please wait while our team activate your account.",
        "balanceFound": "Balance Found!",
        "currentBalance": "Current Balance: ",
        "registration": "Registration",
        "accountSummary": "Account summary",
        "chart.calls": "Calls graph",
        "quickStats": "Quick stats",
        "email": "Email",
        "welcome": "Welcome",
        "update": "Update",
        "error": "Something happened. Please reload the page or come back later",
        "others": "Others",
        "currency": "Currency",
        "number": "Number",
        "privado": "Loading...",
        "loading.info": "We are loading your information...",
        "no.calls": "You have no calls",
        "info.calls": "Each field is optional and they have default values: If 'User ID' is empty you'll see your own calls, also, if fields 'Since' and 'To' are both empty it will set from 00:00 AM until now",
        "aboutYou": "About You",
        "noDataTitle": "Who are you?",
        "noDataText1": "It seems that there's no information of this user in our web, please contact us",
        "noDataText2": "to solve this problem",
        "noDataTextemail": " by email ",

        // Header
        "header.menu.userBalance": " Check Users Balance",
        "header.menu.userInformation": " Users Information",
        "header.menu.ranges": "Payment Ranges",
        "header.menu.reports": "Reports",
        "header.menu.accounting": "Accounting",
        "header.menu.billing": "Billing",
        "header.menu.logout": " Log Out",
        "header.menu.profile": " Your Profile",
        "header.menu.changePassword": " Change Password",
        "header.menu.aggregates": " Visualize Aggregates",
        "header.menu.devices": " Devices Details",
        "header.menu.rates": " Visualize Rates",
        "header.menu.update.balance": " Update Balances",
        "header.menu.create.tariff": " Create a new tariff",
        "header.menu.update.tariff": " Change name of tariffs",
        "header.menu.payments": " Payments history",
        "header.menu.add.payments.admin": " Make payments",
        "header.menu.add.payments": " Add balance to your account",

        // Footer 
        "footer.policyLink.termsAndConditions": "Terms & Conditions",
        "footer.policyLink.privacy": "Privacy Policy",
        "footer.policyLink.termsUse": "Terms of Use",
        "footer.policyLink.userGuide": "User's Guide",
        "footer.copyright": "©2020-2021 AriaTel SAS. All rights reserved.",

        // Out of service
        "outOfService.title": "Out of service",
        "outOfService.text": "This service is currently unavailable, we are working on it!",
        "outOfService.redirectLink": "Go to available alternatives",

        // 404
        "404.title": "Oops! We couldn't find that page",
        "404.text": "It seems the page you are looking for doesn't exist",
        "404.redirectLink": "Go back to dashboard",

        // Your Profiles
        "profile.agreement.date":"Agreement date",
        "profile.agreement.number":"Agreement number",
        "profile.taxation.country":"Taxation country",
        "profile.vat.regularNumber":"VAT Regular Number",
        "profile.vat.percent":"VAT percent",
        "profile.language":"Language",
        "profile.username":"Username",
        "profile.company":"Company",
        "profile.email":"Email",
        "profile.movil":"Phone Number",
        "profile.country":"Country",
        "profile.city":"City",
        
        // Email Confirmation
        "verify_email.success.title": "Your email was confirmed",
        "verify_email.success.p1": "Now our team will assign you additional information.",
        "verify_email.success.p2": "At the moment you could sign in but you'll be limited until our team give you an ID",
        "verify_email.error.title": "It has occured a problem",
        "verify_email.error.p1": "Try again later or re-send the verification email",
        "confirmation.login.error": "Your email has not been confirmed",
        "confirmation.login.error.link": "verify here",
        "resend.button": "Re-send verification",
        "resend.title": "Enter the email linked to your account",
        "resend.want.button": "I want to verify my email",
        "verify_lobby.title": "Please, confirm your Email",
        "verify_lobby.p1": "Confirm your email address with the link we sent to the given address in the registration.",
        "verify_lobby.p2": "If you didn't receive the mail, please click on the button below to resend your email confirmation",

        
        // Quickstat
        "quickstat.today": "Today",
        "quickstat.month": "Month",
        "quickstat.calls": "Calls",
        "quickstat.duration": "Duration",
        "quickstat.revenue": "Revenue",
        "quickstat.self_cost": "Self Cost",
        "quickstat.profit": "Profit",
        "quickstat.margin": "Margin",
        "quickstat.price": "Price",
        
        // Formularios
        "forms.field.email": "Email",
        "forms.field.username": "Username",
        "forms.invalid": "Invalid form, please try again",
        "forms.field.password": "Password",

            // Validaciones
            "forms.field.empty": "This field cannot be empty",
            "forms.field.invalidMail": "Please write a valid Email address",
            "forms.field.invalidPhone": 'The phone number is invalid,  don\'t use symbols like "(  )  [  ]  {  }"',
            "forms.field.passwordUnmatch": "Passwords don't match",

            // Registro
            "forms.signUp.title": "Sign Up",
            "forms.signUp.repeatPassword": "Repeat Password",
            "forms.signUp.phone": "Phone Number",
            "forms.signUp.country": "Country",
            "forms.signUp.state": "State or Region",
            "forms.signUp.company": "Company",
            "forms.signUp.loginRedirection.text": "I already have an account",
            "forms.signUp.loginRedirection.link": " log In",
                // onSubmit                
                "forms.signUp.submitButton":"Send User Request",
                "forms.signUp.success": " Your account was registered, now our team will activate your account as soon as possible",
                "forms.signUp.error": " Something happened, please try again later",
        
            // Inicio
            "forms.login.title": "Sign In",
            "forms.login.signUpRedirect.text": "Don't have an account? ",
            "forms.login.signUpRedirect.link": "Sign Up",
            "forms.login.changePwRedirect.text": "Forgot Password? ",
            "forms.login.changePwRedirect.link": "Change It",
                // onSubmit 
                "forms.login.submitButton": "Log In",
                "forms.login.dataError": "There's an error when we are trying to search your data",
                "forms.login.userOrpasswordIncorrect": "Your Username or Password is incorrect",
                "forms.login.error": "There's an error, Try again Later",

            // Password Reset
            "forms.passReset.title": "User's Email",
            "forms.passReset.loginRedirect": "Back to log in",                
                // onSubmit
                "forms.passReset.submitButton": "Send Email",
                "forms.passReset.emailError": "Your Email is incorrect",
                "forms.passReset.error": "There's an error, Try again Later",

            // Password Reset Confirm
            "forms.passResetConfirm.info.title": "It has been sent a mail to the address given, check your email to reset your password",
            "forms.passResetConfirm.info.text": "Your password was reseted successfully",
            "forms.passResetConfirm.title": "Change Your Password",
            "forms.passResetConfirm.newPass": "Write your new password",
            "forms.passResetConfirm.newPassRepeat": "Repeat Password",
            "forms.passResetConfirm.token": "Secret Token",
            "forms.passResetConfirm.uid": "User ID",
            "forms.passResetConfirm.tryAgain.text": "We did not send you the email? ",
            "forms.passResetConfirm.tryAgain.link": "Try Again",
                // onSubmit
                "forms.passResetConfirm.submitButton": "Change Password",    
                "forms.passReset.tokenOrIdError": " Invalid Token or ID ",
            
            // Password Change
            "forms.changePass.legend": "Change your Password",
            "forms.changePass.oldPass": "Old Password",
            "forms.changePass.newPass": "New Password",
            "forms.changePass.newPassRepeat": "Repeat New Password",
                //onSubmit
                "forms.changePass.error":"That's not your actual password",
                "forms.changePass.success": "Your password was changed successfully",
                "forms.changePass.submitButton": "Change Password",
    
        // Aggregates
        "aggregates.time_interval.today": "Today",
        "aggregates.time_interval.1d": "1 day ago",
        "aggregates.time_interval.2d": "2 days ago",
        "aggregates.time_interval.1w": "1 week ago",
        "aggregates.time_interval.15d": "15 days ago",
        "aggregates.time_interval.1m": "1 month ago",
        "aggregates.time_interval.2m": "2 month ago", 
        "aggregates.time_interval.3m": "3 month ago", 
        "aggregates.time_interval.6m": "6 month ago",
        "aggregates.time_interval.1y": "1 year ago", 
        "aggregates.time_interval.2y": "2 years ago", 
        "aggregates.time_interval.title": "Choose a time interval",
        "aggregates.dst_name": "DST name",
        "aggregates.dst_group": "DST Group",
        "aggregates.originator": "Originator",
        "aggregates.originator_id": "Originator ID",
        "aggregates.terminator": "Terminator",
        "aggregates.terminator_id": "Terminator ID",
        "aggregates.billed_originator_with_tax": "Originator With Tax",
        "aggregates.duration": "Duration",
        "aggregates.answered_calls": "Answered",
        "aggregates.total_calls": "Total",
        "aggregates.asr": "ASR",
        "aggregates.acd": "ACD",
        "aggregates.pdd": "PDD",
        "aggregates.profit": "Profit",
        "aggregates.customer": "Customer",
        "aggregates.time_b": "Time Billed",
        "aggregates.billed": "Billed",

        // Calls
        "calls.rpid": "RPID",
        "calls.tp_src": "SRC number sended to TP",
        "calls.tp_dst": "DST number sended to TP",
        "calls.dc": "Disconnect Codes",
        "calls.op_dc": "Disconnect Codes OP",
        "calls.tp_dc": "Disconnect Codes TP",
        "calls.routing_attempt": "Routing Attempt",
        "calls.mos": "MOS",
        "calls.mos_packetloss": "Packet loss",
        "calls.mos_jitter": "Jitter",
        "calls.mos_roundtrip": "Roundtrip",
        "calls.accountcode": "Account code", 
        "calls.answer_time": "Answer time", 
        "calls.billsec": "Billsec", 
        "calls.date": "Date", 
        "calls.did_billsec": "DID billsec", 
        "calls.did_inc_price": "DID inc. price", 
        "calls.did_price": "DID price", 
        "calls.did_prov_price": "DID provider price", 
        "calls.did_provider_id": "DID provider ID", 
        "calls.dst_device_id": "Destination device ID", 
        "calls.dst_user_id": "Destination user ID", 
        "calls.end_time": "End time", 
        "calls.hangupcause": "Hangup cause code", 
        "calls.localized_dst": "Localized Destination", 
        "calls.originator_codec": "Originator codec", 
        "calls.originator_ip": "Originator IP", 
        "calls.pai": "PAI", 
        "calls.partner_price": "Partner price", 
        "calls.partner_rate": "Partner rate", 
        "calls.pdd": "PDD", 
        "calls.processed": "Processed", 
        "calls.provider_billsec": "Provider billsec", 
        "calls.provider_id": "Provider ID", 
        "calls.real_billsec": "Real billsec", 
        "calls.real_duration": "Real duration", 
        "calls.reseller_price": "Reseller price", 
        "calls.reseller_rate": "Reseller rate", 
        "calls.server_id": "Server ID", 
        "calls.src_device_id": "Source device ID", 
        "calls.src_user_id": "Source user ID", 
        "calls.terminated_by": "Terminated by", 
        "calls.terminator_codec": "Terminator codec", 
        "calls.terminator_ip": "Terminator IP", 
        "calls.user_billsec": "User billsec", 
        "calls.user_id": "User ID", 
        "calls.calldate2": "Start Time",
        "calls.timezone": "Timezone",
        "calls.clid": "CLID",
        "calls.destination": "Destination",
        "calls.dispod": "Hangup Cause",
        "calls.dst": "Destination",
        "calls.duration": "Duration",
        "calls.nice_billsec": "Nice billsec",
        "calls.prefix": "Prefix",
        "calls.provider": "Provider",
        "calls.provider_price": "Provider price",
        "calls.provider_rate": "Provider rate",
        "calls.src": "Source",
        "calls.user": "User",
        "calls.user_price": "User price",
        "calls.user_rate": "User rate",
        "calls.uniqueid": "Unique ID",
        "calls.titulo": " Calls Record",
        "calls.filter.p_start": "Since",
        "calls.filter.p_end": "To",
        "calls.filter.c_type": "Call Type",
        "calls.filter.c_type_all": "All",
        "calls.filter.c_type_answered": "Answered",
        "calls.filter.c_type_no_answer": "No answered",
        "calls.filter.c_type_failed": "Failed",
        "calls.filter.c_type_busy": "Busy",
        "calls.filter.o_point": "Origination Point",
        "calls.search": "Search",
        "calls.reset": "Clear Fields",
        "calls.segundos": "(Secs)",
        "calls.cdr_table_export": "Call Records",
        "calls.nav.title": "Calls records",
        "records.of": "Records of ",
        "Enero": "January",
        "Febrero":"February",
        "Marzo": "March",
        "Abril": "April",
        "Mayo": "May",
        "Junio": "June",
        "Julio": "July",
        "Agosto": "August",
        "Septiembre": "September",
        "Octubre": "October",
        "Noviembre": "November",
        "Diciembre": "December",
        "records.to.down": "Records to Download",
        "records.month.error": "There's an error trying to search your monthly records",
        "records.week.error": "We couldn't find the requested records",
        "records.week.filter.button":"Filter on records",
        "records.week.filter.reset":"Reset records filtering",
        "records.week.table.reset":"Reset the table below",
        
        // Devices
        "devices.spec": "Specification",
        "devices.val": "Value",
        "devices.error.choose": "You must choose a device",
        "devices.error.search": "We couldn't find anything",
        "devices.error.notFound": "Devices not found",
        "devices.choose": "Choose a device",
        "devices.id" : "ID",
        "devices.name" : "Name",
        "devices.host" : "Host",
        "devices.secret" : "Secret",
        "devices.ipaddr" : "IP address",
        "devices.port" : "Port",
        "devices.accountcode" : "Accountcode",
        "devices.callerid" : "Caller ID",
        "devices.username" : "Username",
        "devices.device_type" : "Device type",
        "devices.user_id" : "User ID",
        "devices.insecure" : "Insecure",
        "devices.description" : "Description",
        "devices.timeout" : "Timeout",
        "devices.allow_duplicate_calls" : "Allow duplicate calls",
        "devices.call_limit" : "Call limit",
        "devices.grace_time" : "Grace time",
        "devices.server_id" : "Server ID",
        "devices.change_failed_code_to" : "Change failed code to",
        "devices.max_timeout" : "Max timeout",
        "devices.cps_call_limit" : "CPS call limit",
        "devices.cps_period" : "CPS period",
        "devices.callerid_number_pool_id" : "Caller ID number pool ID",
        "devices.op" : "OP",
        "devices.op_active" : "OP active",
        "devices.op_tech_prefix" : "OP tech prefix",
        "devices.op_routing_algorithm" : "OP routing algorithm",
        "devices.op_routing_group_id" : "OP routing group ID",
        "devices.op_tariff_id" : "OP tariff ID",
        "devices.op_capacity" : "OP capacity",
        "devices.op_src_regexp" : "OP source regular expression",
        "devices.op_src_deny_regexp" : "OP source deny regular expression",
        "devices.tp" : "TP",
        "devices.tp_active" : "TP active",
        "devices.tp_tech_prefix" : "TP tech prefix",
        "devices.tp_tariff_id" : "TP tariff ID",
        "devices.tp_capacity" : "TP capacity",
        "devices.tp_src_regexp" : "TP source regular expression",
        "devices.tp_src_deny_regexp" : "TP source deny regular expression",
        "devices.custom_sip_header" : "Custom SIP header",
        "devices.register" : "Register",
        "devices.interpret_busy_as_failed" : "Interpret Busy as failed",
        "devices.interpret_noanswer_as_failed" : "Interpret No Answered as failed",
        "devices.sticky_contact" : "Sticky contact",
        "devices.periodic_check" : "Periodic check",
        "devices.alive" : "Alive",
        "devices.op_custom_tariff_id" : "OP custom tariff ID",
        "devices.ipaddr_range_start" : "IP address range start",
        "devices.ipaddr_range_end" : "IP address range end",
        "devices.op_destination_transformation" : "OP destination transformation",
        "devices.quality_routing_id" : "Quality routing ID",
        "devices.op_source_transformation" : "OP source transformation",
        "devices.tp_source_transformation" : "TP source transformation",
        "devices.disable_q850" : "Disable Q.850 reason",
        "devices.forward_rpid" : "Forward RPID",
        "devices.forward_pai" : "Forward PAI",
        "devices.bypass_media" : "Bypass media",
        "devices.disable_sip_uri_encoding" : "Disable SIP URI encoding",
        "devices.use_invite_dst" : "Use Destination from invite request",
        "devices.inherit_codec" : "Inherit codec",
        "devices.enforce_lega_codecs" : "Enforce lega codecs",
        "devices.use_pai_if_cid_anonymous" : "Use PAI if CID is anonymous",
        "devices.ring_instead_progress" : "Ring instead Session Progress",
        "devices.set_sip_contact" : "Set SIP contact",
        "devices.hidden_device" : "Hidden device",
        "devices.change_rpidpai_host" : "Change RPID/PAI host",
        "devices.progress_timeout" : "Progress Timeout",
        "devices.op_number_pool_id" : "OP number pool ID",
        "devices.op_match_tariff_id" : "OP match tariff ID",
        "devices.op_use_pai_as_number" : "OP use PAI as number",
        "devices.op_callerid_matches" : "OP caller ID matches",
        "devices.op_dst_matches" : "OP destination matches",
        "devices.op_dst_number_pool_id" : "Destination number pool ID",
        "devices.ignore_183nosdp" : "Ignore SIP 183 without SDP",
        "devices.op_fake_ring" : "OP fake ring",
        "devices.op_username" : "OP username",
        "devices.op_password" : "OP password",
        "devices.cip" : "CIP",
        "devices.callerid_number_pool_type" : "Caller ID number pool type",
        "devices.callerid_number_pool_deviation" : "Caller ID number pool deviation",
        "devices.op_tariff_intra" : "OP tariff intra",
        "devices.op_tariff_inter" : "OP tariff inter",
        "devices.op_tariff_indeter" : "OP tariff indeter",
        "devices.us_jurisdictional_routing" : "US jurisdictional routing",
        "devices.tp_tariff_intra" : "TP tariff intra",
        "devices.tp_tariff_inter" : "TP tariff inter",
        "devices.tp_tariff_indeter" : "TP tariff indeter",
        "devices.tp_us_jurisdictional_routing" : "TP US jurisdictional routing",
        "devices.tariff_intra" : "tariff intra",
        "devices.tariff_inter" : "tariff inter",
        "devices.tariff_indeter" : "tariff indeter",
        "devices.codecs" : "Codec",

        // Rates
        "rates.of.tariff": "Rates of tariff ID",
        "rates.of.deviceid": "Rates of device ID",
        "rates.error.choose.nothing": "You must choose a tariff",
        "rates.tariffID": "Tariff ID",
        "rates.deviceID": "Device ID",
        "rates.searchby": "Search By",
        "rates.deviceID.type": "Choose a Device",
        "rates.tariffID.type": "Choose a Tariff",
        "rates.direction": "Direction",
        "rates.destination": "Destination",
        "rates.prefix": "Prefix",
        "rates.code": "Code",
        "rates.tariff_rate": "Destination Rate",
        "rates.con_fee": "Connection Fee",
        "rates.increment": "Increment",
        "rates.min_time": "Min time",
        "rates.start_time": "Start time",
        "rates.end_time": "End time",

        // Update Balance
        "update.balance.h6": " Add or Subtract to the current balance",
        "update.balance.new.balance": "New balance",
        "update.balance.balance.now": "Balance Now (USD)",
        "User was not found": "User was not found",
        "User balance updated": "User balance updated",
        "update.balance.error.nothing": "You must fill all the fields",
        "Incorrect hash": "Error trying to change balance",

        // Create Tariff
        "tariff.tariff.name": "Tariff Name",
        "tariff.create": "Create",
        "tariff.update.wrongid": "Tariff ID is incorrect",
        "tariff.success": "Tariff created successfully",
        "tariff.Incorrect hash": "Error trying to create a new tariff",
        "tariff.update.Incorrect hash": "Error trying to update tariff",
        "tariff.name": "Name",

        // Update Tariff
        "update.tariff.success": "Tariff name changed successfully",
        "update.tariff.current.name": "Current Name",
        "update.tariff.new.name": "New name",

        // Payments
        "payments.amount.payed": "Payed amount",
        "payments.amount.payed.tax": "Payed amount with tax",
        "payments.amount.charged": "Charged amount",
        "payments.amount.between": "Amount between",
        "payments.Payments were not found": "Payments were not found",
        "Payments were not found": "Payments were not found",
        "Bad login": "You are not allowed to do this action",
        "payments.Bad login": "You are not allowed to get this information",
        "payments.Incorrect hash": "There's an error when we search the information requested",
        "payments.user": "User",
        "payments.date": "Date",
        "payments.amount": "Amount",
        "payments.amount_with_tax": "Amount with tax",
        "payments.comment": "Comment",
        "payments.currency": "Currency",

        // Add Payments
        "cc.card.warning": "EVERY transaction will be charged to your card in the Colombian Peso currency (COP)",
        "error.add.cc.repeated": "It seems that your credit card is already registered",
        "error.add.cc": "There was an error when we tried to save your credit card, try again later",
        "banks.city.state": "City/State of bank",
        "banks.info.accountnumber.ahorro": "Savings account number",
        "banks.info.accountnumber.corriente": "Checking account number",
        "banks.info.nit": "NIT",
        "banks.info.contact": "Bank contact",
        "banks.info.ach": "ACH Routing number",
        "banks.info.abaoriban": "ABA Code",
        "banks.info.accountname": "Account name",
        "banks.info.accountnumber": "Account number",
        "banks.info.address": "Bank Address",
        "banks.info.phone": "Bank phone number",
        "banks.info.swiftcode": "SWIFT code",
        "add.payments.choose.amount": "Choose an amount",
        "payments.p_id": "Payment ID",
        "add.payments.choose.method": "Payment methods",
        "add.payments.transfer": "Bank Transfer",
        "add.payments.creditCard": "Credit Card",
        "add.payments.text.banks": "Add balance to your account with the payment methods in the following banks:",
        "add.payments.bank.title": "Banks you can pay",
        "add.payments.failed.user.title": "We couldn't upload your document",
        "add.payments.success.user.title": "Your invoice was uploaded successfully!",
        "add.payments.success.user.text": "Now, our team will check your document/image, then we will add the balance to your account",
        "add.payments.empty": "You must fill each field of the form",
        "add.payments.report": "Report your payment",
        "add.payments.choose.file": "Choose a file",
        "add.payments.error.server": "Oops! there's an error when we try to upload your file, try again later",
        "add.payments.error.filetype": "Your document must be an image or a PDF",
        "add.payments.error.password": "The password is incorrect",
        "add.payments.error0": "Amount entered is out of range",
        "add.payments.error1": "Type of data is incorrect",
        "add.payments.error2": "Payment can be 0.0$",
        "add.payments.error3": "Add a comment to the payment",
        "add.payments.error4": "You need to type the payment ID",
        "add.payments.taxInfo": "You can enter amount with OR without tax. Not both at the same time. Based on what you enter, tax will be added or not.",
        "add.payments.providerInfo": "Payments are default from client, if you choose set to provider payment will be to the provider.",
        "add.payments.inTax": "Amount in tax",
        "add.payments.toProvider": "Set payment to provider",
        "add.payments.create": "Make payment",
        "add.payments.create.pse": "Pay by PSE",
        "add.payments.create.creditCard": "Pay by credit card",
        "add.payments.create.transfer": "Pay by bank transfer",
        "add.payments.close": "Close",
        "add.payments.generatePDF": "Generate PDF",
        "add.payments.errorMsg": "It has occured an error trying to create the payment, please be sure you choosed a real user",
        "add.payments.failed": "Payment Creation Failed!",
        "add.payments.success": "Payment Done!",

        // PayU
        "payu.status": "Transaction status",
        "payu.transaction": "Transaction",
        "payu.about": "About the payment",
        "payu.amount": "Amount (USD)",
        "payu.tax": "Tax amount",
        "payu.description": "Payment Description",
        "payu.email": "Buyer email",
        "payu.ref": "Reference",
        "payu.id": "Transaction ID",
        "payu.date": "Transaction date",
        "payu.card": "About the card used",
        "payu.cardtype": "Type of card",
        "payu.fees": "Established payment fees",
        "payu.foot": "If you have any problem please contact the following phone number",
        
        // Add and delete Offers
        "offer.days": " days",
        "offer.disponible": "Offer will be available the next ",
        "offer.pendiente": "Offer will be active in ",
        "addoffers.tooltip.info": "<i class='text-warning'>Choosing only one date means it will be the expiration date, starting the offer in the current day</i><div class='my-2 border border-info rounded h-0 w-100'></div><i class='text-warning'>If you let this field empty, it will take period of a week starting the current day</i>",
        "addoffers.select.time": "Select the duration of the offer",
        "addoffers.title": "Add Offers",
        "addoffers.newoffer": "Add new offer",
        "addoffers.header": " Add or delete offers",
        "addoffers.select.country": "Select a location",
        "addoffers.percent": "Discount",
        "addoffers.price": "Normal price",
        "addoffers.finalPrice": "Final price",
        "addoffers.button": "Create Offer",
        "addoffers.current": "Current offers",
        "addoffers.alert.create.success": "Offer Added successfully!",
        "addoffers.alert.create.error": "There was a problem trying to create the offer",
        "addoffers.alert.delete.success": "Offer deleted successfully",
        "addoffers.alert.delete.error": "There was a problem trying to delete the offer",
        "nooffers": "There are not offers currently",
        "notopdestinos": "There are not top destinations yet",
        
        // Metodos de pago
        "btn.bancolombia.title": "Payment throught Botón Bancolombia",
        "btn.bancolombia.redirection.title": "You were redirected to Bancolombia's portal",
        // "btn.bancolombia.": "",
        // "btn.bancolombia.": "",
        // "btn.bancolombia.": "",
        // "btn.bancolombia.": "",
        "pse.person": "Person type",
        "pse.pasaporte": "Passport",
        "pse.banks.load.error": "There was an error trying to load the bank information, please try again later",
        "pse.title": "Payment throught PSE",
        "pse.doc": "Identification document",
        "pse.boton": "Make transaction",
        "pse.redirection.title": "You were redirected to the PSE portal",
        "pse.redirection.body": "You can close this window",
        "pse.transaction.done": "PSE Transaction done",
        "pse.boton.pdf": "Download PDF invoice",
        "pse.invoice": "Receipt",
        "pse.factura": "Bill",
        "pse.bank": "Bank Transaction",
        "cash.title": "Payment throught cash",
        "cash.choose": "Choose an amount",
        "cash.response.title": "Cash transaction created",
        "cash.exp.date": "Expiration date",
        "cash.auth": "Authorization",
        "cc.title": "Credit card transaction done",
        "cc.conf.body": "Are you sure you want to proceed",
        "cc.conf.title": "Delete Credit Card Register",
        "cc.list.title": "Credit cards registered",
        "cc.recharge": "Recharge balance",
        "cc.nocc": "There was an error trying to search your credit cards",
        "cc.nofoundcc": "You don't have registered credit cards",
        "cc.number": "Credit card number",
        "cc.exp": "Expiration date",
        "cc.type": "Type",
        "cc.name": "Cardholder name",
        "cc.msgsuccess": "Credit card deleted successfully",
        "cc.msg.error": "Credit card cannot be deleted",
        "cc.cards": "cards",
        "cc.register": "Register cards",
        "cc": "Credit card",
        "cc.dues": "Dues",
        "cc.amount.recharge": "Amount",
        "cc.pay.title": "Payment throught credit card",
        "cc.hasnt": "I don't have credit cards registered",
        "cc.trans.res": "Transaction result",
        "cc.recharge.error": "There was an error when recharging your balance",
        "cc.error.list.title": "We found the following errors in your form",
        "cc.mm.error": "Invalid Month",
        "cc.yy.error": "Invalid Year",
        "cc.date.error": "Invalid Date",
        "cc.your.name": "Your name here",
        "cc.save": "Save card",
        "cc.save.success": "Credit card saved successfully",
        "cc.save.success.body": "Now you can use it every time you want to make a transaction throught credit card",
        "cc.save.error": "There was an error when saving your credit card",
        "cc.add.another": "Add another credit card",
        "cc.pay.redirection": "Make a transaction throught credit card",
        "cc.choose.card": "Choose a credit card",
        "cash.choose.fran": "Choose a franchise",

        // User Details
        "details.placeholder.username": "Type the username",
        "details.placeholder.userID": "Type the user ID",
        "profile.details.username": "Username",
        "profile.details.first_name": "First Name",
        "profile.details.surname": "Surname",
        "profile.details.personalid": "Personal ID",
        "profile.registration.reg_address": "Address",
        "profile.registration.reg_postcode": "Postal Code",
        "profile.registration.reg_city": "City",
        "profile.registration.reg_country": "Country",
        "profile.registration.reg_state": "State",
        "profile.registration.reg_phone": "Phone",
        "profile.registration.reg_mobile": "Mobile",
        "profile.registration.reg_fax": "Fax",
    }
}