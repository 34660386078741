import React from 'react'

/* 
   -----------------------------------------------------------------------------------------------
    Componente de Alertas
   -----------------------------------------------------------------------------------------------
*/
class Alert extends React.Component {
    render(){
        return (
            <div id={this.props.id} className={"alertas-forms alert " + this.props.alert} role="alert" >
                {this.props.children}
            </div>
        )
    }
}

export default Alert