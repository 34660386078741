import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import Titulo from './tools/Titulo'
import { IntlContext } from "../../translation/IntlProviderWrapper"
import { FormattedMessage } from 'react-intl'
import secureStorage from '../../tools/SecureStorage'
import CryptoTools from '../../tools/crypto_tools'
import { AuthContext } from '../AuthContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactGA from 'react-ga'

/* 
   -----------------------------------------------------------------------------------------------
    Muestra la informacion del usuario actual
   -----------------------------------------------------------------------------------------------
*/
export default function UserProfile(){
    const lang = useContext(IntlContext)
    const u_data = useContext(AuthContext)
    const crypto = new CryptoTools()
    const [error, setError] = useState(false)
    const [details, setDetails] = useState({})
    const [balance, setBalance] = useState("")
    const username = crypto.desencriptar(u_data.m2.username)
    const tax = JSON.parse(crypto.desencriptar(
        useContext(AuthContext).m2.tax)
    )
    const address = JSON.parse(crypto.desencriptar(
        useContext(AuthContext).m2.address)
    )

    const active_icon = <FontAwesomeIcon icon="check-circle" className="text-success"/>
    const not_active_icon = <FontAwesomeIcon icon="minus-circle" className="text-muted"/>
    const error_active_icon = <FontAwesomeIcon icon="times-circle" className="text-danger"/>
    
    useEffect(() => {
        const s = axios.CancelToken.source()
        ReactGA.pageview(window.location.pathname);
        axios.get('/api/v1/get/profile/?p=user_details_get&u=' + username,
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`
                },
                CancelToken: s.token
            }
        ).then(res => {
            if(res.data.page){
                const data = res.data.page
                setDetails(data)
            }else{
                setError(true)
            }
        }).catch(error => {
            setError(true)
        })

        return () => {
            s.cancel("component unmounted")
        }
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        const source = axios.CancelToken.source()
        axios.get('/api/v1/get/profile/?p=user_balance_get',
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`
                },
                cancelToken: source.token
            }
        ).then(res => setBalance(`${Intl.NumberFormat().format(res.data.page.balance)} ${res.data.page.currency}`)).catch(error => {
            setDetails("error")
        })

        return () => {
            source.cancel("component unmounted")
        }
    }, [])

    return (
        <>
            <Titulo>
                <FormattedMessage id="header.menu.profile"/>
            </Titulo>

            <section className="row justify-content-center mb-3">
                <div className="col-12 col-md-8 col-xl-6">
                    <div className="card shadow-sm user-details-card-tabs">
                        <div className="card-body">
                            <ul className="nav nav-tabs mb-3 nav-fill" id="Udetails" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="u-general-details" data-toggle="tab" href="#u-general-details-content" role="tab" aria-controls="u-general-details-content" aria-selected="true">General</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="u-m2-details" data-toggle="tab" href="#u-m2-details-content" role="tab" aria-controls="u-m2-details-content" aria-selected="true"><FormattedMessage id="contact"/></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-capitalize" id="u-accounting-details" data-toggle="tab" href="#u-accounting-details-content" role="tab" aria-controls="u-accounting-details-content" aria-selected="true"><FormattedMessage id="header.menu.accounting"/></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="u-m2-tax" data-toggle="tab" href="#u-m2-tax-content" role="tab" aria-controls="u-m2-tax-content" aria-selected="true"><FormattedMessage id="tax_info"/></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="u-m2-address" data-toggle="tab" href="#u-m2-address-content" role="tab" aria-controls="u-m2-address-content" aria-selected="true"><FormattedMessage id="profile.registration.reg_address"/></a>
                                </li>
                            </ul>                       
                            { error ? 
                                <h5 className="text-center text-muted font-weight-bold">
                                    <FormattedMessage id="error.signup.modal.title"/>
                                </h5>
                            :   <div className="tab-content" id="UdetailsContent">
                                    <div className="tab-pane fade show active" id="u-general-details-content" role="tabpanel" aria-labelledby="general-details">
                                        <table className="table table-sm table-hover">
                                            <tbody>
                                                <tr>
                                                    <th scope="row" className="text-capitalize border-0">
                                                        <FormattedMessage id="profile.details.username"/>
                                                    </th>
                                                    <td className="border-0">
                                                        {u_data.username_dj}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-capitalize">
                                                        <FormattedMessage id="profile.details.first_name"/>
                                                    </th>
                                                    <td>
                                                        {u_data.m2.first_name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-capitalize">
                                                        <FormattedMessage id="profile.details.surname"/>
                                                    </th>
                                                    <td>
                                                        {u_data.m2.last_name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-capitalize">
                                                        <FormattedMessage id="profile.language"/>
                                                    </th>
                                                    <td>
                                                        {lang.locale === "en" ? 
                                                            "English (US)" : "Español (CO)"
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>


                                    <div className="tab-pane fade" id="u-m2-details-content" role="tabpanel" aria-labelledby="general-details">
                                        <table className="table table-sm table-hover">
                                            <tbody>
                                                <tr>
                                                    <th scope="row" className="border-0">Phone</th>
                                                    <td className="border-0">{u_data.m2.contact.phone || "------"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Main Email</th>
                                                    <td>{u_data.m2.contact.main_email || "------"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">NOC Email</th>
                                                    <td>{u_data.m2.contact.noc_email || "------"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Billing Email</th>
                                                    <td>{u_data.m2.contact.billing_email || "------"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Rates Email</th>
                                                    <td>{u_data.m2.contact.rates_email || "------"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>


                                    <div className="tab-pane fade" id="u-accounting-details-content" role="tabpanel" aria-labelledby="general-details">
                                        <table className="table table-sm table-hover">
                                            <tbody>
                                                <tr>
                                                    <th scope="row" className="text-capitalize border-0">
                                                        <FormattedMessage id="balance"/>
                                                    </th>
                                                    <td className="border-0">
                                                        {balance}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-capitalize">
                                                        <FormattedMessage id="currency"/>
                                                    </th>
                                                    <td>
                                                        {details.details === undefined ? "------" : 
                                                        details.details.main_detail.balance_currency === null ?
                                                        "------" : details.details.main_detail.balance_currency}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-capitalize">
                                                        <FormattedMessage id="profile.agreement.date"/>
                                                    </th>
                                                    <td>
                                                        {details.details === undefined ? "" : 
                                                        details.details.other_details.agreement_date === null ?
                                                        "------" : details.details.other_details.agreement_date}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-capitalize">
                                                        <FormattedMessage id="profile.taxation.country"/>
                                                    </th>
                                                    <td>
                                                        {details.details === undefined ? "" : 
                                                        details.details.other_details.taxation_country === null ?
                                                        "------" : details.details.other_details.taxation_country}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <FormattedMessage id="profile.vat.regularNumber"/>
                                                    </th>
                                                    <td>
                                                        {details.details === undefined ? "" : 
                                                        details.details.other_details.vat_reg_number === null ?
                                                        "------" : details.details.other_details.vat_reg_number}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <FormattedMessage id="profile.vat.percent"/>
                                                    </th>
                                                    <td>
                                                        {details.details === undefined ? "" : 
                                                        details.details.other_details.vat_percent === null ?
                                                        "------" : details.details.other_details.vat_percent}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                
                                
                                    <div className="tab-pane fade flex-wrap" id="u-m2-tax-content" role="tabpanel" aria-labelledby="general-details">
                                        <div className="w-100 d-flex justify-content-around align-items-center flex-wrap mb-2">
                                            <div className="text-center">
                                                <b><FormattedMessage id="tax_total_name"/>:</b> <br/> 
                                                {tax.total_tax_name}
                                            </div>
                                            <div className="text-center">
                                                <b><FormattedMessage id="tax_compound"/>:</b> <br/> 
                                                {tax.compound_tax ? active_icon : error_active_icon}
                                            </div>
                                        </div>
                                        <table className="table table-sm table-hover table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">
                                                        <FormattedMessage id="devices.name"/>
                                                    </th>
                                                    <th className="text-center">
                                                        <FormattedMessage id="devices.val"/>
                                                    </th>
                                                    <th className="text-center">
                                                        <FormattedMessage id="isEnabled"/>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" className="text-center">{tax.tax1_name}</th>
                                                    <td className="text-center">{parseFloat(tax.tax1_value).toFixed(4)} %</td>
                                                    <td className="text-center">{tax.tax1_enabled ? active_icon : not_active_icon}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-center">{tax.tax2_name}</th>
                                                    <td className="text-center">{parseFloat(tax.tax2_value).toFixed(4)} %</td>
                                                    <td className="text-center">{tax.tax2_enabled ? active_icon : not_active_icon}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-center">{tax.tax3_name}</th>
                                                    <td className="text-center">{parseFloat(tax.tax3_value).toFixed(4)} %</td>
                                                    <td className="text-center">{tax.tax3_enabled ? active_icon : not_active_icon}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-center">{tax.tax4_name}</th>
                                                    <td className="text-center">{parseFloat(tax.tax4_value).toFixed(4)} %</td>
                                                    <td className="text-center">{tax.tax4_enabled ? active_icon : not_active_icon}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>


                                    <div className="tab-pane fade" id="u-m2-address-content" role="tabpanel" aria-labelledby="general-details">
                                        <table className="table table-sm table-hover">
                                            <tbody>
                                                <tr>
                                                    <th scope="row" className="text-capitalize border-0"><FormattedMessage id="profile.registration.reg_country"/></th>
                                                    <td className="border-0">{address.county || "------"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-capitalize"><FormattedMessage id="profile.registration.reg_state"/></th>
                                                    <td>{address.state || "------"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-capitalize"><FormattedMessage id="profile.registration.reg_city"/></th>
                                                    <td>{address.city || "------"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-capitalize"><FormattedMessage id="profile.registration.reg_address"/></th>
                                                    <td>{address.address || "------"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-capitalize"><FormattedMessage id="profile.registration.reg_postcode"/></th>
                                                    <td>{address.postcode || "------"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-capitalize"><FormattedMessage id="profile.registration.reg_phone"/></th>
                                                    <td>{address.phone || "------"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-capitalize"><FormattedMessage id="profile.registration.reg_mobile"/></th>
                                                    <td>{address.mob_phone || "------"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-capitalize"><FormattedMessage id="email"/></th>
                                                    <td>{address.email || "------"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="text-capitalize">FAX</th>
                                                    <td>{address.fax || "------"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>                                
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}