import React, { useState, useEffect, useRef, useContext } from 'react'
import axios from 'axios'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column';
import { injectIntl, FormattedMessage, useIntl } from 'react-intl';
import Titulo from '../compartidos/tools/Titulo';
import secureStorage from '../../tools/SecureStorage';
import ButtonLoader from '../compartidos/tools/ButtonLoader';
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { InputText } from 'primereact/inputtext'
import {InputSwitch} from 'primereact/inputswitch'
import { InputNumber } from 'primereact/inputnumber'
import { UsersOPInput, UsersTPInput } from './UsersInput';
import { AuthContext } from '../AuthContext';
import CryptoTools from '../../tools/crypto_tools';
import ReactGA from 'react-ga'


// eslint-disable-next-line no-extend-native
String.prototype.secsToHHMMSS = function () {
    let sec_num = parseInt(this, 10)
    let hours   = Math.floor(sec_num / 3600)
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60)
    let seconds = sec_num - (hours * 3600) - (minutes * 60)

    if (hours   < 10) {hours   = "0"+hours}
    if (minutes < 10) {minutes = "0"+minutes}
    if (seconds < 10) {seconds = "0"+seconds}
    return hours+' hrs, '+minutes+' mins, '+seconds+ ' secs'
}

// eslint-disable-next-line no-extend-native
String.prototype.secsToHHMMSSPoints = function () {
    let sec_num = parseInt(this, 10)
    let hours   = Math.floor(sec_num / 3600)
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60)
    let seconds = sec_num - (hours * 3600) - (minutes * 60)

    if (hours   < 10) {hours   = "0"+hours}
    if (minutes < 10) {minutes = "0"+minutes}
    if (seconds < 10) {seconds = "0"+seconds}
    return hours+':'+minutes+':'+seconds
}

/* 
   -----------------------------------------------------------------------------------------------
    Funcion que convierte en flotante la cadena pasada como primer parametro
    y lo redondea hasta la cantidad de decimales indicados en el segundo parametro
   -----------------------------------------------------------------------------------------------
*/
function convertirDecimal(numero, decimales){
    return parseFloat(parseFloat(numero).toFixed(decimales))
}

/* 
   -----------------------------------------------------------------------------------------------
    Componente que muestra los Aggregates
   -----------------------------------------------------------------------------------------------
*/
const Aggregates = () => {
    const crypto = new CryptoTools()
    const intl = useIntl()
    const [rows, setRows] = useState([])
    const [first, setFirst] = useState(null)
    const [rowsNumber, setRowsNumber] = useState(10)
    const source = axios.CancelToken.source()
    const [loader, setLoader] = useState()
    const [totals, setTotals] = useState({})
    const dt = useRef()
    const currency_in_table = useRef("COP")
    const users = JSON.parse(crypto.desencriptar(
        useContext(AuthContext).m2.users_disponibles)
    ).reduce((obj, item) => Object.assign(obj, { [item.username]: item.uid}), {})

    // Datos de filtros
    const [timeAgg, setTimeAgg] = useState("today")
    const [currency, setCurrency] = useState("COP")
    const [groupby, setGroupby] = useState(["group_by_originator", "group_by_terminator", "group_by_dst_group"])
    const [callerID, setCallerID] = useState("")
    const [destination, setDestination] = useState("")
    const [destinationGroup, setDestinationGroup] = useState("")
    const [realBillsec, setRealBillsec] = useState(false)
    const [userPerspective, setUserPerspective] = useState(false)
    const [answeredCalls, setAnsweredCalls] = useState(0)
    const [terminator, setTerminator] = useState("")
    const [originator, setOriginator] = useState("")
    const [op, setOp] = useState("default")
    const [tp, setTp] = useState("default")
    const valor_por_defecto_de_listas = [{"label": "All", "value": "default"}]
    const [originator_lists, set_originator_lists] = useState(valor_por_defecto_de_listas)
    const [terminator_lists, set_terminator_lists] = useState(valor_por_defecto_de_listas)
    const [error, setError] = useState(false)
    const intervals = [
        {label: intl.formatMessage({id: "aggregates.time_interval.today"}), value:"today"},
        {label: intl.formatMessage({id: "aggregates.time_interval.1d"}), value:"1d"},
        {label: intl.formatMessage({id: "aggregates.time_interval.2d"}), value:"2d"},
        {label: intl.formatMessage({id: "aggregates.time_interval.1w"}), value:"1w"},
        {label: intl.formatMessage({id: "aggregates.time_interval.1m"}), value:"1m"},
    ]

    const currency_list = [
        {label: "COP", value: "COP"},
        {label: "USD", value: "USD"},
        {label: "EUR", value: "EUR"}
    ]
    
    const group_by = [
        {label: 'Originator', value: 'group_by_originator'},
        {label: 'Terminator', value: 'group_by_terminator'},
        {label: 'Destination Group', value: 'group_by_dst_group'},
        {label: 'Origination Point', value: 'group_by_op'},
        {label: 'Termination Point', value: 'group_by_tp'},
        {label: 'Destination', value: 'group_by_dst'},
    ]
    
    // Datos basicos de las columnas de la tabla
    const columns = [
        {name: intl.formatMessage({id: "aggregates.dst_group"}), field: "dst_group", width: '300px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'}), footer: "Total:"},
        {name: intl.formatMessage({id: "aggregates.originator"}), field: "billed_originator", width: '200px', filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'}), template: (v) => formatCurrency(v.billed_originator)},
        {name: intl.formatMessage({id: "aggregates.billed_originator_with_tax"}), field: "billed_originator_with_tax", width: '230px', filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'}), template: (v) => formatCurrency(v.billed_originator_with_tax)},
        {name: intl.formatMessage({id: "aggregates.terminator"}), field: "billed_terminator", width: '125px', filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'}), template: (v) => formatCurrency(v.billed_terminator)},
        {name: intl.formatMessage({id: "aggregates.profit"}), field: "profit", width: '200px', filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'}), template: (v) => formatCurrency(v.profit)},
        {name: intl.formatMessage({id: "aggregates.originator"}), field: "billed_duration_originator", width: '230px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id: "aggregates.terminator"}), field: "billed_duration_terminator", width: '230px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id: "aggregates.duration"}), field: "duration", width: '230px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id: "aggregates.answered_calls"}), field: "answered_calls", width: '150px', filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id: "aggregates.total_calls"}), field: "total_calls", width: '125px', filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id: "aggregates.asr"}), field: "asr", width: '100px', filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'}), template: (v) => v.asr + " %", footer: totals["asr"]},
        {name: intl.formatMessage({id: "aggregates.acd"}), field: "acd", width: '100px', filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'}), footer: totals["acd"]},
        {name: intl.formatMessage({id: "aggregates.pdd"}), field: "pdd", width: '100px', filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'}), footer: totals["pdd"]},
        {name: intl.formatMessage({id: "aggregates.originator"}), field: "originator", width: '180px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'}), footer: null},
        {name: intl.formatMessage({id: "aggregates.originator_id"}), field: "originator_id", width: '160px', filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id: "aggregates.terminator"}), field: "terminator", width: '215px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id: "aggregates.terminator_id"}), field: "terminator_id", width: '160px', filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
    ]

    // Recorremos cada columna y creamos un componente con la data proporcionada por columns
    let dynamicColumns = columns.map((col) => {
        return( 
            <Column key={col.field} 
                    field={col.field} 
                    header={col.name} 
                    sortable={true} 
                    style={{width: col.width}}
                    filter={true}
                    footer={totals[col.field] || null}
                    footerClassName="text-left"
                    body={col.template || null}
                    showClear
                    filterMatchMode={col.filterMatchMode === '' ? 'startsWith' : col.filterMatchMode}
                    filterPlaceholder={col.placeholder}/> 
        )
    })
    
    const _getAggregates = (e) => {
        e.preventDefault()
        setLoader(true)
        setRows([])
        setTotals([])
        setError(false)
        axios.get(_getUrl(),
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`
                },
                cancelToken: source.token
            }
        ).then(res => {
                if(res.data.page){
                    const data = res.data.page.aggregates.aggregate
                    currency_in_table.current = currency
                    for(let key of Object.keys(data)){
                        data[key].originator_id = parseInt(data[key].originator_id)
                        data[key].terminator_id = parseInt(data[key].terminator_id)
                        data[key].billed_originator = convertirDecimal(data[key].billed_originator, 4)
                        data[key].billed_originator_with_tax = convertirDecimal(data[key].billed_originator_with_tax, 4)
                        data[key].billed_terminator = convertirDecimal(data[key].billed_terminator, 4)
                        data[key].billed_duration_originator = formatSecTime(parseInt(data[key].billed_duration_originator))
                        data[key].billed_duration_terminator = formatSecTime(parseInt(data[key].billed_duration_terminator))
                        data[key].duration = formatSecTime(parseInt(data[key].duration))
                        data[key].answered_calls = parseInt(data[key].answered_calls)
                        data[key].total_calls = parseInt(data[key].total_calls)
                        data[key].asr = convertirDecimal(data[key].asr, 2)
                        data[key].acd = data[key].acd.secsToHHMMSSPoints()
                        data[key].pdd = convertirDecimal(data[key].pdd, 4)
                        data[key].profit = convertirDecimal(data[key].profit, 4)
                    }

                    const totales = res.data.page.totals
                    totales["billed_originator"] = convertirDecimal(totales["billed_originator"], 4) + " " + currency_in_table.current
                    totales["billed_originator_with_tax"] = convertirDecimal(totales["billed_originator_with_tax"], 4) + " " + currency_in_table.current
                    totales["billed_terminator"] = convertirDecimal(totales["billed_terminator"], 4) + " " + currency_in_table.current
                    totales["billed_duration_originator"] = formatSecTime(parseInt(totales["billed_duration_originator"]))
                    totales["billed_duration_terminator"] = formatSecTime(parseInt(totales["billed_duration_terminator"]))
                    totales["duration"] = formatSecTime(parseInt(totales["duration"]))
                    totales["answered_calls"] = parseInt(totales["answered_calls"])
                    totales["total_calls"] = parseInt(totales["total_calls"])
                    totales["asr"] = convertirDecimal(totales["asr"], 2) + " %"
                    totales["acd"] = totales["acd"].secsToHHMMSSPoints()
                    totales["pdd"] = convertirDecimal(totales["pdd"], 4)
                    totales["profit"] = convertirDecimal(totales["profit"], 4) + " " + currency_in_table.current
                    totales["pdd_counter"] = parseInt(totales["pdd_counter"])                   
                    totales["dst_group"] = "Total:"
                    return [data, totales]
                }
        }).then(res => {
            setRows(res[0])
            setTotals(res[1])
            setLoader(false)
        // mostramos una pantalla de error si ocurre algo en el servidor
        }).catch(error => {
            setRows([])
            setTotals([])
            setLoader(false)
            setError(true)
        })
    }

    const calcularTiempo = (dias) => {
        const fecha = new Date(Date.now() - 24 * dias * 3600 * 1000)
        return parseInt(fecha.setHours(0,0,0,0) / 1000)
    }

    const addOPTPToUrl = () => {
        let queries = ""
        if(terminator in users){
            queries += `&s_terminator_id=${users[terminator]}`
            if(tp !== "default"){
                queries += `&s_tp_device=${tp}`
            }
        }
        if(originator in users){
            queries += `&s_originator_id=${users[originator]}`
            if(op !== "default"){
                queries += `&s_op_device=${op}`
            }
        }
        return queries
    }

    const addGroupByToURL = () => {
        let queries = ""
        for(let x in groupby){
            queries += `&${x}=1`
        }
        return queries
    }

    const addSwitchesToURL = () => {
        let queries = ""
        if(realBillsec){
            queries += `&use_real_billsec=1`
        }
        if(userPerspective){
            queries += `&from_user_perspective=1`
        }
        return queries
    }

    const addCurrencyToURL = () => {
        // eslint-disable-next-line
        return "&a_currency=" + currency.replaceAll(/[^\w\s\-_,ñ\[\]]/g, "")
    }

    const addIntervalToURL = () => {
        switch (timeAgg) {
            case "1d":
                return ("&from=" + calcularTiempo(1))
            case "2d":
                return ("&from=" + calcularTiempo(2))
            case "1w":
                return ("&from=" + calcularTiempo(7))
            case "1m":
                return ("&from=" + calcularTiempo(30))
            case "today":
            default:
                return ""
        }
    }

    const addInputTexts = () => {
        let queries = ""
        if(callerID !== "" && callerID !== null){
            // eslint-disable-next-line
            queries += `&a_src=${callerID.replaceAll(/[^\w\s\-_,ñ\[\]]/g, "")}`
        }
        if(destination !== "" && destination !== null){
            // eslint-disable-next-line
            queries += `&a_dst=${destination.replaceAll(/[^\w\s\-_,ñ\[\]]/g, "")}`
        }
        if(destinationGroup !== "" && destinationGroup !== null){
            // eslint-disable-next-line
            queries += `&dst_group=${destinationGroup.replaceAll(/[^\w\s\-_,ñ\[\]]/g, "")}`
        }
        return queries
    }

    const addAnsweredCalls = () => {
        if(answeredCalls === 0  || answeredCalls === null || isNaN(answeredCalls)){
            return ""
        }
        return "&answered_calls=" + answeredCalls
    }

    const _getUrl = () => {
        let url = '/api/v1/get/profile/?p=aggregate_get'
            + addIntervalToURL()
            + addGroupByToURL()
            + addSwitchesToURL()
            + addCurrencyToURL()
            + addInputTexts()
            + addAnsweredCalls()
            + addOPTPToUrl();
        return url
    }

    useEffect(() => {

        ReactGA.pageview(window.location.pathname);
        
        return () => {
            try {
                source.cancel("component unmounted")
            } catch (error) {}
        }
        // eslint-disable-next-line
    }, [])

    const formatCurrency = (value) => {
        return `${currency_in_table.current === "EUR" ? "€" : "$"} ${value}`
    }

    const formatSecTime = (v) => {
        const time = v.toString()
        return time.secsToHHMMSS()
    }
    
    const exportarCSV = () => {
        dt.current.exportCSV()
    }

    useEffect(() => {
        setTp("default")
        set_terminator_lists(valor_por_defecto_de_listas)
        if(terminator in users){
            axios.get("/api/v1/get/profile/?p=user_details_get&u=" + terminator.trim(),
                {
                    headers: {
                        "Authorization": `Bearer ${secureStorage.getItem("access")}`
                    }
                }
            ).then(res => {
                if(res.data.page){
                    let data = res.data.page
                    if("u_devices" in data){
                        const devices = data.u_devices.map((el) => {
                            return (
                                {
                                    "label": Object.values(el).toString(),
                                    "value": Object.keys(el).toString()
                                }
                            )
                        })
                        set_terminator_lists([...valor_por_defecto_de_listas, ...devices])
                    }
                }else{
                    set_terminator_lists(valor_por_defecto_de_listas)
                }
            }).catch(error => {
                set_terminator_lists(valor_por_defecto_de_listas)
            })
        } // eslint-disable-next-line
    }, [terminator])

    useEffect(() => {
        setOp("default")
        set_originator_lists(valor_por_defecto_de_listas)
        if(originator in users){
            axios.get("/api/v1/get/profile/?p=user_details_get&u=" + originator.trim(),
                {
                    headers: {
                        "Authorization": `Bearer ${secureStorage.getItem("access")}`
                    }
                }
            ).then(res => {
                if(res.data.page){
                    let data = res.data.page
                    if("u_devices" in data){
                        const devices = data.u_devices.map((el) => {
                            return (
                                {
                                    "label": Object.values(el).toString(),
                                    "value": Object.keys(el).toString()
                                }
                            )
                        })

                        set_originator_lists([...valor_por_defecto_de_listas, ...devices])
                    }
                }else{
                    set_originator_lists(valor_por_defecto_de_listas)
                }
            }).catch(error => {
                set_originator_lists(valor_por_defecto_de_listas)
            })
        } // eslint-disable-next-line
    }, [originator])

    return(
        <>
            <Titulo className="d-flex justify-content-center justify-content-lg-between">
                <FormattedMessage id="header.menu.aggregates"/>
                <button className="btn btn-outline-success d-none d-lg-block" disabled={rows.length < 1} type="button" onClick={exportarCSV}>
                    <FormattedMessage id="exportcsv"/>
                </button>
            </Titulo>

            <form method="GET" className="mb-3 aggregates-get-form" onSubmit={(e) => _getAggregates(e)}>
                <div className="row align-items-end justify-content-end">

                    <div className="col-6 col-sm-4 col-md-2 mb-1">
                        <label htmlFor="intervalsa" className="p-d-block">
                            <FormattedMessage id="aggregates.time_interval.title"/>:
                        </label>
                        <Dropdown className="p-inputtext-sm w-100" id="intervalsa" value={timeAgg} onChange={(e) => setTimeAgg(e.value)} options={intervals}/>
                    </div>

                    <div className="col-6 col-sm-3 col-md-2 mb-1">
                        <label className="p-d-block">
                            <FormattedMessage id="currency"/>:
                        </label>
                        <Dropdown className="p-inputtext-sm w-100" value={currency} onChange={(e) => setCurrency(e.value)} options={currency_list}/>
                    </div>
                    
                    <div className="col-12 col-sm-5 col-md-4 mb-1 d-flex flex-column justify-content-end">
                        <label className="p-d-block">
                            Group by:
                        </label>
                        <MultiSelect className="p-inputtext-sm w-100" value={groupby} options={group_by} onChange={(e) => setGroupby(e.value)}/>
                    </div>

                    <div className="col-6 col-sm-2 col-md-2 mb-1 d-flex flex-column justify-content-end">
                        <label className="p-d-block">
                            CallerID:
                        </label>
                        <InputText type="text" className="p-inputtext-sm aggregates-input-text w-100" value={callerID} onChange={(e) => setCallerID(e.target.value)}/>
                    </div>

                    <div className="col-6 col-sm-2 col-md-2 mb-1">
                        <label className="p-d-block">
                            Answered Calls:
                        </label>
                        <div className="p-inputgroup p-inputtext-sm">
                            <span className="p-inputgroup-addon font-weight-bold border-right-0">
                                &gt;=
                            </span>
                            <InputNumber inputClassName="p-inputtext-sm" min={0} inputStyle={{borderRadius: "0 3px 3px 0"}} value={answeredCalls} onChange={(e) => setAnsweredCalls(e.value)}/>
                        </div>
                    </div> 

                    <div className="col-6 col-sm-4 col-md-2 mb-1">
                        <label className="p-d-block">
                            Destination:
                        </label>
                        <InputText type="text" className="aggregates-input-text w-100" value={destination} onChange={(e) => setDestination(e.target.value)}/>
                    </div>

                    <div className="col-6 col-sm-4 col-md-2 mb-1">
                        <label className="p-d-block">
                            Destination Group:
                        </label>
                        <InputText type="text" className="aggregates-input-text w-100" value={destinationGroup} onChange={(e) => setDestinationGroup(e.target.value)}/>
                    </div>

                    <div className="col-6 col-sm-3 col-md-2 mb-1">
                        <label className="p-d-block">
                            Terminator:
                        </label>
                        <UsersTPInput style={{fontSize: "82%"}} value={terminator} onChange={(e) => setTerminator(e.target.value)}/>
                    </div>

                    <div className="col-6 col-sm-3 col-md-2 mb-1">
                        <label className="p-d-block">
                            Termination Point:
                        </label>
                        <Dropdown className="w-100" disabled={terminator_lists.length === 1} value={tp} onChange={(e) => setTp(e.value)} options={terminator_lists}/>
                    </div>

                    <div className="col-6 col-sm-3 col-md-2 mb-1">
                        <label className="p-d-block">
                            Originator:
                        </label>
                        <UsersOPInput style={{fontSize: "82%"}} value={originator} onChange={(e) => setOriginator(e.target.value)}/>
                    </div>

                    <div className="col-6 col-sm-3 col-md-2 mb-1">
                        <label className="p-d-block">
                            Origination Point:
                        </label>
                        <Dropdown className="w-100" disabled={originator_lists.length === 1} value={op} onChange={(e) => setOp(e.value)} options={originator_lists}/>
                    </div>                   
                    
                    <div className="col-6 col-sm-5 col-md-3 mb-1 mt-2 d-flex justify-content-start align-items-center">
                        <label className="p-d-block mr-3 mb-0">
                            Use Real Billsec:
                        </label>
                        <InputSwitch checked={realBillsec} onChange={(e) => setRealBillsec(e.value)}/>
                    </div>

                    <div className="col-6 col-sm-5 col-md-4 mb-1 mt-2 d-flex justify-content-start align-items-center">
                        <label className="p-d-block mr-3 mb-0">
                            From User Perspective:
                        </label>
                        <InputSwitch checked={userPerspective} onChange={(e) => setUserPerspective(e.value)}/>
                    </div>

                    <div className="col-12 col-sm-2 col-md-2 mb-1 mt-2">
                        <ButtonLoader 
                            loader={loader} className="button-tooltip-loader btn btn-block btn-ariatel--primary btn-sm" 
                            type="submit" style={{maxHeight: '25.63px', fontSize: "10px"}} showError={error}
                            onClick={(e) => _getAggregates(e)} tooltipPlace="top"
                            errorTitle={intl.formatMessage({id:"devices.error.search"})}
                        >
                            <FormattedMessage id="calls.search" />
                        </ButtonLoader> 
                    </div>
                </div>
            </form>
            
            {/* Tabla */}
            <DataTable className="false-header-aggregates">
                <Column style={{width: '300px'}}></Column>
                <Column style={{width: '755px'}} header={intl.formatMessage({id: "aggregates.billed"})}></Column>
                <Column style={{width: '690px'}} header={intl.formatMessage({id: "aggregates.time_b"})}></Column>
                <Column style={{width: '575px'}} header={intl.formatMessage({id: "quickstat.calls"})}></Column>
                <Column style={{width: '715px'}} header={intl.formatMessage({id: "aggregates.customer"})}></Column>
            </DataTable>
            <DataTable value={rows}
                ref={dt}
                exportFilename="aggregates"
                rowHover
                sortMode="multiple" 
                removableSort={true}
                className="table-aggregates"
                paginator={true}
                first={first} 
                rows={rowsNumber}
                rowsPerPageOptions={[10, 20, 30]} 
                onPage={(e) => {
                    setFirst(e.first)
                    setRowsNumber(e.rows)
                }}
            >
                {dynamicColumns}
            </DataTable>
            
            <button className="btn btn-block btn-outline-success d-block d-lg-none mt-3" disabled={rows.length < 1} type="button" onClick={exportarCSV}>
                <FormattedMessage id="exportcsv"/>
            </button>
        </>
    )
}

export default injectIntl(Aggregates)