import React from 'react'
import FormularioDeInicio from './forms/FormularioDeInicio'
import LanguageSwitch from "../../translation/LanguageSwitch"
import Header from './Header'
import Footer from './Footer'
import ReactGA from 'react-ga'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que contiene los wrappers del Formulario de Inicio de Sesión
   -----------------------------------------------------------------------------------------------
*/
class AppInicio extends React.Component{
    componentDidMount() {
      ReactGA.pageview(window.location.pathname);
    }

    render(){
      return (
        <>
          <div className="sign-in-form d-flex flex-column justify-content-between">
            {/* Header */}
            <Header/>
            {/* Main */}
            <main className="d-flex justify-content-center pt-5"> 
              <FormularioDeInicio/>
            </main>
            {/* Lenguaje Switch */}
            <LanguageSwitch margins="mt-3 pb-3" />
            <Footer/>
          </div>
        </>
      )
    }
}
export default AppInicio