/* 
   -----------------------------------------------------------------------------------------------
    Clase que permite tratar con todas las cookies del sitio        
   -----------------------------------------------------------------------------------------------
*/
export default class CookieTools{

    setCookie(cname, cvalue, exphours) {
      let d = new Date()
      d.setTime(d.getTime() + (exphours * 60 * 60 * 1000))
      const expires = "expires="+d.toUTCString()
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
    }
      
    getCookie(cname) {
      const name = cname + "="
      const ca = document.cookie.split(';')
      for(let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ""
    }

    deleteCookie(cname){
      let ck = this.getCookie(cname)
      if(ck !== ""){
        this.setCookie(cname, [], -10000)
      }
    }
}