import React, { useEffect } from 'react'
import LanguageSwitch from '../../translation/LanguageSwitch'
import { FormattedMessage } from 'react-intl'
import $ from 'jquery'
import Header from '../formulario/Header'
import ReactGA from 'react-ga'

/* 
   -----------------------------------------------------------------------------------------------
   Componente con mensaje de error cuando un usuario no tiene profile 
   (Puede suceder con superusers creados desde CLI)
   -----------------------------------------------------------------------------------------------
*/
export default function UserNoProfile(){

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
    },[])

    return(
        <>
          {/* Header */}
          <Header/>
          {/* Main */}
          <main className="d-flex justify-content-center pt-5">
              <div className="position-relative bg-white rounded shadow border mx-2 py-3 px-4" style={{maxWidth: "28em"}}>
                <h2 className="text-center"><FormattedMessage id="noDataTitle"/></h2>
                <hr/>
                <p className="text-justify">
                    <FormattedMessage id="noDataText1"/>
                    <a href="mailto:dev1@ariatel.co" data-toggle="tooltip" data-placement="bottom" title="dev1@ariatel.co">
                        <FormattedMessage id="noDataTextemail"/>
                    </a> 
                    <FormattedMessage id="noDataText2"/>
                </p>
              </div>
          </main>
          {/* Lenguaje Switch */}
          <LanguageSwitch margins="my-3" />
        </>
    )
}