import React from 'react'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que contiene el contenido de cada TAB
   -----------------------------------------------------------------------------------------------
*/
class TabContainer extends React.Component{
    render(){
        return (                    
            <div className={"tab-pane flex-wrap fade rounded bg-light border p-3 " + this.props.class} id={this.props.id} role="tabpanel" aria-labelledby={this.props.ariaLabelledBy + "-tab"}>
                {this.props.children}
            </div>
        )
    }
}

export default TabContainer