import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, IntlContext } from 'react-intl';
import secureStorage from '../../tools/SecureStorage';
import { AuthContext } from '../AuthContext';
import Spinner from '../Spinner';

/* 
   -----------------------------------------------------------------------------------------------
    Muestra la informacion de un usuario
   -----------------------------------------------------------------------------------------------
*/
export default function UserInfo(props) {
    const data = useContext(AuthContext)
    const lang = useContext(IntlContext)
    const [balance, setBalance] = useState("")
    const [currency, setCurrency] = useState("")
    const [details, setDetails] = useState(null)

    useEffect(() => {
        const source = axios.CancelToken.source()
        axios.get('/api/v1/get/profile/?p=user_details_get',
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`
                },
                cancelToken: source.token
            }
        ).then(res => setDetails(res.data.page.details)).catch(error => {
            setDetails("error")
        })

        return () => {
            source.cancel("component unmounted")
        }
    }, [])

    useEffect(() => {
        const source = axios.CancelToken.source()
        axios.get('/api/v1/get/profile/?p=user_balance_get',
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`
                },
                cancelToken: source.token
            }
        ).then(res => {
            setBalance(`${Intl.NumberFormat().format(res.data.page.balance)} ${res.data.page.currency}`)
            setCurrency(res.data.page.currency)
        }).catch(error => {
            setDetails("error")
        })

        return () => {
            source.cancel("component unmounted")
        }
    }, [])

    if(details === null){
        return <Spinner altura="h-100"/>
    }else if(details === "error"){
        return(
            <div className="text-center">
                <FontAwesomeIcon className="text-ariatel--primary mt-4 mb-4" icon="phone-slash" size="4x"/>
                <h4 className="text-center text-muted">
                    <FormattedMessage id="forms.login.dataError"/>
                </h4>
            </div>
        )
    }else{
        return(
            <>
                <div className="row">
                    <div className="col-12 order-1 order-xl-1 col-xl-12">
                        <table className="table table-sm table-hover">
                            <tbody>
                                <tr>
                                    <th className="border-0" scope="row">
                                        <FormattedMessage id="balance"/>
                                    </th>
                                    <td className="border-0">
                                        {balance}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Username</th>
                                    <td>{data.username_dj || "------"}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Phone</th>
                                    <td>{data.m2.contact.phone || "------"}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Main Email</th>
                                    <td>{data.m2.contact.main_email || "------"}</td>
                                </tr>
                                <tr>
                                    <th scope="row"><FormattedMessage id="payments.currency"/></th>
                                    <td>{currency}</td>
                                </tr>
                                <tr>
                                    <th scope="row"><FormattedMessage id="profile.language"/></th>
                                    <td>
                                        {lang.locale === "en" ? 
                                        "English (US)" : "Español (CO)"
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
    
                    <div className="col-12 mb-3 order-0 order-xl-0 col-xl-12">
                        <div className="card text-left">
                            <div className="card-body">
                                <h5 className="card-title font-weight-bold ">
                                    {data.m2.first_name} {data.m2.last_name}
                                </h5>
                                <address className="card-text">
                                    {details.other_details.taxation_country}
                                </address>
                            </div>
                            <div className="card-footer" style={{ fontSize: '.9rem' }}>
                                <span className="text-muted font-weight-bold">
                                    <FormattedMessage id="profile.agreement.date"/>:
                                </span> {details.other_details.agreement_date} <br/>
                                <span className="text-muted font-weight-bold">
                                    <FormattedMessage id="profile.agreement.number"/> #:
                                </span> {details.other_details.agreement_number === null ? "------" : details.other_details.agreement_number}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}