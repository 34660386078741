import React from 'react'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que renderiza los links de un componente tipo Tab
   -----------------------------------------------------------------------------------------------
*/
class TabLink extends React.Component{
    render(){
        return (
            <li className="nav-item">
                <a className={"nav-link tab-link " + this.props.class} id={this.props.id + "-tab"} data-toggle="tab" href={this.props.href} role="tab" aria-controls={this.props.ariaControl} aria-selected={this.props.ariaSelected}>{this.props.children}</a>
            </li>
        )
    }
}

export default TabLink