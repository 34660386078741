import React, { useState, useEffect, useRef } from 'react'
import Titulo from '../../../tools/Titulo'
import $ from 'jquery'
import * as html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { useHistory } from 'react-router'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que permite que un usuario reporte su pago con un recibo de tipo imagen o pdf
   -----------------------------------------------------------------------------------------------
*/
export default function ResponseCreditCard(props){
    const [transaction, setTransaction] = useState({})
    const seccion = useRef(null)
    const history = useHistory()

    useEffect(() => {
        setTransaction(history.location.state)
        // eslint-disable-next-line  
    }, []);

    const printDocument = () => {
        html2canvas(seccion.current, {
            useCORS: true,
            allowTaint: true,
            scrollY: -window.scrollY,
            scale: 1
          }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF("p", "px", "a4")

            const pageWidth = pdf.internal.pageSize.getWidth()
            const pageHeight = pdf.internal.pageSize.getHeight()
            const widthRatio = pageWidth / canvas.width
            const heightRatio = pageHeight / canvas.height
            const ratio = widthRatio > heightRatio ? heightRatio : widthRatio
            const canvasWidth = canvas.width * ratio
            const canvasHeight = canvas.height * ratio

            const marginX = ((pageWidth - canvasWidth) / 2) - 2.5
            const marginY = (pageHeight - canvasHeight) / 2
            pdf.addImage(imgData, 'JPEG', marginX, marginY, canvasWidth, canvasHeight)
            pdf.save("recibo.pdf")
        })
    }

    return(
        <>
            <Titulo className="d-flex justify-content-center justify-content-lg-between">
                <FormattedMessage id="cc.title"/>
                <button className="btn btn-outline-ariatel--danger d-none d-lg-block" disabled={$.isEmptyObject(transaction)} type="button" onClick={printDocument}>
                    <FormattedMessage id="pse.boton.pdf"/>
                </button>
            </Titulo>
            {$.isEmptyObject(transaction) ? null :
                <section ref={seccion} className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <div className="card">
                            <div className="card-header">
                                {transaction.status}
                            </div>
                            <div className="card-body">
                                <table className="table table-bordered table-sm">
                                    <tbody>
                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="pse.factura"/></th>
                                            <td>{transaction.id}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="cc.dues"/></th>
                                            <td>{transaction.payment_method.installments}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="payments.amount"/> (COP)</th>
                                            <td>$ {transaction.amount_in_cents/100} {transaction.currency}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="pse.bank"/></th>
                                            <td className="text-capitalize">{transaction.payment_method.extra.brand}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="payu.status"/></th>
                                            <td>{transaction.status}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="tariff.name"/></th>
                                            <td>{transaction.customer_data.full_name}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="profile.movil"/></th>
                                            <td>{transaction.customer_data.phone_number}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="profile.email"/></th>
                                            <td>{transaction.customer_email}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="payu.date"/></th>
                                            <td>{new Date(transaction.created_at).toLocaleString()}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="profile.registration.reg_address"/></th>
                                            <td>{transaction.shipping_address.address_line_1}, {transaction.shipping_address.city}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className="border-top d-flex justify-content-between">
                                    <span>
                                        Ariatel S.A.S 
                                    </span>
                                    <span>
                                        NIT. 900.650.791-0
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
            }
            <div className="text-center">
                <small className="text-muted"><FormattedMessage id="pending.message"></FormattedMessage><Link to="/u/payments/history/" className="text-lowercase"><FormattedMessage id="header.menu.payments"></FormattedMessage></Link></small>
            </div>
        </>
    )
}