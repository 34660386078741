import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Dashboard from '../compartidos/Dashboard'
import Main from '../compartidos/secciones/Main'
import Footer from '../compartidos/secciones/Footer'
import Header from '../compartidos/secciones/Header'
import UserProfile from '../compartidos/UserProfile'
import ChangePassword from '../compartidos/ChangePassword'
import Payments from '../compartidos/Payments'
import Rates from '../compartidos/Rates'
import PaymentCreate from '../compartidos/add payments/PaymentCreate'
import Transfer from '../compartidos/add payments/methods/Transfer'
import Policy from '../compartidos/policy/Policy'
import Dashboard404 from '../404/Dashboard404'
import OutOfService from '../404/OutOfService'
import { HeaderNavOnlyLink, FooterNavOnlyLink } from '../compartidos/tools/HeaderLinks'
import FooterNav from '../compartidos/tools/FooterNav'
// import CdrLobby from '../compartidos/call records/CdrLobby'
import CdrTable from '../compartidos/call records/CdrTable'
import PSE from '../compartidos/add payments/wompi/PSE/PSE'
import PSEResponse from '../compartidos/add payments/wompi/PSE/PSEResponse'
import BotonBancolombia from '../compartidos/add payments/wompi/Boton Bancolombia/BotonBancolombia'
import BotonBancolombiaResponse from '../compartidos/add payments/wompi/Boton Bancolombia/BotonBancolombiaResponse'
import AddCreditCard from '../compartidos/add payments/wompi/Credit Card/AddCreditCard'
import ListCreditCard from '../compartidos/add payments/wompi/Credit Card/ListCreditCard'
import CreditCard from '../compartidos/add payments/wompi/Credit Card/CreditCard'
import ResponseCreditCard from '../compartidos/add payments/wompi/Credit Card/ResponseCreditCard'
import ResponseCreditCardHistory from '../compartidos/add payments/wompi/Credit Card/ResponseCreditCardHistory'
import PSEResponseHistory from '../compartidos/add payments/wompi/PSE/PSEResponseHistory'
import BotonBancolombiaResponseHistory from '../compartidos/add payments/wompi/Boton Bancolombia/BotonBancolombiaResponseHistory'


export default function OPUser(){
    const LG = <>
        <HeaderNavOnlyLink className="d-none d-md-block" icon="clipboard-list" msgId="header.menu.rates" to="/u/rates/"/>
        <HeaderNavOnlyLink className="d-none d-md-block" icon="hand-holding-usd" msgId="header.menu.add.payments" to="/u/pay/"/>
        <HeaderNavOnlyLink className="d-none d-md-block" icon="scroll" msgId="header.menu.payments" to="/u/payments/history/"/>
        <HeaderNavOnlyLink className="d-none d-md-block" icon="table" msgId="calls.nav.title" to="/u/calls/records/"/>
    </>
 
    const SM = <>
        <FooterNav>
            <FooterNavOnlyLink icon="clipboard-list" msgId="header.menu.rates" to="/u/rates/"/>
            <FooterNavOnlyLink icon="hand-holding-usd" msgId="header.menu.add.payments" to="/u/pay/"/>
            <FooterNavOnlyLink icon="scroll" msgId="header.menu.payments" to="/u/payments/history/"/>
            <FooterNavOnlyLink icon="table" msgId="calls.nav.title" to="/u/calls/records/"/>
        </FooterNav>
    </>

    return(
        <div id="container-of-client" className="d-flex flex-wrap flex-row justify-content-start">
            <Header footerNav={SM} navLG={LG}/>
            <Main>
                <Switch>

                        {/* Policy */}
                            <Route exact path="/u/policy/terms-and-conditions/">
                                <Policy/>
                            </Route>

                            <Route exact path="/u/policy/privacy-policy/">
                                <Policy/>
                            </Route>

                            <Route exact path="/u/policy/terms-of-use/">
                                <Policy/>
                            </Route>

                            <Route exact path="/u/policy">
                                <Redirect to="/u/policy/terms-and-conditions/"/>
                            </Route>

                            <Route path="/u/policy/*">
                                <Redirect to="/u/policy/terms-and-conditions/"/>
                            </Route>
                        {/* Policy */}

                        <Route exact path="/u/">
                            <Dashboard/>
                        </Route>
                        <Route exact path="/u/profile/">
                            <UserProfile/>
                        </Route>
                        <Route exact path="/u/change/password/">
                            <ChangePassword/>
                        </Route>
                        <Route exact path="/u/payments/history/">
                            <Payments/>
                        </Route>
                        <Route exact path="/u/rates/">
                            <Rates/>
                        </Route>
                        <Route exact path="/u/pay/">
                            <PaymentCreate/>
                        </Route>
                        <Route exact path="/u/pay/by/transfer/">
                            <Transfer/>
                        </Route>
                        <Route exact path="/u/pay/by/pse/">
                            <OutOfService/>
                        </Route>
                        <Route exact path="/u/pay/by/pse/response/*">
                            <PSEResponse/>
                        </Route>
                        <Route exact path="/u/pay/by/bancolombia/">
                            <OutOfService/>
                        </Route>
                        <Route exact path="/u/pay/by/bancolombia/response/*">
                            <BotonBancolombiaResponse/>
                        </Route>
                        <Route exact path="/u/add/credit-card/">
                            <AddCreditCard/>
                        </Route>
                        <Route exact path="/u/list/credit-card/">
                            <ListCreditCard/>
                        </Route>
                        <Route exact path="/u/pay/by/credit-card/">
                            <OutOfService/>
                        </Route>
                        <Route exact path="/u/pay/by/credit-card/wompi/response/">
                            <ResponseCreditCard/>
                        </Route>
                        <Route exact path="/u/transaction/credit-card/:reference/">
                            <ResponseCreditCardHistory/>
                        </Route>
                        <Route exact path="/u/transaction/pse/:reference/">
                            <PSEResponseHistory/>
                        </Route>
                        <Route exact path="/u/transaction/boton-bancolombia/:reference/">
                            <BotonBancolombiaResponseHistory/>
                        </Route>
                        <Route exact path="/u/calls/records/">
                            {/* <CdrLobby/> */}
                            <CdrTable/>
                        </Route>
                        {/* <Route exact path="/u/calls/records/last/week/">
                            <CdrTable/>
                        </Route> */}
                        <Route exact path="/u/404/">
                            <Dashboard404/>
                        </Route>
                        <Route exact path="*">
                            <Redirect to="/u/404/"/>
                        </Route>
                </Switch>
            </Main>
            <Footer/>
        </div>
    )
  }