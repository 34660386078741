import React, { useState, useEffect, useRef, useContext } from 'react'
import Titulo from '../../compartidos/tools/Titulo'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import { FormattedMessage, useIntl } from 'react-intl'
import secureStorage from '../../../tools/SecureStorage'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {DatePicker} from 'antd'
import { Dropdown } from 'primereact/dropdown'
import moment from 'moment-timezone'
import hangupcausecodes from '../hangupcausecodeswithout300.json'
import FilterInput from '../tools/FilterInput'
import ButtonLoader from '../tools/ButtonLoader'
import ObjectUtils from 'primereact/components/utils/ObjectUtils'
import { AuthContext } from '../../AuthContext'
import ReactGA from 'react-ga'

// eslint-disable-next-line
String.prototype.secsToHHMMSSPoints = function () {
    let sec_num = parseInt(this, 10)
    let hours   = Math.floor(sec_num / 3600)
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60)
    let seconds = sec_num - (hours * 3600) - (minutes * 60)

    if (hours   < 10) {hours   = "0"+hours}
    if (minutes < 10) {minutes = "0"+minutes}
    if (seconds < 10) {seconds = "0"+seconds}
    return hours+':'+minutes+':'+seconds
}

const { RangePicker } = DatePicker

/* 
   -----------------------------------------------------------------------------------------------
    Componente que muestra la tabla de los cdr de la ultima semana del usuario
   -----------------------------------------------------------------------------------------------
*/
export default function CdrTable(){
    const intl = useIntl()
    const [rows, setRows] = useState([])
    const dt = useRef()
    const [pagination, setPagination] = useState({})
    const [error, setError] = useState(false)
    const [loader, setLoader] = useState(false)
    const [source, setSource] = useState({"value": "", "current_filter": ""})
    const [destination, setDestination] = useState({"value": "", "current_filter": "icontains"})
    const [tariff, setTariff] = useState({"value": "", "current_filter": ""})
    const [duration, setDuration] = useState({"value": "", "current_filter": ""})
    const [status, setStatus] = useState("")
    const [hangup, setHangup] = useState(null)
    const data = useContext(AuthContext).m2
    const [multiSort, setMultiSort] = useState([])
    const [dstLocalizado, setDstLocalizado] = useState({"value": "", "current_filter": "iendswith"})
    const [int, setInt] = useState(
        [moment().subtract(1, 'h'),moment(new Date().setHours(23,59,59,59))]
    )
    const toformat = (campo) => encodeURIComponent(campo.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss'))
    const emptyMessage = <h3 className="dashboard-main-title text-muted py-4"><FormattedMessage id="records.week.error"/></h3>;
    const columns = [
        {name: intl.formatMessage({id:"calls.calldate2"}), field: "calldate", width: '228px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id:"calls.answer_time"}), field: "answer_time", width: '228px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id:"calls.end_time"}), field: "end_time", width: '228px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id:"calls.calldate2"}), field: "calldate_hide", width: '228px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'}), className: "d-none"},
        {name: intl.formatMessage({id:"calls.answer_time"}), field: "answer_time_hide", width: '228px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'}), className: "d-none"},
        {name: intl.formatMessage({id:"calls.end_time"}), field: "end_time_hide", width: '228px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'}), className: "d-none"},
        {name: intl.formatMessage({id:"calls.src"}), field: "src", width: '170px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id:"calls.prefix"}), field: "prefix", width: '120px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id:"calls.localized_dst"}), field: "localized_dst", width: '210px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id:"calls.dst"}), field: "dst_name", width: '250px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id:"tariff"}), field: "tariff_name", width: '250px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id:"calls.terminated_by"}), field: "terminated_by", width: '180px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id:"calls.pdd"}), field: "pdd", width: '104px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id:"calls.duration"}), field: "duration", width: '138px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id:"calls.user_price"}), field: "user_price", width: '200px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id:"calls.hangupcause"}), field: "disposition", width: '202px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id:"calls.originator_ip"}), field: "originator_ip", width: '140px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.hangupcause"}), field: "hangupcause", width: '202px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.accountcode"}), field: "accountcode", width: '174px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.billsec"}), field: "billsec", width: '158px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.did_billsec"}), field: "did_billsec", width: '124px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.did_inc_price"}), field: "did_inc_price", width: '154px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'}), template: (v) => parseFloat(v.did_inc_price).toString()},
        // {name: intl.formatMessage({id:"calls.did_price"}), field: "did_price", width: '150px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'}), template: (v) => parseFloat(v.did_price).toString()},
        // {name: intl.formatMessage({id:"calls.did_prov_price"}), field: "did_prov_price", width: '206px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'}), template: (v) => parseFloat(v.did_prov_price).toString()},
        // {name: intl.formatMessage({id:"calls.did_provider_id"}), field: "did_provider_id", width: '202px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.clid"}), field: "clid", width: '262px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.date"}), field: "date", width: '100px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.dst_device_id"}), field: "dst_device_id", width: '222px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.dst_user_id"}), field: "dst_user_id", width: '200px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.originator_codec"}), field: "originator_codec", width: '166px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.pai"}), field: "pai", width: '150px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.partner_price"}), field: "partner_price", width: '172px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'}), template: (v) => parseFloat(v.partner_price).toString()},
        // {name: intl.formatMessage({id:"calls.partner_rate"}), field: "partner_rate", width: '168px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'}), template: (v) => parseFloat(v.partner_rate).toString()},
        // {name: intl.formatMessage({id:"calls.processed"}), field: "processed", width: '120px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.provider_billsec"}), field: "provider_billsec", width: '192px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.provider_id"}), field: "provider_id", width: '162px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.provider_price"}), field: "provider_price", width: '198px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'}), template: (v) => parseFloat(v.provider_price).toString()},
        // {name: intl.formatMessage({id:"calls.provider_rate"}), field: "provider_rate", width: '192px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'}), template: (v) => parseFloat(v.provider_rate).toString()},
        // {name: intl.formatMessage({id:"calls.real_billsec"}), field: "real_billsec", width: '138px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'}), template: (v) => parseFloat(v.real_billsec).toString()},
        // {name: intl.formatMessage({id:"calls.real_duration"}), field: "real_duration", width: '146px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'}), template: (v) => parseFloat(v.real_duration).toString() + intl.formatMessage({id:"calls.segundos"})},
        // {name: intl.formatMessage({id:"calls.reseller_price"}), field: "reseller_price", width: '178px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'}), template: (v) => parseFloat(v.reseller_price).toString()},
        // {name: intl.formatMessage({id:"calls.reseller_rate"}), field: "reseller_rate", width: '168px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'}), template: (v) => parseFloat(v.reseller_rate).toString()},
        // {name: intl.formatMessage({id:"calls.server_id"}), field: "server_id", width: '148px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.src_device_id"}), field: "src_device_id", width: '224px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.src_user_id"}), field: "src_user_id", width: '168px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.terminator_codec"}), field: "terminator_codec", width: '198px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.terminator_ip"}), field: "terminator_ip", width: '170px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.uniqueid"}), field: "uniqueid", width: '274px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.user_billsec"}), field: "user_billsec", width: '178px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.user_id"}), field: "user_id", width: '140px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        // {name: intl.formatMessage({id:"calls.user_rate"}), field: "user_rate", width: '178px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'}), template: (v) => parseFloat(v.user_rate).toString()},
    ]
    
    // Luego de renderizar el componente hace un querie para obtener la tabla y esconde el modal
    // que contiene el formulario de filtrado para evitar errores y al desmontar el componente tambien
    // lo ocultamos con el mismo fin
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
        // parseFilters()
        queriePage("/cdr/?" + parseFilters()) //+ (filters === "" ? ("calldate__gte=" + encodeURIComponent(moment(new Date().setHours(0,0,0,0)).format('YYYY-MM-DD HH:mm:ss'))) : filters))
        // eslint-disable-next-line
    }, [])

    const status_options = [
        {label: intl.formatMessage({id: "calls.filter.c_type_all"}), value: ""},
        {label: intl.formatMessage({id: "calls.filter.c_type_no_answer"}), value: "NO ANSWER"},
        {label: intl.formatMessage({id: "calls.filter.c_type_answered"}), value: "ANSWERED"},
        {label: intl.formatMessage({id: "calls.filter.c_type_failed"}), value: "FAILED"},
        {label: intl.formatMessage({id: "calls.filter.c_type_busy"}), value: "BUSY"},
    ]

    function onSortFunction (event) {
        var value = [...rows];
        const t = "YYYY-MM-DD hh:mm:ss"
        value.sort((data1, data2) => {
            let value1 = ObjectUtils.resolveFieldData(data1, event.field);
            let value2 = ObjectUtils.resolveFieldData(data2, event.field);
            let result = null;

            result = (moment(value1, t) < moment(value2, t)) ? -1 : (moment(value1, t) > moment(value2, t)) ? 1 : 0;

            return (event.order * result);
        });

        return value;
    }

    // Recorremos cada columna y creamos un componente con la data proporcionada por columns
    let dynamicColumns = columns.map((col) => {
        return( 
            <Column 
                key={"cdr-table-column" + col.field} 
                field={col.field}
                sortField={["calldate", "answer_time","end_time"].includes(col.field) ? `${col.field}_hide` : null}
                header={col.name} 
                sortable={true}
                style={{width: col.width}}
                className={col.className}
                showClear
                sortFunction={["calldate_hide", "answer_time_hide","end_time_hide"].includes(col.field) ? onSortFunction : null}
                body={col.template || null}
            /> 
        )
    })

    const parseFilters = () => {
        let url = ""

        // Dates handling
        if(Array.isArray(int)){
            if(int[0] === null){
                url += `&calldate__gte=${toformat(moment().subtract(1, 'h'))}`
                setInt([moment().subtract(1, 'h'), int[1]])
            }else{
                url += `&calldate__gte=${toformat(int[0])}` 
            }

            if(int[1] === null){
                url += `&calldate__lte=${toformat(moment(new Date().setHours(23,59,59,59)))}`
                setInt([int[0],moment(new Date().setHours(23,59,59,59))])
            }else{
                url += `&calldate__lte=${toformat(int[1])}` 
            }
        }else{
            setInt([moment().subtract(1, 'h'),moment(new Date().setHours(23,59,59,59))])
            url += `&calldate__gte=${toformat(moment().subtract(1, 'h'))}&calldate__lte=${toformat(moment(new Date().setHours(23,59,59,59)))}`
        }

        if (status !== "" &&  status_options.map(e => e.value).includes(status)) {
            url += `&disposition=${encodeURIComponent(status.trim())}`
        }

        if (hangup !== "" && hangup !== null && hangupcausecodes.map(e => e.code).includes(hangup)) {
            url += `&hangupcause=${encodeURIComponent(hangup.trim())}`
        }

        if (source.value !== ""){
            let field = "&src"
            if (source.current_filter !== "") {
                field += "__" + source.current_filter
            }
            field += "=" + encodeURIComponent(source.value.trim())
            url += field
        }

        if (destination.value !== ""){
            let field = "&dst_name"
            if (destination.current_filter !== "") {
                field += "__" + destination.current_filter
            }
            field += "=" + encodeURIComponent(destination.value.trim())
            url += field
        }

        if (tariff.value !== ""){
            let field = "&tariff_name"
            if (tariff.current_filter !== "") {
                field += "__" + tariff.current_filter
            }
            field += "=" + encodeURIComponent(tariff.value.trim())
            url += field
        }

        if (duration.value !== ""){
            let field = "&duration"
            if (duration.current_filter !== "") {
                field += "__" + duration.current_filter
            }
            field += "=" + encodeURIComponent(duration.value.trim())
            url += field
        }

        if (dstLocalizado.value !== ""){
            let field = "&localized_dst"
            if (dstLocalizado.current_filter !== "") {
                field += "__" + dstLocalizado.current_filter
            }
            field += "=" + encodeURIComponent(dstLocalizado.value.trim())
            url += field
        }

        if (multiSort.length > 0) {
            url += "&ordering=" + multiSort.map(e => `${e["order"] > 0 ? "" : "-"}${e["field"].replace("_hide", "")}`).toString()       
        }

        return url
    }

    const handleSorting = (e) => {
        if(e.multiSortMeta.length > 0){
            const index = multiSort.findIndex(el => el.field === e.multiSortMeta[0].field)
            if(index >= 0){
                setMultiSort(multiSort.map((el, i) => i === index ? e.multiSortMeta[0] : el))
            }else{
                setMultiSort([...multiSort, e.multiSortMeta[0]])
            }
        }else{
            if (multiSort.length === 0) {
                setMultiSort([])
            }
            dt.current.resetColumnOrder()
        }
    }

    useEffectNotInit(() => {queriePage(`/cdr/?${parseFilters()}`)}, [multiSort])

    const handleReset = () => {
        setSource({"value": "", "current_filter": ""})
        setDestination({"value": "", "current_filter": ""})
        setDuration({"value": "", "current_filter": ""})
        setDstLocalizado({"value": "", "current_filter": ""})
        setTariff({"value": "", "current_filter": ""})
        setStatus("")
        setHangup(null)
        setInt([moment().subtract(1, 'h'),moment(new Date().setHours(23,59,59,59))])
        setMultiSort([])
        dt.current.resetColumnOrder()
        queriePage( `/cdr/?calldate__gte=${toformat(moment().subtract(1, 'h'))}&calldate__lte=${toformat(moment(new Date().setHours(23,59,59,59)))}`)
    }

    const disabledDate = (current) => (current > moment(new Date().setHours(23,59,59,59)) || current < moment().subtract(data.max_days, 'd'))

    // Formatea los datetimes a la hora local
    const formatEpochDate = (epoch_date) => {
        if (!epoch_date){
            return ""
        }

        const options = {
            // timeZoneName: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        }
        const utc = moment.utc(epoch_date, "YYYY-MM-DD hh:mm:ss").toDate()

        return utc.toLocaleString([], options)
    }

    // Exporta la pagina de la tabla
    // const exportarCSV = () => {
    //     dt.current.exportCSV()
    // }    

    const queriePage = (url) => {
        // Hace una peticion HTTP GET con los parametros de filtrado actuales o del numero de pagina
        // Parametro 'url': str
        setLoader(true) // Mostramos el Loader
        setError(false) // Indicamos que no ha habido un error en esta consulta
        // setRows([]) // Eliminamos los rows de la tabla anterior
        axios.get(url,
            {
                headers: {
                    Authorization: `Bearer ${secureStorage.getItem("access")}`
                }
            }
        ).then(res => {
            if("detail" in res.data){ // Si ese campo existe quiere decir que hubo un error, generalmente la pagina no existe
                setRows([])
                setError(true)
                setLoader(false)
            }else{
                if(res.data.results.length === 0){ // Si no hubo resultados decimos que no hay nada
                    setRows([])
                    setError(true)
                    setPagination({})
                    setLoader(false)
                }else{
                    const info = {...res.data, results: []}
                    setPagination(info)
                    
                    const results = res.data.results.map(record => {
                        record["calldate_hide"] = record["calldate"]
                        record["answer_time_hide"] = record["answer_time"]
                        record["end_time_hide"] = record["end_time"]
                        record["calldate"] = formatEpochDate(record["calldate"])
                        record["answer_time"] = formatEpochDate(record["answer_time"])
                        record["end_time"] = formatEpochDate(record["end_time"])
                        record["duration"] = record["duration"].toString().secsToHHMMSSPoints()
                        record["pdd"] = parseFloat(record["pdd"]).toFixed(4)
                        record["user_price"] = `$ ${parseFloat(record["user_price"]).toFixed(4).replace(/.0+$/, "")}`
                        record["disposition"] = `${record["disposition"]} (${record["hangupcause"]})`
                        return record
                    })

                    setRows(results)
                    setLoader(false)
                }
            }
        }
        ).catch(err => {
            console.error(err)
            setError(true)
            setLoader(false)
        })
        // $(window).scrollTop(0) // Luego de hacer la peticion colocamos al usuario al top de la pagina
    }

    // Handler al hacer click en un boton para cambiar de pagina
    const handlePagination = (button) => {
        // Parametro 'button': ('previous' | 'next') -> indica si vamos para adelante o atras
        if(button in pagination.links){
            queriePage(pagination.links[button].slice(25)) // EN DESARROLLO EL SLICE ES DE 21 MIENTRAS QUE EN PRODUCCION SON 25
        }else{ // Por si el usuario se las ingenia para ir a un link que no existe
            console.error("Error trying to paginate to unknow page")
        }
    }

    // Handler al hacer click en un boton para cambiar de pagina a la ultima o primera pagina
    const handlePaginationLimits = (limit) => {
        // Parametro 'limit': ('end' | 'start') -> es el limite
        const path = `/cdr/?${parseFilters()}&page=`
        if(limit === "end"){
            // Aqui calculamos cual numero es la ultima pagina
            queriePage(path + Math.ceil(pagination.total/pagination.page_size))
        }else{
            // 1 siempre sera la primera pagina
            queriePage(path + 1)
        }
    }
    
    // Sub-Componente que se encarga muestra la paginacion de la tabla
    const Paginator = () => {
        if("links" in pagination){
            return(
                <>
                    <div className="d-flex justify-content-center align-items-center">
                        <button type="button" onClick={() => handlePaginationLimits("first")} className="btn btn-sm btn-custom-paginator" disabled={!pagination.links.previous || loader}>
                            <FontAwesomeIcon icon="fast-backward"/>
                        </button>
                        <button type="button" onClick={() => handlePagination("previous")} className="btn btn-sm btn-custom-paginator" disabled={!pagination.links.previous || loader}>
                            <FontAwesomeIcon icon="step-backward"/>
                        </button>
                        <small className="px-1 mb-0">
                            <FormattedMessage id="page"/> {pagination.page} <FormattedMessage id="of"/> {Math.ceil(pagination.total/pagination.page_size)}
                        </small>
                        <button type="button" onClick={() => handlePagination("next")} className="btn btn-sm btn-custom-paginator" disabled={!pagination.links.next || loader}>
                            <FontAwesomeIcon icon="step-forward"/>
                        </button>
                        <button type="button" onClick={() => handlePaginationLimits("end")} className="btn btn-sm btn-custom-paginator" disabled={!pagination.links.next || loader}>
                            <FontAwesomeIcon icon="fast-forward"/>
                        </button>
                    </div>
                    <div className="text-center">
                        <small className="font-weight-light">
                            {rows.length} <FormattedMessage id="elements"/> <FormattedMessage id="of"/> {pagination.total}
                        </small>
                    </div>
                </>
            )
        }
        return null
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        queriePage(`/cdr/?${parseFilters()}`)
    }

    const handleExport = (e) => {
        e.preventDefault()
        window.open(`http://billing.ariatel.us/cdr/csv/?${parseFilters()}`, "_self")
    }

    return(
        <>
            <Titulo className="d-flex justify-content-center justify-content-lg-between">
                <FormattedMessage id="calls.titulo"/>
                <button type="button" className="btn btn-outline-success d-none d-lg-block" onClick={handleExport}>
                    <FormattedMessage id="exportcsv"/>
                </button>
            </Titulo>

            <form className="mb-3" onSubmit={handleSubmit}>
                <div className="form-row align-items-end justify-content-center justyf-content-xl-start">
                    <div className="form-group mb-2 col-12 col-lg-5">
                        <label style={{fontSize: "13px"}} htmlFor="input-int-cdrs" className="w-100">
                            <FormattedMessage id="aggregates.time_interval.title"/>:
                        </label>
                        <RangePicker
                            format="YYYY-MM-DD HH:mm:ss"
                            name="input-int-cdrs"
                            ranges={{
                                [intl.formatMessage({id: "3.hours.ago"})]: [moment().subtract(3, 'h'), moment()],
                                [intl.formatMessage({id: "quickstat.today"})]: [moment(new Date().setHours(0,0,0,0)), moment(new Date().setHours(23,59,59,59))],
                                [intl.formatMessage({id: "yesterday"})]: [moment(new Date().setHours(0,0,0,0)).subtract(1, 'd'), moment(new Date().setHours(23,59,59,59)).subtract(1, 'd')],
                            }}
                            showTime
                            style={{borderColor: "#a6a6a6"}}
                            separator={<FontAwesomeIcon className="text-muted" size="sm" icon="arrow-right"/>}
                            value={int}
                            placeholder={["From", "Till"]}
                            onChange={(date, string) => setInt(date)}
                            id="input-int-cdrs"
                            className="w-100"
                            disabledDate={disabledDate}
                        />
                    </div>
                    <div className="form-group mb-2 col-6 col-sm-3 col-lg-2">
                        <label style={{fontSize: "13px"}} htmlFor="input-status-cdrs" className="w-100">
                            <FormattedMessage id="status"/>:
                        </label>
                        <Dropdown options={status_options} name="input-status-cdrs" id="input-status-cdrs" className="w-100 cdr-subfilter-input" onChange={(e) => setStatus(e.value)} value={status}/>
                    </div>
                    <div className="form-group mb-2 col-6 col-sm-2 col-lg-3">
                        <label style={{fontSize: "13px"}} htmlFor="input-status-cdrs" className="w-100">
                            <FormattedMessage id="calls.hangupcause"/>:
                        </label>
                        <Dropdown panelStyle={{maxWidth: "300px", fontSize: "13px"}} filter filterBy="code" filterPlaceholder="Search a code" optionLabel="msg" optionValue="code" options={hangupcausecodes} name="input-hangupcode-cdrs" id="input-hangupcode-cdrs" className="cdr-subfilter-input w-100" onChange={(e) => setHangup(e.value)} value={hangup}/>
                    </div>
                    <div className="w-100 d-none d-lg-block"></div>
                    <FilterInput state={source} className="col-12 col-sm-3 col-lg-2" filters={["icontains", "istartswith", "iendswith"]} label="Source" setState={setSource}/>
                    <FilterInput state={destination} label={<FormattedMessage id="calls.dst"/>} className="col-12 col-sm-4" filters={["icontains", "istartswith", "iendswith"]} setState={setDestination}/>
                    <FilterInput state={duration} label={<><FormattedMessage id="calls.duration"/> <FormattedMessage id="calls.segundos"/></>} keyfilter="pint" className="col-6 col-sm-4"  filters={["gt","gte","lt","lte"]} setState={setDuration}/>
                    <FilterInput state={dstLocalizado} label={<FormattedMessage id="calls.localized_dst"/>} className="col-6 col-sm-3" filters={["icontains", "istartswith", "iendswith"]} setState={setDstLocalizado}/>
                    <FilterInput state={tariff} label={<FormattedMessage id="tariff"/>} className="col-12 col-sm-3" filters={["icontains", "istartswith", "iendswith"]} setState={setTariff}/>
                    <div className="col-6 col-sm-4 mb-2 d-flex mt-2">
                        <button className="btn btn-outline-info btn-sm" disabled={loader} onClick={handleReset} type="reset"><FontAwesomeIcon icon="eraser"/></button>
                        <ButtonLoader 
                            loader={loader} className="button-tooltip-loader w-100 ml-2 btn btn-ariatel--primary btn-sm" 
                            type="submit"
                            onClick={handleSubmit}
                        >
                            <FormattedMessage id="calls.search" />
                        </ButtonLoader> 
                    </div>
                </div>
            </form>

            <small className="text-muted"><FormattedMessage id="dates.info.1"/> {Intl.DateTimeFormat().resolvedOptions().timeZone} <FormattedMessage id="calls.timezone"/> (GMT{moment().utcOffset()/60})</small>

            <DataTable value={rows}
                emptyMessage={emptyMessage}
                scrollable
                loading={error ? false : loader}
                scrollHeight="50vh"
                ref={dt}
                className="cdr-datatable"
                exportFilename={intl.formatMessage({id: "calls.cdr_table_export"})}
                sortMode="multiple"
                footer={<Paginator/>}
                removableSort
                dynami
                multiSortMeta={multiSort}
                onSort={handleSorting}
                // style={{minHeight: "50vh"}}
            >
                {dynamicColumns}
            </DataTable>
            <small className="text-muted"><FormattedMessage id="values.tables"/></small>
            <button type="button" className="btn btn-block btn-outline-success d-block d-lg-none mt-3" onClick={handleExport}>
                <FormattedMessage id="exportcsv"/>
            </button>
        </>
    )
}

const useEffectNotInit = (fn, inputs) => {
    const didMountRef = useRef(false)
  
    useEffect(() => {
        didMountRef.current ? fn() : didMountRef.current = true
        // eslint-disable-next-line
    }, inputs)
}