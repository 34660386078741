import React, { useState, useRef, useContext, useEffect } from 'react'
import Titulo from './tools/Titulo'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import { FormattedMessage, useIntl } from 'react-intl'
import secureStorage from '../../tools/SecureStorage'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown } from 'primereact/dropdown'
import moment from 'moment-timezone'
import FilterInput from './tools/FilterInput'
import ButtonLoader from './tools/ButtonLoader'
import CryptoTools from '../../tools/crypto_tools'
import { AuthContext } from '../AuthContext'
import ReactGA from 'react-ga'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que muestra la tabla de los rates del usuario
   -----------------------------------------------------------------------------------------------
*/
export default function Rates(){
    const intl = useIntl()
    const [rows, setRows] = useState([])
    const dt = useRef()
    const [error, setError] = useState(false)
    const emptyMessage = <h3 className="dashboard-main-title text-muted py-4"><FormattedMessage id="records.week.error"/></h3>;
    const emptyMessageInit = <h3 className="dashboard-main-title text-muted py-4"><FormattedMessage id="searchInv"/></h3>;
    const [pagination, setPagination] = useState({})
    const [loader, setLoader] = useState(false)
    const [prefix, setPrefix] = useState({"value": "", "current_filter": "iexact"})
    const [destination, setDestination] = useState({"value": "", "current_filter": "icontains"})
    const [tariffRate, setTariffRate] = useState({"value": "", "current_filter": "iexact"})
    const [tariffId, setTariffId] = useState("")
    const [multiSort, setMultiSort] = useState([])
    const columns = [
        {name: intl.formatMessage({id: "rates.prefix"}), field: "prefix", width: '113px', filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id: "rates.destination"}), field: "destino", width: '325px', filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id: "rates.tariff_rate"}), field: "rate", width: '140px', filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id: "rates.con_fee"}), field: "connection_fee", width: '200px', filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id: "rates.increment"}), field: "increment_s", width: '150px', filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id: "rates.min_time"}), field: "min_time", width: '200px', filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id: "rates.start_time"}), field: "start_time", width: '190px', filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id: "rates.end_time"}), field: "end_time", width: '190px', filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
    ]

    const data = useContext(AuthContext)
    const crypto = new CryptoTools()
    let tarifas, tarifas_ordenadas, uniq, opciones;
    if(crypto.desencriptar(data.user_type) !== "Admin"){
        tarifas = JSON.parse(crypto.desencriptar(data.m2.tarifas_disponibles).replaceAll("'", '"'))
        tarifas_ordenadas = JSON.parse(
            crypto.desencriptar(data.m2.dispositivos_disponibles)
        ).map(
            (el) => ({label: tarifas[el[0]].tariff_name, value: tarifas[el[0]].tariff_id})
        )
        uniq = new Set(tarifas_ordenadas.map(e => JSON.stringify(e)));
        opciones = Array.from(uniq).map(e => JSON.parse(e));    
    } else {
        opciones = JSON.parse(crypto.desencriptar(data.m2.tarifas_disponibles)).map((el) => ({label: el.name, value: el.id}))
    }

    useEffect(() => {ReactGA.pageview(window.location.pathname);}, [])
    
    // Recorremos cada columna y creamos un componente con la data proporcionada por columns
    let dynamicColumns = columns.map((col) => {
        return( 
            <Column 
                key={"cdr-table-column" + col.field} 
                field={col.field} 
                header={col.name} 
                sortable={true} 
                style={{width: col.width}}
                showClear
                body={col.template || null}
            /> 
        )
    })

    const parseFilters = () => {
        let url = ""

        if (tariffId !== "" && opciones.map((el) => el.value).includes(tariffId)){
            url += `&tariff_id=${tariffId}`
        }

        if (prefix.value !== ""){
            let field = "&prefix"
            if (prefix.current_filter !== "") {
                field += "__" + prefix.current_filter
            }
            field += "=" + encodeURIComponent(prefix.value.trim())
            url += field
        }

        if (destination.value !== ""){
            let field = "&destino"
            if (destination.current_filter !== "") {
                field += "__" + destination.current_filter
            }
            field += "=" + encodeURIComponent(destination.value.trim())
            url += field
        }

        if (tariffRate.value !== ""){
            let field = "&rate"
            if (tariffRate.current_filter !== "") {
                field += "__" + tariffRate.current_filter
            }
            field += "=" + encodeURIComponent(tariffRate.value.trim())
            url += field
        }

        if (multiSort.length > 0) {
            url += "&ordering=" + multiSort.map(e => `${e["order"] > 0 ? "" : "-"}${e["field"]}`).toString()
        }

        return url
    }

    const handleSorting = (e) => {
        if(e.multiSortMeta.length > 0){
            const index = multiSort.findIndex(el => el.field === e.multiSortMeta[0].field)
            if(index >= 0){
                setMultiSort(multiSort.map((el, i) => i === index ? e.multiSortMeta[0] : el))
            }else{
                setMultiSort([...multiSort, e.multiSortMeta[0]])
            }
        }else{
            if (multiSort.length === 0) {
                setMultiSort([])
            }
            dt.current.resetColumnOrder()
        }

        
    }

    useEffectNotInit(() => {queriePage(`/tariff/rates/list/?${parseFilters()}`)}, [multiSort])

    const handleReset = () => {
        setPrefix({"value": "", "current_filter": ""})
        setDestination({"value": "", "current_filter": ""})
        setTariffRate({"value": "", "current_filter": ""})
        setMultiSort([])
        dt.current.resetColumnOrder()
        queriePage( `/tariff/rates/list/?tariff_id=${opciones.map((el) => el.value).includes(tariffId) ? tariffId : ""}`)
    }

    const queriePage = (url) => {
        // Hace una peticion HTTP GET con los parametros de filtrado actuales o del numero de pagina
        // Parametro 'url': str
        setLoader(true) // Mostramos el Loader
        setError(false) // Indicamos que no ha habido un error en esta consulta
        // setRows([]) // Eliminamos los rows de la tabla anterior
        axios.get(url,
            {
                headers: {
                    Authorization: `Bearer ${secureStorage.getItem("access")}`
                }
            }
        ).then(res => {
            if("detail" in res.data){ // Si ese campo existe quiere decir que hubo un error, generalmente la pagina no existe
                setRows([])
                setError(true)
                setLoader(false)
            }else{
                if(res.data.results.length === 0){ // Si no hubo resultados decimos que no hay nada
                    setRows([])
                    setError(true)
                    setPagination({})
                    setLoader(false)
                }else{
                    const info = {...res.data, results: []}
                    setPagination(info)
                    
                    const results = res.data.results.map(record => {
                        record["prefix"] = parseInt(record["prefix"])
                        record["rate"] = `$ ${parseFloat(record["rate"]).toFixed(5)}`
                        record["connection_fee"] = `$ ${parseFloat(record["connection_fee"]).toFixed(5)}`
                        return record
                    })

                    setRows(results)
                    setLoader(false)
                }
            }
        }
        ).catch(err => {
            console.error(err)
            setError(true)
            setLoader(false)
        })
        // $(window).scrollTop(0) // Luego de hacer la peticion colocamos al usuario al top de la pagina
    }

    // Handler al hacer click en un boton para cambiar de pagina
    const handlePagination = (button) => {
        // Parametro 'button': ('previous' | 'next') -> indica si vamos para adelante o atras
        if(button in pagination.links){
            queriePage(pagination.links[button].slice(25)) // EN DESARROLLO EL SLICE ES DE 21 MIENTRAS QUE EN PRODUCCION SON 25
        }else{ // Por si el usuario se las ingenia para ir a un link que no existe
            console.error("Error trying to paginate to unknow page")
        }
    }

    // Handler al hacer click en un boton para cambiar de pagina a la ultima o primera pagina
    const handlePaginationLimits = (limit) => {
        // Parametro 'limit': ('end' | 'start') -> es el limite
        const path = `/tariff/rates/list/?${parseFilters()}&page=`
        if(limit === "end"){
            // Aqui calculamos cual numero es la ultima pagina
            queriePage(path + Math.ceil(pagination.total/pagination.page_size))
        }else{
            // 1 siempre sera la primera pagina
            queriePage(path + 1)
        }
    }
    
    // Sub-Componente que se encarga muestra la paginacion de la tabla
    const Paginator = () => {
        if("links" in pagination){
            return(
                <>
                    <div className="d-flex justify-content-center align-items-center">
                        <button type="button" onClick={() => handlePaginationLimits("first")} className="btn btn-sm btn-custom-paginator" disabled={!pagination.links.previous || loader}>
                            <FontAwesomeIcon icon="fast-backward"/>
                        </button>
                        <button type="button" onClick={() => handlePagination("previous")} className="btn btn-sm btn-custom-paginator" disabled={!pagination.links.previous || loader}>
                            <FontAwesomeIcon icon="step-backward"/>
                        </button>
                        <small className="px-1 mb-0">
                            <FormattedMessage id="page"/> {pagination.page} <FormattedMessage id="of"/> {Math.ceil(pagination.total/pagination.page_size)}
                        </small>
                        <button type="button" onClick={() => handlePagination("next")} className="btn btn-sm btn-custom-paginator" disabled={!pagination.links.next || loader}>
                            <FontAwesomeIcon icon="step-forward"/>
                        </button>
                        <button type="button" onClick={() => handlePaginationLimits("end")} className="btn btn-sm btn-custom-paginator" disabled={!pagination.links.next || loader}>
                            <FontAwesomeIcon icon="fast-forward"/>
                        </button>
                    </div>
                    <div className="text-center">
                        <small className="font-weight-light">
                            {rows.length} <FormattedMessage id="elements"/> <FormattedMessage id="of"/> {pagination.total}
                        </small>
                    </div>
                </>
            )
        }
        return null
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        queriePage(`/tariff/rates/list/?${parseFilters()}`)
    }

    const handleExport = (e) => {
        e.preventDefault()
        window.open(`http://billing.ariatel.us/rates/csv/?${parseFilters()}`, "_self")
    }

    return(
        <>
            <Titulo className="d-flex justify-content-center justify-content-lg-between">
                <FormattedMessage id="header.menu.rates"/>
                <button type="button" disabled={(tariffId === "")} className="btn btn-outline-success d-none d-lg-block" onClick={handleExport}>
                    <FormattedMessage id="exportcsv"/>
                </button>
            </Titulo>

            <form className="mb-3" onSubmit={handleSubmit}>
                <div className="form-row align-items-end justify-content-center justyf-content-xl-start">
                    <div className="form-group mb-2 col-12 col-lg-2">
                        <label htmlFor="tariffIds-search-rates" className="p-d-block">
                            <FormattedMessage id="rates.tariffID.type"/>:
                        </label>
                        <Dropdown value={tariffId} inputId="tariffIds-search-rates"
                            onChange={e => setTariffId(e.value)}
                            filter filterBy="label"
                            options={opciones} optionValue="value" optionLabel="label" 
                            className="w-100 p-inputtext-sm cdr-subfilter-input" disabled={loader}
                            panelStyle={{maxWidth: "300px", fontSize: "13px"}}
                        />
                    </div>
                    {/* <div className="w-100 d-none d-lg-block"></div> */}
                    <FilterInput disabled={tariffId === "" || tariffId === null} state={prefix} label={<FormattedMessage id="calls.prefix"/>} className="col-6 col-sm-2 col-md-2" filters={["icontains", "istartswith", "iendswith"]} setState={setPrefix}/>
                    <FilterInput disabled={tariffId === "" || tariffId === null} state={tariffRate} label={<FormattedMessage id="rates.tariff_rate"/>} className="col-6 col-sm-3 col-md-2"  filters={["gt","gte","lt","lte"]} setState={setTariffRate} keyfilter="pint"/>
                    <FilterInput disabled={tariffId === "" || tariffId === null} state={destination} label={<FormattedMessage id="calls.dst"/>} className="col-sm-4 col-md-3" filters={["icontains", "istartswith", "iendswith"]} setState={setDestination}/>
                    <div className="col-6 col-sm-3 mb-2 d-flex mt-2">
                        <button className="btn btn-outline-info btn-sm" disabled={loader} onClick={handleReset} type="reset"><FontAwesomeIcon icon="eraser"/></button>
                        <ButtonLoader 
                            loader={loader} className="button-tooltip-loader w-100 ml-2 btn btn-ariatel--primary btn-sm" 
                            type="submit"
                            onClick={handleSubmit}
                        >
                            <FormattedMessage id="calls.search" />
                        </ButtonLoader> 
                    </div>
                </div>
            </form>
            <small className="text-muted"><FormattedMessage id="dates.info.1"/> {Intl.DateTimeFormat().resolvedOptions().timeZone} <FormattedMessage id="calls.timezone"/> (GMT{moment().utcOffset()/60})</small>

            <DataTable value={rows}
                emptyMessage={error ? emptyMessage : emptyMessageInit}
                scrollable
                loading={error ? false : loader}
                scrollHeight="50vh"
                ref={dt}
                className="cdr-datatable"
                exportFilename={intl.formatMessage({id: "calls.cdr_table_export"})}
                sortMode="multiple"
                footer={<Paginator/>}
                removableSort
                multiSortMeta={multiSort}
                onSort={handleSorting}
            >
                {dynamicColumns}
            </DataTable>
            <small className="text-muted"><FormattedMessage id="values.tables"/></small>

            <button type="button" disabled={(tariffId === "")} className="btn btn-block btn-outline-success d-block d-lg-none mt-3" onClick={handleExport}>
                <FormattedMessage id="exportcsv"/>
            </button> 
        </>
    )
}

const useEffectNotInit = (fn, inputs) => {
    const didMountRef = useRef(false)
  
    useEffect(() => {
        didMountRef.current ? fn() : didMountRef.current = true
        // eslint-disable-next-line
    }, inputs)
}
