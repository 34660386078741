import React, { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import Titulo from '../compartidos/tools/Titulo'
import {FormattedMessage, useIntl} from 'react-intl'
import ButtonLoader from '../compartidos/tools/ButtonLoader'
import secureStorage from '../../tools/SecureStorage'
import UsersInput from './UsersInput'
import { InputNumber } from 'primereact/inputnumber'
import {Checkbox} from 'primereact/checkbox'
import { Radio } from 'antd'


export default function UserRanges() {
    const intl = useIntl()
    const [user, setUser] = useState("")
    const [currency, setCurrency] = useState(null)
    const [uid, setUid] = useState(null)
    const [loader, setLoader] = useState(false)
    const [loaderRg, setLoaderRg] = useState(false)
    const [error, setError] = useState(false)
    const [error1, setError1] = useState(false)
    const [error2, setError2] = useState(false)
    const [success, setSuccess] = useState(false)
    const [rangesAvailable, setRangesAvailable] = useState([])
    const [selectedRange, setSelectedRange] = useState(null)
    const [multiplo, setMultiplo] = useState(null)
    const [auto, setAuto] = useState(true)

    const get_nearest = val => {
        let numero = Array.from(parseInt(val).toString(), x => parseInt(x));
        if (numero.slice(1).some(v => v > 0)){
            numero = numero.fill(0, 1);
            numero[0] += 1;
            return parseFloat(numero.join(""));
        } else if (numero.length > 1) {
            return parseFloat(numero.join(""));
        } else {
            return 10;
        }
    }

    useEffect(() => {
        axios.post('/system/payment-ranges/list/', {},
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`
                }
            }
        ).then(res => {
            setRangesAvailable(res.data.system_ranges)
        }).catch(error => {
            setError(true)
        })
    }, [])

    const getUrl = () => {
        let url = "/user/payment/range/?u="
        url += user.trim()
        return url
    }

    const _getUserDetails = (e) => {
        e.preventDefault()
        setLoader(true)
        setError(false)
        setSuccess(false)
        setSelectedRange(null)

        // eslint-disable-next-line
        if(user !== ""){
            axios.get(getUrl(),
                {
                    headers: {
                        "Authorization": `Bearer ${secureStorage.getItem("access")}`
                    }
                }
            ).then(res => {
                setCurrency(res.data.currency)
                setUid(res.data.user_id)
                setSelectedRange(res.data.system_range_id)
                setMultiplo(res.data.multiple)
                setAuto(res.data.auto)
                setLoader(false)
            }).catch(error => {
                setLoader(false)
                setError(true)
            })
        }else{
            setTimeout(() => {
                setLoader(false)
                setError(true)
            }, 1000)
        }

    }

    const _changeRange = (e) => {
        e.preventDefault()
        setLoaderRg(true)
        setError1(false)
        setError2(false)
        setSuccess(false)

        const sendNewRange = datos => {

            axios.post('/user/payment/range/', datos,
                {
                    headers: {
                        "Authorization": `Bearer ${secureStorage.getItem("access")}`
                    }
                }
            ).then(res => {
                setLoaderRg(false)
                setSuccess(true)
            }).catch(error => {
                setLoaderRg(false)
                setError1(true)
            })
        }

        if(auto){
            sendNewRange({
                "is_default": true,
                "id": uid,
                "system_range_id": selectedRange
            })
        }else{
            if (uid !== null) {
                sendNewRange({
                    "is_default": false,
                    "system_range_id": selectedRange,
                    "multiple": multiplo,
                    "id": uid
                })
            } else {
                setLoaderRg(false)
                setError2(true)
            }
        }
    }

    return(
        <>
            <Titulo>
                <FormattedMessage id="title.range"></FormattedMessage>
            </Titulo>
            
            <form method="GET" className="mb-3" onSubmit={(e) => _getUserDetails(e)}>
                <div className="row align-items-end justify-content-center">
                    <div className="col-7 col-sm-8 col-md-4">
                        <label htmlFor="u-details-auto" className="p-d-block">
                            <FormattedMessage id="details.placeholder.username"/>:
                        </label>
                        <UsersInput value={user} onChange={e => setUser(e.value)}/>
                    </div>

                    <div className="col-5 col-sm-4 col-md-2">
                        <ButtonLoader
                            loader={loader} className="button-tooltip-loader btn btn-block btn-ariatel--primary btn-sm" 
                            type="submit" style={{minHeight: '35px'}} showError={error}
                            onClick={(e) => _getUserDetails(e)} tooltipPlace="top"
                            errorTitle={intl.formatMessage({id:"devices.error.search"})}
                        >
                            <FormattedMessage id="calls.search" />
                        </ButtonLoader> 
                    </div>
                </div>
            </form>

            {currency !== null && uid !== null ? 
                <section className="row justify-content-center mb-3">
                    <div className="col-12 col-md-8 col-xl-6">
                        <div className="card shadow-sm user-details-card-tabs">
                            <div className="card-body">

                                <form method="POST" className="mb-3" onSubmit={(e) => _changeRange(e)}>
                                <div className="text-center mb-4">
                                    <label htmlFor="range" className="p-d-block d-block">
                                        Rangos (USD):
                                    </label>
                                    <Radio.Group buttonStyle="solid" onChange={(e) => setSelectedRange(e.target.value)} value={selectedRange} defaultValue={selectedRange}>
                                        {rangesAvailable.map((el, i) => {
                                            return (
                                                <Radio.Button value={el.id} key={"range-" + i} name="range" checked={selectedRange === el.id}>{el.min}$ - {el.max}$</Radio.Button>
                                                )
                                            })}
                                    </Radio.Group>
                                </div>

                                    
                                    <div className="row align-items-end justify-content-center">
                                        <div className="col-4 mb-3">
                                            <label htmlFor="u-details-auto" className="p-d-block">
                                                Multiplo del rango:
                                            </label>
                                            <InputNumber disabled={auto} className="d-block w-100" inputClassName="w-100 p-inputtext-sm" value={multiplo} mode="currency" currency={currency ? currency : "USD"} currencyDisplay="code" min={0} onValueChange={(v) => setMultiplo(v.value)}/>
                                        </div>
                                        <div className="col mb-3">
                                            <ButtonLoader
                                                loader={loaderRg} className="button-tooltip-loader btn btn-block btn-success btn-sm" 
                                                type="submit" style={{minHeight: '35px'}} 
                                                onClick={(e) => _changeRange(e)}
                                            >
                                                <FormattedMessage id="change.range.button"></FormattedMessage>
                                            </ButtonLoader> 
                                        </div>

                                        <div className="col-12 text-left">
                                            <Checkbox style={{marginTop: "-1px"}} onChange={(e) => setAuto(e.checked)} checked={!!auto}></Checkbox>
                                            <small htmlFor="default" className="p-checkbox-label mb-0 ml-2">Multiplo del rango automatico (Igual a la tasa cambiara de la moneda del usuario)</small>
                                        </div>
                                    </div>
                                </form>

                                <hr/>

                                <div className="text-center text-info">
                                    { selectedRange !== null ? 
                                        `Rango final (${currency}) del usuario desde ${
                                            Intl.NumberFormat().format(get_nearest(multiplo * rangesAvailable.filter(x => x["id"] === selectedRange)[0].min))
                                        } ${currency === "EUR" ? "€" : "$"} hasta ${
                                            Intl.NumberFormat().format(
                                                get_nearest(multiplo * rangesAvailable.filter(x => x["id"] === selectedRange)[0].min) * (rangesAvailable.filter(x => x["id"] === selectedRange)[0].max / rangesAvailable.filter(x => x["id"] === selectedRange)[0].min)
                                            )
                                        } ${currency === "EUR" ? "€" : "$"}`  
                                    : null }
                                </div>

                                <div className="text-center">
                                    {error1 ? <small className="text-danger"><FormattedMessage id ="forms.signUp.error"/> </small> : null}
                                    {error2 ? <small className="text-danger"><FormattedMessage id ="forms.invalid"/> </small> : null}
                                    {success ? <small className="text-success"><FormattedMessage id ="success.range"/> </small> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            : null}

        </>
    )
}