import CryptoJS from 'crypto-js'
import cryptoRandomString from 'crypto-random-string'

const k = cryptoRandomString({length: 30, type: 'ascii-printable'}) // Si da error conservar string fija

export default class CryptoTools{

    encriptar(text){
        return (CryptoJS.AES.encrypt(text, k).toString());
    }

    desencriptar(text){
        const bytes  = CryptoJS.AES.decrypt(text, k);
        return bytes.toString(CryptoJS.enc.Utf8);
    }

}