import React from "react"
import { IntlContext } from "./IntlProviderWrapper"
/* 
   -----------------------------------------------------------------------------------------------
    Componente de botones que cambian el idioma       
   -----------------------------------------------------------------------------------------------
*/
const LanguageSwitch = (props) => {
  // Traemos las funciones para cambiar de idioma y "locale" para que el boton indique
  // cual idioma está activo
  const { switchToEnglish, switchToSpanish, locale } = React.useContext(IntlContext)
  return (
    // Permite customizacion en diferentes partes de la interfaz
    <div className={"text-center d-block " + props.margins}>
      {/* Ingles */}
      <button 
        style={locale === "en" ? {opacity: '.5', cursor: 'no-drop'} : {cursor: 'click'}}
        className="language-button language-button--en" 
        onClick={switchToEnglish}></button>
      {/* Español */}
      <button 
        style={locale === "es" ? {opacity: '.5', cursor: 'no-drop'} : {cursor: 'click'}}
      className="language-button language-button--es" onClick={switchToSpanish}></button>
    </div>
  )
}

export default LanguageSwitch