import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import Titulo from '../../../tools/Titulo'
import $ from 'jquery'
import secureStorage from '../../../../../tools/SecureStorage'
import { AuthContext } from '../../../../AuthContext'
import ButtonLoader from '../../../tools/ButtonLoader'
import { InputText } from 'primereact/inputtext'
import { FormattedMessage, useIntl } from 'react-intl'
import { Dropdown } from 'primereact/dropdown'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReactGA from 'react-ga'
import CurrencyInput from 'react-currency-input-field'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que permite que un usuario reporte su pago con un recibo de tipo imagen o pdf
   -----------------------------------------------------------------------------------------------
*/
export default function PSE(){
    const data = useContext(AuthContext)
    const intl = useIntl()
    const [banks, setBanks] = useState([])
    const [amount, setAmount] = useState('')
    const [bank, setBank] = useState("")
    const [doctype, setDoctype] = useState("CC")
    const [docNumber, setDocNumber] = useState("")
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [loader, setLoader] = useState(false)
    const [userType, setUserType] = useState(0)
    const [phone, setPhone] = useState("")
    const [errorGeneral, setErrorGeneral] = useState("")
    const [LoaderBank, setLoaderBank] = useState(true)
    const errorLimit = useRef(0)
    const [errors, setErrors] = useState({
        "docNumber": "",
        "email": "",
        "name": "",
        "phone": "",
        "userType": "",
        "bank": "",
        "amount": ""
    })
    const form = useRef(null)

    const symbol_currency = {
        "COP": "$",
        "USD": "$",
        "EUR": "€"
    }

    const handleChangeAmount = value => {
        if (value > parseFloat(data.m2.payment_range.max)) {
            setAmount(parseFloat(data.m2.payment_range.max))
        } else {
            setAmount(value)
        }
    }

    const errores_generales = {
        "forms.passReset.error": intl.formatMessage({id: "forms.passReset.error"}),
        "forms.invalid": intl.formatMessage({id: "forms.invalid"}),
    }

    const docs_availables = [
        {"label": "CC", "value": "CC"},
        // {"label": "CE", "value": "CE"},
        // {"label": intl.formatMessage({id: "pse.pasaporte"}), "value": "PA"},
        // {"label": "TI", "value": "TI"},
        {"label": "NIT", "value": "NIT"}
    ]

    const tipo_de_persona = [
        {"label": "Natural", "value": 0},
        {"label": "Jurídica", "value": 1}
    ]

    const is_valid = () => {
        let valid = true
        let errors_found = {}

        if(name === ""){ 
            errors_found["name"] =  intl.formatMessage({id: "forms.field.empty"});
            valid = false;
        }

        if(amount === "" || isNaN(parseFloat(amount.replace(",", "."))) || parseFloat(amount.replace(",", ".")) < 0 || amount === null || parseFloat(amount.replace(",", ".")) < parseFloat(data.m2.payment_range.min) || parseFloat(amount.replace(",", ".")) > parseFloat(data.m2.payment_range.max)){
            errors_found["amount"] = intl.formatMessage({id: "add.payments.error1"});
            valid = false;
        }

        if(docNumber === "" || isNaN(docNumber)){ 
            errors_found["docNumber"] = intl.formatMessage({id: "add.payments.error1"});
            valid = false;
        }

        if(email === ""){ 
            errors_found["email"] = intl.formatMessage({id: "forms.field.empty"});
            valid = false;
        }

        if(phone === "" || isNaN(phone) || phone.length < 5){ 
            errors_found["phone"] = intl.formatMessage({id: "add.payments.error1"});
            valid = false;
        }

        if(userType === "" || userType === null){ 
            errors_found["userType"] = intl.formatMessage({id: "forms.field.empty"});
            valid = false;
        }

        if(bank === "" || bank === null){ 
            errors_found["bank"] = intl.formatMessage({id: "forms.field.empty"});
            valid = false;
        }

        setErrors(errors_found)

        return valid
    }

    const handleOnBlur = (name) => {
        setErrors({...errors, [name]: ""})
    }
    
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);

        axios.get('/wompi/get-public-key/', 
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`,
                    "Content-Type": "application/json"
                }
            }
        ).then(resp => {

            axios.get("https://production.wompi.co/v1/pse/financial_institutions",
                {
                    headers: {
                        "Authorization": `Bearer ${resp.data["public_key"]}`,
                        "Content-Type": "application/json"
                    }
                }
            ).then(res => {
                setBanks(res.data.data)
                setLoaderBank(false)
            }).catch(error => setErrorGeneral(intl.formatMessage({id: "pse.banks.load.error"})))

        }).catch(error => setErrorGeneral(intl.formatMessage({id: "pse.banks.load.error"})));

        // eslint-disable-next-line
    }, [])

    const resetForm = () => {
        setAmount('')
        setBank("")
        setDoctype("CC")
        setDocNumber("")
        setEmail("")
        setName("")
        setUserType(0)
        setPhone("")
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setErrorGeneral("")

        if(is_valid()){
            setLoader(true)
            
            axios.post("/wompi/pse/payment/", 
            {
                "user_type": 0,
                "user_legal_id_type": doctype,
                "user_legal_id": docNumber,
                "financial_institution_code": bank,
                "name": name,
                "amount": parseFloat(amount.replace(",", ".")),
                "email": email,
                "phone": phone
            },
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`,
                }
            }
            ).then(res => {
                longPollingRedirect(res.data.transaction.data.reference)
            }).catch(error => {
                setLoader(false)
                setErrorGeneral(errores_generales[error.response.data.error])
            })
        }
    }

    const longPollingRedirect = (reference) => {
        axios.post('/wompi/get-transaction/', 
            {
                "reference": reference
            },
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`,
                }
            }
        ).then(res => {
            const data = res.data.transaction.data[0]
            if("extra" in data.payment_method){
                if ("async_payment_url" in data.payment_method.extra) {
                    var win = window.open(data.payment_method.extra.async_payment_url, '_self')
                    win.focus()
                    resetForm()
                    setLoader(false)
                    $("#pse-success-redirection").modal("show")
                } else {
                    setTimeout(() => longPollingRedirect(reference), 500)
                }
            } else {
                setTimeout(() => longPollingRedirect(reference), 500)
            }
        }).catch((error) => {
            const helper = (value) => value + 1;
            errorLimit.current = helper(errorLimit.current);
            if (errorLimit.current < 5) {
                setTimeout(() => longPollingRedirect(reference), 1000)
            } else {
                console.log(error)
                errorLimit.current = helper(-1)
                setErrorGeneral(errores_generales["forms.invalid"])
                setLoader(false)
            }
        })
    }

    return(
        <>
            <Titulo className="d-flex justify-content-center justify-content-lg-between align-items-end">
                <FormattedMessage id="pse.title"/>
                {data.m2.default_curr === data.m2.currency ?
                    null
                :
                    <small className="font-italic d-none d-lg-block" style={{fontSize: "1rem"}}><FormattedMessage id="exchange.msg"/> {data.m2.default_curr}: <b>{Intl.NumberFormat().format(data.m2.exchange_rate)} {data.m2.currency}</b></small>
                }
            </Titulo> 

            <form ref={form} onSubmit={handleSubmit} method="POST" id="form-epayco-pay" style={{maxWidth: "600px"}} className="m-auto bg-white rounded shadow border p-4">
                <div className="form-row">
                    <div className="form-group col-12 col-sm-12">
                        <label htmlFor="first_name">
                            <FormattedMessage id="devices.name"/>:
                        </label>
                        <InputText onBlur={() => handleOnBlur("name")} inputMode="text" className="w-100" type="text" value={name} onChange={e => setName(e.target.value)} name="first_name" id="first_name"/>
                        <small className="text-danger">
                            {errors["name"] === "" ? "" : errors["name"]}
                        </small>
                    </div>
                </div>

                <div className="form-row">
                    <div className="col-12 mb-3">
                        <div className="form-row">
                            <label htmlFor="doc_number" className="col-12">
                                <FormattedMessage id="pse.doc"/>:
                            </label>
                            <div className="form-group ml-1 mb-0">
                                <Dropdown options={docs_availables} value={doctype} onChange={e => setDoctype(e.value)} name="doc_type" id="doc_type"/>
                            </div>
                            <div className="form-group col mb-0">
                                <InputText onBlur={() => handleOnBlur("docNumber")} keyfilter="pint" className="w-100" inputMode="numeric" type="text" value={docNumber} onChange={e => setDocNumber(e.target.value)} name="doc_number" id="doc_number"/>
                            </div>
                        </div>
                        <small className="text-danger">
                            {errors["docNumber"] === "" ? "" : errors["docNumber"]}
                        </small>
                    </div>
                </div>
                
                <div className="form-row">
                    <div className="col-12 col-sm-6 mb-3">
                        <label htmlFor="phone">
                            <FormattedMessage id="profile.registration.reg_phone"/>: 
                        </label>
                        <PhoneInput onBlur={() => handleOnBlur("phone")} placeholder="" country={data.m2.currency === "COP" ? "co" : "us"} name="phone" id="phone" inputClass="w-100 input-number-payments" containerClass="w-100" value={phone} onChange={e => setPhone(e)}/>
                        <small className="text-danger">
                            {errors["phone"] === "" ? "" : errors["phone"]}
                        </small>
                    </div>

                    <div className="col-12 col-sm-6 mb-3">
                        <label htmlFor="userType">
                            <FormattedMessage id="pse.person"/>:
                        </label>
                        <Dropdown onBlur={() => handleOnBlur("userType")} placeholder={intl.formatMessage({id: "bank.choose"})} className="p-inputtext-sm w-100" value={userType} onChange={e => setUserType(e.value)} options={tipo_de_persona} name="userType" id="userType"/>
                        <small className="text-danger">
                            {errors["userType"] === "" ? "" : errors["userType"]}
                        </small>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-12 col-sm-6">
                        <label htmlFor="email">
                            <FormattedMessage id="email"/>:
                        </label>
                        <InputText onBlur={() => handleOnBlur("email")} keyfilter="email" inputMode="email" type="email" className="w-100" value={email} onChange={e => setEmail(e.target.value)} name="email" id="email"/>
                        <small className="text-danger">
                            {errors["email"] === "" ? "" : errors["email"]}
                        </small>
                    </div>
                    <div className="form-group col-12 col-sm-6">
                        <label htmlFor="bank">
                            <div className={LoaderBank ? "mr-2 spinner-border-sm spinner-border text-primary" : "d-none"} role="status">
                                <span className="sr-only">
                                    <FormattedMessage id="privado"/>
                                </span>
                            </div>
                            <FormattedMessage id="bank"/>:
                        </label>
                        <Dropdown onBlur={() => handleOnBlur("bank")} disabled={LoaderBank} placeholder={intl.formatMessage({id: "bank.choose"})} optionLabel="financial_institution_name" optionValue="financial_institution_code" className="w-100" value={bank} onChange={e => setBank(e.value)} options={banks} name="bank" id="bank"/>
                        <small className="text-danger">
                            {errors["bank"] === "" ? "" : errors["bank"]}
                        </small>
                    </div>
                </div>
                
                <div className="text-center">
                    <label className="p-d-block my-2">
                        <FormattedMessage id="cash.choose"/> ({data.m2.currency}):
                    </label>
                    <CurrencyInput onBlur={() => handleOnBlur("amount")} intlConfig={{'currency': data.m2.currency}} prefix={symbol_currency[data.m2.currency]} groupSeparator="." decimalSeparator="," allowNegativeValue={false} step={data.m2.currency === "COP" ? 10000 : 10} id="amount" name="amount" value={amount} className="w-100 p-inputtext" placeholder={intl.formatMessage({id: "enter.amount"})} decimalsLimit={2} onValueChange={(val, name) => handleChangeAmount(val)}/>
                    <small className={!errors["amount"] ? "text-muted" : "text-danger"}><FormattedMessage id="monto.warning.range"></FormattedMessage> {symbol_currency[data.m2.currency]}{Intl.NumberFormat().format(data.m2.payment_range.min)}  - {symbol_currency[data.m2.currency]}{Intl.NumberFormat().format(data.m2.payment_range.max)}</small>
                </div>


                <div className="text-center mt-2">
                    <ButtonLoader 
                        loader={loader} 
                        className="button-tooltip-loader btn btn-ariatel--primary" 
                        type="submit"
                    >
                        <FormattedMessage id="pse.boton"/>
                    </ButtonLoader>
                </div>
                <div className="text-center">
                    <small className="text-danger">
                        {errorGeneral}
                    </small>
                </div>
                {data.m2.default_curr === data.m2.currency ?
                    null
                :
                    <small className="text-center text-muted pt-2 border-top mt-3 font-italic d-block d-lg-none" style={{fontSize: ".8rem"}}><FormattedMessage id="exchange.msg"/> {data.m2.default_curr}: <b>{Intl.NumberFormat().format(data.m2.exchange_rate)} {data.m2.currency}</b></small>
                }
            </form>

            <div data-backdrop="static" keyboard="false" className="modal fade" id="pse-success-redirection" tabIndex="-1" role="dialog" aria-labelledby="pse-success-redirection-label" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title text-ariatel--primary text-center font-weight-bold" id="pse-success-redirection-label">
                                <FormattedMessage id="pse.redirection.title"/>
                            </h5>
                        </div>
                        <div className="modal-body text-ariatel--default text-center font-weight-bold">
                            <FormattedMessage id="pse.redirection.body"/>
                        </div>
                    </div>
                </div>
            </div>
                
        </>
    )
}