import React, { useEffect } from 'react'
import LanguageSwitch from "../../translation/LanguageSwitch"
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import Header from '../formulario/Header'
import ReactGA from 'react-ga'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que aparece cuando un usuario no logueado intenta ir a una pagina que no existe
   -----------------------------------------------------------------------------------------------
*/
export default function Surroundings404(){
    useEffect(() => {ReactGA.pageview(window.location.pathname);}, [])
    return(
        <div>
            <Header/>
            <main className="text-center pt-5 px-2"> 
                <div className="card d-inline-block">
                    <div className="card-body">
                        <h3 className="h4 text-muted">
                            <FormattedMessage id="404.title"/>
                        </h3>
                        <hr/>
                        <p className="mb-3">
                            <FormattedMessage id="404.text"/>
                        </p>
                        <Link to="/sign-in/" className="btn btn-ariatel--primary">
                            <FormattedMessage id="forms.passReset.loginRedirect"/>
                        </Link>
                    </div>
                </div>                
            </main>
            <LanguageSwitch margins="my-3" />
        </div>
    )
} 
