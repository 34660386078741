import React, { useEffect, useState } from 'react'
import axios from 'axios'
import secureStorage from '../tools/SecureStorage'
import { AuthContext } from './AuthContext'
import { useHistory } from 'react-router'
import CryptoTools from '../tools/crypto_tools'

// variable global que detendrá la ejecucion en loop del Refresh el JWT
var stop = 0

/* 
   -----------------------------------------------------------------------------------------------
    Actualizamos el Contexto para añadir la informacion del usuario
   -----------------------------------------------------------------------------------------------
*/
export default function DataLobby(props){
    const [data, setData] = useState({})
    const [timerID, setTimerID] = useState(null)
    const refreshT = secureStorage.getItem("refresh")
    const [accessT, setAccessT] = useState(secureStorage.getItem("refresh"))
    const history = useHistory()
    const crypto = new CryptoTools()

    // Funcion que hace una peticion para recibir la informacion del usuario
    // setea el state Data el cual enviamos como informacion del contexto
    const ask_for_user_info = (ct) => {
        axios.post('/user/data/',{},
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`,
                    "Content-Type": "application/json"
                },
                cancelToken: ct.token
            }
        ).then(res => {
            const d = res.data
            let user_type;
            if(!("m2" in d)){
                user_type = "NM2"
            }else{
                switch (d.m2.user_type[0]){
                    case "Admin":
                    case "Manager":
                        user_type = "Admin"
                        break;
                    case "User":
                        user_type = "User"
                        break;
                    case "OP":
                        user_type = "OP"
                        break;
                    case "TP":
                        user_type = "TP"
                        break;
                    case "TP/OP":
                        user_type = "TP/OP"
                        break;
                    default:
                        break;
                }
            }
            
            let u_data = {
                "isAuth": true,
                "user_type": crypto.encriptar(user_type),
                "username_dj": d.username
            }
            
            const exchange = {
                "1": "3000",
                "10": "30000",
                "20": "70000",
                "30": "100000",
                "50": "180000",
                "100": "360000",
                "200": "720000",
                "300": "1000000",
                "500": "1800000",
                "1000": "3600000"
            }

            if("m2" in d){
                if(d["m2"]["currency"] === "COP"){
                    d["m2"]["amounts"] = d["m2"]["amounts"].map(e => exchange[e])
                }

                u_data["m2"] = {
                    ...d["m2"],
                    "username": crypto.encriptar(d.m2.username),
                    "dispositivos_disponibles": crypto.encriptar(JSON.stringify(d.m2.dispositivos_disponibles)),
                    "tarifas_disponibles": crypto.encriptar(JSON.stringify(d.m2.tarifas_disponibles)),
                    "sw": crypto.encriptar(d.m2.switch),
                    "tax": crypto.encriptar(JSON.stringify(d.m2.tax)),
                    "address": crypto.encriptar(JSON.stringify(d.m2.address))
                }

                delete(u_data["m2"]["user_type"])

                if(d.m2.user_type[0] === "Admin" || d.m2.user_type[0] === "Manager"){
                    u_data["m2"]["users_disponibles"] = crypto.encriptar(
                        JSON.stringify(d.m2.users_disponibles)
                    )
                    u_data["m2"]["users_tp_disponibles"] = crypto.encriptar(
                        JSON.stringify(d.m2.users_tp_disponibles)
                    )
                    u_data["m2"]["users_op_disponibles"] = crypto.encriptar(
                        JSON.stringify(d.m2.users_op_disponibles)
                    )
                }
            }
            setData(u_data)
                        
        }).catch(error => {
            if("response" in error){
                console.error(error)
                if(error.response.status === 401 || error.response.status === 403){
                    secureStorage.clear()
                    history.push("/sign-in/")
                }
            }
        })
    }

    // Funcion recursiva que refresca el JWT cada 3 minutos
    const refresh_jwt = () => {
        
        if(!stop){

            if(!refreshT || !accessT){
                secureStorage.clear()
                history.push("/sign-in/")
                stop = 1
                return null
            }

            axios.post('/api/v1/token/refresh/',
                {
                    refresh: refreshT
                },
                {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            ).then(res => {
                // Eliminamos el token caducado
                secureStorage.removeItem("access")
                // Asignamos el nuevo
                secureStorage.setItem("access", res.data.access)
                setAccessT(res.data.access)
            }).then(() => {
                // Llamamos a la funcion una vez mas y almacenamos su PID para que
                // al momento de desmontar el componente, eliminemos el Proceso pendiente
                setTimerID(setTimeout(() => refresh_jwt(), 3599999))
            }).catch(error => {
                // Un error te devuelve al login y elimina todos los tokens
                secureStorage.clear()
                stop = 1
                history.push("/sign-in/")
                return null
            })
            
        }
    }

    // Al montar el componente:
    useEffect(() => {
        // La variable global se asigna a 0 por si el usuario cerró la sesion con anterioridad
        stop = 0
        const source = axios.CancelToken.source()
        // Pedimos la data del usuario (parametro: cancelToken para evitar leaked memory error)
        ask_for_user_info(source)
        // Ejecutamos por primera vez el Refresh token
        refresh_jwt()

        // Al desmontar el componente:
        return () => {
            // Detenemos el bucle
            stop = 1
            // Paramos el SetTimeout
            clearTimeout(timerID)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <AuthContext.Provider value={data}>
            {props.children}
        </AuthContext.Provider>
    )
}