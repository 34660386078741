import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Titulo from '../compartidos/tools/Titulo'
import {InputText} from 'primereact/inputtext'
import { FormattedMessage } from 'react-intl'
import ButtonLoader from '../compartidos/tools/ButtonLoader'
import secureStorage from '../../tools/SecureStorage'
import ReactGA from 'react-ga'

/* 
   -----------------------------------------------------------------------------------------------
    Este componente permite crear una nueva tarifa solo con colocar el nombre,
    devuelve el ID asignado
   -----------------------------------------------------------------------------------------------
*/
export default function TariffsCreate(props){
    const [tariffName, setTariffName] = useState('')
    const [loader, setLoader] = useState(false) 
    const [callback, setCallback] = useState({})

    useEffect(() => {ReactGA.pageview(window.location.pathname);}, [])

    // Funcion que hace la peticion para crear la nueva Trifa
    const handleSubmit = (e) => {
        e.preventDefault()
        // Borramos el callback mostrado en la operacion anterior
        setCallback({})
        setLoader(true)
        // Chequeamos que los campos no esten vacios y tengo un formato valido
        // eslint-disable-next-line
        if(tariffName !== '' && !tariffName.match(/[^\w\s\-_,ñ\[\]]/g)){
            // URL con los datos
            let url = `/api/v1/get/profile/?p=tariff_wholesale_update&name=${tariffName}`
            // Hacemos la peticion
            axios.get(url,
                {
                    headers: {
                        "Authorization": `Bearer ${secureStorage.getItem("access")}`
                    }
                }
            ).then(res => {
                let respuesta = res.data
                // En caso de que la respuesta sea un error
                if(JSON.stringify(respuesta).match(/"(error|message)":"((\\"|[^"])*)"/i)){
                    respuesta = JSON.stringify(respuesta).match(/"(error|message)":"((\\"|[^"])*)"/i)
                    respuesta = respuesta[0].match(/"([\w\s])+"/g)
                    setCallback({respuesta: "error", error: respuesta[1]})
                // Si todo sale bien al hacer la peticion
                }else{
                    setCallback({tariffName: tariffName, respuesta: "ok", ...res.data.page})
                }
                setLoader(false)
            }).catch(error => {
                setLoader(false)
                setCallback({respuesta: "error", error: "tariff.Incorrect hash"})
            })
        }else{
            setLoader(false)
            setCallback({respuesta: "error", error: "Nombre de usuario ingresado incorrecto"})
        }
    }
    
    return(
        <>
            <Titulo>
                <FormattedMessage id="header.menu.create.tariff"/>
            </Titulo>

            <form method="GET" className="mb-3" onSubmit={(e) => handleSubmit(e)}>
                <div className="row align-items-end justify-content-center">

                    <div className="col-7 col-sm-8 col-md-4">
                        <label htmlFor="tariff-name-create" className="p-d-block">
                            <FormattedMessage id="tariff.tariff.name"/>:
                        </label>
                        <InputText id="tariff-name-create" value={tariffName} 
                            className="w-100 p-inputtext-sm"
                            onChange={e => setTariffName(e.target.value)}
                        />
                    </div>

                    <div className="col-5 col-sm-4 col-md-2">
                        <ButtonLoader
                            loader={loader} className="button-tooltip-loader btn btn-block btn-ariatel--primary btn-sm" 
                            type="submit" style={{minHeight: '35px'}}
                            onClick={(e) => handleSubmit(e)} 
                        >
                            <FormattedMessage id="tariff.create" />
                        </ButtonLoader> 
                    </div>
                </div>
            </form>

            {/* Lugar donde saldra el callback cuando llegue la respuesta del servidor */}
            <div style={{minHeight: '30vh'}}>
                {callback.respuesta ? 
                <section className="row justify-content-center mb-3">
                    <div className="col-12 col-md-6">
                        <div className="card shadow-sm user-details-card-tabs">
                            <div className="card-body">
                            <Checker respuesta={callback.respuesta} 
                                error={callback.error} 
                                success={callback}
                                tariffName={callback.tariffName}
                                tariffID={callback.tariff_id ? callback.tariff_id  : null}
                            />
                            </div>
                        </div>
                    </div>
                </section>
                : null}
            </div>
        </> 
    )
}

/* 
   -----------------------------------------------------------------------------------------------
    Componente que muestra un formato segun la data que le pasemos por sus props,
    la usamos para asignar varios formatos a las respuestas que nos de el servidor
   -----------------------------------------------------------------------------------------------
*/
const Checker = (props) => {
    return (
        <>
            {props.respuesta === "error" ?
                <>
                    <h2 className="text-secondary border-bottom pb-3 text-center mb-3 font-weight-normal">
                        Error
                    </h2>

                    <h4 className="text-center text-muted font-weight-light">
                        <FormattedMessage id={
                            props.error.replace(/"/g, "") === "Incorrect hash" ?
                            "tariff." + props.error.replace(/"/g, "") :
                            props.error.replace(/"/g, "")
                        }/>
                    </h4>
                </> :
                <div className="container">
                    <h4 className="text-secondary border-bottom pb-3 text-center mb-3 font-weight-normal">
                        <FormattedMessage id="tariff.success"/>
                    </h4>

                    <table className="table">
                        <thead>
                            <tr>
                                <th className="border-top-0" style={{borderBottomWidth: "1px"}} scope="col">ID</th>
                                <th className="border-top-0" style={{borderBottomWidth: "1px"}} scope="col">
                                    <FormattedMessage id="tariff.name"/>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">{props.tariffID}</th>
                                <td>{props.tariffName}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </>
    )
}