import React, {useState, useEffect, useContext} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AuthContext } from '../../AuthContext'
import secureStorage from '../../../tools/SecureStorage'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que muestra las ofertas actuales disponibles
   -----------------------------------------------------------------------------------------------
*/
const Ofertas = () => {
        const [ofertas, setOfertas] = useState([])
        const data = useContext(AuthContext)
        const settings = {
            arrows: false,
            autoplay: true,
            dots: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        }

        // Hacemos una peticion apenas se monte el componente para recibir las ofertas
        useEffect(() => {
            const source = axios.CancelToken.source()
            axios.get( '/ofertas/?p=get_all_nu',
                {
                    headers: {
                        "Authorization": `Bearer ${secureStorage.getItem("access")}`,
                        'content-type': 'application/json'
                    },
                    cancelToken: source.token
                }
            ).then(resp => {
                setOfertas(resp.data.ofertas)
            }).catch(error => {
                // Si hay un error la marca como vacio
                setOfertas([])
            })

            return () => {
                source.cancel("component unmounted")
            }
        }, [])

        return(
            // Card que delimita el cuadro de ofertas
            <div className="card">
                {/* Si hay ofertas muestra el carousel, mientras no haya muestra un texto */}
                {ofertas.length > 0 ?
                    <Slider {...settings}>
                        {ofertas.map((e,i) => {
                            return (
                                <div className="card-body" key={i}>
                                    <h3 className="text-center display-3 text-primary">
                                        {e.percent}%
                                    </h3>
                                    <hr className="mb-1"/>
                                    <p className="text-center text-muted mb-0 font-weight-bold text-capitalize">
                                        {e.country}
                                    </p>
                                    <hr className="mt-1"/>
                                    <h4 className="text-center text-muted mb-4">
                                        <span style={{textDecoration: 'line-through', color: '#a5a5a5'}}>
                                            {data.m2.currency === "EUR" ? "€ " : "$ "}
                                            {data.m2.default_curr === data.m2.currency ?
                                                e.price_without_discount
                                            :
                                                (parseFloat(e.price_without_discount) * parseFloat(data.m2.exchange_rate)).toFixed(2)
                                            } 
                                        </span> 
                                        <FontAwesomeIcon className="mx-2" icon="long-arrow-alt-right"/> 
                                        <span className="text-primary">
                                            {data.m2.currency === "EUR" ? "€ " : "$ "}
                                            {data.m2.default_curr === data.m2.currency ?
                                                e.final_price
                                            :
                                                (parseFloat(e.final_price) * parseFloat(data.m2.exchange_rate)).toFixed(2)
                                            } 
                                        </span>
                                    </h4>
                                </div>
                            )
                        })}
                    </Slider> 
                    :
                    <div className="card-body">
                        <h3 className="text-center display-3 text-primary">
                            %
                        </h3>
                        <hr className="mb-1"/>
                        <p className="text-center text-muted mb-0 font-weight-bold text-capitalize">
                            <FormattedMessage id="nooffers"/>
                        </p>
                        <hr className="mt-1"/>
                        <h4 className="text-center text-muted mb-4">
                            <span style={{textDecoration: 'line-through', color: '#a5a5a5'}}>
                                $
                            </span> 
                            <FontAwesomeIcon className="mx-2" icon="long-arrow-alt-right"/> 
                            <span className="text-primary">
                                $
                            </span>
                            <br/>
                        </h4>
                    </div>
                }
            </div>
        )
}

export default Ofertas