import React from 'react';

/* 
   -----------------------------------------------------------------------------------------------
    Componente que se encarga de renderizar el mismo main en cada pagina interna
   -----------------------------------------------------------------------------------------------
*/
export default function Main(props){
    return(
        <main className="px-md-4 main-dashboard container-fluid">
            {props.children}
        </main>
    )
}