import React, { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column';
import Titulo from '../compartidos/tools/Titulo';
import { FormattedMessage, useIntl } from 'react-intl';
import secureStorage from '../../tools/SecureStorage';
import ButtonLoader from '../compartidos/tools/ButtonLoader';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'antd'
import UsersInput from './UsersInput';
import { AuthContext } from '../AuthContext';
import ReactGA from 'react-ga'
import moment from 'moment'

/* 
   -----------------------------------------------------------------------------------------------
    Clase que hace la peticion solicitando el historial de pagos y retorna la respuesta 
   -----------------------------------------------------------------------------------------------
*/
class Peticiones {
    getPayments(url){
        return axios.get(url,
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`
                }
            }
        ).then(res => {
            if(res.data.page){
                return res.data.page.payments.payment
            }else{
                return res
            }
        })
    }
}

/* 
   -----------------------------------------------------------------------------------------------
    Componente que nos permite visualizar el historial de pagos
   -----------------------------------------------------------------------------------------------
*/
const PaymentsAdmin = () => {
    const [rows, setRows] = useState([])
    const [from, setFrom] = useState('1y')
    const [loader, setLoader] = useState(false)
    const [first, setFirst] = useState(null)
    const [rowsNumber, setRowsNumber] = useState(10)
    const [error, setError] = useState('')
    const intl = useIntl()
    const dt = useRef()
    // const [totals, setTotals] = useState({})
    const [user, setUser] = useState("")
    const [currency, setCurrency] = useState("ALL")
    const [minAmount, setMinAmount] = useState(null)
    const [maxAmount, setMaxAmount] = useState(null)
    const u_data = useContext(AuthContext)

    useEffect(() => {ReactGA.pageview(window.location.pathname);}, [])

    const currency_list = [
        {label: "All", value: "ALL"},
        {label: "COP", value: "COP"},
        {label: "USD", value: "USD"},
        {label: "EUR", value: "EUR"}
    ]
    
    const intervals = [
        // {label: intl.formatMessage({id: "aggregates.time_interval.today"}), value:"today"},
        // {label: intl.formatMessage({id: "aggregates.time_interval.1d"}), value:"1d"},
        // {label: intl.formatMessage({id: "aggregates.time_interval.2d"}), value:"2d"},
        // {label: intl.formatMessage({id: "aggregates.time_interval.1w"}), value:"1w"},
        // {label: intl.formatMessage({id: "aggregates.time_interval.15d"}), value:"15d"},
        // {label: intl.formatMessage({id: "aggregates.time_interval.1m"}), value:"1m"},
        // {label: intl.formatMessage({id: "aggregates.time_interval.2m"}), value:"2m"},
        // {label: intl.formatMessage({id: "aggregates.time_interval.3m"}), value:"3m"},
        // {label: intl.formatMessage({id: "aggregates.time_interval.6m"}), value:"6m"},
        {label: intl.formatMessage({id: "aggregates.time_interval.1y"}), value:"1y"},
        {label: intl.formatMessage({id: "aggregates.time_interval.2y"}), value:"2y"},
    ]

    // Datos basicos de las columnas de la tabla
    const columns = [
        {name: intl.formatMessage({id: "payments.user"}), field: "user", width: "150px", filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'}), footer: "Total:"},
        {name: intl.formatMessage({id: "payments.date"}), field: "date", width: "160px", filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: `${intl.formatMessage({ id: "payments.amount.charged" })} (${u_data.m2.default_curr})`, field: "amount", width: "160px", filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: `${intl.formatMessage({ id: "payments.amount_with_tax" })} (${u_data.m2.default_curr})`, field: "amount_with_tax", width: "270px", filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id: "exchange.rate"}), field: "exchange_rate", width: "120px", filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: `${intl.formatMessage({ id: "payments.amount.payed" })}`, field: "user_amount", width: "160px", filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: `${intl.formatMessage({ id: "payu.status" })}`, field: "status", width: "160px", filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: intl.formatMessage({id: "add.payments.choose.method"}), field: "payment_method", width: "120px", filterMatchMode: '', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
        {name: `${intl.formatMessage({ id: "payu.ref" })}`, field: "comment", width: "223px", filterMatchMode: 'contains', placeholder: intl.formatMessage({id: 'filter.placeholder'})},
    ]
    
    // Recorremos cada columna y creamos un componente con la data proporcionada por columns
    let dynamicColumns = columns.map((col) => {
        return( 
            <Column key={col.field} 
                    field={col.field} 
                    header={col.name}
                    body={col.body || null}
                    sortable={true} 
                    filter={true}
                    className={u_data.m2.default_curr === u_data.m2.currency ? col.className : null}
                    filterHeaderClassName={u_data.m2.default_curr === u_data.m2.currency ? col.className : null}
                    // footer={col.footer || null}
                    // footerClassName="text-left"
                    style={col.width ? {width: col.width} : null}
                    filterMatchMode={col.filterMatchMode === '' ? 'startsWith' : col.filterMatchMode}
                    filterPlaceholder={col.placeholder} /> 
        )
    })

    const calcularTiempo = (dias) => {
        const fecha = new Date(Date.now() - 24 * dias * 3600 * 1000)
        return parseInt(fecha.setHours(0,0,0,0) / 1000)
    }

    const addIntervalToURL = () => {
        switch (from) {
            case "today":
                return ("&from_s=" + calcularTiempo(0))
            case "1d":
                return ("&from_s=" + calcularTiempo(1))
            case "2d":
                return ("&from_s=" + calcularTiempo(2))
            case "1w":
                return ("&from_s=" + calcularTiempo(7))
            case "15d":
                return ("&from_s=" + calcularTiempo(15))
            case "1m":
                return ("&from_s=" + calcularTiempo(30))
            case "2m":
                return ("&from_s=" + calcularTiempo(60))
            case "3m":
                return ("&from_s=" + calcularTiempo(90))
            case "6m":
                return ("&from_s=" + calcularTiempo(180))
            case "1y":
                return ("&from_s=" + calcularTiempo(365))
            case "2y":
                return ("&from_s=" + calcularTiempo(730))
            default:
                return ""
        }
    }

    const addAmountsToURL = () => {
        let querie = ""

        if(minAmount !== null){
            querie += `&amount_min_s=${minAmount}`
        }
        if(maxAmount !== null){
            querie += `&amount_max_s=${maxAmount}`
        }

        return querie
    }

    const addCurrencyToURL = () => {
        if (currency === "ALL"){
            return ""
        }
        return `&currency_s=${currency}`
    }

    const isValid = () => {
        let errors = []
        
        // eslint-disable-next-line
        if(user.match(/[^\w\s\-_,ñ\[\]]/g)){
            errors.push(false)
        }

        if(minAmount !== null && maxAmount !== null){
            if(minAmount > maxAmount){
                errors.push(false)
            }
        }

        if(!isNaN(minAmount) && minAmount !== null){
            errors.push(false)
        }

        if(!isNaN(maxAmount) && maxAmount !== null){
            errors.push(false)
        }

        return errors.reduce((a, b) => a && b, true)
    }
    
    const getUrl = () => {
        let url = "/wompi/get/payment-history/?p=payments_get"
        url += addIntervalToURL()
        url += addAmountsToURL()
        url += addCurrencyToURL()
        if(user !== ""){
            url += "&u=" + user.trim()
        }
        return url
    }

    const handleReset = () => {
        // setTotals({})
        setRows([])
        setError("")
    }

    const _getPayments = (e) => {
        e.preventDefault()
        handleReset()
        isValid() ? send(getUrl()) : setError("forms.invalid")
    }

    // Funcion que hace la peticion una vez todo validado
    const send = (url) => {
        setLoader(true)

        const data = new Peticiones()

        // Hacemos la peticion con la URL enviada desde handleSubmit()
        data.getPayments(url).then(res => {
            const payments = Array.isArray(res.data.payments) ? res.data.payments : [res.data.payments]
            setRows(payments.map(el => {
                if(el["user_amount"]){
                    el["user_amount"] = `${Intl.NumberFormat().format(parseFloat(el.user_amount))} ${el.exchange_currency}`
                }

                if(el["exchange_rate"]){
                    el["exchange_rate"] = `${Intl.NumberFormat().format(parseFloat(el.exchange_rate))} ${el.exchange_currency}`
                }

                switch (el["payment_method"]) {
                    case "pse":
                        el["payment_method"] = "PSE"
                        break;
                    case "credit_card":
                        el["payment_method"] = "Credit Card"
                        break;
                    case "bancolombia_button":
                        el["payment_method"] = "Botón Bancolombia"
                        break;
                    default:
                        el["payment_method"] = "Other" 
                        break;
                }

                if(el["amount"]){
                    el["amount"] = `${Intl.NumberFormat().format(parseFloat(el.amount))} ${u_data.m2.default_curr}`
                }

                if(el["amount_with_tax"]){
                    el["amount_with_tax"] = `${Intl.NumberFormat().format(parseFloat(el.amount_with_tax))} ${u_data.m2.default_curr}`
                }

                if(el["date"]){
                    const local = moment(moment.utc(el.date).toDate()).local().format('YYYY-MM-DD HH:mm:ss')
                    el["date"] = local
                }

                return el
            }))
            setLoader(false)
        }).catch(error => {
            setError("Incorrect hash")
            setLoader(false)
        })
    }

    const exportarCSV = () => {
        dt.current.exportCSV()
    }
    

    return(
        <>
            <Titulo className="d-flex justify-content-center justify-content-lg-between">
                <FormattedMessage id="header.menu.payments"/>
                <button className="btn btn-outline-success d-none d-lg-block" disabled={rows.length < 1} type="button" onClick={exportarCSV}>
                    <FormattedMessage id="exportcsv"/>
                </button>
            </Titulo>

            <form method="GET" className="mb-3 payments-get-form" onSubmit={(e) => _getPayments(e)}>   
                <div className="row align-items-end justify-content-center">

                    <div className="col-7 col-sm-3 col-lg-3 mb-3 mb-lg-0">
                        <label htmlFor="u-payments-history" className="p-d-block">
                            <FormattedMessage id="details.placeholder.username"/>:
                        </label>
                        <UsersInput value={user} onChange={e => setUser(e.value)}/>
                    </div>

                    <div className="col-5 col-sm-4 col-lg-3 mb-3 mb-lg-0">
                        <label htmlFor="intervalsph" className="p-d-block">
                            <FormattedMessage id="aggregates.time_interval.title"/>:
                        </label>
                        <Dropdown className="w-100" id="intervalsph" value={from} onChange={(e) => setFrom(e.value)} options={intervals}/>
                    </div>
                   
                    <div className="col-4 col-sm-3 col-lg-2 mb-3 mb-lg-0">
                        <div>
                            <label className="p-d-block">
                                <FormattedMessage id="payments.amount.between"/>:
                            </label>
                        </div>
                        <div className="d-flex justify-content-start align-items-center">
                            <InputNumber
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(v) => setMinAmount(v)} placeholder="Min"
                                style={{borderColor: "rgb(166, 166, 166)", maxHeight: "27.59px"}}
                            />
                            -
                            <InputNumber
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(v) => setMaxAmount(v)} placeholder="Max"
                                style={{borderColor: "rgb(166, 166, 166)", maxHeight: "27.59px"}}
                            />
                        </div>
                    </div>
    
                    <div className="col-4 col-sm-2 col-lg-2 mb-3 mb-lg-0">
                        <label className="p-d-block">
                            <FormattedMessage id="currency"/>:
                        </label>
                        <Dropdown id="currency-payments-get" className="w-100" value={currency} options={currency_list} onChange={(e) => setCurrency(e.value)}/>
                    </div>
                    
                    <div className="col-4 col-sm-2 mb-3 mb-sm-0 mb-md-3 mb-lg-0 order-last">
                        <ButtonLoader
                            loader={loader} className="button-tooltip-loader btn btn-block btn-ariatel--primary btn-sm" 
                            type="submit" style={{fontSize: '12px'}}
                            onClick={(e) => _getPayments(e)}
                        >
                            <FormattedMessage id="calls.search" />
                        </ButtonLoader>
                    </div>
                </div>
            </form>
            <small className="text-muted font-italic d-block text-center">
                <FormattedMessage id="payments.helper"/>
            </small>

            {/* Muestra un error si lo hay */}
            {error ? 
                <section className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <h2 className="text-secondary border-bottom pb-3 text-center mb-3 font-weight-normal">
                                    Ooops!
                                </h2>

                                <h4 className="text-center text-muted font-weight-light">
                                    <FormattedMessage id={error}/>
                                </h4>
                            </div>
                        </div>
                    </div>
                </section> : 
                <>
                    <DataTable value={rows}
                        ref={dt}
                        exportFilename={intl.formatMessage({id: "payments.of"}) + " " + (user ||  intl.formatMessage({id: "everyone"})) + " desde " + intl.formatMessage({id: `aggregates.time_interval.${from}`}).toLocaleLowerCase()}
                        sortMode="multiple" 
                        removableSort={true} 
                        paginator={true}
                        first={first} 
                        rows={rowsNumber}
                        rowsPerPageOptions={[10, 20, 30]} 
                        onPage={(e) => {
                            setFirst(e.first)
                            setRowsNumber(e.rows)
                        }}>
                        {dynamicColumns}
                    </DataTable>
                    <button className="btn btn-block btn-outline-success d-block d-lg-none mt-3" disabled={rows.length < 1} type="button" onClick={exportarCSV}>
                        <FormattedMessage id="exportcsv"/>
                    </button>
                </>
            }
        </>
    )

}

export default PaymentsAdmin