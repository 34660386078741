import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import {FormattedMessage} from 'react-intl'
import ReactGA from 'react-ga'


/* 
   -----------------------------------------------------------------------------------------------
    Componente que aparece cuando un usuario logueado intenta ir a una pagina esta fuera de servicio
   -----------------------------------------------------------------------------------------------
*/
export default function OutOfService(){
    useEffect(() => {ReactGA.pageview(window.location.pathname);}, [])

    return(
        <section className="my-5 text-center">
            <FontAwesomeIcon size="7x" icon="sad-tear" className="mb-4" color="#292845" />
            <h3 className="h4 text-ariatel--primary">
                <FormattedMessage id="outOfService.title"/>
            </h3>
            <small className="text-muted d-block">
                <FormattedMessage id="outOfService.text"/>
            </small>
            {/* Link que redirige a la alternativa */}
            <Link to="/u/" className="btn btn-ariatel--primary mt-5">
                <FormattedMessage id="outOfService.redirectLink"/>
            </Link>
        </section>
    )
}