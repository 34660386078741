import React from 'react'
import DecoracionOnda from '../decoraciones/DecoracionOnda'
import Logo from '../decoraciones/logo-ariatel/logo-ariatel.png'
import Logo_1 from '../decoraciones/logo-ariatel/logo-ariatel@1x.png'
import Logo_1_5 from '../decoraciones/logo-ariatel/logo-ariatel@1.5x.png'
import Logo_2 from '../decoraciones/logo-ariatel/logo-ariatel@2x.png'
import Logo_3 from '../decoraciones/logo-ariatel/logo-ariatel@3x.png'
import { Link } from 'react-router-dom'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que contiene el header de cada pagina publica
   -----------------------------------------------------------------------------------------------
*/
class Header extends React.Component{
    render(){
      return (
          <header className="text-center mb-3">
            <DecoracionOnda id="DecoracionTop" />
            <Link to="/">
              <img alt="Logo de AriaTel" style={{maxWidth: "45%"}} src={Logo}
                srcSet={
                  `${Logo_1} 1x, ${Logo_1_5} 1.5x, ${Logo_2} 2x, ${Logo_3} 3x`
                }/>
            </Link>
          </header>
      )
    }
}
export default Header