import React from 'react'
import FormularioDeInicioAdminDjango from './forms/FormularioDeInicioAdminDjango'
import LanguageSwitch from "../../translation/LanguageSwitch"
import Header from './Header'
import ReactGA from 'react-ga'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que contiene los wrappers del Formulario de Inicio de Sesión
   -----------------------------------------------------------------------------------------------
*/
class AppInicioDjangoAdmin extends React.Component{
    componentDidMount() {
      ReactGA.pageview(window.location.pathname);
    }

    render(){
      return (
        <div>
          {/* Header */}
          <Header/>
          {/* Main */}
          <main className="d-flex justify-content-center pt-5"> 
            <FormularioDeInicioAdminDjango/>
          </main>
          {/* Lenguaje Switch */}
          <LanguageSwitch margins="mt-3 pb-3" />
        </div>
      )
    }
}
export default AppInicioDjangoAdmin