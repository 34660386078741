import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../decoraciones/logo-ariatel/logo-ariatel.png'
import Logo_1 from '../../decoraciones/logo-ariatel/logo-ariatel@1x.png'
import Logo_1_5 from '../../decoraciones/logo-ariatel/logo-ariatel@1.5x.png'
import Logo_2 from '../../decoraciones/logo-ariatel/logo-ariatel@2x.png'
import Logo_3 from '../../decoraciones/logo-ariatel/logo-ariatel@3x.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LanguageSwitch from '../../../translation/LanguageSwitch'
import { FormattedMessage } from 'react-intl'
import axios from 'axios'
import { HeaderNavDropdownButton } from '../tools/HeaderLinks'
import secureStorage from '../../../tools/SecureStorage'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que se encarga de renderizar el header en cada pagina interna
   -----------------------------------------------------------------------------------------------
*/
export default function Header(props) {

    const _logOut = () => {
        axios.post('/api/v1/rest-auth/logout/').then(res => {
            secureStorage.clear()
        }).catch(error => console.error(error))
    }

    return(
        <>
            <header className="header-dashboard">
                <nav className="navbar fixed-top shadow-sm border-bottom navbar-light bg-white px-md-4 py-0">
                    <Link className="navbar-brand py-0" to="/u/">
                        <img src={Logo} alt="logo de Ariatel"
                        srcSet={
                            `${Logo_1} 1x, ${Logo_1_5} 1.5x, ${Logo_2} 2x, ${Logo_3} 3x`
                        }/>
                        <h1 className="d-none">Ariatel S.A.S</h1>
                    </Link>
                    
                    <div className="navbar-expand align-self-stretch">
                        <ul className="navbar-nav h-100">

                            {/* Links para Desktop */}
                            {props.navLG}

                            <HeaderNavDropdownButton icon="user-cog" className="border-left pl-sm-3 ml-sm-3">
                                    {/* <Link className="dropdown-item text-capitalize" 
                                        to="/u/change/password/">
                                        <FontAwesomeIcon size="sm" className="mr-2" icon="exchange-alt" />
                                        <FormattedMessage id="header.menu.changePassword"/>
                                    </Link>  */}

                                    <Link className="dropdown-item text-capitalize"
                                        to="/u/profile/">
                                        <FontAwesomeIcon size="sm" className="mr-2" icon="user-circle" />
                                        <FormattedMessage id="header.menu.profile"/>
                                    </Link>
                                    <Link className="dropdown-item text-capitalize"
                                        to="/u/list/credit-card/">
                                        <FontAwesomeIcon size="sm" className="mr-2" icon="list" />
                                        <FormattedMessage id="cc.list.title"/>
                                    </Link>
                                    <Link className="dropdown-item text-capitalize"
                                        to="/u/add/credit-card/">
                                        <FontAwesomeIcon size="sm" className="mr-2" icon="plus-square" />
                                        <FormattedMessage id="cc.register"/>
                                    </Link>
                                    <div className="dropdown-divider"></div>

                                    <Link className="dropdown-item text-capitalize"
                                        onClick={() => _logOut()} to="/sign-in/">
                                        <FontAwesomeIcon size="sm"  className="mr-2" icon="door-open" />
                                        <FormattedMessage id="header.menu.logout"/>
                                    </Link>

                                    <div className="dropdown-divider"></div>

                                <LanguageSwitch margins=""/>
                            </HeaderNavDropdownButton>
                        </ul>
                    </div>
                </nav>
            </header>

            {/* Links para el footer Navbar */}
            {props.footerNav}
        </>

    )
}