import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import Titulo from '../../../tools/Titulo'
import $ from 'jquery'
import secureStorage from '../../../../../tools/SecureStorage'
import * as html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import 'react-phone-input-2/lib/style.css'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que permite que un usuario reporte su pago con un recibo de tipo imagen o pdf
   -----------------------------------------------------------------------------------------------
*/
export default function PSEResponseHistory(){
    const [transaction, setTransaction] = useState({})
    const seccion = useRef(null)
    const [error, setError] = useState(false)
    const [banks, setBanks] = useState([]);
    const { reference } = useParams()

    useEffect(() => {
        axios.post('/wompi/get-transaction/', 
            {
                "reference": reference
            },
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`,
                }
            }
        ).then(res => {
            const data = res.data.transaction.data[0]
            setTransaction(data)
        }).catch((error) => {
            console.log(error)
            setError(true)
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        axios.get('/wompi/get-public-key/', 
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`,
                    "Content-Type": "application/json"
                }
            }
        ).then(resp => {

            axios.get("https://production.wompi.co/v1/pse/financial_institutions",
                {
                    headers: {
                        "Authorization": `Bearer ${resp.data["public_key"]}`,
                        "Content-Type": "application/json"
                    }
                }
            ).then(res => {
                setBanks(res.data.data)
            }).catch(error => setBanks([]))

        }).catch(error => setBanks([]));

        // eslint-disable-next-line
    }, [])

    const printDocument = () => {
        html2canvas(seccion.current, {
            useCORS: true,
            allowTaint: true,
            scrollY: -window.scrollY,
            scale: 1
          }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF("p", "px", "a4")

            const pageWidth = pdf.internal.pageSize.getWidth()
            const pageHeight = pdf.internal.pageSize.getHeight()
            const widthRatio = pageWidth / canvas.width
            const heightRatio = pageHeight / canvas.height
            const ratio = widthRatio > heightRatio ? heightRatio : widthRatio
            const canvasWidth = canvas.width * ratio
            const canvasHeight = canvas.height * ratio

            const marginX = ((pageWidth - canvasWidth) / 2) - 2.5
            const marginY = (pageHeight - canvasHeight) / 2
            pdf.addImage(imgData, 'JPEG', marginX, marginY, canvasWidth, canvasHeight)
            pdf.save("recibo.pdf")
        })
    }

    return(
        <>
            <Titulo className="d-flex justify-content-center justify-content-lg-between">
                <FormattedMessage id="pse.transaction.done"/>
                <button className="btn btn-outline-ariatel--danger d-none d-lg-block" disabled={$.isEmptyObject(transaction)} type="button" onClick={printDocument}>
                    <FormattedMessage id="pse.boton.pdf"/>
                </button>
            </Titulo>

            

            {$.isEmptyObject(transaction) ? 
                <div className="callsChart-loader d-flex w-100 align-items-center justify-content-center" style={{minHeight: '400px'}}>
                    {error ? 
                        <div className="text-muted h3">
                            <FormattedMessage id="502error"/>
                        </div>
                    :
                        <div className="spinner-priv spinner-border text-primary" role="status">
                            <span className="sr-only">
                                <FormattedMessage id="privado"/>
                            </span>
                        </div>
                    }
                </div>
            :
                <section ref={seccion} className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <div className="card">
                            <div className="card-header">
                                {transaction.status}
                            </div>
                            <div className="card-body">
                                <table className="table table-bordered table-sm">
                                    <tbody>
                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="pse.factura"/></th>
                                            <td>{transaction.id}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="payu.date"/></th>
                                            <td>{new Date(transaction.created_at).toLocaleString()}</td>
                                        </tr>

                                        {banks.length > 0 ?
                                            <tr>
                                                <th scope="row" className="text-capitalize"><FormattedMessage id="pse.bank"/></th>
                                                <td>{banks.find((el) => el.financial_institution_code === transaction.payment_method.financial_institution_code).financial_institution_name}</td>
                                            </tr>
                                            : null
                                        }

                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="devices.description"/></th>
                                            <td>{transaction.payment_method.payment_description}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="payments.amount"/> (COP)</th>
                                            <td>$ {transaction.amount_in_cents/100} {transaction.currency}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="payu.status"/></th>
                                            <td>{transaction.status}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="tariff.name"/></th>
                                            <td>{transaction.customer_data.full_name}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="pse.person"/></th>
                                            <td className="text-capitalize">{transaction.payment_method.user_type ? "Jurídica" : "Natural"}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="profile.movil"/></th>
                                            <td>{transaction.customer_data.phone_number}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="profile.email"/></th>
                                            <td>{transaction.customer_email}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-capitalize"><FormattedMessage id="pse.doc"/></th>
                                            <td>{transaction.payment_method.user_legal_id_type} {transaction.payment_method.user_legal_id}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className="border-top d-flex justify-content-between">
                                    <span>
                                        Ariatel S.A.S 
                                    </span>
                                    <span>
                                        NIT. 900.650.791-0
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}