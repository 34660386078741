import React from 'react'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que se encarga de renderizar el mismo titulo en cada pagina interna
   -----------------------------------------------------------------------------------------------
*/
export default function Titulo(props) {
    return(
        <h2 className={"dashboard-main-title text-center text-lg-left text-ariatel--default mt-4 mb-4 pb-3 border-bottom " + (props.className || "")}>
            {props.children}
        </h2>
    )
}