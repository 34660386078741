import React, { useEffect, useState } from 'react'
import Titulo from '../../../tools/Titulo'
import 'react-credit-cards/es/styles-compiled.css'
import axios from 'axios'
import secureStorage from '../../../../../tools/SecureStorage'
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"
import {CardAdapt} from '../../../tools/Cards'
import { confirmDialog } from 'primereact/confirmdialog'
import { FormattedMessage, useIntl } from "react-intl";
import ReactGA from 'react-ga'


export default function ListCreditCard(){
    const [currentCards, setCurrentCards] = useState([])
    const [error, setError] = useState(false)
    const [loader, setLoader] = useState(false)
    const intl = useIntl()

    const confirm = (id) => {
        confirmDialog({
            message: intl.formatMessage({id: "cc.conf.body"}),
            header: intl.formatMessage({id: "cc.conf.title"}),
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteCard(id),
            reject: () => null
        })
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
        setError(false)
        axios.post( '/wompi/credit-card/list/', {},
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`,
                    'content-type': 'application/json'
                }
            }
        ).then(resp => setCurrentCards(resp.data.cards)
        ).catch(() => {
            setCurrentCards([])
            setError(true)
        })
    }, [])

    const cardSuccess = (alertype) => {
        const handler = '.alert.alert-success.' + alertype
        $(handler).removeClass("d-none")
        setTimeout(() => {
            $(handler).addClass("d-none")
        }, 5000)
    }

    // Muestra una alerta de error al realizar cierta accion de creacion/eliminacion
    const cardError = (alertype) => {
        const handler = '.alert.alert-danger.' + alertype
        $(handler).removeClass("d-none")
        setTimeout(() => {
            $(handler).addClass("d-none")
        }, 5000)
    }

    const handleAlert = (alertype, bool) => {
        bool ? cardSuccess(alertype) : cardError(alertype)
    }

    const deleteCard = (id) => {
        setLoader(true)
        
        // Hacemos la peticion
        axios.post("/wompi/credit-card/delete/", {"id": id},
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`,
                    'content-type': 'application/json'
                }
            }
        ).then(res => {
            if(res.data.status === "Eliminado correctamente"){
                const fresh_cards = currentCards.filter((e) => {return e.id !== id})
                setCurrentCards(fresh_cards)
            }
            setLoader(false)
            handleAlert("delete", res.data.status === "Eliminado correctamente")
        }).catch(() => {
            setLoader(false)
            handleAlert("delete",false)
        })
    }

    return (
        <>
            <Titulo className="d-flex justify-content-center justify-content-lg-between">
                <FormattedMessage id="cc.list.title"/>
                <div>
                    <Link to="/u/pay/by/credit-card/" className="btn btn-info d-none d-lg-block">
                        <FormattedMessage id="cc.recharge"/>
                    </Link>
                </div>
            </Titulo>

            <CardAdapt header={<HeaderCCList total={currentCards.length} />}>
                <table className="table-cards table rounded mb-0 table-hover">
                    {error && <caption id="nocards" className="my-5 text-center">
                        <FontAwesomeIcon icon="exclamation-triangle" size="4x"/>
                        <h4 className="text-muted my-4 dashboard-main-title">
                            <FormattedMessage id="cc.nocc"/>
                        </h4>
                        <Link to="/u/add/credit-card/" className="btn btn-sm btn-outline-ariatel--secondary"><FormattedMessage id="cc.register"/></Link>
                    </caption>}

                    {(!error && currentCards.length === 0) && <caption id="nocards" className="my-5 text-center">
                        <FontAwesomeIcon icon="exclamation-triangle" size="4x"/>
                        <h4 className="text-muted my-4 dashboard-main-title">
                            <FormattedMessage id="cc.nofoundcc"/>
                        </h4>
                        <Link to="/u/add/credit-card/" className="btn btn-sm btn-outline-ariatel--secondary"><FormattedMessage id="cc.register"/></Link>
                    </caption>}

                    <thead>
                        <tr>
                            <th className="text-capitalize border-top-0 border-bottom-1" scope="col">
                            <FormattedMessage id="cc.number"/>
                            </th>
                            <th className="text-capitalize border-top-0 border-bottom-1" scope="col">
                            <FormattedMessage id="cc.exp"/>
                            </th>
                            <th className="text-capitalize border-top-0 border-bottom-1" scope="col">
                            <FormattedMessage id="cc.type"/>
                            </th>
                            <th className="text-capitalize border-top-0 border-bottom-1" scope="col">
                            <FormattedMessage id="cc.name"/>
                            </th>
                            <th className="border-top-0 border-bottom-1" style={{width:"50px"}} scope="col"></th>
                        </tr>
                    </thead>

                    {!error && <tbody>
                        {currentCards.map((element, index) => {
                            return(
                                <tr key={index}>
                                    <td>{element.bin} ******* {element.last_four}</td>
                                    <td>{element.exp_date}</td>
                                    <td>{element.brand}</td>
                                    <td>{element.card_holder}</td>
                                    <td>
                                        {loader ?
                                            <div className="spinner-border spinner-border-sm text-info" role="status">
                                                <span className="sr-only">Loading</span>
                                            </div> : 
                                            <button disabled={loader} type="button" onClick={() => confirm(element.id)} 
                                                className="btn p-0 del-card" 
                                                style={{verticalAlign: "top"}}>
                                                <FontAwesomeIcon icon="trash-alt" className="text-muted"/>
                                            </button>
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>}                        
                </table>
            </CardAdapt>
            
            <Alerts/>
        </>
    )
}

const Alerts = () => {
    return(
        <>
            <div className="alert alert-success delete position-fixed d-none" 
                style={{bottom: "15px", right: "15px", zIndex: 1000}} 
                role="alert"
            >
                <FormattedMessage id="cc.msgsuccess"/>
            </div>
            <div className="alert alert-danger delete position-fixed d-none"
                style={{bottom: "15px", right: "15px", zIndex: 1000}} 
                role="alert">
                <FormattedMessage id="cc.msg.error"/>
            </div>
        </>
    )
}

const HeaderCCList = (props) => {
    const intl = useIntl()

    useEffect(() => {
        $('#btn-add-cc').tooltip()
        return () => {
            $('#btn-add-cc').tooltip('dispose')
        }
    }, [])
    
    return(
        <div  className="d-flex justify-content-between align-self-center">
            <div>
                Total: {props.total} <FormattedMessage id="cc.cards"/>
            </div>
            <Link data-title={intl.formatMessage({id: "cc.add.another"})} id="btn-add-cc" data-trigger="hover" data-toggle="tooltip" data-placement="left" to="/u/add/credit-card/" className="add-offer-link">
                <FontAwesomeIcon icon="plus-square" color="#fff"/>
            </Link>
        </div>
    )
}