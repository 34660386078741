import SecureStorage from 'secure-web-storage'
import * as CryptoJS from 'crypto-js'

const cs_sc = '@r1@t3lS.@.S_y3ar_2o2o.'

/* 
-----------------------------------------------------------------------------------------------
Esta instancia de objeto de SecureStorage  se encarga de codificar y decodificar 
el texto en el localStorage              
-----------------------------------------------------------------------------------------------
*/
const secureStorage = new SecureStorage(localStorage, {
    hash: function hash(key) {
        key = CryptoJS.SHA256(key, cs_sc);
 
        return key.toString();
    },
    encrypt: function encrypt(data) {
        data = CryptoJS.AES.encrypt(data, cs_sc);
 
        data = data.toString();
 
        return data;
    },
    decrypt: function decrypt(data) {
        data = CryptoJS.AES.decrypt(data, cs_sc);
 
        data = data.toString(CryptoJS.enc.Utf8);
 
        return data;
    }
});

export default secureStorage