import React, { useState, useRef, useEffect, useContext } from 'react'
import axios from 'axios'
import {InputText} from 'primereact/inputtext'
import Titulo from '../compartidos/tools/Titulo'
import $ from 'jquery'
import {InputNumber} from 'primereact/inputnumber'
import {InputSwitch} from 'primereact/inputswitch'
import {FormattedMessage, useIntl} from 'react-intl'
import Pdf from "react-to-pdf"
import secureStorage from '../../tools/SecureStorage'
import UsersInput from './UsersInput'
import { Dropdown } from 'primereact/dropdown'
import { AuthContext } from '../AuthContext'
import ReactGA from 'react-ga'

/* 
   -----------------------------------------------------------------------------------------------
    Este componente añade pagos al M2, esta hecho para que aquellas transferencias confirmadas
    sean añadidas por este medio, sin ingresar al Switch del M2.
   -----------------------------------------------------------------------------------------------
*/
function PaymentCreateAdmin(){
    // Nos permite generar un PDF
    const pdf = useRef(null)
    const intl = useIntl()
    const data = useContext(AuthContext)
    // Formularios y su campo de error
    const [userIDError, setUserIDError] = useState('')
    const [amountError, setAmountError] = useState('')
    const [commentError, setCommentError] = useState('')
    const [amount, setAmount] = useState(0)
    const [inTax, setInTax] = useState(false)
    const [comment, setComment] = useState('')
    const [toProvider, setToProvider] = useState(false)
    // Loader del boton de submit
    const [loader, setLoader] = useState(false)
    // Respuesta
    const [callback, setCallback] = useState({})
    const [user, setUser] = useState("")

    const [currency, setCurrency] = useState(data.m2.currency)

    const currency_list = [
        {label: "COP", value: "COP"},
        {label: "USD", value: "USD"},
        {label: "EUR", value: "EUR"}
    ]

    // Inicializamos los Tooltips para que puedan ser activados
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
    }, [])

    // Funcion que contiene los tipos de errores posibles en el formulario
    // --- setInputError: indica que campo es (Componente de error del campo)
    // --- number: tipo de error segun el array errors dentro de la funcion
    const throwError = (setInputError,number) => {
        const errors = [
            intl.formatMessage({id: "forms.field.empty"}),
            intl.formatMessage({id: "add.payments.error1"}),
            intl.formatMessage({id: "add.payments.error2"}),
            intl.formatMessage({id: "add.payments.error3"}),
            intl.formatMessage({id: "add.payments.error4"})
        ]

        setInputError(errors[number])
    }

    // Funcion que resetea los campos del formulario, del callback y 
    // esconde el modal de success (En caso de que este activo)
    const handleReset = () => {
        $('#modalSuccess').modal('hide')
        setUser('')
        setUserIDError('')
        setAmountError('')
        setCommentError('')
        setAmount(0)
        setInTax(false)
        setComment('')
        setToProvider(false)
        setCallback({})
    }

    // Funcion que hace valida la data del formulario
    // Si todo es validado hace un llamado a la funcion send() para hacer la peticion
    const handleSubmit = () => {
        let url ="/api/v1/get/profile/?p=payment_create"
        let errors = 0
        const fields = ['user', 'amount', 'inTax', 'comment', 'toProvider', 'currency']
        // Verifica que los campos no esten vacios
        if(user !== '' && amount > 0 && comment !== ''){
            for(let i = 0; i < fields.length; i++){
                switch (fields[i]) {
                    // Evita inyecciones
                    case 'user':
                        // eslint-disable-next-line
                        if(!user.match(/[^\w\s\-_,ñ\[\]]/g)){
                            url += `&u=${user.trim()}`
                        }else{
                            throwError(setUserIDError, 1)
                            errors += 1
                        }
                        break;
                    // Evita que sea algo diferente a un numero
                    case 'amount':
                        if(!isNaN(amount)){
                            url += `&amount=${amount}`
                        }else{
                            throwError(setAmountError, 1)
                            errors += 1
                        }
                        break
                    case 'inTax':
                        if(inTax){
                            url += "&amount_in_tax=1"
                        }
                        break
                    // Evita inyecciones
                    case 'comment':
                        if(!comment.match(/[^\w\s,.-]/g)){
                            url += `&comment=${comment}`
                        }else{
                            throwError(setCommentError, 3)
                            errors += 1
                        }
                        break
                    case 'toProvider':
                        if(toProvider){
                            url += "&payment_to_provider=1"
                        }
                        break
                    case 'currency':
                        url += `&currency=${currency}`
                        break
                    default:
                        break
                }
            }
            // Si no hay errores hace la peticion
            if(errors === 0){
                send(url)
            }
        // Si no son validos muestra errores debajo de los
        // campos que fallan en la validacion
        }else{
            if(user === ''){
                throwError(setUserIDError, 0)
            }
            // eslint-disable-next-line
            if(user.match(/[^\w\s\-_,ñ\[\]]/g)){
                throwError(setUserIDError, 1)
            }
            if(amount <= 0){
                throwError(setAmountError, 2)
            }
            if(comment === ''){
                throwError(setCommentError, 3)
            }
        }
    }

    // Funcion que hace la peticion una vez todo validado
    const send = (url) => {
        setLoader(true)
        // Hacemos la peticion
        axios.get(url,
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`
                }
            }
        ).then(res => {
            let respuesta = res.data
            // En caso de que la respuesta sea un error
            if(JSON.stringify(respuesta).match(/"(error)":"((\\"|[^"])*)"/i)){
                respuesta = JSON.stringify(respuesta).match(/"(error)":"((\\"|[^"])*)"/i)
                respuesta = respuesta[0].match(/"([\w\s])+"/g)
                setCallback(respuesta[1])
                setLoader(false)
                $('#modalError').modal('show')
            // Si todo sale bien al hacer la peticion
            }else{
                setCallback(respuesta.page.response.payment)
                setLoader(false)
                $('#modalSuccess').modal('show')
            }
        }).catch(error => {
            // Mostramos un modal indicando el error
            setCallback("add.payments.errorMsg")
            setLoader(false)
            $('#modalError').modal('show')
        })
    }

    return(
        <>
            <Titulo>
                <FormattedMessage id="header.menu.add.payments.admin"/>
            </Titulo>
            <div className="container">
                {/* Texto de ayuda */}
                <small className="text-muted">
                    <FormattedMessage id="add.payments.taxInfo"/>
                </small>
                <br className="d-none d-sm-block"/>
                <hr className="d-sm-none"/>
                {/* Texto de ayuda */}
                <small className="text-muted">
                    <FormattedMessage id="add.payments.providerInfo"/>
                </small>

                {/* Formulario de pago */}
                <form>
                    <div className="form-row mt-3">
                        {/* USER */}
                        <div className="col-12 col-sm-6 mb-3">
                            <label htmlFor="u-pay-c" className="p-d-block">
                                <FormattedMessage id="details.placeholder.username"/>:
                            </label>
                            <UsersInput value={user} onChange={e => setUser(e.value)}/>
                            <small className="text-danger">{userIDError}</small>
                        </div>

                        {/* Monto */}
                        <div className="col-12 col-sm-4 mb-3">
                            <label htmlFor="payment-create-amount">
                                <FormattedMessage id="payments.amount"/>
                            </label>
                            <InputNumber id="payment-create-amount" className="w-100 p-inputtext-sm" value={amount} onChange={e => {
                                setAmount(e.value)
                                setAmountError('')
                            }} min={0} showButtons mode="currency" currency={currency} />
                            <small className="text-danger">{amountError}</small>
                        </div>

                        <div className="col-12 col-sm-2 mb-3">
                            <label className="p-d-block">
                                <FormattedMessage id="currency"/>:
                            </label>
                            <Dropdown className="w-100 p-inputtext-sm" value={currency} options={currency_list} onChange={(e) => setCurrency(e.value)}/>
                        </div>

                        {/* Comentario */}
                        <div className="col-12 mb-3">
                            <label htmlFor="payment-create-comment">
                                <FormattedMessage id="payments.comment"/>
                            </label>
                            <InputText id="payment-create-comment" maxLength="30" className="form-control" value={comment} onChange={(e) => {
                                setComment(e.target.value)
                                setCommentError('')
                            }} />
                            <small className="text-danger">{commentError}</small>
                        </div>

                        {/* Botones Switch "in tax" y "set to provider" */}
                        <div className="col-12 mb-3 d-block d-sm-flex flex-wrap justify-content-around align-items-center">
                            <div className="mb-3 mb-sm-0 d-flex d-sm-block justify-content-end flex-row-reverse">
                                <label className="text-center text-sm-left mb-0 mb-sm-1 ml-3 ml-sm-0" htmlFor="payment-create-inTax">
                                    <FormattedMessage id="add.payments.inTax"/>
                                </label>
                                <InputSwitch id="payment-create-inTax" className="d-block m-sm-auto" checked={inTax} onChange={(e) => setInTax(e.value)} />
                            </div>
                            <div className="mb-3 mb-sm-0 d-flex d-sm-block justify-content-end flex-row-reverse">
                                <label className="text-center text-sm-left mb-0 mb-sm-1 ml-3 ml-sm-0" htmlFor="payment-create-toProvider">
                                    <FormattedMessage id="add.payments.toProvider"/>
                                </label>
                                <InputSwitch id="payment-create-toProvider" className="d-block m-sm-auto" checked={toProvider} onChange={(e) => setToProvider(e.value)} />
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div className="col-12 text-center">
                            <button 
                                    onClick={handleSubmit}
                                    type="button"
                                    data-toggle="tooltip" data-placement="left"
                                    title="Hubo un error al intentar actualizar el balance"
                                    data-trigger="manual"
                                    disabled={loader}
                                    className="button-device mr-3 d-inline-flex align-items-center btn btn-ariatel--primary">
                                <FormattedMessage id="add.payments.create"/>
                                <div className={
                                    loader ? "spinner-border ml-3 spinner-border-sm text-light" : 
                                        "d-none spinner-border ml-3 spinner-border-sm text-light"
                                    } 
                                        role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </form>

                {/* Modal que marca operaciones exitosas */}
                <div className="modal fade" id="modalSuccess" tabIndex="-1" role="dialog" aria-labelledby="modalSuccessLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-success font-weight-bold" id="modalSuccessLabel">
                                <FormattedMessage id="add.payments.success"/>
                            </h5>
                            <button type="button" className="close" onClick={handleReset} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body py-0">
                        <table className="table" ref={pdf}>
                            <tbody>
                                <tr>
                                    <th className="border-top-0" scope="row">Date</th>
                                    <td className="border-top-0">
                                        {(new Date(Date.now())).toLocaleDateString()} {(new Date(Date.now())).toLocaleTimeString()}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Payment Destination</th>
                                    <td>{toProvider ? "To Provider" : "From Client"}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Paid to User</th>
                                    <td>{user}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Currency</th>
                                    <td>{currency}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Payment ID</th>
                                    <td>{callback.hasOwnProperty('payment_id') ? callback.payment_id : null}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Tax</th>
                                    <td>{callback.hasOwnProperty('tax') ? 
                                    parseFloat(callback.tax) === 0 ? "-----" : parseFloat(callback.tax).toFixed(2)
                                    : null}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Amount</th>
                                    <td>{callback.hasOwnProperty('amount') ? parseFloat(callback.amount).toFixed(2) : null}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Gross</th>
                                    <td>{callback.hasOwnProperty('gross') ? parseFloat(callback.gross).toFixed(2) : null}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Comment</th>
                                    <td>{comment}</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                        <div className="modal-footer">
                            {/* Permite crear un pdf del recibo */}
                            <Pdf targetRef={pdf} filename="bill.pdf" options={
                                {
                                    unit: 'px',
                                    format: [341,341]
                                }
                            }>
                                {({ toPdf }) => <button type="button" className="btn btn-primary" onClick={toPdf}><FormattedMessage id="add.payments.generatePDF"/></button>}
                            </Pdf>
                            <button type="button" className="btn btn-ariatel--danger" onClick={handleReset}>
                                <FormattedMessage id="add.payments.close"/>
                            </button>
                        </div>
                        </div>
                    </div>
                </div>
                
                {/* Modal que marca operaciones fallidas */}
                <div className="modal fade" id="modalError" tabIndex="-1" role="dialog" aria-labelledby="modalErrorLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-danger font-weight-bold" id="modalErrorLabel">
                                <FormattedMessage id="add.payments.failed"/>
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {callback &&<FormattedMessage id={JSON.stringify(callback).replace(/["\\]/g, "")}/>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-ariatel--danger" data-dismiss="modal">
                                <FormattedMessage id="add.payments.close"/>
                            </button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentCreateAdmin