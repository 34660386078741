import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import Titulo from '../../tools/Titulo'
import $ from 'jquery'
import BoFa from '../../../decoraciones/bancos/Bank-of-America-logo.png'
import Chase from '../../../decoraciones/bancos/Chase.png'
import Bancolombia from '../../../decoraciones/bancos/bancolombia.png'
import Banitsmo from '../../../decoraciones/bancos/banitsmo.png'
import {FormattedMessage, injectIntl} from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown } from 'primereact/dropdown'
import secureStorage from '../../../../tools/SecureStorage'
import { InputText } from 'primereact/inputtext'
import { AuthContext } from '../../../AuthContext'
import CurrencyInput from 'react-currency-input-field'
import ReactGA from 'react-ga'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que permite que un usuario reporte su pago con un recibo de tipo imagen o pdf
   -----------------------------------------------------------------------------------------------
*/
function Transfer({intl}){
    const data = useContext(AuthContext)
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState('')
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState('')
    const [bank, setBank] = useState(null)
    const [email, setEmail] = useState('')
    const [amount, setAmount] = useState('')
    const banks = [
        {label: "Bank Of America", value: "BofA"},
        {label: "Chase", value: "Chase Bank"},
        {label: "Banitsmo", value: "Banitsmo"}
    ]

    if(data.m2.currency === "COP"){
        banks.push({label: "Bancolombia", value: "Bancolombia"})
    }

    // Inicializamos los tooltips para marcar errores
    // Establecemos eventos de resize en la data sobre los bancos (AUN EN CONSTRUCCION)
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
    }, [])

    // Funcion que trunca el texto "n" que se excede de "len" letras
    const truncate = (n, len) => {
        let ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase();
        let filename = n.replace('.'+ext,'');
        if(filename.length <= len) {
            return n;
        }
        filename = filename.substr(0, len) + (n.length > len ? '(...)' : '');
        return filename + '.' + ext;
    }

    // Funcion que envia la data del formulario para crear el reporte de pago
    const handleSubmit = () => {
        setLoader(true)
        // Chequeamos que los campos no esten vacios (Hacemos la validacion en el back)
        if(file !== null && bank !== null && parseFloat(amount.replace(",", ".")) > 0 && !isNaN(parseFloat(amount.replace(",", "."))) && email !== ""){
            // Instanciamos un objeto de tipo FormData donde añadiremos todos los datos
            let form_data = new FormData()
            form_data.append('invoice', file, file.name)
            form_data.append('bank', bank)
            form_data.append('email', email)
            form_data.append('amount', parseFloat(amount.replace(",", ".")))
            // Enviamos la data
            axios.post("/add/payment/",form_data,
                {
                    headers: {
                        "Authorization": `Bearer ${secureStorage.getItem("access")}`,
                        'content-type': 'multipart/form-data'
                    }
                }
            ).then(resp => {
                // Borramos los datos
                setFile(null)
                setFileName('')
                setBank(null)
                setEmail('')
                setAmount('')
                $('#payment_invoice').val('')
                setLoader(false)
                $('#modal_success').modal('toggle')
            }).catch(error => {
                // Si hay errores asignamos el error a nuestro estado Error
                if(error.response.status === 400){
                    setError(intl.formatMessage({id: 'add.payments.error.filetype'}))
                }else if(error.response.status === 401){
                    setError(intl.formatMessage({id: 'add.payments.error.password'}))
                }else{
                    setError(intl.formatMessage({id: 'add.payments.error.server'}))
                }
                // Borramos los datos
                setFile(null)
                setFileName('')
                setLoader(false)
                setAmount('')
                // Mostramos el error en un modale
                $('#modal_failed').modal('toggle')
            })
        }else{
            // Si los campos estan vacios mostramos un tooltip de Error
            setLoader(false)
            $('[data-toggle="tooltip"]').tooltip('show')
            setTimeout(() => {
                $('[data-toggle="tooltip"]').tooltip('hide')
            }, 3000)
        }
    }

    return(
        <>
            <>
                <Titulo className="d-flex justify-content-center justify-content-lg-between align-items-end">
                    <FormattedMessage id="add.payments.create.transfer"/>
                    {/* {data.m2.default_curr === data.m2.currency ?
                        null
                    :
                        <small className="font-italic d-none d-lg-block" style={{fontSize: "1rem"}}><FormattedMessage id="exchange.msg"/> {data.m2.default_curr}: <b>{Intl.NumberFormat().format(data.m2.exchange_rate)} {data.m2.currency}</b></small>
                    } */}
                </Titulo>
                
                <div className="container-fluid">
                    <section className="row mb-4 justify-content-center"> 
                        <form className="form-row col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                            <div className="col-12">
                            {/* Correo electronico */}
                                <div className="form-group">
                                    <label htmlFor="email" className="text-muted">
                                        <FormattedMessage id="profile.email"/>:
                                    </label>
                                    <InputText placeholder="email@example.com" keyfilter="email" id="email" className="w-100" value={email} onChange={e => setEmail(e.target.value)}/>
                                </div>
                            </div>
                            <div className="col-12">
                                {/* Banco en el que hizo la transferencia */}
                                <div className="form-group">
                                    <label htmlFor="bank-drop" className="text-muted">
                                        <FormattedMessage id="bank"/>:
                                    </label>
                                    <Dropdown value={bank} name="bank-drop" id="bank-drop"
                                        onChange={e => setBank(e.value)}
                                        options={banks}
                                        required
                                        className="w-100"
                                        placeholder={intl.formatMessage({id: "bank.choose"})}
                                    />
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                            {/* Monto transferido */}
                                <div className="form-group">
                                    <label htmlFor="amount" className="text-muted">
                                        <FormattedMessage id="payments.amount"/> {bank === null ? "" : bank === "Bancolombia" ? "(COP)" : "(USD)"}:
                                    </label>
                                    <CurrencyInput disabled={bank === null} prefix="$" groupSeparator="." decimalSeparator="," allowNegativeValue={false} step={bank === "Bancolombia" ? 10000 : 10} id="amount" name="amount" value={amount} className="w-100 p-inputtext" placeholder={intl.formatMessage({id: "enter.amount"})} decimalsLimit={2} onValueChange={(val, name) => setAmount(val)}/>
                                </div>
                            </div>

                            {/* Documento recibo */}
                            <div className="col-12 mb-3">
                                <label htmlFor="payment_invoice" 
                                    style={{minHeight:'10rem'}} 
                                    className="
                                    mb-0 btn btn-ariatel--primary text-white text-center 
                                    d-flex flex-column justify-content-center align-items-center"
                                >
                                    <FontAwesomeIcon icon="upload" size="2x" className="mb-3"/>
                                    {fileName === '' ?  
                                        <FormattedMessage id="add.payments.choose.file"/> : 
                                        truncate(fileName, 25)
                                    }                             
                                </label>
                                <input type="file"
                                    id="payment_invoice"
                                    name="payment_invoice"
                                    className="d-none"
                                    accept="image/png, image/jpeg, image/jpg, application/pdf" 
                                    onChange={e => {
                                        if(e.target.files[0]){
                                            setFile(e.target.files[0])
                                            setFileName(e.target.files[0].name)
                                        }
                                    }}
                                    required
                                />
                            </div>

                            <div className="col-12">
                                {/* Submit Button */}
                                <button 
                                    onClick={handleSubmit}
                                    type="button"
                                    data-toggle="tooltip" data-placement="top"
                                    title={intl.formatMessage({id: 'add.payments.empty'})}
                                    data-trigger="manual"
                                    disabled={loader}
                                    className="w-100 d-flex justify-content-center align-items-center btn btn-outline-ariatel--primary"
                                >
                                    <FormattedMessage id="add.payments.report"/>
                                    <div className={
                                        loader ? "spinner-border ml-3 spinner-border-sm text-primary" : 
                                            "d-none spinner-border ml-3 spinner-border-sm text-primary"
                                        } 
                                            role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                    </section>

                    {data.m2.default_curr === data.m2.currency ?
                        null
                    :
                        <small className="text-center text-muted pb-2 border-bottom mb-2 font-italic d-block d-lg-none" style={{fontSize: ".8rem"}}><FormattedMessage id="exchange.msg"/> {data.m2.default_curr}: <b>{Intl.NumberFormat().format(data.m2.exchange_rate)} {data.m2.currency}</b></small>
                    }
                
                    <section className="row">
                        <div className="col-12">
                            <div className="card d-block shadow-sm">
                                <div className="card-body">
                                    <h4 className="text-center text-ariatel--default">
                                        <FormattedMessage id="add.payments.bank.title"/>
                                    </h4>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-12 col-lg-3 mb-2 mb-lg-0">
                                            <div className="nav flex-lg-column nav-pills nav-fill nav-able-banks-pay" id="v-pills-banks-pay-nav" role="tablist" aria-orientation="vertical">
                                                <a className="nav-link active" id="v-pills-bofa-tab" data-toggle="pill" href="#v-pills-bofa" role="tab" aria-controls="v-pills-bofa" aria-selected="true">Bank of America</a>
                                                <a className="nav-link" id="v-pills-chase-bank-tab" data-toggle="pill" href="#v-pills-chase-bank" role="tab" aria-controls="v-pills-chase-bank" aria-selected="false">Chase Bank</a>
                                                {data.m2.currency === "COP" ? <a className="nav-link" id="v-pills-bancolombia-co-tab" data-toggle="pill" href="#v-pills-bancolombia-co" role="tab" aria-controls="v-pills-bancolombia-co" aria-selected="false">Bancolombia</a> : null}
                                                <a className="nav-link" id="v-pills-bancolombia-pa-tab" data-toggle="pill" href="#v-pills-bancolombia-pa" role="tab" aria-controls="v-pills-bancolombia-pa" aria-selected="false">Bancolombia Panamá</a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-9">
                                            <div className="tab-content bg-light rounded border p-3" id="v-pills-banks-pay">
                                                <div className="tab-pane fade show active" id="v-pills-bofa" role="tabpanel" aria-labelledby="v-pills-bofa-tab">
                                                    <BankTemplate logo={BoFa} alt="Bank of America Logo">
                                                        <table className="table table-sm table-hover d-none d-sm-table">
                                                            <tbody>
                                                                <tr>
                                                                    <th className="border-0" scope="row">
                                                                        <FormattedMessage id="banks.info.accountname"/>
                                                                    </th>
                                                                    <td className="border-0">ARIATEL CORPORATION</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <FormattedMessage id="banks.info.accountnumber"/>
                                                                    </th>
                                                                    <td>8980 7705 1003</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <FormattedMessage id="banks.info.abaoriban"/>
                                                                    </th>
                                                                    <td>026009593</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <FormattedMessage id="banks.info.swiftcode"/>
                                                                    </th>
                                                                    <td>BOFAUS3N</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <FormattedMessage id="banks.info.ach"/>
                                                                    </th>
                                                                    <td>063100277</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <FormattedMessage id="banks.info.address"/>
                                                                    </th>
                                                                    <td><address className="mb-0">9705 NW 41 ST DORAL, FL 33178</address></td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <FormattedMessage id="banks.info.phone"/>
                                                                    </th>
                                                                    <td>+1 800-729-9473</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.info.accountname"/>:
                                                            </b>
                                                            <br/><span className="ml-2">ARIATEL CORPORATION</span>
                                                        </p>
                                                        <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.info.accountnumber"/>:
                                                            </b>
                                                            <br/><span className="ml-2">8980 7705 1003</span>
                                                        </p>
                                                        <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.info.abaoriban"/>:
                                                            </b>
                                                            <br/><span className="ml-2">026009593</span>
                                                        </p>
                                                        <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.info.swiftcode"/>:
                                                            </b>
                                                            <br/><span className="ml-2">BOFAUS3N</span>
                                                        </p>
                                                        <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.info.ach"/>:
                                                            </b>
                                                            <br/><span className="ml-2">063100277</span>
                                                        </p>
                                                        <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.info.address"/>:
                                                            </b>
                                                            <br/><span className="ml-2">9705 NW 41 ST DORAL, FL 33178</span>
                                                        </p>
                                                        <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.info.phone"/>:
                                                            </b>
                                                            <br/><span className="ml-2">+1 800-729-9473</span>
                                                        </p>
                                                    </BankTemplate>
                                                </div>
                                                <div className="tab-pane fade" id="v-pills-chase-bank" role="tabpanel" aria-labelledby="v-pills-chase-bank-tab">
                                                    <BankTemplate logo={Chase} alt="Chase Bank Logo">
                                                        <table className="table table-sm table-hover d-none d-sm-table">
                                                            <tbody>
                                                                <tr>
                                                                    <th className="border-0" scope="row">
                                                                        <FormattedMessage id="banks.info.accountname"/>
                                                                    </th>
                                                                    <td className="border-0">ARIATEL CORPORATION</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <FormattedMessage id="banks.info.accountnumber"/>
                                                                    </th>
                                                                    <td>855 806 365</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <FormattedMessage id="banks.info.abaoriban"/>
                                                                    </th>
                                                                    <td>267084131</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <FormattedMessage id="banks.info.swiftcode"/>
                                                                    </th>
                                                                    <td>CHASUS33</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <FormattedMessage id="banks.info.address"/>
                                                                    </th>
                                                                    <td><address className="mb-0">2495 NW 87th Ave Doral, FL 33172</address></td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <FormattedMessage id="banks.info.phone"/>
                                                                    </th>
                                                                    <td>+1 800-242-7383</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.info.accountname"/>:
                                                            </b>
                                                            <br/><span className="ml-2">ARIATEL CORPORATION</span>
                                                        </p>
                                                        <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.info.accountnumber"/>:
                                                            </b>
                                                            <br/><span className="ml-2">855 806 365</span>
                                                        </p>
                                                        <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.info.abaoriban"/>:
                                                            </b>
                                                            <br/><span className="ml-2">267084131</span>
                                                        </p>
                                                        <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.info.swiftcode"/>:
                                                            </b>
                                                            <br/><span className="ml-2">CHASUS33</span>
                                                        </p>
                                                        <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.info.address"/>:
                                                            </b>
                                                            <br/><span className="ml-2">2495 NW 87th Ave Doral, FL 33172</span>
                                                        </p>
                                                        <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.info.phone"/>:
                                                            </b>
                                                            <br/><span className="ml-2">+1 800-242-7383</span>
                                                        </p>
                                                    </BankTemplate>
                                                </div>
                                                {data.m2.currency === "COP" ? 
                                                    <div className="tab-pane fade" id="v-pills-bancolombia-co" role="tabpanel" aria-labelledby="v-pills-bancolombia-co-tab">
                                                        <BankTemplate logo={Bancolombia} alt="Bancolombia Logo">
                                                            <table className="table table-sm table-hover d-none d-sm-table">
                                                                <tbody>
                                                                <tr>
                                                                    <th className="border-0" scope="row">
                                                                            <FormattedMessage id="banks.info.accountname"/>
                                                                        </th>
                                                                        <td className="border-0">GRUPO EMPRESARIAL VIDA SAS</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">
                                                                            <FormattedMessage id="banks.info.accountnumber.ahorro"/>
                                                                        </th>
                                                                        <td>131 65907 269</td>
                                                                    </tr>
                                                                    {/* <tr>
                                                                        <th scope="row">
                                                                            <FormattedMessage id="banks.info.accountnumber.corriente"/>
                                                                        </th>
                                                                        <td>666 39439 766</td>
                                                                    </tr> */}
                                                                    <tr>
                                                                        <th scope="row">
                                                                            <FormattedMessage id="banks.info.swiftcode"/>
                                                                        </th>
                                                                        <td>COLOCOPM</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">
                                                                            <FormattedMessage id="banks.info.nit"/>
                                                                        </th>
                                                                        <td>900385208 - 1</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">
                                                                            <FormattedMessage id="banks.info.address"/>
                                                                        </th>
                                                                        <td><address className="mb-0">Calle 39 # 24 - 07, Bogotá - Colombia</address></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">
                                                                            <FormattedMessage id="banks.info.phone"/>
                                                                        </th>
                                                                        <td>+57 1-340-7802</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <p className="d-sm-none">
                                                                <b>
                                                                    <FormattedMessage id="banks.info.accountname"/>:
                                                                </b>
                                                                <br/><span className="ml-2">GRUPO EMPRESARIAL VIDA SAS</span>
                                                            </p>
                                                            <hr className="my-2 d-sm-none"/>
                                                            <p className="d-sm-none">
                                                                <b>
                                                                    <FormattedMessage id="banks.info.accountnumber.ahorro"/>:
                                                                </b>
                                                                <br/><span className="ml-2">131 65907 269</span>
                                                            </p>
                                                            <hr className="my-2 d-sm-none"/>
                                                            {/* <p className="d-sm-none">
                                                                <b>
                                                                    <FormattedMessage id="banks.info.accountnumber.corriente"/>:
                                                                </b>
                                                                <br/><span className="ml-2">666 39439 766</span>
                                                            </p>
                                                            <hr className="my-2 d-sm-none"/> */}
                                                            <p className="d-sm-none">
                                                                <b>
                                                                    <FormattedMessage id="banks.info.swiftcode"/>:
                                                                </b>
                                                                <br/><span className="ml-2">COLOCOPM</span>
                                                            </p>
                                                            <hr className="my-2 d-sm-none"/>
                                                            <p className="d-sm-none">
                                                                <b>
                                                                    <FormattedMessage id="banks.info.nit"/>:
                                                                </b>
                                                                <br/><span className="ml-2">900385208 - 1</span>
                                                            </p>
                                                            
                                                            <hr className="my-2 d-sm-none"/>
                                                            <p className="d-sm-none">
                                                                <b>
                                                                    <FormattedMessage id="banks.info.address"/>:
                                                                </b>
                                                                <br/><span className="ml-2">Calle 39 # 24 - 07, Bogotá - Colombia</span>
                                                            </p>
                                                            <hr className="my-2 d-sm-none"/>
                                                            <p className="d-sm-none">
                                                                <b>
                                                                    <FormattedMessage id="banks.info.phone"/>:
                                                                </b>
                                                                <br/><span className="ml-2">+57 1-340-7802</span>
                                                            </p>
                                                        </BankTemplate>
                                                    </div>
                                                : null}
                                                <div className="tab-pane fade" id="v-pills-bancolombia-pa" role="tabpanel" aria-labelledby="v-pills-bancolombia-pa-tab">
                                                    <BankTemplate logo={Banitsmo} alt="Bancolombia Panamá Logo">
                                                        <table className="table table-sm table-hover d-none d-sm-table">
                                                            <tbody>
                                                                <tr>
                                                                    <th className="border-0" scope="row">
                                                                        <FormattedMessage id="banks.info.accountname"/>
                                                                    </th>
                                                                    <td className="border-0">ARIA TEL</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <FormattedMessage id="banks.info.accountnumber"/>
                                                                    </th>
                                                                    <td>801 00004 914</td>
                                                                </tr>
                                                                {/* <tr>
                                                                    <th scope="row">
                                                                        <FormattedMessage id="banks.info.abaoriban"/>
                                                                    </th>
                                                                    <td>N/A</td>
                                                                </tr> */}
                                                                <tr>
                                                                    <th scope="row">
                                                                        <FormattedMessage id="banks.info.swiftcode"/>
                                                                    </th>
                                                                    <td>COLOPAPA</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <FormattedMessage id="banks.info.address"/>
                                                                    </th>
                                                                    <td><address className="mb-0">Cl. #47 y Aquilino de la Guardia. Plaza Marbella - Edf. Bancolombia, Rep. de Panamá</address></td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <FormattedMessage id="banks.city.state"/>
                                                                    </th>
                                                                    <td><address className="mb-0 text-capitalize">Panamá - República de Panamá</address></td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <FormattedMessage id="banks.info.phone"/>
                                                                    </th>
                                                                    <td>+507 2690740, +507 2089700</td>
                                                                </tr>
                                                                {/* <tr>
                                                                    <th scope="row">
                                                                        <FormattedMessage id="banks.info.contact"/>
                                                                    </th>
                                                                    <td>Beatriz Paola Rodriguez Gil - Jeferson Steven Lengua Vivas</td>
                                                                </tr> */}
                                                            </tbody>
                                                        </table>
                                                        <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.info.accountname"/>:
                                                            </b>
                                                            <br/><span className="ml-2">ARIA TEL</span>
                                                        </p>
                                                        <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.info.accountnumber"/>:
                                                            </b>
                                                            <br/><span className="ml-2">801 00004 914</span>
                                                        </p>
                                                        {/* <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.info.abaoriban"/>:
                                                            </b>
                                                            <br/><span className="ml-2">N/A</span>
                                                        </p> */}
                                                        <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.info.swiftcode"/>:
                                                            </b>
                                                            <br/><span className="ml-2">COLOPAPA</span>
                                                        </p>
                                                        <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.city.state"/>:
                                                            </b>
                                                            <br/><span className="ml-2">Cl. #47 y Aquilino de la Guardia. Plaza Marbella - Edf. Bancolombia, Rep. de Panamá</span>
                                                        </p>
                                                        <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.city.state"/>:
                                                            </b>
                                                            <br/><span className="ml-2 text-capitalize">Panamá - República de Panamá</span>
                                                        </p>
                                                        <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.info.phone"/>:
                                                            </b>
                                                            <br/><span className="ml-2">+507 2690740, +507 2089700</span>
                                                        </p>
                                                        {/* <hr className="my-2 d-sm-none"/>
                                                        <p className="d-sm-none">
                                                            <b>
                                                                <FormattedMessage id="banks.info.contact"/>:
                                                            </b>
                                                            <br/><span className="ml-2">Beatriz Paola Rodriguez Gil - Jeferson Steven Lengua Vivas</span>
                                                        </p> */}
                                                    </BankTemplate>
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>

            {/* Modals que mostraremos luego de realizar una operacion exitosa */}
            <div className="modal fade" id="modal_success" tabIndex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-success" id="modal"><FormattedMessage id="add.payments.success.user.title"/></h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <FormattedMessage id="add.payments.success.user.text"/>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-ariatel--danger" data-dismiss="modal"><FormattedMessage id="add.payments.close"/></button>
                    </div>
                    </div>
                </div>
            </div>
            
            {/* Modals que mostraremos luego de realizar una operacion fallida */}
            <div className="modal fade" id="modal_failed" tabIndex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-danger" id="modal"><FormattedMessage id="add.payments.failed.user.title"/></h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {error}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-ariatel--danger" data-dismiss="modal"><FormattedMessage id="add.payments.close"/></button>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const BankTemplate = (props) => {
    return(
        <div className="row w-100 mx-0">
            <div className="d-none col-md-3 border-right d-md-inline-flex justify-content-center align-items-center">
                <img className="mw-100" alt={props.alt || ""} src={props.logo}/>
            </div>
            <div className="col-12 col-md px-0 px-md-3">
                {props.children}
            </div>
        </div>
    )
}

export default injectIntl(Transfer)