import React from 'react'
import { useState } from 'react'
import axios from 'axios'
import Titulo from '../compartidos/tools/Titulo'
import {FormattedMessage, useIntl} from 'react-intl'
import ButtonLoader from '../compartidos/tools/ButtonLoader'
import secureStorage from '../../tools/SecureStorage'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import UsersInput from './UsersInput'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que permite ver los datos de un usuario dado su M2 USERNAME o M2 ID
   -----------------------------------------------------------------------------------------------
*/
function UserDetails() {
    const intl = useIntl()
    const [details, setDetails] = useState({})
    const [user, setUser] = useState("")
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState(false)
    const [tarifas, setTarifas] = useState([])

    const getUrl = () => {
        let url = "/api/v1/get/profile/?p=user_details_get&u="
        url += user.trim()
        return url
    }

    const _getUserDetails = (e) => {
        e.preventDefault()
        setDetails({})
        setTarifas({})
        setLoader(true)
        setError(false)

        // eslint-disable-next-line
        if(user !== "" && !user.match(/[^\w\s\-_,ñ\[\]]/g)){
            axios.get(getUrl(),
                {
                    headers: {
                        "Authorization": `Bearer ${secureStorage.getItem("access")}`
                    }
                }
            ).then(res => {
                if(res.data.page){
                    let data = res.data.page
                    if("u_tariffs" in data){
                        const tariff_table_info = data.u_tariffs.map((el) => {
                            if(data.u_devices.some((e) => e.hasOwnProperty(el.device))){
                                return (
                                    {
                                        device: `${el.device}, ${data.u_devices.filter((e) => e[el.device])[0][el.device]}`,
                                        tariff: `${el.tariff_id}, ${el.tariff_name}`,
                                    }
                                )
                            }else{
                                return undefined
                            }
                        })
                        setTarifas(tariff_table_info.filter(e => !!e))
                    }
                    setLoader(false)
                    setDetails(data)
                }else{
                    setLoader(false)
                    setError(true)
                }
            }).catch(error => {
                setLoader(false)
                setError(true)
            })
        }else{
            setTimeout(() => {
                setLoader(false)
                setError(true)
            }, 1000)
        }

    }

    return(
        <>
            <Titulo>
                <FormattedMessage id="header.menu.userInformation"/>
            </Titulo>
            
            <form method="GET" className="mb-3" onSubmit={(e) => _getUserDetails(e)}>
                <div className="row align-items-end justify-content-center">

                    <div className="col-7 col-sm-8 col-md-4">
                        <label htmlFor="u-details-auto" className="p-d-block">
                            <FormattedMessage id="details.placeholder.username"/>:
                        </label>
                        <UsersInput value={user} onChange={e => setUser(e.value)}/>
                    </div>

                    <div className="col-5 col-sm-4 col-md-2">
                        <ButtonLoader
                            loader={loader} className="button-tooltip-loader btn btn-block btn-ariatel--primary btn-sm" 
                            type="submit" style={{minHeight: '35px'}} showError={error}
                            onClick={(e) => _getUserDetails(e)} tooltipPlace="top"
                            errorTitle={intl.formatMessage({id:"devices.error.search"})}
                        >
                            <FormattedMessage id="calls.search" />
                        </ButtonLoader> 
                    </div>
                </div>
            </form>

            <section className="row justify-content-center mb-3">
                <div className="col-12 col-md-8 col-xl-6">
                    <div className="card shadow-sm user-details-card-tabs">
                        <div className="card-body">
                            <ul className="nav nav-tabs mb-3 nav-fill" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="general-details" data-toggle="tab" href="#general-details-content" role="tab" aria-controls="general-details-content" aria-selected="true">General</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="m2-details" data-toggle="tab" href="#m2-details-content" role="tab" aria-controls="m2-details-content" aria-selected="true">Switch</a>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="general-details-content" role="tabpanel" aria-labelledby="general-details">
                                    <table className="table table-sm table-hover">
                                        <tbody>
                                            <tr>
                                                <th scope="row" className="text-capitalize border-0">
                                                    <FormattedMessage id="profile.details.username"/>
                                                </th>
                                                <td className="border-0">
                                                    {details.details === undefined ? "" : 
                                                    details.details.other_details.username === null ?
                                                    "-----" : details.details.other_details.username}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="text-capitalize">
                                                    <FormattedMessage id="profile.details.first_name"/>
                                                </th>
                                                <td>
                                                    {details.details === undefined ? "" : 
                                                    details.details.other_details.first_name === null ?
                                                    "-----" : details.details.other_details.first_name}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="text-capitalize">
                                                    <FormattedMessage id="profile.details.surname"/>
                                                </th>
                                                <td>
                                                    {details.details === undefined ? "" : 
                                                    details.details.other_details.surname === null ?
                                                    "-----" : details.details.other_details.surname}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="text-capitalize">balance</th>
                                                <td>
                                                    {details.details === undefined ? "" : 
                                                    details.details.main_detail.balance === null ?
                                                    "-----" : details.details.main_detail.balance}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="text-capitalize">
                                                    <FormattedMessage id="currency"/>
                                                </th>
                                                <td>
                                                    {details.details === undefined ? "" : 
                                                    details.details.main_detail.balance_currency === null ?
                                                    "-----" : details.details.main_detail.balance_currency}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="tab-pane fade flex-wrap" id="m2-details-content" role="tabpanel" aria-labelledby="m2-details">
                                    <table className="table table-sm table-hover">
                                        <tbody>
                                            <tr>
                                                <th scope="row" className="text-capitalize border-0">
                                                    <FormattedMessage id="calls.user_id"/>
                                                </th>
                                                <td className="border-0">
                                                    {details.userid === undefined ? "" : 
                                                    details.userid === null ?
                                                    "-----" : details.userid}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="text-capitalize">
                                                    <FormattedMessage id="utype"/>
                                                </th>
                                                <td>
                                                    {details.u_user_type === undefined ? "" : 
                                                    details.u_user_type === null ?
                                                    "-----" : details.u_user_type}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    <FormattedMessage id="profile.details.personalid"/>
                                                </th>
                                                <td>
                                                    {details.details === undefined ? "" : 
                                                    details.details.other_details.personalid === null ?
                                                    "-----" : details.details.other_details.personalid}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    <FormattedMessage id="profile.agreement.number"/>
                                                </th>
                                                <td>
                                                    {details.details === undefined ? "" : 
                                                    details.details.other_details.agreement_number === null ?
                                                    "-----" : details.details.other_details.agreement_number}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="text-capitalize">
                                                    <FormattedMessage id="profile.agreement.date"/>
                                                </th>
                                                <td>
                                                    {details.details === undefined ? "" : 
                                                    details.details.other_details.agreement_date === null ?
                                                    "-----" : details.details.other_details.agreement_date}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className="text-capitalize">
                                                    <FormattedMessage id="profile.taxation.country"/>
                                                </th>
                                                <td>
                                                    {details.details === undefined ? "" : 
                                                    details.details.other_details.taxation_country === null ?
                                                    "-----" : details.details.other_details.taxation_country}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    <FormattedMessage id="profile.vat.regularNumber"/>
                                                </th>
                                                <td>
                                                    {details.details === undefined ? "" : 
                                                    details.details.other_details.vat_reg_number === null ?
                                                    "-----" : details.details.other_details.vat_reg_number}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    <FormattedMessage id="profile.vat.percent"/>
                                                </th>
                                                <td>
                                                    {details.details === undefined ? "" : 
                                                    details.details.other_details.vat_percent === null ?
                                                    "-----" : details.details.other_details.vat_percent}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="w-100 mt-2">
                                        <DataTable value={tarifas} rowHover>
                                            <Column field="device" header={intl.formatMessage({id: "devices"})}/>
                                            <Column field="tariff" header={intl.formatMessage({id: "tariffs"})}/>
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default UserDetails