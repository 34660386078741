import React, { useContext, useEffect, useRef, useState } from 'react'
import Titulo from '../../../tools/Titulo'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import { InputText } from "primereact/inputtext"
import { InputMask } from 'primereact/inputmask'
import PhoneInput from 'react-phone-input-2'
import { Dropdown } from 'primereact/dropdown'
import { AuthContext } from '../../../../AuthContext'
import ButtonLoader from '../../../tools/ButtonLoader'
import axios from 'axios'
import secureStorage from '../../../../../tools/SecureStorage'
import $ from 'jquery'
import { Link } from "react-router-dom"
import { FormattedMessage, useIntl } from 'react-intl'
import ReactGA from 'react-ga'
import {CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

export default function AddCreditCard(props){
    const data = useContext(AuthContext)
    const intl = useIntl()
    const [cvc, setCvc] = useState("")
    const [expiry, setExpiry] = useState("")
    const [number, setNumber] = useState("")
    const [focus, setFocus] = useState("")
    const [firstName, setFirstName] = useState("")
    const [doctype, setDoctype] = useState("CC")
    const [docNumber, setDocNumber] = useState("")
    const [pubk, setPubk] = useState(null)
    const [phone, setPhone] = useState("")
    const [country, setCountry] = useState("")
    const [city, setCity] = useState("")
    const [email, setEmail] = useState("")
    const [address, setAddress] = useState("")
    const [errorGeneral, setErrorGeneral] = useState("error.add.cc");
    const [loader, setLoader] = useState(false)
    const form = useRef(null)
    const [errors, setErrors] = useState({
        "docNumber": "",
        "email": "",
        "firstName": "",
        "phone": "",
        "address": "",
        "city": "",
        "expiry": "",
        "cvc": "",
        "number": ""
    })

    const fields_labels = {
        "cvc": "CVC",
        "expiry": intl.formatMessage({id: "cc.exp"}),
        "number": intl.formatMessage({id: "cc.number"}),
        "firstName": intl.formatMessage({id: "cc.name"}),
        "docNumber": intl.formatMessage({id: "pse.doc"}),
        "phone": intl.formatMessage({id: "profile.registration.reg_phone"}),
        "city": intl.formatMessage({id: "profile.registration.reg_city"}),
        "email":  intl.formatMessage({id: "email"}),
        "address":  intl.formatMessage({id: "profile.registration.reg_address"})
    }

    const errores_generales = {
        "forms.passReset.error": intl.formatMessage({id: "forms.passReset.error"}),
        "forms.invalid": intl.formatMessage({id: "forms.invalid"}),
        "error.add.cc.repeated": intl.formatMessage({id: "error.add.cc.repeated"}),
        "error.add.cc": intl.formatMessage({id: "error.add.cc"})
    }

    const docs_availables = [
        {"label": "CC", "value": "CC"},
        {"label": "CE", "value": "CE"},
        {"label": "Pasaporte", "value": "PA"},
        {"label": "TI", "value": "TI"},
        {"label": "NIT", "value": "NIT"}
    ]

    

    const valid_expiration = (date) => {
        let valid = true
        let msg = ""
        
        const date_obj = new Date()

        const [month, year] = date.split("/")
        
        if(parseInt(month) > 12){
            msg = intl.formatMessage({id: "cc.mm.error"});
            valid = false;
        }

        if(parseInt(year) < (date_obj.getFullYear() - 2000)){
            msg = intl.formatMessage({id: "cc.yy.error"});
            valid = false;
        }else if(parseInt(year) === (date_obj.getFullYear() - 2000) && parseInt(month) < (date_obj.getMonth() + 1)){
            msg = intl.formatMessage({id: "cc.date.error"});
            valid = false;
        }

        return [valid, msg]
    }

    const is_valid = () => {
        let valid = true
        let errors_found = {}

        if(firstName === ""){ 
            errors_found["firstName"] = intl.formatMessage({id: "forms.field.empty"});
            valid = false;
        }

        if(docNumber === "" || isNaN(docNumber)){ 
            errors_found["docNumber"] = intl.formatMessage({id: "add.payments.error1"});
            valid = false;
        }

        if(email === ""){ 
            errors_found["email"] = intl.formatMessage({id: "forms.field.empty"});
            valid = false;
        }

        if(phone === "" || isNaN(phone) || phone.length < 5){ 
            errors_found["phone"] = intl.formatMessage({id: "add.payments.error1"});
            valid = false;
        }

        if(cvc === "" || isNaN(cvc) || cvc.length < 3){ 
            errors_found["cvc"] = intl.formatMessage({id: "add.payments.error1"});
            valid = false;
        }

        if(address === ""){ 
            errors_found["address"] = intl.formatMessage({id: "forms.field.empty"});
            valid = false;
        }

        if(country === ""){ 
            errors_found["country"] = intl.formatMessage({id: "forms.field.empty"});
            valid = false;
        }

        if(city === ""){ 
            errors_found["city"] = intl.formatMessage({id: "forms.field.empty"});
            valid = false;
        }

        if(firstName === ""){ 
            errors_found["firstName"] = intl.formatMessage({id: "forms.field.empty"});
            valid = false;
        }

        if(number === "" || isNaN(number) || number.length < 8){ 
            errors_found["number"] = intl.formatMessage({id: "add.payments.error1"});
            valid = false;
        }

        const [is_exp_valid, error_msg] = valid_expiration(expiry)

        if(!is_exp_valid){
            errors_found["expiry"] = error_msg;
            valid = is_exp_valid;
        }
        
        setErrors(errors_found)

        return valid
    }

    const handleReset = () => {
        setCvc("")
        setExpiry("")
        setNumber("")
        setFocus("")
        setFirstName("")
        setDoctype("CC")
        setDocNumber("")
        setPhone("")
        setCity("")
        setCountry("")
        setEmail("")
        setAddress("")
    }
    

    const handleSubmit = (e) => {
        e.preventDefault()

        if(is_valid()){
            setLoader(true)
            
            const [m, y] = expiry.split("/")

            axios.post('https://production.wompi.co/v1/tokens/cards',
                {
                    "number": number,
                    "cvc": cvc,
                    "exp_month": m,
                    "exp_year": y,
                    "card_holder": firstName
                },
                {
                    headers: {
                        "Authorization": `Bearer ${pubk}`,
                        "Content-Type": "application/json"
                    }
                }
            ).then(res => {
                axios.post('/wompi/add-payment-source/', 
                    {
                        ...res.data.data,
                        "doc_type": doctype,
                        "doc_number": docNumber,
                        "phone": phone,
                        "country": CountryRegionData.find((value) => value[0] === country)[1],
                        "city": city,
                        "email": email,
                        "address": address
                    },
                    {
                        headers: {
                            "Authorization": `Bearer ${secureStorage.getItem("access")}`,
                            "Content-Type": "application/json"
                        }
                    }
                ).then(resp => {
                    setLoader(false)
                    handleReset()
                    $("#success-credit-card-creation").modal("show")
                }).catch(error => {
                    setLoader(false)
                    setErrorGeneral(errores_generales[error.response.data.error])
                    $("#error-credit-card-creation").modal("show")
                })

            }).catch(error => {
                setLoader(false)
                console.log(error.response)
                console.log(pubk)
                setErrorGeneral(errores_generales["error.add.cc"])
                $("#error-credit-card-creation").modal("show")
            })

        } else {
            $("#error-form-front-credit-card-creation").modal("show")
        }      
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
        $("#success-credit-card-creation").modal("hide")
        $("#error-credit-card-creation").modal("hide")
        $("#error-form-front-credit-card-creation").modal("hide")

        axios.get('/wompi/get-public-key/', 
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`,
                    "Content-Type": "application/json"
                }
            }
        ).then(resp => setPubk(resp.data["public_key"])).catch(error => setPubk(null));

        return () => {
            $("#success-credit-card-creation").modal("hide")
            $("#error-credit-card-creation").modal("hide")
            $("#error-form-front-credit-card-creation").modal("hide")
        }
    }, [])    

    return(
        <>
            <Titulo>
                <FormattedMessage id="cc.register"/>
            </Titulo>

            <form ref={form} onSubmit={handleSubmit} className="row flex-row-reverse justify-content-center">
                <div className="order-1 order-md-0 col-12 col-md-6 col-lg-4 d-flex flex-column align-items-center justify-content-center">
                    {/* <hr className="w-100"/> */}
                    <Cards
                        cvc={cvc.length > 2 ? cvc.split("").fill("*", 0, -1).join("") : "*".repeat(cvc.length)}
                        expiry={expiry}
                        focused={focus}
                        preview={false}
                        name={firstName}
                        number={number}
                        placeholders={{name: intl.formatMessage({id: "cc.your.name"})}}
                    />
                    <hr className="w-100"/>

                    <ul className="list-unstyled text-center m-0">
                        <li>
                            <b className="text-muted text-capitalize"><FormattedMessage id="pse.doc"/>:</b> {docNumber ? doctype : ""} {docNumber ? docNumber : "---------"}
                        </li>
                        <li>
                            <b className="text-muted text-capitalize"><FormattedMessage id="profile.registration.reg_phone"/>:</b> {phone ? phone : " ---------"}
                        </li>
                        <li>
                            <b className="text-muted text-capitalize"><FormattedMessage id="profile.registration.reg_country"/>:</b> {country ? country : " ---------"}
                        </li>
                        <li>
                            <b className="text-muted text-capitalize"><FormattedMessage id="profile.registration.reg_city"/>:</b> {city ? city : " ---------"}
                        </li>
                        <li>
                            <b className="text-muted text-capitalize"><FormattedMessage id="email"/>:</b> {email ? email : " ---------"}
                        </li>
                        <li>
                            <b className="text-muted text-capitalize"><FormattedMessage id="profile.registration.reg_address"/>:</b> {address ? address : " ---------"}
                        </li>
                    </ul>
                    <hr className="w-100"/>

                    <div>
                        <button type="reset" onClick={handleReset} className="btn btn-outline-ariatel--secondary mr-2">
                            <FormattedMessage id="calls.reset"/>
                        </button>
                        <ButtonLoader 
                            loader={loader} 
                            className="button-tooltip-loader btn btn-ariatel--primary" 
                            type="submit"
                        >
                           <FormattedMessage id="cc.save"/>
                        </ButtonLoader>
                    </div>
                    <hr className="w-100 mb-1"/>
                    <small><Link className="text-muted" style={{textDecoration: "underline"}} to="/u/list/credit-card/"><FormattedMessage id="cc.list.title"/></Link></small>
                </div>

                <div className="order-0 order-md-1 col-12 col-md-6 col-xl-4" method="POST">
                    <div className="form-row">
                        <div className="form-group col-12">
                            <label htmlFor="number">
                             <FormattedMessage id="cc.number"/>:
                            </label>
                            <InputText className="w-100" name="number" id="number" maxLength={20} value={number} keyfilter="pint" inputMode="numeric" onChange={e => setNumber(e.target.value)} onFocus={e => setFocus(e.target.name)}/>
                        </div>
                    </div>
                
                    <div className="form-row">
                        <div className="form-group col-12">
                            <label htmlFor="first_name">
                                <FormattedMessage id="cc.name"/>:
                            </label>
                            <InputText className="w-100" name="first_name" id="first_name" value={firstName} onChange={e => setFirstName(e.target.value)} onFocus={e => setFocus("name")}/>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-8">
                            <label htmlFor="expiry">
                                <FormattedMessage id="cc.exp"/>:
                            </label>
                            <InputMask mask="99/99" placeholder="mm/yy" maxlength={4} className="w-100" name="expiry" id="expiry" value={expiry} onChange={e => setExpiry(e.value)} onFocus={e => setFocus(e.name)}/>
                        </div>
                        <div className="form-group col-4">
                            <label htmlFor="cvc">
                                CVC:
                            </label>
                            <InputText className="w-100" name="cvc" id="cvc" maxLength={4} keyfilter="pint" autoComplete="off" inputMode="numeric" type="password"  value={cvc} onChange={e => setCvc(e.target.value)} onFocus={e => setFocus(e.target.name)}/>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-12 col-sm-6 mb-3">
                            <div className="form-row">
                                <label htmlFor="doc_number" className="col-12">
                                    <FormattedMessage id="pse.doc"/>:
                                </label>
                                <div className="form-group ml-1 mb-0">
                                    <Dropdown options={docs_availables} value={doctype} onChange={e => setDoctype(e.value)} name="doc_type" id="doc_type" onFocus={e => setFocus("name")}/>
                                </div>
                                <div className="form-group col mb-0">
                                    <InputText keyfilter="pint" className="w-100" inputMode="numeric" type="text" value={docNumber} onChange={e => setDocNumber(e.target.value.replaceAll(".", ""))} name="doc_number" id="doc_number" onFocus={e => setFocus("name")}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 mb-3">
                            <label htmlFor="phone">
                                <FormattedMessage id="profile.registration.reg_phone"/>:
                            </label>
                            <PhoneInput placeholder="" country={data.m2.currency === "COP" ? "co" : "us"} name="phone" id="phone" inputClass="w-100 input-number-payments" containerClass="w-100" value={phone} onChange={e => setPhone(e)} onFocus={e => setFocus("name")}/>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-6">
                            <label htmlFor="email">
                                <FormattedMessage id="email"/>:
                            </label>
                            <InputText className="w-100" keyfilter="email" inputMode="email" name="email" id="email" value={email} onChange={e => setEmail(e.target.value)} onFocus={e => setFocus("name")}/>
                        </div>
                        <div className="form-group col-6">
                            <label htmlFor="country">
                                <FormattedMessage id="profile.registration.reg_country"/>:
                            </label>
                            <CountryDropdown className="w-100" style={{padding:'0.429rem 0.429rem', border: '1px solid #a6a6a6'}} name="country" id="country" value={country} onChange={val => setCountry(val)} onFocus={e => setFocus("name")}/>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-4">
                            <label htmlFor="city">
                                <FormattedMessage id="profile.registration.reg_city"/>:
                            </label>
                            <RegionDropdown className="w-100" style={{padding:'0.429rem 0.429rem', border: '1px solid #a6a6a6'}} country={country} name="city" id="city" value={city} onChange={val => setCity(val)} onFocus={e => setFocus("name")}/>
                        </div>
                        <div className="form-group col-8">
                            <label htmlFor="address">
                                <FormattedMessage id="profile.registration.reg_address"/>:
                            </label>
                            <InputText className="w-100" name="address" id="address" value={address} onChange={e => setAddress(e.target.value)} onFocus={e => setFocus("name")}/>
                        </div>
                    </div>
                </div>
            </form>

            <div className="modal fade" id="success-credit-card-creation" tabIndex="-1" role="dialog" aria-labelledby="success-credit-card-creation-label" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-ariatel--primary font-weight-bold" id="success-credit-card-creation-label">
                                <FormattedMessage id="cc.save.success"/>
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <FormattedMessage id="cc.save.success.body"/>
                        </div>
                        <div className="modal-footer border-0">
                            <Link className="btn btn-sm btn-info" to="/u/list/credit-card/"><FormattedMessage id="cc.list.title"/></Link>
                            <Link className="btn btn-sm btn-outline-ariatel--secondary" to="/u/pay/by/credit-card/"><FormattedMessage id="cc.pay.redirection"/></Link>
                            <button type="button" className="btn btn-sm btn-ariatel--primary" data-dismiss="modal"><FormattedMessage id="cc.add.another"/></button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="error-credit-card-creation" tabIndex="-1" role="dialog" aria-labelledby="error-credit-card-creation-label" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-ariatel--default font-weight-bold" id="error-credit-card-creation-label">
                                <FormattedMessage id="cc.save.error"/>
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {errorGeneral}
                        </div>
                        <div className="modal-footer border-0">
                            <Link className="btn btn-sm btn-info" to="/u/list/credit-card/"><FormattedMessage id="cc.list.title"/></Link>
                            <button type="button" className="btn btn-sm btn-ariatel--primary" data-dismiss="modal"><FormattedMessage id="add.payments.close"/></button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="error-form-front-credit-card-creation" tabIndex="-1" role="dialog" aria-labelledby="error-form-front-credit-card-creation-label" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-muted font-weight-bold" id="error-form-front-credit-card-creation-label">
                                <FormattedMessage id="cc.error.list.title"/>:
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ul>
                                {Object.keys(errors).map((key) => {
                                    return (
                                        <li key={key + "-form-error"}>
                                           <b>{fields_labels[key]}:</b> {errors[key]}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="modal-footer border-0">
                            <Link className="btn btn-sm btn-info" to="/u/list/credit-card/"><FormattedMessage id="cc.list.title"/></Link>
                            <button type="button" className="btn btn-sm btn-outline-ariatel--secondary" data-dismiss="modal"><FormattedMessage id="add.payments.close"/></button>
                        </div>
                    </div>
                </div>
            </div>            
        </>
    )
}