import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { AuthContext } from '../AuthContext'
import Card, { CardAdapt } from '../compartidos/tools/Cards'
import Ofertas from '../compartidos/dashboard components/Ofertas'
import Titulo from '../compartidos/tools/Titulo'
import QuickStat from './QuickStat'
import { Link } from 'react-router-dom'
import UserInfo from './UserInfo'
import TopDestinos from './TopDestinos'
import CallsChart from './charts/CallsChart'
import ReactGA from 'react-ga'

/* 
   -----------------------------------------------------------------------------------------------
    Componente del dashboard que contiene una serie de subcomponentes con informaciones de 
    lectura rapida
   -----------------------------------------------------------------------------------------------
*/
export default function Dashboard() {
    const data = useContext(AuthContext)
    useEffect(() => {ReactGA.pageview(window.location.pathname);}, [])
    return(
        <>
            <Titulo>
                <FormattedMessage id="welcome"/>, {
                    (data.m2.first_name || data.m2.last_name) ? 
                    (`${data.m2.first_name} ${data.m2.last_name}`) :
                    data.m2.username_dj
                }
            </Titulo>
            <section className="row">
                <div className="col-12 col-md-6 order-0 order-lg-0 mb-3">
                    <Card header={<HeaderSummary/>}>
                        <UserInfo/>
                    </Card>
                </div>
                <div className="col-12 col-lg-6 order-3 order-lg-1 mb-3">
                    <Card header={<HeaderQuickstats/>}>
                        <QuickStat/>
                    </Card>
                </div>
                <div className="col-12 col-lg-8 order-2 order-lg-2 mb-3">
                    <Card header={<HeaderChart/>}>
                        <CallsChart/>
                    </Card>
                </div>

                <div className="col-12 col-md-6 order-1 order-lg-3 col-lg-4 d-flex flex-column justify-content-between mb-3">
                    <CardAdapt className="mb-3" header={<HeaderDestinos/>}>
                        <TopDestinos/>
                    </CardAdapt>
                    <CardAdapt header={<HeaderOfertas/>}>
                        <Ofertas/>
                    </CardAdapt>
                </div>
            </section>
        </>
    )
}

const HeaderDestinos = () => {
    const intl = useIntl()
    
    return(
        <>
           <FontAwesomeIcon icon="globe" size="sm"/> {intl.formatMessage({id: "destinos"})}
        </>
    )
}

const HeaderOfertas = () => {
    const intl = useIntl()
    
    return(
        <div className="d-flex justify-content-between align-self-center">
            <div>
                <FontAwesomeIcon icon="tag" size="sm"/> {intl.formatMessage({id: "ofertas"})}
            </div>
            <Link to="/u/create/offers/" className="add-offer-link">
                <FontAwesomeIcon icon="plus-square" color="#fff"/>
            </Link>
        </div>
    )
}

const HeaderChart = () => {
    const intl = useIntl()
    
    return(
        <>
           <FontAwesomeIcon icon="chart-line" size="sm"/> {intl.formatMessage({id: "chart.calls"})}
        </>
    )
}

const HeaderSummary = () => {
    const intl = useIntl()
    
    return(
        <>
           <FontAwesomeIcon icon="info-circle" size="sm"/> {intl.formatMessage({id: "accountSummary"})}
        </>
    )
}

const HeaderQuickstats = () => {
    const intl = useIntl()
    
    return(
        <>
           <FontAwesomeIcon icon="list" size="sm"/> {intl.formatMessage({id: "quickStats"})}
        </>
    )
}