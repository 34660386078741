import React, { useRef, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormattedMessage } from 'react-intl'
import $ from 'jquery'

/* 
   -----------------------------------------------------------------------------------------------
    Link que redirige a otra URL para el Nav en desktop
   -----------------------------------------------------------------------------------------------
*/
export const HeaderNavOnlyLink = (props) => {
    return(
        <li className={"nav-item " + props.className}>
            <NavLink className="px-2 px-lg-3 nav-link text-capitalize h-100 d-flex flex-column justify-content-end align-items-center header-link-lg" to={props.to || "/u/"}>
                <FontAwesomeIcon icon={props.icon} className="mb-2"/>
                <span>
                    <FormattedMessage id={props.msgId}/>
                </span>
            </NavLink>
        </li>
    )
}

/* 
   -----------------------------------------------------------------------------------------------
    Link que redirige a otra URL para el Nav en movil
   -----------------------------------------------------------------------------------------------
*/
export const FooterNavOnlyLink = (props) => {
    return(
        <li className={"nav-item position-relative " + props.className}>
            <NavLink className="pb-1 pt-2 nav-link text-capitalize h-100 d-flex justify-content-center flex-column align-items-center" to={props.to}>
                <FontAwesomeIcon icon={props.icon} size="sm" className="mb-1"/>
                <span style={{fontSize: "10px"}}>
                    <FormattedMessage id={props.msgId}/>
                </span>
            </NavLink>
        </li>
    )
}

/* 
   -----------------------------------------------------------------------------------------------
    Boton que activa/desactiva el Sidebar
   -----------------------------------------------------------------------------------------------
*/
export const FooterNavSidebarButton = (props) => {
    return(
        <>
            <li className={"nav-item " + props.className}>
                <button type="button" className="w-100 btn pb-1 pt-2 nav-link text-capitalize d-flex justify-content-center flex-column align-items-center" onClick={props.onClick}>
                    <FontAwesomeIcon icon={props.icon} size="sm" className="mb-1"/>
                    <span style={{fontSize: "10px"}}>
                        <FormattedMessage id={props.msgId}/>
                    </span>
                </button>
            </li>
        </>        
    )
}

/* 
   -----------------------------------------------------------------------------------------------
    Link en el Sidebar
   -----------------------------------------------------------------------------------------------
*/
export const FooterNavSidebarLink = (props) => {
    return(
        <Link className="dropdown-item text-capitalize px-1 py-2" style={{fontSize: "13px"}}
            to={props.to}>
            <FontAwesomeIcon className="mr-2" icon={props.icon} size="sm"/>
            <FormattedMessage id={props.msgId}/>
        </Link>
    )
}

/* 
   -----------------------------------------------------------------------------------------------
    Link dentro del dropdown para el Nav en desktop
   -----------------------------------------------------------------------------------------------
*/
export const HeaderNavDropdownLink = (props) => {
    return(
        <Link className="dropdown-item text-capitalize" style={{fontSize: "13px"}}
            to={props.to}>
            <FontAwesomeIcon className="mr-2" icon={props.icon} size="sm"/>
            <FormattedMessage id={props.msgId}/>
        </Link>
    )
}


/* 
   -----------------------------------------------------------------------------------------------
    Boton que activa/desactiva el dropdown para el Nav en desktop
   -----------------------------------------------------------------------------------------------
*/
export const HeaderNavDropdownButton = (props) => {
    const id = useRef(null)

    useEffect(() => {
        $('.dropdown-toggle').dropdown()
    }, [])

    return(
        <li className={"nav-item dropdown align-self-center " + props.className}>
            {/* Dropdown */}
            <NavLink className="nav-link dropdown-toggle text-capitalize" 
                        to="/u/" id={id} role="button" 
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <FontAwesomeIcon size="lg" icon={props.icon}/>
            </NavLink>

            {/* Links disponibles en el menu */}
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby={id} style={{fontSize: "13px"}}>
                {props.children}
            </div>
        </li>
    )
}