import React from 'react'
/* 
   -----------------------------------------------------------------------------------------------
    Componente que contiene los SVG's de las ondas de ariatel
   -----------------------------------------------------------------------------------------------
*/
class DecoracionOnda extends React.Component{
    render(){
        return (
            <svg version="1.1" id={this.props.id}  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1280 77.5" style={{enableBackground:'new 0 0 1280 77.5', verticalAlign:'top'}} xmlSpace="preserve">
                <path id="path2285" className="st0" d="M0,0h1280c-136.8,28.5-213.3,64.1-467.7,68.4
                    C516.9,74.8,153.9,28.7,0,0z"/>
                <g>
                    <defs>
                        <path id="path2298" d="M72,0h1173.3c0,0-270.8,85.5-771.3,53.4C318.2,42.7,72,0,72,0L72,0z"/>
                    </defs>
                    <clipPath id="path2298_1_">
                        <use xlinkHref="#path2298" style={{overflow:'visible'}}/>
                    </clipPath>
                    <rect x="12.6" y="-218.1" className="st1" width="1280" height="616"/>
                    <g className="st2">
                        
                        <g>
                            <path className="st3" d="M732.4,76.3c35.8,48.9,6,127.8-54.3,144.4c-59.2,21-125.8-27.6-122.1-88.9c-0.9-61.2,68.2-111.3,126.4-91.8
                                C702.9,45.9,720.8,59,732.4,76.3L732.4,76.3z"/>
                            <g>
                                <path className="st4" d="M724.5,79.1c21.3,30,19.5,70.9,0,102.6c-10.3,16.6-26.3,31.8-45.3,37.7c-17.5,5.3-36.8,3.7-53.5-2.4
                                    c-30.8-11.4-55.6-38.4-60.7-71.2c-5.3-34.9,9.4-69.7,38.4-91.6c17.2-13,36.7-17.9,57.7-14.7C686.9,43.4,709.9,58.1,724.5,79.1
                                    c1.4,2,16-5.8,16-5.7l0,0C739,71.4,724.6,79.2,724.5,79.1L724.5,79.1c5.3-1.8,10.6-3.8,16-5.7c-14.6-21.1-37.4-35.4-63.1-39.7
                                    c-19.4-3.3-39,1.1-57.4,8.1c-37.1,14.2-69,45.7-71.5,86.3c-2.6,41.4,19.9,77.8,58.5,93.4c17.4,7,36.6,8.7,55.3,4.5
                                    c19.2-4.3,39.3-11.5,55.1-24c39-30.6,51.3-89.3,23.2-128.7C738.9,71.3,724.7,79.3,724.5,79.1z"/>
                            </g>
                        </g>
                        <g>
                            <path className="st3" d="M733.7,51.1c46.5,62.9,9.7,166-69,187.3c-77.3,26.7-163-37.5-158.7-116.8C504,42.3,593.6-22.9,668.7,3.4
                                C695.4,11.3,718.6,28.4,733.7,51.1z"/>
                            <g>
                                <path className="st4" d="M725.8,53.9c28.5,39.6,25.9,95-1.1,136.5c-14.7,22.3-36,40.2-62,47.5c-22.6,6.3-47.5,3.7-68.7-4.4
                                    c-41.5-15.6-74.5-53.3-79.3-97.9c-5-46,14.8-90.1,54.3-117.3C621.8-18.2,692.6,5.6,725.8,53.9c1.5,2.2,16-5.8,16-5.7
                                    c-18.6-26.9-47.3-45.5-80-51.8C637-8.4,611.6-3.5,588,5.5c-46.6,17.8-85.9,58-89.7,108.6c-1.9,25.7,2.5,50.4,15.7,72.5
                                    c12.9,21.5,32.7,38.7,55.6,49.3c22.3,10.2,47.5,13.9,72.3,9.3c25.3-4.7,51.8-14.6,72.2-30.9c49.4-40,64.1-115.8,27.6-166.3
                                    C740.2,46.2,725.9,54.2,725.8,53.9z"/>
                            </g>
                        </g>
                        <g>
                            <path className="st3" d="M741,37.7c49,66.6,9.3,174.5-73.4,197.1c-81,28.4-171.7-38.5-166.9-122.3c-1.6-83.6,92.7-152.1,172.1-125
                                C700.7-3.9,725,14,741,37.7z"/>
                            <g>
                                <path className="st4" d="M733,40.6c30.1,41.9,27,100.4-2.2,144.1c-15.1,22.8-37,41.1-63.5,49.3c-24.5,7.4-51.4,5.3-74.8-3.4
                                    c-43.4-16.1-78-55.2-83.5-101.6C503.5,80.7,524.6,33.9,565.5,5c23.7-16.8,51.3-23.3,79.6-19C681.2-8.8,713.1,11.5,733,40.6
                                    c1.5,2.2,16-5.8,16-5.7C729.5,6.6,699.1-13,664.7-19.3c-26.2-4.8-52.9,0.3-77.8,10.1c-49.3,19-90.3,62.1-93.8,115.6
                                    c-1.7,27.2,2.9,52.9,17.2,76c13.9,22.4,35,40.3,59.2,51c23.3,10.3,49.7,13.8,75.4,8.8c26.8-5.2,54.5-15.8,75.7-33.3
                                    c51.1-42.4,66.5-121,28.5-173.8C747.5,32.7,733.1,40.9,733,40.6z"/>
                            </g>
                        </g>
                        <g>
                            <path className="st3" d="M848.7,43.8c52.4,70.7,11.7,186.5-77,210.4c-87,29.7-183-42.5-178.5-131.8c-2.5-89.1,98.2-162.5,182.5-132.6
                                C805.6-0.9,831.6,18.5,848.7,43.8L848.7,43.8z"/>
                            <g>
                                <path className="st4" d="M840.7,46.6c32.7,45.2,29.4,108.9-2.5,155.8c-16.7,24.7-40.9,43.9-69.9,51.9c-26.1,7.2-54.5,4-78.8-5.6
                                    c-46.3-18.3-82.7-60.9-87.7-110.9c-5.3-52.3,17.7-101.9,62.9-132.2C724.3-34.3,804-7.8,840.7,46.6c1.4,2,16-5.8,16-5.7
                                    c-20-29.7-51.6-50.1-87-57.9c-27.7-6.2-56.1-1.4-82.9,8.6C633.9,11.3,589.4,56.9,585.5,114c-1.9,28.7,2.9,56.3,17.5,80.9
                                    c14.3,23.9,36,43.4,61.4,55.5c24.7,11.7,52.8,16.3,80.4,11.7c28.7-4.8,59.2-16.2,82-35c55-45.2,70.5-130,29.8-186.1
                                    C855.2,38.9,840.9,46.9,840.7,46.6z"/>
                            </g>
                        </g>
                        <g>
                            <path className="st3" d="M856.2,46.8c58.3,78.9,12.1,207.9-86.5,234.6C673,314.7,565.6,234.6,571,135
                                c-2.5-99.4,109.9-181.1,204-148.1C808.2-3.1,837.3,18.4,856.2,46.8z"/>
                            <g>
                                <path className="st4" d="M848.3,49.6c36.6,50.3,32.3,121.5-3.3,173.7c-18.8,27.7-45.3,48.3-77.4,57.8c-28.8,8.6-60.7,5.6-88.1-4.5
                                    c-52.2-19.4-93.6-66.5-100-122.4c-6.7-58.2,19-114.3,69-148.4c28.5-19.4,61.5-27.1,95.1-21.9C786.2-9.6,824.4,14.8,848.3,49.6
                                    c1.4,2,16-5.8,16-5.7c-23.3-34-60-57.6-101.4-65.2c-30.5-5.7-61.7,0.3-90.7,11.7C613.7,13.3,565.4,66,563,129.5
                                    c-1.2,32,4.4,61.8,21.4,88.8c16.2,25.8,40.3,46.6,68.2,59.5c27.2,12.4,58.1,17.2,88.3,12.1c32.3-5.6,66.2-18.6,91.4-40.1
                                    C892,199.2,909.1,106.1,864.2,44C862.6,41.7,848.5,49.9,848.3,49.6z"/>
                            </g>
                        </g>
                        <g>
                            <path className="st3" d="M916.7,25.9c83,113.3,13.6,295.5-126,334c-136.8,48.7-291.5-63.7-282.4-205.6
                                c-1.8-141.8,158.1-257.8,293-212.4C848.4-44.4,889.9-14.2,916.7,25.9L916.7,25.9z"/>
                            <g>
                                <path className="st4" d="M908.8,28.7c52.7,73,44.2,176.1-8.9,250.2c-13.1,18.3-28.8,34.8-46.8,48.5c-19.6,15-40.4,24.3-63.5,32
                                    c-40.7,13.5-85.4,11.3-125.1-1.9c-76.9-25.5-139-93.3-147.6-174.8c-4.3-41.1,1.7-84,20.7-121.9c18.6-37.4,48.4-69.5,83.7-92.6
                                    c36.8-24.1,78.9-35.9,122.3-33.2c51.5,3.1,101.8,26.1,137.8,61.2C891.9,6.2,900.9,17.1,908.8,28.7c1.4,2,16-5.8,16-5.7l0,0
                                    C923.5,21,908.9,28.9,908.8,28.7L908.8,28.7c5.3-1.8,10.6-3.8,16-5.7c-32.5-47.6-84-80.1-141.6-90.8
                                    c-43.2-8.2-87.7-0.3-128.7,16.1C571.9-18.7,504.5,57.3,500.7,146.9c-0.9,22.7-0.5,44.9,5,66.9c5.2,21.1,14.1,41.2,26,59.5
                                    c23.4,36.3,58,65.1,97.9,82.6c39.2,17.1,83.6,23.1,126.7,14.6c44.7-8.8,89.8-27.2,124.4-57.7c72.2-63.7,102.1-173.4,62.5-258.9
                                    c-5-10.8-11.2-21.1-18.1-30.8C923.2,21,909,29,908.8,28.7z"/>
                            </g>
                        </g>
                        <g>
                            <path className="st3" d="M918.1,14.9c85.7,116.4,16.5,305.4-128.4,345c-142,49.6-300.6-67.6-292-214.1
                                c-3-146.4,162.2-266.3,301.1-218.5C847.5-58,890.1-26.6,918.1,14.9L918.1,14.9z"/>
                            <g>
                                <path className="st4" d="M909.7,14.3c54.7,75.4,46.5,182.2-8.2,259.1c-27.6,39-67.3,69.5-113.2,83.7c-22.1,6.8-43.6,12.5-66.6,13.1
                                    c-22.1,0.7-43.6-3.6-64-10.7c-79.8-27.5-143.3-99.1-151.2-183.8c-4-42.5,2.2-86.4,22.2-125.3c19.5-38.2,50.1-70.7,86.3-94.1
                                    c36.7-23.7,80.3-38.6,123.7-38.7c24.4,0,48,6.7,70.1,15.5c28.1,11.3,53.8,28.1,74.9,49.3C893.5-7.7,902.1,3,909.7,14.3
                                    c1,1.5,6.5,1.4,8.2,1.6c0.8,0,8.4-0.3,8.5-0.2c-57.4-85-175-122.1-275.3-86.7C564.6-40.4,493.9,40.2,489.4,131.9
                                    c-2.4,46.6,5.6,90.4,30,129.9c23.4,37.7,58.1,68.2,98.5,87.3c82,39,190,29.5,262.1-32.3c76.3-65.5,106.7-180.5,65.2-269.4
                                    c-5.3-11.2-11.6-21.8-18.8-31.9c-1.1-1.6-6.5-1.4-8.2-1.6C917.4,14.1,909.9,14.6,909.7,14.3z"/>
                            </g>
                        </g>
                        <g>
                            <path className="st3" d="M852.3-21c85,116,13.6,302.4-129.3,341.8c-139.9,50-298.4-64.7-288.9-210.1c-1.6-145.2,162-263.8,300-217.6
                                C782.4-92.8,824.8-62,852.3-21z"/>
                            <g>
                                <path className="st4" d="M844.4-18.2c53.8,74.2,45,179.1-8.6,254.9c-13.3,18.8-29.5,35.9-47.8,50c-20.1,15.5-41.3,25.3-65.2,33.3
                                    c-42,13.9-87.6,12.3-128.5-1.2C515,293,451.2,223.2,442.6,139.4c-4.2-41.8,2-85.4,21.3-123.9c19.2-38.6,50-71.6,86.6-95.3
                                    c37.5-24.3,80.2-36.3,124.3-33.9c53.2,3,105.3,26.7,142.5,63C827.3-40.8,836.4-29.8,844.4-18.2c1.4,2,16-5.8,16-5.7
                                    c-33-48.6-85.4-81.7-144.3-92.7c-44.4-8.4-90.2-0.3-132.6,16.6C498.9-66,430.1,11.8,426.2,103.2c-1,23.2-0.5,45.7,4.9,68.1
                                    c5.3,21.5,14.4,42,26.3,60.6c24.1,37.2,59.4,66.9,100.4,84.8c40.3,17.6,86,23.7,130.2,14.6c45.7-9.3,91.7-28.1,127.1-59.5
                                    c73.5-65.2,104-176.9,63.6-264.2c-5.1-11.2-11.4-21.7-18.7-31.6C858.8-25.9,844.5-17.9,844.4-18.2z"/>
                            </g>
                        </g>
                        <g>
                            <path className="st3" d="M798.3,29.3c84.8,116,12.7,301.7-129.9,341c-139.4,50-297.9-63.9-288.1-209
                                c-1.1-145.1,162.2-263.3,300-217.6C728.5-42.3,770.9-11.6,798.3,29.3z"/>
                            <g>
                                <path className="st4" d="M790.3,32.1c53.8,74.6,44.5,179.6-9.8,255c-13.4,18.5-29.1,35.2-47.3,49.3c-19.6,15.2-40.6,25.2-63.9,32.8
                                    c-42.1,13.8-86.9,13-128.2-0.2c-79-25.4-143.3-94.6-152.1-178.1c-4.4-41.8,2.1-85.7,21.2-124.2c19.2-38.4,49.8-71.5,86-95.1
                                    c37.6-24.5,80.3-36.8,124.7-34.6c53.3,2.7,105.3,26.1,142.4,62.6C773.4,9.4,782.4,20.3,790.3,32.1c1.4,2,16-5.8,16-5.7
                                    c-32.9-48.5-85.4-81.3-144-92.3c-44.7-8.2-90.6-0.1-133,17.1C444.7-14.6,375.8,63.8,372.7,155.7c-0.8,23.1-0.4,45.6,5.4,67.8
                                    c5.4,21.4,14.7,41.8,26.8,60.1c24.1,36.6,59.5,65.7,100.1,83.1c40.4,17.4,85.8,23,130,13.8c45.7-9.5,91.3-28.4,126.6-59.9
                                    c72.9-65.1,103.5-176,63.5-262.9c-5.1-11.2-11.4-21.7-18.7-31.6C804.8,24.3,790.5,32.3,790.3,32.1z"/>
                            </g>
                        </g>
                        <g>
                            <path className="st3" d="M689.4-122.4C741.7-51.3,699.1,64,610.8,88C524.3,118.4,427.4,47,432.7-42.4c-1.6-89.3,99-162.5,183.8-133.4
                                C646.3-167,672.4-147.9,689.4-122.4z"/>
                            <g>
                                <path className="st4" d="M681.4-119.6c32.2,44.8,28.6,107.2-2.2,153.8c-16.4,24.9-39.9,44.1-68.6,53c-25.6,8-53.7,5.8-78.2-2.7
                                    c-46.8-16.6-84.6-58-90.9-107.8c-6.8-52,15.9-102.9,60.2-134.1c25.5-17.8,55-25.1,85.4-20.7
                                    C625.6-172.7,659.9-150.9,681.4-119.6c1.5,2.2,15.9-5.8,16-5.7c-21-30.5-54-51.4-91.1-58.2c-28-5-56.4,0.8-83,11.2
                                    c-52.8,20.8-96.1,68-98.7,125.4c-1.2,28.9,3.8,55.8,19.4,80.1c15,23.4,37.3,42.2,63.1,53.4c25.2,10.9,53.3,14.4,80.9,8.8
                                    c28.1-5.7,57-16.9,79.3-35.5c54.1-45.3,70.4-129,29.9-185.1C695.9-127.4,681.6-119.4,681.4-119.6z"/>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}

export default DecoracionOnda