import React from 'react'
import { FormattedMessage } from 'react-intl'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que renderiza la pagina donde encontramos la Politica de la Privacidad
   -----------------------------------------------------------------------------------------------
*/
class PrivacyPolicy extends React.Component {
    render(){
        return (
            <section>
                <h2 className="w-100 text-left border-bottom mt-3 mb-4 pb-4">
                    <FormattedMessage id="footer.policyLink.privacy"/>
                </h2>
                <div className="text-justify">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio nesciunt, magni, quis tempora perferendis dicta
                        consectetur adipisci aliquid a cupiditate reprehenderit tempore sint consequuntur rem numquam fugit rerum accusantium!
                        Tenetur!
                    </p>
                </div>
            </section>
   
        )
    }
}

export default PrivacyPolicy