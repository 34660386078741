import { Dropdown } from 'primereact/dropdown'
import React, { useContext, useEffect, useState } from 'react'
import Titulo from '../../../tools/Titulo'
import { InputNumber } from "primereact/inputnumber"
import { AuthContext } from '../../../../AuthContext'
import ButtonLoader from '../../../tools/ButtonLoader'
import axios from 'axios'
import secureStorage from '../../../../../tools/SecureStorage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCcVisa,
    faCcMastercard,
    faCcAmex,
    faCcDinersClub,
    faCcDiscover,
    faCcJcb
} from '@fortawesome/free-brands-svg-icons'
import $ from "jquery"
import { confirmDialog } from 'primereact/confirmdialog'
import { Link, useHistory } from 'react-router-dom'
import {FormattedMessage, useIntl} from 'react-intl'
import ReactGA from 'react-ga'
import CurrencyInput from 'react-currency-input-field'


export default function CreditCard(){
    const data = useContext(AuthContext)
    const intl = useIntl()
    const history = useHistory()
    const [token, setToken] = useState("")
    const [amount, setAmount] = useState('')
    const [dues, setDues] = useState(1)
    const [creditcards, setCreditcards] = useState([])
    const [loader, setLoader] = useState(false)
    const [currentBalance, setCurrentBalance] = useState("")
    const [errorGeneral, setErrorGeneral] = useState("")
    
    const errores_generales = {
        "forms.passReset.error": intl.formatMessage({id: "forms.passReset.error"}),
        "forms.invalid": intl.formatMessage({id: "forms.invalid"}),
    }

    const type = {
        "VISA": faCcVisa,
        "EBT": faCcMastercard,
        "MASTERCARD": faCcMastercard,
        "DISCOVER": faCcDiscover,
        "AMERICAN EXPRESS": faCcAmex,
        "DINERS CLUB INTERNATIONAL": faCcDinersClub,
        "HIPERCARD": "credit-card",
        "CHINA UNION PAY": "credit-card",
        "JCB": faCcJcb
    }
    
    const type_color = {
        "VISA": "#6DA9F2",
        "EBT": "#DAA44F",
        "MASTERCARD": "#DAA44F",
        "DISCOVER": "#ED9777",
        "AMERICAN EXPRESS": "#76E3C8",
        "DINERS CLUB INTERNATIONAL": "#7B9AE3",
        "HIPERCARD": "#948B81",
        "CHINA UNION PAY": "#948B81",
        "JCB": "#DA5A52"
    }
    
    const [errors, setErrors] = useState({
        "token": "",
        "dues": "",
        "amount": ""
    })

    const fields_labels = {
        "token": intl.formatMessage({id: "cc"}),
        "dues": intl.formatMessage({id: "cc.dues"}),
        "amount": intl.formatMessage({id: "payments.amount"})
    }

    const symbol_currency = {
        "COP": "$",
        "USD": "$",
        "EUR": "€"
    }

    const is_valid = () => {
        let valid = true
        let errors_found = {}

        if(token === ""){ 
            errors_found["token"] = "forms.field.empty";
            valid = false;
        }

        if(dues === "" || isNaN(dues) || dues.length < 1){ 
            errors_found["dues"] = "add.payments.error1";
            valid = false;
        }

        if(amount === "" || isNaN(parseFloat(amount.replace(",", "."))) || parseFloat(amount.replace(",", ".")) <= 0 || amount === null || parseFloat(amount.replace(",", ".")) < parseFloat(data.m2.payment_range.min) || parseFloat(amount.replace(",", ".")) > parseFloat(data.m2.payment_range.max)){
            errors_found["amount"] = "add.payments.error0";
            valid = false;
        }       
        setErrors(errors_found)
        return valid
    }

    const resetForm = () => {
        setToken("")
        setAmount('')
        setDues(1)
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
        axios.get("/epayco/credit-card/payment/",
        {
            headers: {
                "Authorization": `Bearer ${secureStorage.getItem("access")}`,
            }
        }
        ).then(res => {
            setCurrentBalance(Intl.NumberFormat().format(parseFloat(res.data.user_balance).toFixed(2)))
        }).catch(error => alert(intl.formatMessage({id: "cc.nocc"})))


        axios.post( '/wompi/credit-card/list/', {},
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`,
                    'content-type': 'application/json'
                }
            }
        ).then(resp => setCreditcards(resp.data.cards)
        ).catch(() => {
            setCreditcards([])
        })

        return () => {
            $("#error-form-front-credit-card-payment").modal('hide')
            $("#error-credit-card-payment").modal("hide")
        }
        // eslint-disable-next-line
    },[])

    const handleSubmit = () => {
        
        axios.post("/wompi/credit-card/payment/", 
            {
                "id": token,
                "amount": parseFloat(amount.replace(",", ".")),
                "dues": dues
            },
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`,
                }
            }
        ).then(res => {
            resetForm()
            setLoader(false)
            history.push("/u/pay/by/credit-card/wompi/response/", res.data.transaction)
            
        }).catch(error => {
            setErrorGeneral(errores_generales[error.response.data.error])
            setLoader(false)
            $("#error-credit-card-payment").modal("show")
        })
    }

    const creditCardTemplate = (option) => {
        return (
            <div className="card-item py-2 border-bottom cc-dropdown-payment-item">
                <div className="d-flex justify-content-between align-items-center">
                    <small>{option.card_holder}</small>
                    <FontAwesomeIcon color={type_color[option.brand]} size="2x" icon={type[option.brand]}/>
                </div>
                <div className="d-flex justify-content-between py-2">
                    <small className="text-muted font-italic">{option.bin} ******** {option.last_four}</small>
                    <small className="text-muted font-italic">Exp. {option.exp_date}</small>
                </div>
            </div>
        )
    }

    const selectedCreditCardTemplate = (option, props) => {
        if(option){
            return (
                <div className="d-flex justify-content-start align-items-center">
                    <FontAwesomeIcon className="ml-2" color={type_color[option.brand]} size="lg" icon={type[option.brand]}/>
                    <div className="ml-3">{option.bin} ******** {option.last_four}</div>
                </div>
            )
        }

        return (
            <span>
                {props.placeholder}
            </span>
        )
    }

    const handleChangeAmount = value => {
        if (value > parseFloat(data.m2.payment_range.max)) {
            setAmount(parseFloat(data.m2.payment_range.max))
        } else {
            setAmount(value)
        }
    }

    const confirm = (e) => {
        e.preventDefault()
        setErrorGeneral("")
        if(is_valid()){
            setLoader(true)
            confirmDialog({
                message: <Summary token={token} intl={intl} />,
                header: intl.formatMessage({id: "cc.recharge"}),
                icon: 'pi pi-money-bill',
                accept: () => handleSubmit(),
                reject: () => setLoader(false),
                onHide: (result) => result === undefined ? setLoader(false) : null
            })
        }else{
            $("#error-form-front-credit-card-payment").modal("show")
        }
    }

    const Summary = (props) => {
        const card = creditcards.find((obj) => obj.id === props.token)
        return (
            <>
                <small><b>{props.intl.formatMessage({id: "cc"})}:</b> {card ? `${card.bin} ******** ${card.last_four}` : "-------------"} </small><br/>
                <small><b>{props.intl.formatMessage({id: "cc.dues"})}:</b> {dues ? dues : "-------------"} </small> <br/>
                <small><b>{props.intl.formatMessage({id: "cc.amount.recharge"})}:</b> {amount ? Intl.NumberFormat().format(parseFloat(amount.replace(",", "."))) : "-------------"} ({data.m2.currency}) </small><br/>
            </>
        )
    }

    return (
        <>
            <Titulo className="d-flex justify-content-center justify-content-lg-between align-items-end">
                <FormattedMessage id="cc.pay.title"/>
                {data.m2.default_curr === data.m2.currency ?
                    null
                :
                    <small className="font-italic d-none d-lg-block" style={{fontSize: "1rem"}}><FormattedMessage id="exchange.msg"/> {data.m2.default_curr}: <b>{Intl.NumberFormat().format(data.m2.exchange_rate)} {data.m2.currency}</b></small>
                }
            </Titulo>

            <form onSubmit={confirm} method="POST" id="form-epayco-pay-cc" style={{maxWidth: "600px"}} className="m-auto bg-white rounded shadow border p-4">
                
                <div className="text-center text-muted font-italic">
                    <FormattedMessage id="currentBalance"/>{currentBalance} ({data.m2.default_curr})
                </div>
                <hr/>
                
                <div className="form-row">
                    <div className="form-group col-12 col-sm-8">
                        <label htmlFor="cc">
                            <FormattedMessage id="cc"/>:
                        </label>
                        <Dropdown valueTemplate={selectedCreditCardTemplate} itemTemplate={creditCardTemplate} placeholder={intl.formatMessage({id: "cc.choose.card"})} optionLabel="card_holder" optionValue="id" className="w-100 cc-dropdown-payment" options={creditcards} value={token} onChange={e => setToken(e.value)} name="cc" id="cc"/>
                        <small className="text-muted"><FormattedMessage id="cc.list.title"/> <Link style={{textDecoration: "underline"}} to="/u/list/credit-card/"><FormattedMessage id="aqui"/></Link></small>
                    </div>

                    <div className="form-group col-12 col-sm-4">
                        <label htmlFor="dues">
                            <FormattedMessage id="cc.dues"/>:
                        </label>
                        <InputNumber className="w-100" inputClassName="w-100" inputMode="number" max={12} min={1} value={dues} onChange={e => setDues(e.value)} name="dues" id="dues"/>
                    </div>
                </div>

                <div className="text-center">
                    <label className="p-d-block my-2">
                        <FormattedMessage id="cash.choose"/> ({data.m2.currency}):
                    </label>
                    <CurrencyInput intlConfig={{'currency': data.m2.currency}} groupSeparator="." decimalSeparator="," prefix={symbol_currency[data.m2.currency]} allowNegativeValue={false} step={data.m2.currency === "COP" ? 10000 : 10} id="amount" name="amount" value={amount} className="w-100 p-inputtext" placeholder={intl.formatMessage({id: "enter.amount"})} decimalsLimit={2} onValueChange={(val, name) => handleChangeAmount(val)}/>
                    <small className="text-muted"><FormattedMessage id="monto.warning.range"></FormattedMessage> {symbol_currency[data.m2.currency]}{Intl.NumberFormat().format(data.m2.payment_range.min)}  - {symbol_currency[data.m2.currency]}{Intl.NumberFormat().format(data.m2.payment_range.max)}</small>
                </div>
                <hr/>
                <div className="text-center mt-2">
                    <button type="reset" disabled={loader} onClick={resetForm} className="btn btn-outline-ariatel--secondary mr-2">
                        <FormattedMessage id="calls.reset"/>
                    </button>
                    <ButtonLoader 
                        loader={loader} 
                        className="button-tooltip-loader btn btn-ariatel--primary" 
                        type="submit"
                    >
                        <FormattedMessage id="cc.recharge"/>
                    </ButtonLoader>
                </div> 
                <br className="mb-1"/>
                <div className="text-center">
                    <small className="text-muted">
                        <FormattedMessage id="cc.card.warning"/>
                        {/* <Link className="text-muted" style={{textDecoration: "underline"}} to="/u/add/credit-card/"><FormattedMessage id="cc.hasnt"/></Link> */}
                    </small>
                </div>
                {data.m2.default_curr === data.m2.currency ?
                    null
                :
                    <small className="text-center text-muted pt-2 border-top mt-2 font-italic d-block d-lg-none" style={{fontSize: ".8rem"}}><FormattedMessage id="exchange.msg"/> {data.m2.default_curr}: <b>{Intl.NumberFormat().format(data.m2.exchange_rate)} {data.m2.currency}</b></small>
                }
            </form>


            <div className="modal fade" id="success-credit-card-payment" tabIndex="-1" role="dialog" aria-labelledby="success-credit-card-payment-label" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-ariatel--default font-weight-bold" id="success-credit-card-payment-label">
                                <FormattedMessage id="cc.trans.res"/>
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {errorGeneral}
                        </div>
                        <div className="modal-footer border-0">
                            <button type="button" className="btn btn-sm btn-ariatel--primary" data-dismiss="modal"><FormattedMessage id="forms.passResetConfirm.tryAgain.link"></FormattedMessage></button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="error-credit-card-payment" tabIndex="-1" role="dialog" aria-labelledby="error-credit-card-payment-label" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-ariatel--default font-weight-bold" id="error-credit-card-payment-label">
                                <FormattedMessage id="cc.recharge.error"/>
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {errorGeneral}
                        </div>
                        <div className="modal-footer border-0">
                            <button type="button" className="btn btn-sm btn-ariatel--primary" data-dismiss="modal"><FormattedMessage id="forms.passResetConfirm.tryAgain.link"></FormattedMessage></button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="error-form-front-credit-card-payment" tabIndex="-1" role="dialog" aria-labelledby="error-form-front-credit-card-payment-label" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-muted font-weight-bold" id="error-form-front-credit-card-payment-label">
                                <FormattedMessage id="cc.error.list.title"/>:
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ul>
                                {Object.keys(errors).map((key) => {
                                    return (
                                        <li key={key + "-form-error"}>
                                           <b>{fields_labels[key]}:</b>  {errors[key] ? <FormattedMessage id={errors[key]}/> : null}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="modal-footer border-0">
                            <button type="button" className="btn btn-sm btn-outline-ariatel--secondary" data-dismiss="modal"><FormattedMessage id="add.payments.close"/></button>
                        </div>
                    </div>
                </div>
            </div> 
             
        </>
    )
}