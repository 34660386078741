import React, { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import TabContainer from '../tabs/TabContainer'
import TabLink from '../tabs/TabLink'
import { FormattedMessage, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import secureStorage from '../../../tools/SecureStorage'

/* 
   -----------------------------------------------------------------------------------------------
    Funcion que formatea una fecha en minutos a una cadena mas legible
   -----------------------------------------------------------------------------------------------
*/
// eslint-disable-next-line no-extend-native
String.prototype.minsToHHMMSS = function () {
    let mins_num = this
    let hours   = Math.floor(mins_num / 60)
    let minutes = Math.floor((mins_num - ((hours * 3600)) / 60))
    let seconds = Math.floor((mins_num * 60) - (hours * 3600) - (minutes * 60))
    
    if (hours   < 10) {hours   = "0"+hours}
    if (minutes < 10) {minutes = "0"+minutes}
    if (seconds < 10) {seconds = "0"+seconds}
    return hours+" hours, "+minutes+" mins, "+seconds+" seconds"
}

/* 
   -----------------------------------------------------------------------------------------------
    Componente que muestra los quickstats 
   -----------------------------------------------------------------------------------------------
*/
function QuickStat(props) {
    const [stats, setStats] = useState({})
    const [error, setError] = useState(false)
    
    // A penas se monte el componente hacemos la peticion del quickstat
    useEffect(() => {
        const source = axios.CancelToken.source()

        // Hacemos la peticion
        axios.get('/api/v1/get/profile/?p=quickstats_get',
            {
                headers: {
                    "Authorization": `Bearer ${secureStorage.getItem("access")}`
                },
                cancelToken: source.token
            }
        ).then(res => {
            if(res.data.page){
                setStats(res.data.page.quickstats) 
            }else{
                setError(true)
            }
        }).catch(error => {
            // Marcamos que hay un error si lo hay
            setError(true)
        })

        return () => {
            source.cancel("component unmounted")
        }
    }, [])

    // Funcion que formatea los datos segun se key (Añade prefijos y sufijos)
    const formatData = (value, e) => {
        switch(e){
            case 'calls':
                return (Intl.NumberFormat().format(value) + " calls")
            case 'duration':
                return (value.minsToHHMMSS())
            case 'revenue':
                return ("$ " + Intl.NumberFormat().format(parseFloat(value).toFixed(2)))
            case 'self_cost':
                return ("$ " + Intl.NumberFormat().format(parseFloat(value).toFixed(2)))
            case 'profit':
                return ("$ " + Intl.NumberFormat().format(parseFloat(value).toFixed(2)))
            case 'margin':
                return (Intl.NumberFormat().format(value) + " %")
            case 'price':
                return ("$ " + Intl.NumberFormat().format(parseFloat(value).toFixed(2)))
            default:
        }
    }

    return(
        <>
            {/* Menu */}
            <ul className="nav nav-pills nav-fill mb-3" id="stats" role="tablist">
                <TabLink class="active" id="today" href="#today" ariaControls="today" ariaSelected="true">
                    <FormattedMessage id="quickstat.today"/>
                </TabLink>
                <TabLink id="month" href="#month" ariaControls="month" ariaSelected="false">
                    <FormattedMessage id="quickstat.month"/>
                </TabLink>
            </ul>

            {/* Muestra los active calls */}
            {stats.active_calls ? 
                <table className="table mb-0">
                    <tbody>
                        <tr>
                            <td className="text-center">
                                <span className="font-weight-bold">
                                    <FormattedMessage id="active.calls"/>:
                                </span> 
                                <br/>
                                {stats.active_calls.total}
                            </td>
                            <td className="text-center">
                                <span className="font-weight-bold">
                                    <FormattedMessage id="answered.calls"/>:
                                </span>
                                <br/>
                                {stats.active_calls.answered_calls}
                            </td>
                        </tr>
                    </tbody>
                </table>
                : null
            }

            {/* 
                Si no hay errores muestra los tabs comunes
                Si los hay muestra un texto de Error 
            */}
            {!error ? 
                <div className="tab-content" id="stats-content">
                    {/* Quickstats del dia */}
                    <TabContainer class="show active" id="today" ariaLabelledBy="today">
                        <table className="table table-sm table-hover mb-0">
                            <tbody>
                                {stats.today ? 
                                    Object.keys(stats.today).map((e, i) => {
                                        return(
                                            <tr key={"today-"+i}>
                                                <th className="text-capitalize">
                                                    <FormattedMessage id={"quickstat."+e}/>
                                                </th>
                                                <td>{formatData(stats.today[e], e)}</td>
                                            </tr>
                                        )
                                    }) 
                                : null}
                            </tbody>
                        </table>
                    </TabContainer>
                    
                    {/* Quickstats del mes */}
                    <TabContainer id="month" ariaLabelledBy="month">
                        <table className="table table-sm table-hover mb-0">
                            <tbody>
                                {stats.month ? 
                                    Object.keys(stats.month).map((e, i) => {
                                        return(
                                            <tr key={"month-"+i}>
                                                <th className="text-capitalize">
                                                    <FormattedMessage id={"quickstat."+e}/>
                                                </th>
                                                <td>{formatData(stats.month[e], e)}</td>
                                            </tr>
                                        )
                                    }) 
                                : null}
                            </tbody>
                        </table>
                    </TabContainer>
                </div>
            : 
                <div className="text-center quickstat-error">
                    <FontAwesomeIcon className="text-muted mt-4 mb-4" icon="phone-slash" size="4x"/>
                    <h4 className="text-center text-muted">
                        <FormattedMessage id="forms.login.dataError"/>
                    </h4>
                </div>
            }
        </>
    )
}

export default injectIntl(QuickStat)