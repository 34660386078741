import React from 'react';

/* 
   -----------------------------------------------------------------------------------------------
    Componente que se encarga de renderizar las cards del dashboard
   -----------------------------------------------------------------------------------------------
*/
export default function Card(props) {
    return (
        <section className={"h-100 card shadow-sm " + props.className}>
            <div className="card-header bg-purple bb-blue-sm text-white">
                {props.header}
            </div>
            <div className="card-body bb-blue">
                {props.children}
            </div>
        </section>
    )
}

/* 
   -----------------------------------------------------------------------------------------------
    Componente que se encarga de renderizar las cards del dashboard 
    (Estas e alargan hasta quedar simetricas con las cartas de su otra seccion)
   -----------------------------------------------------------------------------------------------
*/
export function CardAdapt(props) {
    return (
        <section className={"card shadow-sm " + props.className}>
            <div className="card-header bg-purple bb-blue-sm text-white">
                {props.header}
            </div>
            <div className="card-body bb-blue">
                {props.children}
            </div>
        </section>
    )
}