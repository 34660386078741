import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'
import {Dropdown} from 'primereact/dropdown'
import Titulo from '../compartidos/tools/Titulo'
import { AuthContext } from '../AuthContext'
import CryptoTools from '../../tools/crypto_tools'
import ButtonLoader from '../compartidos/tools/ButtonLoader'
import secureStorage from '../../tools/SecureStorage'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import ReactGA from 'react-ga'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que muestra detalles de los dispositivos
   -----------------------------------------------------------------------------------------------
*/
function Devices() {
    const data = useContext(AuthContext)
    const intl = useIntl()
    const crypto = new CryptoTools()
    const devices = JSON.parse(
        crypto.desencriptar(data.m2.dispositivos_disponibles)
    ).map(
        (el) => {return({label: `${el[0]} - ${el[1]}`, value: el[0]})}
    ).sort((a, b) => a.value - b.value
    ).sort((a,b) => {
        const uno = a.label.split(" - ")[1]
        const dos = b.label.split(" - ")[1]

        if (uno === "DO NOT EDIT" && dos !== "DO NOT EDIT"){
            return 1
        } else if (uno !== "DO NOT EDIT" && dos === "DO NOT EDIT"){
            return -1
        } else {
            return 0
        }
    })
    const [device, setDevice] = useState('')
    const [loader, setLoader] = useState(false)
    const [details, setDetails] = useState([])
    const [error, setError] = useState(false)
    const [first, setFirst] = useState(null)
    const [rowsNumber, setRowsNumber] = useState(10)

    useEffect(() => {ReactGA.pageview(window.location.pathname);}, [])

    const getUrl = () => {
        let url = "/api/v1/get/profile/?p=device_details_get&device_id="
        if(device === "" || !!device.toString().match(/[\D]/g)){
            url += devices[0]["value"]
            setDevice(devices[0]["value"])
        }else{
            url += device
        }
        return url
    }
    
    // Funcion que hace la peticion para recibir los datos del dispositivo indicado
    const _getDevicesDetails = (e) => {
        e.preventDefault()
        setDetails([])
        setLoader(true)
        setError(false)
        // Si se selecciono un dispositivo
        if(devices.length > 0){
            axios.get(getUrl(),
                {
                    headers: {
                        "Authorization": `Bearer ${secureStorage.getItem("access")}`
                    }
                }
            ).then(res => {
                // Mostramos los detalles del dispositivo
                if(res.data.page){
                    const device = res.data.page.status.devices.device
                    let details = []
                    for(const key in device){
                        if(key !== "codecs"){
                            details.push({title: intl.formatMessage({id: "devices."+key}), value: device[key] || "----"})
                        }else{
                            details.push({title: intl.formatMessage({id: "devices."+key}), value: device[key]["audio_codecs"]})
                        }
                    }
                    setDetails(details) 
                }
                setLoader(false)
            }).catch(error => {
                setLoader(false)
                setError(true)
                setDevice('')
                setDetails([])
            })

        }else{
            setTimeout(()=>{
                setLoader(false)
                setError(true)
                setDetails([])
            }, 1000)
        }
    }


    return(
        <>
            <Titulo>
                <FormattedMessage id="header.menu.devices"/>
            </Titulo>
            <form method="GET" className="mb-3" onSubmit={(e) => _getDevicesDetails(e)}>
                <div className="row align-items-end justify-content-center">

                    <div className="col-7 col-sm-4 col-md-3 col-xl-3">
                        <label htmlFor="devices-drop" className="p-d-block">
                            <FormattedMessage id="devices.choose"/>:
                        </label>
                        {/* Devices dropdown */}
                        <Dropdown value={device} inputId="devices-drop"
                            onChange={e => setDevice(e.value)}
                            optionLabel="label" optionValue="value"
                            options={devices} className="w-100 p-inputtext-sm"
                        />
                    </div>

                    <div className="col-5 col-sm-3 col-md-2">
                        <ButtonLoader
                            loader={loader} className="button-tooltip-loader btn btn-block btn-ariatel--primary btn-sm" 
                            type="submit" style={{minHeight: '35px'}} showError={error}
                            onClick={(e) => _getDevicesDetails(e)} tooltipPlace="top"
                            errorTitle={intl.formatMessage({id:"devices.error.search"})}
                        >
                            <FormattedMessage id="calls.search" />
                        </ButtonLoader> 
                    </div>
                </div>
            </form>
            
            <div style={{minHeight: '30vh'}}>
                {/* Datos del dispositivo para pantallas grandes */}
                <DataTable 
                    value={details} 
                    className="p-datatable-sm p-datatable-striped devices-table"
                    paginator={true}
                    first={first} 
                    rows={rowsNumber}
                    rowsPerPageOptions={[10, 20, 30]} 
                    onPage={(e) => {
                        setFirst(e.first)
                        setRowsNumber(e.rows)
                    }}
                    rowHover={true}
                >
                    <Column 
                        filterPlaceholder={intl.formatMessage({id: 'filter.placeholder'})} 
                        header={intl.formatMessage({id: 'devices.spec'})} 
                        filter={true} className="font-weight-bold" field="title"
                    ></Column>
                    <Column 
                        filterPlaceholder={intl.formatMessage({id: 'filter.placeholder'})} 
                        header={intl.formatMessage({id: 'devices.val'})} 
                        filter={true} field="value"
                    ></Column>
                </DataTable>
            </div>
        </>
    )
}

export default Devices