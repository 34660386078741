import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import 'primeicons/primeicons.css'
import 'primereact/resources/themes/nova/theme.css'
import 'primereact/resources/primereact.css'
import './index.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.min'
import { library } from '@fortawesome/fontawesome-svg-core'
import ScrollRestoration from "./componentes/ScrollRestoration"
// Importamos los iconos 
import { 
  faUserCog, 
  faDoorOpen, 
  faExchangeAlt,
  faUserCircle,
  faSadTear,
  faMobileAlt,
  faGlobe,
  faFileImport,
  faPlusSquare,
  faUpload,
  faLongArrowAltRight,
  faTag,
  faPhoneSlash,
  faVoteYea,
  faCreditCard,
  faMoneyCheck,
  faArrowRight,
  faTrashAlt,
  faUsers,
  faFileInvoiceDollar,
  faPercentage,
  faDonate,
  faPiggyBank,
  faScroll,
  faHistory,
  faAddressBook,
  faHandHoldingUsd,
  faUserTie,
  faUser,
  faClipboardList,
  faTasks,
  faChartLine,
  faInfoCircle,
  faList,
  faQuestionCircle,
  faTable,
  faFileCsv,
  faEllipsisH,
  faSearch,
  faStepBackward,
  faStepForward,
  faFastBackward,
  faFastForward,
  faFilter,
  faEraser,
  faExclamationTriangle,
  faQuestion,
  faCashRegister,
  faCheckCircle,
  faMinusCircle,
  faTimesCircle,
  faCoins
} from '@fortawesome/free-solid-svg-icons'

// Esto nos permite usar los iconos de maneras mas elegantes y legibles
library.add( 
  faUserCog, 
  faDoorOpen, 
  faExchangeAlt,
  faUserCircle,
  faSadTear,
  faMobileAlt,
  faGlobe,
  faFileImport,
  faPlusSquare,
  faUpload,
  faLongArrowAltRight,
  faTag,
  faPhoneSlash,
  faVoteYea,
  faCreditCard,
  faMoneyCheck,
  faArrowRight,
  faTrashAlt,
  faUsers,
  faFileInvoiceDollar,
  faPercentage,
  faDonate,
  faPiggyBank,
  faScroll,
  faHistory,
  faAddressBook,
  faHandHoldingUsd,
  faUserTie,
  faUser,
  faClipboardList,
  faTasks,
  faChartLine,
  faInfoCircle,
  faList,
  faQuestionCircle,
  faTable,
  faFileCsv,
  faEllipsisH,
  faSearch,
  faStepBackward,
  faStepForward,
  faFastBackward,
  faFastForward,
  faFilter,
  faEraser,
  faExclamationTriangle,
  faQuestion,
  faCashRegister,
  faCheckCircle,
  faMinusCircle,
  faTimesCircle,
  faCoins )

ReactDOM.render((
  <BrowserRouter>
    <ScrollRestoration />
    <App />
  </BrowserRouter>
  ), document.getElementById('root')
)