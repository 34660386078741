import React from 'react'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import Header from './formulario/Header'
import LanguageSwitch from '../translation/LanguageSwitch'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que atrapa errores en tiempo de ejecucion de la UI y luego lo redirige
    a otra interfaz para no desmontar todo
   -----------------------------------------------------------------------------------------------
*/
class ErrorCatcher extends React.Component{
    constructor(props){
        super(props)
        // No hay error
        this.state = { hasError: false }
        this.backToLogIn = this.backToLogIn.bind(this)
    }

    // Catcher que indican que hay error modificando el estado
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
      }
    // Catcher que indican que hay error modificando el estado
    componentDidCatch(error, info){
        this.setState({hasError: true})
    }

    // Funcion para boton que te devuelve al login 
    backToLogIn(){
        this.props.history.push("/sign-in/")
        window.location.reload()
    }

    render(){
        //Si hay un error muestra Interfaz de "Hubo un error"
        if(this.state.hasError){
        return (
            <div>
                <Header/>
                <main className="text-center pt-5 px-2"> 
                    <div className="card d-inline-block">
                        <div className="card-body">
                            <h3>
                                <FormattedMessage id="errorcatcher.title"/>
                            </h3>
                            <hr/>
                            <p className="mb-5">
                                <FormattedMessage id="errorcatcher.text"/>
                            </p>
                            <button type="button" className="btn btn-ariatel--primary" onClick={this.backToLogIn}>
                                <FormattedMessage id="forms.passReset.loginRedirect"/>
                            </button>
                        </div>
                    </div>
                    

                    
                </main>
                <LanguageSwitch margins="my-3" />
            </div>
        )
        }
        // Si no hay error renderiza los hijos
        return this.props.children
    }
}

export default withRouter(ErrorCatcher)