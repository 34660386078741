import React from 'react'
import {Switch, NavLink, Route } from 'react-router-dom'
import PrivacyPolicy from './PrivacyPolicy'
import TermsOfUse from './TermsOfUse'
import TermsAndConditions from './TermsAndConditions'
import { FormattedMessage } from 'react-intl'
import ReactGA from 'react-ga'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que renderiza el menu en el que se encuentran todos los links hacia
    el contenido legal y parecidos 
   -----------------------------------------------------------------------------------------------
*/
class Policy extends React.Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }
    
    render() {
        return (
            <>
                <nav className="nav nav-tabs nav-policy mt-5 nav-fill">
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/u/policy/terms-and-conditions/">
                            <FormattedMessage id="footer.policyLink.termsAndConditions"/>
                        </NavLink>
                    </li>
                    
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/u/policy/privacy-policy/">
                            <FormattedMessage id="footer.policyLink.privacy"/>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink className="nav-link" to="/u/policy/terms-of-use/">
                            <FormattedMessage id="footer.policyLink.termsUse"/>
                        </NavLink>
                    </li>
                </nav>

                <div className="bg-white p-3 rounded-bottom border border-top-0">
                    <Switch>
                        <Route path="/u/policy/terms-and-conditions/">
                            <TermsAndConditions/>
                        </Route>

                        <Route path="/u/policy/privacy-policy/">
                            <PrivacyPolicy/>
                        </Route>

                        <Route path="/u/policy/terms-of-use/">
                            <TermsOfUse/>
                        </Route>
                    </Switch>
                </div>
            </>
        )
    }
}

export default Policy