import React, { useState, useEffect } from 'react'
import { InputText } from 'primereact/inputtext'
import { Menu, Dropdown as AntdDrop } from 'antd';
import { Chip } from 'primereact/chip'
import { FormattedMessage } from 'react-intl';

export default function FilterInput(props) {

    const [Chips, setChips] = useState([]);

    const handleFilterDelete = () => {
        setChips([])
        props.setState({...props.state, "current_filter": ""})
    }

    const handleFilterChange = (e) => {
        props.setState({...props.state, "current_filter": e.key})
        setChips([e.key])
    }

    useEffect(() => {
        if (props.state.current_filter !== "" && props.state.current_filter !== "iexact"){
            setChips([props.state.current_filter])
        }
        // eslint-disable-next-line
    }, [])

    const subf = {
        "gt": ">",
        "gte": ">=",
        "lt": "<",
        "lte": "<=",
        "icontains": "...%...",
        "istartswith": "%...",
        "iendswith": "...%",
    }
   

    const menu = (
        <Menu onClick={handleFilterChange}>
            {props.filters !== undefined ? 
                <>
                    {props.filters.includes("gt") ? <Menu.Item key={"gt"}><span className="font-weight-bolder mr-2 d-inline-block text-center" style={{width: "35px"}}>&gt;</span><FormattedMessage id="gt"/></Menu.Item> : null}
                    {props.filters.includes("gte") ? <Menu.Item key={"gte"}><span className="font-weight-bolder mr-2 d-inline-block text-center" style={{width: "35px"}}>&gt;=</span><FormattedMessage id="gte"/></Menu.Item> : null}
                    {props.filters.includes("lt") ? <Menu.Item key={"lt"}><span className="font-weight-bolder mr-2 d-inline-block text-center" style={{width: "35px"}}>&lt;</span><FormattedMessage id="lt"/></Menu.Item> : null}
                    {props.filters.includes("lte") ? <Menu.Item key={"lte"}><span className="font-weight-bolder mr-2 d-inline-block text-center" style={{width: "35px"}}>&lt;=</span><FormattedMessage id="lte"/></Menu.Item> : null}
                    {props.filters.includes("icontains") ? <Menu.Item key={"icontains"}><span className="font-weight-bolder mr-2 d-inline-block text-center" style={{width: "35px"}}>...%...</span><FormattedMessage id="icontains"/></Menu.Item> : null}
                    {props.filters.includes("istartswith") ? <Menu.Item key={"istartswith"}><span className="font-weight-bolder mr-2 d-inline-block text-center" style={{width: "35px"}}>%...</span><FormattedMessage id="istartswith"/></Menu.Item> : null}
                    {props.filters.includes("iendswith") ? <Menu.Item key={"iendswith"}><span className="font-weight-bolder mr-2 d-inline-block text-center" style={{width: "35px"}}>...%</span><FormattedMessage id="iendswith"/></Menu.Item> : null}
                </>
            :
                <>
                    <Menu.Item key={"gt"}><FormattedMessage id="gt"/></Menu.Item>
                    <Menu.Item key={"gte"}><FormattedMessage id="gte"/></Menu.Item>
                    <Menu.Item key={"lt"}><FormattedMessage id="lt"/></Menu.Item>
                    <Menu.Item key={"lte"}><FormattedMessage id="lte"/></Menu.Item>
                    <Menu.Item key={"icontains"}><FormattedMessage id="icontains"/></Menu.Item>
                    <Menu.Item key={"istartswith"}><FormattedMessage id="istartswith"/></Menu.Item>
                    <Menu.Item key={"iendswith"}><FormattedMessage id="iendswith"/></Menu.Item>
                </>
            }
            
        </Menu>
    )

    return (
        <>
            <div className={`form-group mb-2 ${props.className || ""}`}>
                <label style={{fontSize: "13px"}} className="w-100">
                    {props.label}: {props.state.current_filter === "" ? 
                        null : Chips.map((e) => <Chip label={<><FormattedMessage id={props.state.current_filter}/> ({subf[props.state.current_filter]})</>} key="chip-filter" className="btn-ariatel--primary chip-filter" onRemove={handleFilterDelete} removable />)
                    }
                </label>

                <AntdDrop overlay={menu} disabled={props.disabled}>
                    <InputText 
                        onChange={e => props.setState({...props.state, value: e.target.value})}
                        value={props.state.value}
                        disabled={props.disabled}
                        style={{fontSize: ".7rem"}} 
                        className="w-100"
                        keyfilter={props.keyfilter || ""}
                    />
                </AntdDrop>
            </div>
        </>
    )
}