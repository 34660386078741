import React, { useContext, useEffect } from 'react'
import Titulo from '../tools/Titulo'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AuthContext } from '../../AuthContext'
import ReactGA from 'react-ga'
import LogoBancolombia from '../../decoraciones/bancos/Logo_Bancolombia.png'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que renderiza una pagina previa a los clientes para escoger el metodo de Pago
   -----------------------------------------------------------------------------------------------
*/
export default function PaymentCreate(){
    const data = useContext(AuthContext)
    
    useEffect(() => {ReactGA.pageview(window.location.pathname);}, [])

    return(
        <>
            <Titulo className="d-flex justify-content-center justify-content-lg-between align-items-end">
                <FormattedMessage id="add.payments.choose.method"/>
                {data.m2.default_curr === data.m2.currency ?
                    null
                :
                    <small className="font-italic d-none d-lg-block" style={{fontSize: "1rem"}}><FormattedMessage id="exchange.msg"/> {data.m2.default_curr}: <b>{Intl.NumberFormat().format(data.m2.exchange_rate)} {data.m2.currency}</b></small>
                }
            </Titulo>


            <section className="row justify-content-center mb-3">

                {/* Transferencia bancaria */}
                <div className="col-12 col-md-3">
                    <Link to="/u/pay/by/transfer/" className="card btn my-3 btn-ariatel--primary text-white text-center 
                            d-flex flex-row flex-md-column justify-content-between justify-content-md-center align-items-center py-3 py-md-5">
                        <FontAwesomeIcon icon="vote-yea" size="2x" className="d-none d-md-block mb-md-3"/>
                        <h3 className="h5 font-weight-normal d-none d-md-block mb-0 text-white">
                            <FormattedMessage id="add.payments.transfer"/>
                        </h3>
                        <div className="d-flex flex-row justify-content-md-center align-items-center d-md-none">
                            <FontAwesomeIcon icon="vote-yea" size="2x" className="mr-3 mr-md-0 mb-md-3"/>
                            <h3 className="h5 font-weight-normal d-inline mb-0 text-white">
                                <FormattedMessage id="add.payments.transfer"/>
                            </h3>
                        </div>
                        <FontAwesomeIcon icon="arrow-right" size="2x" className="d-md-none"/>
                    </Link>
                </div>

                {/* Tarjeta de credito */}
                {/* PayU */}
                {/* <div className="col-12 col-md-3">
                    <Link to="/u/pay/by/payuu/" className="card btn my-3 btn-ariatel--primary text-white text-center 
                            d-flex flex-row flex-md-column justify-content-between justify-content-md-center align-items-center py-3 py-md-5">
                            <FontAwesomeIcon icon="money-check" size="2x" className="d-none d-md-block mb-md-3"/>
                            <h3 className="h5 font-weight-normal d-none d-md-block mb-0 text-white">
                            PayU WebCheckOut
                            </h3>
                            <div className="d-flex flex-row justify-content-md-center align-items-center d-md-none">
                            <FontAwesomeIcon icon="money-check" size="2x" className="mr-3 mr-md-0 mb-md-3"/>
                            <h3 className="h5 font-weight-normal d-inline mb-0 text-white">
                            PayU WebCheckOut
                            </h3>
                        </div>
                        <FontAwesomeIcon icon="arrow-right" size="2x" className="d-md-none"/>
                    </Link>
                </div> */}

                {/* Credit Card */}
                <div className="col-12 col-md-3">
                    <Link to="/u/pay/by/credit-card/" className="card btn my-3 btn-ariatel--primary text-white text-center 
                            d-flex flex-row flex-md-column justify-content-between justify-content-md-center align-items-center py-3 py-md-5">
                        <FontAwesomeIcon icon="credit-card" size="2x" className="d-none d-md-block mb-md-3"/>
                        <h3 className="h5 font-weight-normal d-none d-md-block mb-0 text-white">
                            Credit Card 
                        </h3>
                        <div className="d-flex flex-row justify-content-md-center align-items-center d-md-none">
                            <FontAwesomeIcon icon="credit-card" size="2x" className="mr-3 mr-md-0 mb-md-3"/>
                            <h3 className="h5 font-weight-normal d-inline mb-0 text-white">
                                Credit Card 
                            </h3>
                        </div>
                        <FontAwesomeIcon icon="arrow-right" size="2x" className="d-md-none"/>
                    </Link>
                </div>


                {/* PSE */}
                {data.m2.currency === "COP" ? 
                    <div className="col-12 col-md-3">
                        <Link to="/u/pay/by/pse/" className="card btn my-3 btn-ariatel--primary text-white text-center 
                                d-flex flex-row flex-md-column justify-content-between justify-content-md-center align-items-center py-3 py-md-5">
                            <FontAwesomeIcon icon="money-check" size="2x" className="d-none d-md-block mb-md-3"/>
                            <h3 className="h5 font-weight-normal d-none d-md-block mb-0 text-white">
                                PSE
                            </h3>
                            <div className="d-flex flex-row justify-content-md-center align-items-center d-md-none">
                                <FontAwesomeIcon icon="money-check" size="2x" className="mr-3 mr-md-0 mb-md-3"/>
                                <h3 className="h5 font-weight-normal d-inline mb-0 text-white">
                                    PSE
                                </h3>
                            </div>
                            <FontAwesomeIcon icon="arrow-right" size="2x" className="d-md-none"/>
                        </Link>
                    </div>
                :
                null                
            }


                {/* efectivo */}
                {data.m2.currency === "COP" ? 
                    <div className="col-12 col-md-3">
                        <Link to="/u/pay/by/bancolombia/" className="card btn my-3 btn-ariatel--primary text-white text-center 
                                d-flex flex-row flex-md-column justify-content-between justify-content-md-center align-items-center py-3 py-md-5">
                            <img className="d-none d-md-block mb-md-3" alt={"Bancolombia"} style={{height: 32}} src={LogoBancolombia}/>
                            <h3 className="h5 font-weight-normal d-none d-md-block mb-0 text-white">
                                Bancolombia
                            </h3>
                            <div className="d-flex flex-row justify-content-md-center align-items-center d-md-none">
                                <img className="mr-3 mr-md-0 mb-md-3" alt={"Bancolombia"} style={{height: 32}} src={LogoBancolombia}/>
                                <h3 className="h5 font-weight-normal d-inline mb-0 text-white">
                                    Bancolombia
                                </h3>
                            </div>
                            <FontAwesomeIcon icon="arrow-right" size="2x" className="d-md-none"/>
                        </Link>
                    </div>
                :
                    null                
                }
            </section>
            {data.m2.default_curr === data.m2.currency ?
                null
            :
                <small className="text-center text-muted pt-2 border-top mt-2 font-italic d-block d-lg-none" style={{fontSize: "1rem"}}><FormattedMessage id="exchange.msg"/> {data.m2.default_curr}: <b>{Intl.NumberFormat().format(data.m2.exchange_rate)} {data.m2.currency}</b></small>
            }
        </>
    )
}