import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import Titulo from '../compartidos/tools/Titulo'
import {InputNumber} from 'primereact/inputnumber'
import ButtonLoader from '../compartidos/tools/ButtonLoader'
import secureStorage from '../../tools/SecureStorage'
import UsersInput from './UsersInput'
import ReactGA from 'react-ga'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que actualiza el balance de un usuario dado su M2 user ID y la cantidad
    puedes sumar o restar, ejemplo: 1,00 suma 1 y -1,00 resta 1 al balance
   -----------------------------------------------------------------------------------------------
*/
function BalanceUpdate() {
    const [balance, setBalance] = useState(0.0)
    const [loader, setLoader] = useState(false) 
    const [response, setResponse] = useState({})
    const [user, setUser] = useState("")

    useEffect(() => {ReactGA.pageview(window.location.pathname);}, [])

    // Funcion que hace la peticion para actualizar el balance
    const handleSubmit = (e) => {
        e.preventDefault()
        // Borramos el callback mostrado en la operacion anterior
        setResponse({})
        setLoader(true)

        // Chequeamos que los campos no esten vacios
        if(balance !== 0 && user !== ''){
            // eslint-disable-next-line
            if (!user.match(/[^\w\s\-_,ñ\[\]]/g)) {                
                // URL con los datos
                let url = `/api/v1/get/profile/?p=user_balance_update&u=${user.trim()}&balance=${balance}`
                // Hacemos la peticion
                axios.get(url,
                    {
                        headers: {
                            "Authorization": `Bearer ${secureStorage.getItem("access")}`
                        }
                    }
                ).then(res => {
                    // Si hay un error
                    if(res.data.page.error){
                        setResponse({respuesta: "error", ...res.data.page})
                    // Si todo marcha bien
                    }else{
                        setResponse({respuesta: "ok", ...res.data.page.page})
                    }
                    setLoader(false)
                }).catch(error => {
                    setLoader(false)
                    // Mostramos un error si ocurrio un problema en el servidor
                    // Error en el boton de "Actualizar"
                    setResponse({respuesta: "error", error: "User was not found"})
                })
            } else {
                setLoader(false)
                setResponse({respuesta: "error", error: "username.incorrect.format"})
            }
        }else{
            setLoader(false)
            // Mostramos un error si algun dato estaba vacio
            // Error en la parte de los campos del formulario
            setResponse({respuesta: "error", error: "update.balance.error.nothing"})
        }
    }

    return(
        <>
            <Titulo>
                <FormattedMessage id="header.menu.update.balance"/>
            </Titulo>

            <section className="container-fluid px-0">
                <div className="row">
                    <div className="col-12 col-md-5 col-xl-3 mb-3">
                        <div className="card shadow-sm">
                            <div className="card-body pt-3">
                                <form method="GET" onSubmit={(e) => handleSubmit(e)}>
                                    <div className="form-row">
                                        <div className="col input-group mb-3">
                                            <label htmlFor="balance-update-u" className="p-d-block">
                                                <FormattedMessage id="details.placeholder.username"/>:
                                            </label>
                                            <UsersInput value={user} onChange={e => setUser(e.value)}/>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col input-group mb-4">
                                            <label htmlFor="balance-update-nb" className="p-d-block">
                                                <FormattedMessage id="update.balance.new.balance"/>:
                                            </label>
                                            <InputNumber value={balance} className="w-100" 
                                                onChange={e => (e.value === null) ? setBalance(0) : setBalance(e.value)} 
                                                prefix=" " mode="decimal" minFractionDigits={1} maxFractionDigits={4}
                                                id="balance-update-nb"
                                            />
                                            <small className="text-muted">
                                                <FormattedMessage id="update.balance.h6"/>
                                            </small>
                                        </div>
                                    </div>


                                    <ButtonLoader
                                        loader={loader} className="button-tooltip-loader btn btn-block btn-ariatel--primary btn-sm" 
                                        type="submit" style={{minHeight: '35px'}} 
                                        onClick={(e) => handleSubmit(e)} tooltipPlace="top"
                                    >
                                        <FormattedMessage id="update"/>
                                    </ButtonLoader> 
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="col mb-3">
                        {/* Lugar donde saldra el callback cuando llegue la respuesta del servidor */}
                        <div style={{minHeight: '30vh'}}>
                            {response.respuesta ? 
                            <Checker respuesta={response.respuesta} error={response.error} success={response}/>
                            : null}
                        </div>
                    </div>
                </div>
            </section>

            
        </>
    )
}

/* 
   -----------------------------------------------------------------------------------------------
    Componente que muestra un formato segun la data que le pasemos por sus props,
    la usamos para asignar varios formatos a las respuestas que nos de el servidor
   -----------------------------------------------------------------------------------------------
*/
const Checker = (props) => {
    return (
        <>
            {props.respuesta === "error" ?
                <>
                    <div className="card shadow-sm">
                        <div className="card-body">
                            <h2 className="text-secondary border-bottom pb-3 text-center mb-3 font-weight-normal">
                                Error
                            </h2>

                            <h4 className="text-center text-muted font-weight-light">
                                <FormattedMessage id={props.error}/>
                            </h4>
                        </div>
                    </div>
                
                </> :
                <div className="card shadow-sm">
                    <div className="card-body">
                        <h2 className="text-secondary border-bottom pb-3 text-center mb-3 font-weight-normal">
                            <FormattedMessage id={props.success.status}/>
                        </h2>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="border-top-0" style={{borderBottomWidth: "1px"}} scope="col">ID</th>
                                    <th className="border-top-0" style={{borderBottomWidth: "1px"}} scope="col">
                                        <FormattedMessage id="profile.username"/>
                                    </th>
                                    <th className="border-top-0" style={{borderBottomWidth: "1px"}} scope="col">
                                        <FormattedMessage id="update.balance.balance.now"/>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">{props.success.user.id}</th>
                                    <td>{props.success.user.username}</td>
                                    <td>{props.success.user.balance}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </>
    )
}

export default BalanceUpdate