import React from 'react'
import { FormattedMessage } from 'react-intl'

/* 
   -------------------
   Spinner loader
   -------------------
*/
export default function Spinner(props){
    return (
        <div className={"d-flex align-items-center justify-content-center " + props.altura}>
            <div className="spinner-priv spinner-border text-primary" role="status">
                <span className="sr-only">
                    <FormattedMessage id="privado"/>
                </span>
            </div>
        </div>
    )
}