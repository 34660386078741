/* 
   -----------------------------------------------------------------------------------------------
    Clase donde se almacenan los metodos de validacion de los formulario
   -----------------------------------------------------------------------------------------------
*/
class Validador{
    constructor(valor){
        this.valor = valor
        this.resultado = []
    }

    checkVacio(msg) {
        const valor = this.valor
        const reg = /^\s+$/g
        if( (!(valor.length > 0)) || (reg.test(valor)) ) {
            this.resultado.push(msg)
        }
        return this
    }

    checkSoloTexto(msg) {
        const valor = this.valor
        if(/([\d[\]{}\\@*+?¡=)(/&%$#"!|¿<>'])+/.test(valor)){
            this.resultado.push(msg)
        }
        return this
    }

    checkEmail(msg) {
        const valor = this.valor
        const reg = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
        if(!(reg.test(valor)) && (valor.length > 0)){
            this.resultado.push(msg)
        }
        return this
    }
    
    checkTel(msg) {
        const valor = this.valor
        const reg = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/g
        if(!(reg.test(valor))  && (valor.length > 0)){
            this.resultado.push(msg)
        }
        return this
    }
}

export default Validador