import React from 'react'
import {Link} from 'react-router-dom'
import Logo from '../../decoraciones/logo-ariatel/logo-ariatel.png'
import Logo_1 from '../../decoraciones/logo-ariatel/logo-ariatel@1x.png'
import Logo_1_5 from '../../decoraciones/logo-ariatel/logo-ariatel@1.5x.png'
import Logo_2 from '../../decoraciones/logo-ariatel/logo-ariatel@2x.png'
import Logo_3 from '../../decoraciones/logo-ariatel/logo-ariatel@3x.png'
import { FormattedMessage } from 'react-intl'
import $ from 'jquery'

/* 
   -----------------------------------------------------------------------------------------------
    Componente que se encarga de renderizar el footer en cada pagina interna
   -----------------------------------------------------------------------------------------------
*/
class Footer extends React.Component{
    componentDidMount(){
        $("#container-of-client").bind('resize', function(){
            alert( 'Height changed to' + $(this).height() );
        });
    }

    render(){
        return (
            <footer style={{backgroundColor: '#e6e6e6'}} className="text-center container-fluid pt-3 pb-sm-4 mt-4" id="inside-footer">
                {/* Logo de ariatel */}
                <img className="my-3" style={{maxWidth: '45%'}} src={Logo} alt="logo de ariatel"
                    srcSet={
                    `${Logo_1} 1x, ${Logo_1_5} 1.5x, ${Logo_2} 2x, ${Logo_3} 3x`
                }/>

                {/* Links para visualizar cuestiones legales */}
                <ul className="nav justify-content-center">
                    <li className="nav-item">
                        <Link className="nav-link text-ariatel--primary" to="/u/policy/terms-and-conditions/">
                            <FormattedMessage id="footer.policyLink.termsAndConditions"/>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link text-ariatel--primary" to="/u/policy/privacy-policy/">
                            <FormattedMessage id="footer.policyLink.privacy"/>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link text-ariatel--primary" to="/u/policy/terms-of-use/">
                            <FormattedMessage id="footer.policyLink.termsUse"/>
                        </Link>
                    </li>
                </ul>

                <hr className="mb-2 mx-2"/>

                {/* Texto de Copyright */}
                <small className="text-muted">
                    <FormattedMessage id="footer.copyright"/>
                </small>
            </footer>
        )
    }
}

export default Footer