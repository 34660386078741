import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Sidebar } from 'primereact/sidebar'
import { FooterNavSidebarButton } from '../compartidos/tools/HeaderLinks'
import $ from 'jquery'

/* 
   -----------------------------------------------------------------------------------------------
    Componente con el contenido de la barra de navegacion inferior mostrada en moviles
   -----------------------------------------------------------------------------------------------
*/
export default function FooterNavAdmin(props) {
    const [visible, setVisible] = useState(false)
    const [visible2, setVisible2] = useState(false)

    useEffect(() => {
        $(document).on("click", function(e){
            if(e.target !== $(".sidebar-admin")){
                setVisible(false)
                setVisible2(false)
            }
        })
    }, [])

    return(
        <>
            <nav className="navbar fixed-bottom shadow-lg border-top navbar-light bg-white p-0 d-sm-none footer-dashboard-nav">
                <div className="navbar-expand align-self-stretch w-100">
                    <ul className="navbar-nav nav-fill">
                        <FooterNavSidebarButton msgId="user.pages" icon="user" onClick={() => setVisible(true)}/>
                        <FooterNavSidebarButton msgId="admin.pages" icon="user-tie" onClick={() => setVisible2(true)}/>
                    </ul>
                </div>
            </nav>
            
            <Sidebar blockScroll visible={visible2} baseZIndex={1000000} onHide={() => setVisible2(false)} position="left" className="overflow-auto sidebar-admin">
                <h6 className="text-muted mb-0"><FormattedMessage id="admin.pages"/></h6>
                <hr className="my-2"/>
                {props.admin}
            </Sidebar>
            <Sidebar blockScroll visible={visible} baseZIndex={1000000} onHide={() => setVisible(false)} position="left" className="overflow-auto sidebar-admin">
                <h6 className="text-muted mb-0"><FormattedMessage id="user.pages"/></h6>
                <hr className="my-2"/>
                {props.user}
            </Sidebar>
        </>
    )
}