import React, { useEffect } from "react"
import AppInicio from "./componentes/formulario/SignIn.js"
// import AppRegistro from "./componentes/formulario/SignUp.js"
// import PassReset from "./componentes/formulario/PassReset.js"
// import PassResetConfirm from "./componentes/formulario/PassResetConfirm.js"
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom"
import { IntlProviderWrapper } from "./translation/IntlProviderWrapper.js"
import ErrorCatcher from "./componentes/ErrorCatcher.js"
// import VerifyEmailRedirect from "./componentes/VerifyEmailRedirection.js"
// import VerifyEmailLobby from "./componentes/VerifyEmailLobby.js"
// import ResendMailConf from "./componentes/formulario/ResendMailConf.js"
import DataLobby from "./componentes/DataLobby.js"
import { AuthContext } from "./componentes/AuthContext.js"
import SecureZone from "./componentes/SecureZone.js"
import Surroundings404 from "./componentes/404/Surroundings404.js"
import AppInicioDjangoAdmin from "./componentes/formulario/SignInAdminDjango.js"
import ReactGA from 'react-ga'

export default function App() {

  useEffect(() => {
    ReactGA.initialize("UA-196180825-1");
  }, []);

  return (
    // Añade la I18N al sitio en el front
    <IntlProviderWrapper>
      {/* Ataja errores en tiempo de ejecucion de la Interfaz */}
      <ErrorCatcher>
        {/* Switch principal del sitio web */}
        <AuthContext.Provider>
          <Switch>
            <Route exact path="/">
              <Redirect to="/sign-in/" />
            </Route>
            <Route exact path="/sign-in/">
              <AppInicio/>
            </Route>
            <Route exact path="/sign-in/dj/admin/">
              <AppInicioDjangoAdmin/>
            </Route>
            {/* <Route exact path="/registration/">
              <AppRegistro/>
            </Route> */}
            <Route path="/u/">
              <DataLobby>
                <SecureZone/>
              </DataLobby>
            </Route>
            {/* <Route exact path="/password-reset/">
              <PassReset/>
            </Route>
            <Route exact path="/accounts/reset/:uid/:tk/">
              <PassResetConfirm/>
            </Route> */}
            <Route path="/404/">
              <Surroundings404/>
            </Route>
            <Route path="*">
              <Redirect to="/404/"/>
            </Route>
            {/* <Route exact path="/confirm-email/send-again/">
              <ResendMailConf/>
            </Route>
            <Route exact path="/confirm-email/:email/">
              <VerifyEmailLobby/>
            </Route>
            <Route exact path="/verify-email/:key/">
              <VerifyEmailRedirect/>
            </Route> */}
          </Switch>
        </AuthContext.Provider>
      </ErrorCatcher>
    </IntlProviderWrapper>
  )
}
