import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import Titulo from '../compartidos/tools/Titulo'
import {InputText} from 'primereact/inputtext'
import { FormattedMessage } from 'react-intl'
import ButtonLoader from '../compartidos/tools/ButtonLoader'
import { AuthContext } from '../AuthContext'
import CryptoTools from '../../tools/crypto_tools'
import { Dropdown } from 'primereact/dropdown'
import secureStorage from '../../tools/SecureStorage'
import ReactGA from 'react-ga'

/* 
   -----------------------------------------------------------------------------------------------
    Este componente permite cambiar el nombre de una tarifa existente dado su ID y
    el nuevo nombre para la tarifa
   -----------------------------------------------------------------------------------------------
*/
export default function TariffsUpdate(){
    const u_data = useContext(AuthContext)
    const [tariffID, setTariffID] = useState(null)
    const [tariffName, setTariffName] = useState('')
    const [loader, setLoader] = useState(false) 
    const [callback, setCallback] = useState({})
    const crypto = new CryptoTools()
    const tarifas = JSON.parse(crypto.desencriptar(u_data.m2.tarifas_disponibles))

    useEffect(() => {ReactGA.pageview(window.location.pathname);}, [])

    // Funcion que hace la peticion para actualizar el balance
    const handleSubmit = (e) => {
        e.preventDefault()
        // Borramos el callback mostrado en la operacion anterior
        setCallback({})
        setLoader(true)

        // Chequeamos que los campos no esten vacios y tengo un formato valido
        // eslint-disable-next-line
        if(tariffName !== '' && tariffID !== '' && !tariffName.match(/[^\w\s\-_,ñ\[\]]/g) && !isNaN(tariffID)){
            // URL con los datos
            let url = `/api/v1/get/profile/?p=tariff_wholesale_update&id=${tariffID}&name=${tariffName}`
            // Hacemos la peticion
            axios.get(url,
                {
                    headers: {
                        "Authorization": `Bearer ${secureStorage.getItem("access")}`
                    }
                }
            ).then(res => {
                const data = res.data
                // Si hay un error
                if('status' in data){
                    setCallback({respuesta: "error", ...data.status})
                }else if('page' in data){
                    // Si hay un error
                    if('error' in data.page){
                        setCallback({respuesta: "error", ...data.page})
                    // Si la respuesta es satisfactoria
                    }else{
                        setCallback({tariffID: tariffID, tariffName: tariffName, respuesta: "ok", ...data.page})
                    }
                // Si hay un error
                }else if('response' in data){
                    setCallback({respuesta: "error", ...data.response})
                }
                setLoader(false)
            }).catch(error => {
                setLoader(false)
                // Mostramos un error si ocurrio un problema del lado del servidor
                setCallback({respuesta: "error", error: "tariff.update.Incorrect hash"})
            })
        }else{
            // Mostramos un error si los datos en el formulario no son validos
            if(tariffName === '' || !!tariffName.match(/[^\w\s]/g)) setCallback({respuesta: "error", error: "Nombre de usuario ingresado incorrecto"})
            if(tariffID === null || isNaN(tariffID)) setCallback({respuesta: "error", error: "tariff.update.wrongid"})
            setLoader(false)
        }
    }
   
    return(
        <>
            <Titulo>
                <FormattedMessage id="header.menu.update.tariff"/>
            </Titulo>


            <section className="container-fluid px-0">
                <div className="row">
                    <div className="col-12 col-md-5 col-xl-3 mb-3">
                        <div className="card shadow-sm">
                            <div className="card-body pt-3">
                                <form method="GET" onSubmit={(e) => handleSubmit(e)}>
                                    <div className="form-row">
                                        <div className="col input-group mb-3">
                                            <label htmlFor="devices-drop" className="p-d-block">
                                                <FormattedMessage id="rates.tariffID.type"/>:
                                                {/* <FormattedMessage id="details.placeholder.username"/>: */}
                                            </label>
                                            <Dropdown value={tariffID} optionLabel="name" 
                                                optionValue="id" options={tarifas} className="w-100"
                                                placeholder="Escoge una"
                                                onChange={e => setTariffID(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col input-group mb-4">
                                            <label htmlFor="devices-drop" className="p-d-block">
                                                Nuevo nombre de la tarifa:
                                            </label>
                                            <InputText value={tariffName} className="w-100" 
                                                onChange={e => setTariffName(e.target.value)} 
                                            />
                                        </div>
                                    </div>


                                    <ButtonLoader
                                        loader={loader} className="button-tooltip-loader btn btn-block btn-ariatel--primary btn-sm" 
                                        type="submit" style={{minHeight: '35px'}} 
                                        onClick={(e) => handleSubmit(e)}
                                    >
                                        <FormattedMessage id="update"/>
                                    </ButtonLoader>
                                </form>
                                <hr className="mb-1"/>
                                <small className="font-italic text-muted d-block" style={{lineHeight: 1.2}}>
                                    <FormattedMessage id="reload.tariffs"/>
                                </small> 
                            </div>
                        </div>
                    </div>
                    <div className="col mb-3">
                        {/* Lugar donde saldra el callback cuando llegue la respuesta del servidor */}
                        <div style={{minHeight: '30vh'}}>
                            {callback.respuesta ? 
                            <Checker respuesta={callback.respuesta} 
                                error={callback.error} 
                                success={callback}
                                tariffID={callback.tariffID}
                                tariffName={callback.tariffName}/>
                            : null}
                        </div>
                    </div>
                </div>
            </section>

        </> 
    )
}

/* 
   -----------------------------------------------------------------------------------------------
    Componente que muestra un formato segun la data que le pasemos por sus props,
    la usamos para asignar varios formatos a las respuestas que nos de el servidor
   -----------------------------------------------------------------------------------------------
*/
const Checker = (props) => {
    return (
        <>
            {props.respuesta === "error" ?
                <div className="card shadow-sm">
                    <div className="card-body">
                        <h2 className="text-secondary border-bottom pb-3 text-center mb-3 font-weight-normal">
                            Error
                        </h2>

                        <h4 className="text-center text-muted font-weight-light">
                            <FormattedMessage id={
                                props.error.replace(/"/g, "") === "Incorrect hash" ?
                                "tariff.update." + props.error.replace(/"/g, "") :
                                props.error.replace(/"/g, "")
                            }/>
                        </h4>
                    </div>
                </div> : 
                <div className="card shadow-sm">
                    <div className="card-body">
                        <h5 className="text-secondary border-bottom pb-3 text-center mb-3 font-weight-normal">
                            <FormattedMessage id="update.tariff.success"/>
                        </h5>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="border-top-0" style={{borderBottomWidth: "1px"}} scope="col">
                                        <FormattedMessage id="rates.tariffID"/>
                                    </th>
                                    <th className="border-top-0" style={{borderBottomWidth: "1px"}} scope="col">
                                        <FormattedMessage id="update.tariff.current.name"/>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">{props.tariffID}</th>
                                    <td>{props.tariffName}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </>
    )
}